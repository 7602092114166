import { BehaviorSubject } from "rxjs";
import initialStates from "../InitialStates/customerService.initialStates";

class CSInteractions {
  csInteractionsSubject;
  constructor() {
    this.csInteractionsSubject = new BehaviorSubject(
      initialStates.interactions
    );
  }

  get get() {
    return this.csInteractionsSubject.asObservable();
  }

  setFilter(filter) {
    this.csInteractionsSubject.next({
      ...this.csInteractionsSubject.value,
      filter,
    });
  }

  setRowCount(rowCount) {
    this.csInteractionsSubject.next({
      ...this.csInteractionsSubject.value,
      rowCount,
    });
  }
  setDateType(dateType) {
    this.csInteractionsSubject.next({
      ...this.csInteractionsSubject.value,
      dateType,
    });
  }
  setStartDate(startDate) {
    this.csInteractionsSubject.next({
      ...this.csInteractionsSubject.value,
      startDate,
    });
  }
  setEndDate(endDate) {
    this.csInteractionsSubject.next({
      ...this.csInteractionsSubject.value,
      endDate,
    });
  }
  setFrequency(frequency) {
    this.csInteractionsSubject.next({
      ...this.csInteractionsSubject.value,
      frequency,
    });
  }

  reset() {
    this.csInteractionsSubject.next(initialStates.interactionsSubject);
  }
}

const CSInteractionsService = (function () {
  var instance;

  function createInstance() {
    var classObj = new CSInteractions();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default CSInteractionsService;
