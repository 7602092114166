import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/account/account.apis";
import initialStates from "../InitialStates/account.initialStates";

class Company {
  companySubject;
  constructor() {
    this.companySubject = new BehaviorSubject(initialStates.company);
  }

  get get() {
    return this.companySubject.asObservable();
  }

  updateCompanyImage(selectedImage) {
    this.companySubject.next((value) => {
      return { ...value, image: selectedImage, isUpdated: "updated" };
    });
  }

  updateCompany({ companyName, giataId, timeZone, photo }) {
    apis
      .updateCompany({ companyName, giataId, timeZone, photo })
      .then((response) => {
        this.companySubject.next((value) => {
          return response.isUpdated === "updated"
            ? {
                ...value,
                companyName: response.companyName,
                timeZone,
                image: photo ? photo : localStorage.getItem("profilPhoto"),
                isUpdated: response.isUpdated,
              }
            : { ...value, isUpdated: response.isUpdated };
        });
      });
  }

  fetchData() {
    apis.getCompany().then((response) => {
      this.companySubject.next((value) => {
        return {
          ...value,
          companyName: response.companyName,
          sites: response.sites,
          companyIsFetched: response.companyIsFetched,
        };
      });
    });
  }

  reset() {
    this.companySubject.next(initialStates.company);
  }
}

const CompanyService = (function () {
  var instance;

  function createInstance() {
    var classObj = new Company();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default CompanyService;
