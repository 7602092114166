import serviceProviders from "../../serviceProvider";
import responseHandler from "./popcount.responseHandler";
import Helpers from "../../../Helpers/Helpers";

const getPopCountHourly = ({ year, month, day, first_hour, last_hour }) => {
  // 1 added to month because month interval is 0-11 but endpoints month interval 1-12
  return serviceProviders.kafkaAxiosClient
    .post(`/pop-problems/hourly_count_number`, {
      companyId: Helpers.getLowerCaseCompanyID(),
      year: year,
      month: month + 1,
      day: day,
      first_hour: first_hour,
      last_hour: last_hour,
    })
    .then(responseHandler.handleGetPopCountHourly)
    .catch(responseHandler.handleError);
};
const getPopCountDaily = ({ year, month, first_day, last_day }) => {
  // 1 added to month because month interval is 0-11 but endpoints month interval 1-12
  return serviceProviders.kafkaAxiosClient
    .post(`/pop-problems/daily_count_number`, {
      companyId: Helpers.getLowerCaseCompanyID(),
      year: year,
      month: month + 1,
      first_day: first_day,
      last_day: last_day,
    })
    .then(responseHandler.handleGetPopCountDaily)
    .catch(responseHandler.handleError);
};

const getPopCountWeekly = ({ year, first_week, last_week }) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/pop-problems/weekly_count_number`, {
      companyId: Helpers.getLowerCaseCompanyID(),
      year: year,
      first_week: first_week,
      last_week: last_week,
    })
    .then(responseHandler.handleGetPopCountWeekly)
    .catch(responseHandler.handleError);
};

const getPopCountMonthly = ({ year, first_month, last_month }) => {
  // 1 added to month because month interval is 0-11 but endpoints month interval 1-12
  return serviceProviders.kafkaAxiosClient
    .post(`/pop-problems/monthly_count_number`, {
      companyId: Helpers.getLowerCaseCompanyID(),
      year: year,
      first_month: first_month + 1,
      last_month: last_month + 1,
    })
    .then(responseHandler.handleGetPopCountMonthly)
    .catch(responseHandler.handleError);
};

const getbadPops = ({ pop }) => {
  var payload = JSON.parse(localStorage.getItem("popTime"));
  return serviceProviders.kafkaAxiosClient
    .post(`/pop-problems/pop_details`, {
      pop: pop,
      period: payload.period,
      companyId: localStorage.getItem("companyId"),
      year: payload.year,
      month: payload.month + 1,
      day: payload.day,
      last_day: payload.last_day,
      first_day: payload.first_day,
      last_hour: payload.last_hour,
      first_hour: payload.first_hour,
      last_week: payload.last_week,
      first_week: payload.first_week,
      first_month: payload.last_month,
      last_month: payload.first_month
    })
    .then(responseHandler.handleGetBadPops)
    .catch(responseHandler.handleError);
};

const apis = {
  getPopCountHourly,
  getPopCountDaily,
  getPopCountWeekly,
  getPopCountMonthly,
  getbadPops,
};

export default apis;
