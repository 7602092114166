import ChartAdapters from "../../../Components/DashboardKPI/ChartAdapters";
import { BarChartOptionsRevertedLegendColors } from "../../../Components/DashboardKPI/DashboardOptions";

const handleTicket = (response) => {
  const ticket = response.data;
  localStorage.setItem("ticketID", ticket);
  localStorage.setItem("connection", "false");
  return ticket;
};

const handleDashboardCalculation = ({ response, timeCategory }) => {
  var newDate = new Date();
  var realDate =
    (newDate.toJSON(),
    new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toJSON());
  var currentDate = realDate.substring(0, realDate.length - 10) + "00:00+00:00";

  var spinner = undefined;
  if (response.data) {
    spinner = false;

    var responseData = response.data;

    const measurements = {
      measurements: responseData.total.measurements,
      activeUser: responseData.total.activeUsers,
      modems: responseData.total.modems,
    };

    //-----------Modem Health---------

    const wifiHealthSum =
      responseData.total.modemHealth_Good +
      responseData.total.modemHealth_Medium +
      responseData.total.modemHealth_Bad;

    const wifiHealthGoodPercentage =
      (100 * responseData.total.modemHealth_Good) / wifiHealthSum;
    const wifiHealthMedPercentage =
      (100 * responseData.total.modemHealth_Medium) / wifiHealthSum;
    const wifiHealthBadPercentage =
      (100 * responseData.total.modemHealth_Bad) / wifiHealthSum;

    const wifiHealthData = {
      goodTotal: wifiHealthGoodPercentage,
      mediumTotal: wifiHealthMedPercentage,
      badTotal: wifiHealthBadPercentage,
    };
    // const wifiHealth = wifiHealthData;

    localStorage.setItem(
      "wifiHealthDataProgress",
      JSON.stringify(wifiHealthData)
    );

    //-----------PLACEMENT---------

    const placementData = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.placementCountList,
        chartId: "cpeChart",
        chartTitleId: "cpeTitle",
        chartTitle: "cpe",
        chartInfo: "cpeInfo",
        graphId: "cpeChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.placement_Good,
          medium: responseData.total.placement_Medium,
          bad: responseData.total.placement_Bad,
        },
      }),
      chartTitleId: "cpeTitle",
      chartTitle: "cpe",
      chartInfo: "cpeInfo",
      graphId: "cpeChart",
    };

    //-----------COVERAGE---------

    const coverageDispatch = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.coverageCountList,
        chartId: "coverageChart",
        chartTitleId: "coverageTitle",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.coverage_Good,
          medium: responseData.total.coverage_Medium,
          bad: responseData.total.coverage_Bad,
        },
      }),
      chartTitleId: "coverageTitle",
      chartTitle: "coverage",
      chartInfo: "coverageInfo",
      graphId: "coverageChart",
    };

    //-----------FIDELITY---------

    const fidelityDispatch = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.fidelityCountList,
        chartId: "fidelityChart",
        chartTitleId: "fidelityTitle",
        chartTitle: "NEIGHBORINTERFERENCE",
        chartInfo: "fidelityInfo",
        graphId: "fidelityChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.fidelity_Good,
          medium: responseData.total.fidelity_Medium,
          bad: responseData.total.fidelity_Bad,
        },
      }),
      chartTitleId: "fidelityTitle",
      chartTitle: "NEIGHBORINTERFERENCE",
      chartInfo: "fidelityInfo",
      graphId: "fidelityChart",
    };

    //-----------SPEEDTEST--------

    const speedTest = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.speedTestCountList,
        chartId: "speedChart",
        chartTitleId: "speedTestTitle",
        chartTitle: "speedtest",
        chartInfo: "speedTestInfo",
        graphId: "speedChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.speedTest_Good,
          medium: responseData.total.speedTest_Medium,
          bad: responseData.total.speedTest_Bad,
        },
      }),
      chartTitleId: "speedTestTitle",
      chartTitle: "speedtest",
      chartInfo: "speedTestInfo",
      graphId: "speedChart",
    };

    //-----------LINKSPEED---------
    const linkSpeed = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.linkSpeedCountList,
        chartId: "linkspeedChart",
        chartTitleId: "linkspeedTitle",
        chartInfo: "linkspeedInfo",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.linkSpeed_Good,
          medium: responseData.total.linkSpeed_Medium,
          bad: responseData.total.linkSpeed_Bad,
        },
      }),
      chartTitleId: "linkspeedTitle",
      chartTitle: "linkspeed",
      chartInfo: "linkspeedInfo",
      graphId: "linkspeedChart",
    };

    //-----------WIFISPEED---------
    const wifiSpeed = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.wifiSpeedCountList,
        chartId: "wifispeedChart",
        chartTitleId: "wifispeedTitle",
        chartTitle: "wifiSpeed",
        chartInfo: "wifiSpeedInfo",
        graphId: "wifiSpeedChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.wifiSpeed_Good,
          medium: responseData.total.wifiSpeed_Medium,
          bad: responseData.total.wifiSpeed_Bad,
        },
      }),
      chartTitleId: "wifispeedTitle",
      chartTitle: "wifispeed",
      chartInfo: "wifiSpeedInfo",
      graphId: "wifiSpeedChart",
    };

    //---------LATENCY---------

    const latencyDispatch = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.latencyCountList,
        chartId: "latencyChart",
        chartTitleId: "latencyTitle",
        chartTitle: "localatency",
        chartInfo: "latencyInfo",
        graphId: "latencyChart",
        chartLabels: ["Short", "Normal", "Long"],
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.latency_Short,
          medium: responseData.total.latency_Normal,
          bad: responseData.total.latency_Long,
        },
      }),
      chartTitleId: "latencyTitle",
      chartTitle: "localatency",
      chartInfo: "latencyInfo",
      graphId: "latencyChart",
    };

    //-----------Streaming Quality---------

    const streamingQuality = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.qualityCountList,
        chartId: "streamingQualityChart",
        chartTitleId: "streamingQualityTitle",
        chartTitle: "streamingQuality",
        chartInfo: "streamingQualityInfo",
        graphId: "streamingQualityChart",
        chartLabels: ["High", "Medium", "Low"],
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.quality_Good,
          medium: responseData.total.quality_Medium,
          bad: responseData.total.quality_Bad,
        },
      }),
      chartTitleId: "streamingQualityTitle",
      chartTitle: "streamingQuality",
      chartInfo: "streamingQualityInfo",
      graphId: "streamingQualityChart",
    };

    //-----------Streaming Latency---------

    const streamingLatency = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.streamingLatCountList,
        chartId: "streamingLatencyChart",
        chartTitleId: "streamingLatencyTitle",
        chartTitle: "streamingLatency",
        chartInfo: "streamingLatencyInfo",
        graphId: "streamingLatencyChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.streaming_Latency_Good,
          medium: responseData.total.streaming_Latency_Medium,
          bad: responseData.total.streaming_Latency_Bad,
        },
      }),
      chartTitleId: "streamingLatencyTitle",
      chartTitle: "streamingLatency",
      chartInfo: "streamingLatencyInfo",
      graphId: "streamingLatencyChart",
    };

    var totalCostSavedCalculation = Math.round(
      (responseData.total.complaint_High +
        responseData.total.complaint_Medium) /
        3
    );
    //setTotalSavedCost(totalCostSavedCalculation);
    localStorage.setItem("totalCostSaved", totalCostSavedCalculation);

    // -----------COMPLAIN---------

    //color mapping was wrong in chart, thats why data manipulated before chart adapter for fix the bug
    const complainData = responseData.complaintCountList.map((data) => {
      return {
        date: data.date,
        highCount: data.lowCount,
        mediumCount: data.mediumCount,
        lowCount: data.highCount,
      };
    });

    const complainDispatch = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: complainData,
        chartId: "complainChart",
        chartTitleId: "complainTitle",
        chartTitle: "Complain",
        chartInfo: "Complain",
        graphId: "complainChart",
        chartLabels: ["Low", "Medium", "High"],
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.complaint_Low,
          medium: responseData.total.complaint_Medium,
          bad: responseData.total.complaint_High,
        },
      }),
      chartTitleId: "complainTitle",
      chartTitle: "Complain",
      chartInfo: "complainInfo",
      graphId: "complainChart",
    };

    const cxScoreDataSum =
      responseData.total.complaint_High +
      responseData.total.complaint_Medium +
      responseData.total.complaint_Low;

    const cxScoreDataGoodPercentage =
      (100 * responseData.total.complaint_High) / cxScoreDataSum;
    const cxScoreDataMedPercentage =
      (100 * responseData.total.complaint_Medium) / cxScoreDataSum;
    const cxScoreDataBadPercentage =
      (100 * responseData.total.complaint_Low) / cxScoreDataSum;

    const setCxScoreDataProgress = {
      badTotal: cxScoreDataBadPercentage,
      mediumTotal: cxScoreDataMedPercentage,
      goodTotal: cxScoreDataGoodPercentage,
    };

    //-----------Optimization Required---------

    const optimizationRequiredDispatch = {
      data: ChartAdapters.chartEndPointAdapter4Parametre(null, {
        countList: responseData.optReqCountList,
        chartId: "optRequired",
        chartTitleId: "optRequiredTitle",
        chartTitle: "optRequired",
        chartInfo: "optRequiredInfo",
        graphId: "optRequiredChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        chartLabels: ["Critic", "Normal", "Low", "No"],
        total: {
          good: responseData.total.optReqLow,
          medium: responseData.total.optReqMedium,
          bad: responseData.total.optReqHigh,
          no: responseData.total.optReqNo,
        },
      }),
      chartTitleId: "optRequiredTitle",
      chartTitle: "optRequired",
      chartInfo: "optRequiredInfo",
      graphId: "optRequiredChart",
      options: BarChartOptionsRevertedLegendColors,
    };

    //Delay

    const avgPingTime = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.avgPingTimeCountList,
        chartId: "avgPingTimeChart",
        chartTitleId: "avgPingTimeTitle",
        chartTitle: "avgPingTimeChart",
        chartInfo: "avgPingTimeChartInfo",
        graphId: "avgPingTimeChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.avgPingTime_Good,
          medium: responseData.total.avgPingTime_Medium,
          bad: responseData.total.avgPingTime_Bad,
        },
      }),
      chartTitleId: "avgPingTimeTitle",
      chartTitle: "avgPingTimeChart",
      chartInfo: "avgPingTimeChartInfo",
      graphId: "avgPingTimeChart",
    };

    //packetloss

    const packetLoss = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.packetLossCountList,
        chartId: "packetLossChart",
        chartTitleId: "packetLossTitle",
        chartTitle: "packetLossChart",
        chartInfo: "packetLossChartInfo",
        graphId: "packetLossChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.packetLoss_Good,
          medium: responseData.total.packetLoss_Medium,
          bad: responseData.total.packetLoss_Bad,
        },
      }),
      chartTitleId: "packetLossTitle",
      chartTitle: "packetLossChart",
      chartInfo: "packetLossChartInfo",
      graphId: "packetLossChart",
    };

    //jitter
    const jitterDispatch = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.jitterCountList,
        chartId: "jitterChart",
        chartTitleId: "jitterTitle",
        chartTitle: "jitterChart",
        chartInfo: "jitterChartInfo",
        graphId: "jitterChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.jitter_Good,
          medium: responseData.total.jitter_Medium,
          bad: responseData.total.jitter_Bad,
        },
      }),
      chartTitleId: "jitterTitle",
      chartTitle: "jitterChart",
      chartInfo: "jitterChartInfo",
      graphId: "jitterChart",
    };

    //gw delay
    const gwAvgPingTime = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.gwAvgPingTimeCountList,
        chartId: "gwAvgPingTimeChart",
        chartTitleId: "gwAvgPingTimeTitle",
        chartTitle: "gwAvgPingTimeChart",
        chartInfo: "gwAvgPingTimeChartInfo",
        graphId: "gwAvgPingTimeChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.gwAvgPingTime_Good,
          medium: responseData.total.gwAvgPingTime_Medium,
          bad: responseData.total.gwAvgPingTime_Bad,
        },
      }),
      chartTitleId: "gwAvgPingTimeTitle",
      chartTitle: "gwAvgPingTimeChart",
      chartInfo: "gwAvgPingTimeChartInfo",
      graphId: "gwAvgPingTimeChart",
    };

    //GW Packetloss

    const gwPacketLoss = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.gwPacketLossCountList,
        chartId: "gwPacketLossChart",
        chartTitleId: "gwPacketLossTitle",
        chartTitle: "gwPacketLossChart",
        chartInfo: "gwPacketLossChartInfo",
        graphId: "gwPacketLossChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.gwPacketLoss_Good,
          medium: responseData.total.gwPacketLoss_Medium,
          bad: responseData.total.gwPacketLoss_Bad,
        },
      }),
      chartTitleId: "gwPacketLossTitle",
      chartTitle: "gwPacketLossChart",
      chartInfo: "gwPacketLossChartInfo",
      graphId: "gwPacketLossChart",
    };

    // GW Jitter
    const gwJitterDispatch = {
      data: ChartAdapters.chartEndPointAdapter(null, {
        countList: responseData.gwJitterCountList,
        chartId: "gwJitterChart",
        chartTitleId: "gwJitterTitle",
        chartTitle: "gwJitterChart",
        chartInfo: "gwJitterChartInfo",
        graphId: "gwJitterChart",
        timeCategory: timeCategory,
        currentDate: currentDate,
        total: {
          good: responseData.total.gwJitter_Good,
          medium: responseData.total.gwJitter_Medium,
          bad: responseData.total.gwJitter_Bad,
        },
      }),
      chartTitleId: "gwJitterTitle",
      chartTitle: "gwJitterChart",
      chartInfo: "gwJitterChartInfo",
      graphId: "gwJitterChart",
    };

    totalCostSavedCalculation = Math.round(
      responseData.total.optReqHigh +
        responseData.total.optReqMedium / 2 +
        responseData.total.optReqLow * 0.2
    );
    var totalSavedOpt = totalCostSavedCalculation * 50;
    var totalSavedCall = totalCostSavedCalculation * 30;
    var totalSavedCostSum = totalSavedOpt + totalSavedCall;

    localStorage.setItem("totalCostSaved", totalSavedCostSum);
    localStorage.setItem("totalCostCallSaved", totalSavedCall);
    localStorage.setItem("totalCostOptSaved", totalSavedOpt);

    console.log(speedTest);
    return {
      spinner,
      measurements,

      wifiHealthData,
      setCxScoreDataProgress,

      totalCostSavedCalculation,
      totalSavedCostSum,
      totalSavedCall,
      totalSavedOpt,

      aggregated: [speedTest, streamingQuality, streamingLatency],
      wifiConnection: [
        wifiSpeed,
        linkSpeed,
        latencyDispatch,
        gwAvgPingTime,
        gwPacketLoss,
        gwJitterDispatch,
      ],
      wifiTopology: [coverageDispatch, fidelityDispatch, placementData],
      ping: [avgPingTime, packetLoss, jitterDispatch],
      optCom: [optimizationRequiredDispatch, complainDispatch],
    };
  }
};

const responseHandler = {
  handleTicket,
  handleDashboardCalculation,
};

export default responseHandler;
