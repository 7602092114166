import React from "react";

class DatePickerCustomInput extends React.Component {
  render() {
    return (
      <button className={this.props.customClass} value={this.props.value} id={this.props.id} onClick={this.props.onClick}>
        {this.props.value}
      </button>
    );
  }
}



export default DatePickerCustomInput;
