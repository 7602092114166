import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/CustomerService/customerService.apis";
import initialStates from "../InitialStates/customerService.initialStates";

class CustomerDataChart {
  customerDataChartSubject;
  constructor() {
    this.customerDataChartSubject = new BehaviorSubject(
      initialStates.customerDataChart
    );
  }

  //get router heat map object
  get get() {
    return this.customerDataChartSubject.asObservable();
  }

  //fetch data from GetBadCustomers endpoint
  fetchData() {
    apis.getCustomerServiceChart().then((response) => {
      this.customerDataChartSubject.next(response);
    });
  }

  //reset GetBadCustomers Subject objects state
  reset() {
    this.customerDataChartSubject.next(initialStates.customerDataChart);
  }
}

const CustomerDataChartService = (function () {
  var instance;

  function createInstance() {
    var classObj = new CustomerDataChart();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default CustomerDataChartService;
