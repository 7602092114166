import { BehaviorSubject } from "rxjs";
import initialStates from "../InitialStates/details.InitialStates";

class DetailsCategory {
  detailsCategorySubject;
  constructor() {
    this.detailsCategorySubject = new BehaviorSubject(initialStates.detailsCategory);
  }

  get get() {
    return this.detailsCategorySubject.asObservable();
  }
  setDetailsCategorySubject(value) {
    // console.log(value)
    this.detailsCategorySubject.next(value);
  }
}

const DetailsCategoryService = (function () {
  var instance;

  function createInstance() {
    var classObj = new DetailsCategory();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default DetailsCategoryService;
