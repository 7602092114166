import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Tabs from "../../Components/HelperComponents/Tabs/Tabs";
import Routers from "../../Components/RoutersComponents/Routers";
import DashboardKPI from "../../Components/DashboardKPI/DashboardKPI";

const WifiAnalytics = (props) => {
  // const { t } = props;
  const [activeTab, setActiveTab] = useState("wifiConnection");
  return (
    <Tabs
      tabs={["wifiConnection", "wifiTopology", "wifiInfrastructre"]}
      defaultTabID={activeTab}
      activeTabCallback={setActiveTab}
      content={[
        <DashboardKPI kpi={"wifiConnection"} timeMenu={true}/>,
        <DashboardKPI kpi={"wifiTopology"} timeMenu={true}/>,
        <Routers />,
      ]}
    />
  );
};

export default withTranslation()(WifiAnalytics);
