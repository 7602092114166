import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "./WifiAnalyzer.css";

const axios = require("axios");

const WifiAnalyzer = (props) => {
  const [ghz, setghz] = React.useState("2.4");
  const [firstTime, setFirstTime] = React.useState(true);

  function draw2GHZAnalyzer() {
    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    var maxHeight = window.innerWidth < 767 ? 260 : 520;
    var lines = 10;
    var marginY = maxHeight / lines;
    var startlocation = 0;
    var marginX = window.innerWidth < 767 ? 15 : 30;
    var maxHeightMargin = window.innerWidth < 767 ? 20 : 40;
    var marginYII = window.innerWidth < 767 ? 6.5 : 13;

    if (firstTime === false) {
      startlocation = 8;
    } else {
      setFirstTime(false);
    }

    ctx.fillStyle = "#000";
    var i = 0;
    for (i = 0; i <= marginY + 1; i++) {
      ctx.fillRect(0, i * marginY, maxHeight, 1);
      if (i < 9)
        ctx.fillText(-10 * i - 10, startlocation, i * marginY + marginYII);
      if (i < 14 && i !== 0)
        ctx.fillText(i, i * marginX + marginX, maxHeight - maxHeightMargin);
      if (i === 14)
        ctx.fillText(i, i * marginX + marginX * 2, maxHeight - maxHeightMargin);
    }
    //ctx.stroke();
  }

  function draw5GHZAnalyzer() {
    var c = document.getElementById("myCanvas");
    var ctx = c.getContext("2d");
    var maxHeight = window.innerWidth < 767 ? 260 : 520;
    var lines = 10;
    var marginY = maxHeight / lines;
    var startlocation = 0;
    var marginX = window.innerWidth < 767 ? 15 : 30;
    var maxHeightMargin = window.innerWidth < 767 ? 20 : 40;
    var marginYII = window.innerWidth < 767 ? 7 : 13;

    if (firstTime === false) {
      startlocation = 8;
    } else {
      setFirstTime(false);
    }

    ctx.fillStyle = "#000";
    var i = 0;
    var firstChannel = 32;
    for (i = 0; i <= marginY + 1; i++) {
      ctx.fillRect(0, i * marginY, maxHeight, 1);
      if (i < 9)
        ctx.fillText(-10 * i - 10, startlocation, i * marginY + marginYII);
      if (i < 9 && i !== 0) {
        firstChannel += 4;
        ctx.fillText(
          firstChannel,
          i * marginX + marginX,
          maxHeight - maxHeightMargin
        );
      }
      if (i === 10)
        ctx.fillText(100, i * marginX + marginX, maxHeight - maxHeightMargin);
      if (i === 12)
        ctx.fillText(
          132,
          i * marginX + (marginX * 2) / 3,
          maxHeight - maxHeightMargin
        );
      if (i === 14)
        ctx.fillText(
          165,
          i * marginX + marginX * 2,
          maxHeight - maxHeightMargin
        );
    }
    //ctx.stroke();
  }

  function randomColor(x, ssid) {
    if (x === 0) x = 1;

    if (x === 1) return "rgb(255,0,0,0.2)";
    else if (x === 2) return "rgb(255,215,0,0.4)";
    else if (x === 3) return "rgb(255,99,71,0.4)";
    else if (x === 4) return "rgb(0,255,0,0.4)";
    else if (x === 5) return "rgb(255,255,0,0.4)";
    else if (x === 6) return "rgb(154,205,50,0.4)";
    else if (x === 7) return "rgb(192,192,192,0.4)";
    else if (x === 8) return "rgb(255,105,180,0.4)";
    else if (x === 9) return "rgb(0,128,0,0.4)";
    else if (x === 10) return "rgb(0,255,255,0.4)";
    else if (x === 11) return "rgb(100,149,237,0.4)";
    else if (x === 12) return "rgb(255,0,255,0.4)";
    else if (x === 13) return "rgb(153,50,204,0.4)";
  }

  React.useEffect(() => {
    var c = document.getElementById("myCanvas");
    if (c !== null) {
      var ctx = c.getContext("2d");
      var maxHeight = window.innerWidth < 767 ? 260 : 520;
      var lines = 10;
      var marginY = maxHeight / lines;
      var marginX = window.innerWidth < 767 ? 15 : 30;
      var ellipseLoc = window.innerWidth < 767 ? 107.5 : 215;
      var ellipseMargin = window.innerWidth < 767 ? 5 : 10;

      var fiveGhzChannels = [
        36, 40, 44, 48, 52, 56, 60, 64, 68, 96, 100, 104, 108, 112, 116, 120,
        124, 128, 132, 136, 140, 144, 149, 153, 157, 161, 165, 169, 173,
      ];

      if (ghz === "5") {
        ctx.clearRect(0, 0, 530, 520);
        draw5GHZAnalyzer();
      } else if (ghz === "2.4") {
        ctx.clearRect(0, 0, 530, 520);
        draw2GHZAnalyzer();
      }

      axios
        .post(
          "https://backend.ambeent.ai/ambeentcore/api/v1/Statistics/GetMeasurementsInfo?id=" +
            props.id
        )
        .then(function (response) {
          var rssiValues = [];

          response.data.map((item) => {
            if (
              rssiValues.some((el) => el.macAddress === item.macAddress) ===
              false
            ) {
              const ssid = item.ssid;
              const channel = item.channel;
              const rssi = item.rssi;
              const channelWidth = item.channelWidth;
              const macAddress = item.macAddress;
              // console.log(
              //   "channel: " + channel + " channelWidth: " + channelWidth
              // );

              // when same channel and rssi values texts are overlapping. sum this with y value of fill text
              var sameRssi =
                rssiValues.filter(
                  (x) => x.rssi === rssi && x.channel === channel
                ).length * -10;
              rssiValues.push({
                rssi: rssi,
                channel: channel,
                macAddress: macAddress,
              });

              if (channel < 14 && ghz === "2.4") {
                ctx.beginPath();
                ctx.font = "20px Helvetica, Sans-Serif;";
                ctx.fillStyle = "#000";
                ctx.ellipse(
                  marginX + marginX * channel,
                  maxHeight - marginY,
                  2 * marginX * (channelWidth === -1 ? 0 : channelWidth + 1)+marginX/2,
                  maxHeight - (marginY * -rssi) / 10,
                  0,
                  1 * Math.PI,
                  2 * Math.PI
                );
                ctx.textAlign = "center";
                ctx.fillText(
                  ssid,
                  marginX + marginX * channel,
                  (marginY * -rssi) / 10 - marginX * 2 + sameRssi
                );
                ctx.fillStyle = randomColor(
                  Math.floor(Math.random() * Math.floor(13)),
                  ssid
                );
                ctx.fill();
                ctx.stroke();
              } else if (ghz === "5" && channel > 14) {
                const channelWidth5 = (item.channelWidth + 1) * 20;
                if (channel > 99) {
                  ctx.beginPath();
                  ctx.font = "20px Helvetica, Sans-Serif;";
                  ctx.fillStyle = "#000";
                  ctx.ellipse(
                    ellipseLoc +
                      ellipseMargin * (fiveGhzChannels.indexOf(channel) + 1),
                    maxHeight - marginY,
                    (marginX / 4) * (2 / channelWidth5 + 1),
                    maxHeight - (marginY * -rssi) / 10,
                    0,
                    1 * Math.PI,
                    2 * Math.PI
                  );
                  ctx.textAlign = "center";
                  ctx.fillText(
                    ssid + "-" + channel,
                    ellipseLoc +
                      ellipseMargin * (fiveGhzChannels.indexOf(channel) + 1),
                    (marginY * -rssi) / 10 - 60 + sameRssi
                  );
                  ctx.fillStyle = randomColor(
                    Math.floor(Math.random() * Math.floor(13)),
                    ssid
                  );
                  ctx.fill();
                  ctx.stroke();
                } else {
                  ctx.beginPath();
                  ctx.font = "20px Helvetica, Sans-Serif;";
                  ctx.fillStyle = "#000";
                  ctx.ellipse(
                    marginX + marginX * (fiveGhzChannels.indexOf(channel) + 1),
                    maxHeight - marginY,
                    (marginX / 4) * (2 / channelWidth5 + 1),
                    maxHeight - (marginY * -rssi) / 10,
                    0,
                    1 * Math.PI,
                    2 * Math.PI
                  );
                  ctx.textAlign = "center";
                  ctx.fillText(
                    ssid + "-" + channel,
                    marginX + marginX * (fiveGhzChannels.indexOf(channel) + 1),
                    (marginY * -rssi) / 10 - marginX * 2 + sameRssi
                  );
                  ctx.fillStyle = randomColor(
                    Math.floor(Math.random() * Math.floor(13)),
                    ssid
                  );
                  ctx.fill();
                  ctx.stroke();
                }
              }
            }
            return true;
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [props.id, ghz, firstTime]);

  return (
    <div
      className="wifiAnalyzer"
      onClick={(e) =>
        e.target.className === "wifiAnalyzer" ? props.hideWifiAnalyzer() : false
      }
    >
      <span
        style={{
          height: window.innerWidth < 767 ? "390px" : "600px",
          width: window.innerWidth < 767 ? "85%" : "600px",
          backgroundColor: "#fff5e3",
          marginLeft: window.innerWidth < 767 ? "2.5%" : "32%",
          marginTop: window.innerWidth < 767 ? "30%" : "5%",
          borderRadius: "10px",
        }}
      >
        <div style={{ display: "flex", marginLeft: "30px" }}>
          <p
            style={{
              marginBottom: "30px",
              marginTop: "20px",
              fontSize: "16px",
              color: "black",
              fontWeight: "700",
            }}
          >
            Wi-Fi Analyzer
          </p>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="filterselect"
              id="ghzFilter"
              value={ghz}
              onChange={(event) => {
                setghz(event.target.value);
              }}
            >
              <FormControlLabel
                value="2.4"
                control={<Radio />}
                label="2.4 GHZ"
              />
              <FormControlLabel value="5" control={<Radio />} label="5 GHZ" />
            </RadioGroup>
          </FormControl>
        </div>
        {window.innerWidth < 767 ? (
          <canvas id="myCanvas" width="265" height="260">
            Your browser does not support the HTML canvas tag.
          </canvas>
        ) : (
          <canvas id="myCanvas" width="530" height="520">
            Your browser does not support the HTML canvas tag.
          </canvas>
        )}
      </span>
    </div>
  );
};

export default WifiAnalyzer;
