import serviceProviders from "../../serviceProvider";
import responseHandler from "./devices.responseHandlers";

const getAndroidReleaseDistribution = (companyId) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/devices/getdeviceandroidreleasedistribution`, {
      companyId: companyId,
    })
    .then(responseHandler.handleGetAndroidReleaseDistribution)
    .catch(responseHandler.handleError);
};

const getAndroidReleasePredictionDistribution = (companyId) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/devices/getdeviceandroidreleasepredictiondistribution`, {
      companyId: companyId,
    })
    .then(responseHandler.handleGetAndroidReleasePredictionDistribution)
    .catch(responseHandler.handleError);
};

const getDeviceManufacturerDistribution = (companyId) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/devices/getmanufacturerdistribution`, { companyId, Type: "mobile", })
    .then(responseHandler.handleGetDeviceManufacturerDist)
    .catch(responseHandler.handleError);
};

const getDeviceModelDist = (manufacturer, companyId) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/devices/getdevicemodeldistribution`, { manufacturer, companyId, Type: "model", })
    .then(responseHandler.handleGetDeviceModel)
    .catch(responseHandler.handleError);
};

const getIosReleasePredictionDistribution = (companyId) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/devices/getdeviceiosreleasepredictiondistribution`, {
      companyId: companyId,
    })
    .then(responseHandler.handleGetIosReleasePredictionDistribution)
    .catch(responseHandler.handleError);
};

const getDeviceIosReleaseDistribution = (companyId) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/devices/getdeviceiosreleasedistribution`, {
      companyId: companyId,
    })
    .then(responseHandler.handleGetIosReleaseDistribution)
    .catch(responseHandler.handleError);
};

const apis = {
  getAndroidReleaseDistribution,
  getAndroidReleasePredictionDistribution,
  getIosReleasePredictionDistribution,
  getDeviceManufacturerDistribution,
  getDeviceModelDist,
  getDeviceIosReleaseDistribution,
};

export default apis;
