import React from "react";
import { withTranslation } from "react-i18next";
import { DevicesService, RouterInfoService } from "../../../Classes";
import initialStates from "../../../Classes/InitialStates/details.InitialStates";
import UseObservable from "../../../Helpers/UseObservable";
import ProgressBar from "react-customizable-progressbar";
import AllHeatMapsService from "../../../Classes/Details/AllHeatMaps";

const RouterInfo = (props) => {
  const { t } = props;
  const { data: routerInfo } = UseObservable({
    observable: RouterInfoService.getInstance().get,
    initialState: initialStates.routerInfo,
  });
  const { data: allHeatMaps } = UseObservable({
    observable: AllHeatMapsService.getInstance().get,
    initialState: initialStates.allHeatMaps,
  });
  const { data: device } = UseObservable({
    observable: DevicesService.getInstance().get,
    initialState: initialStates.device,
  });

  return (
    <div className="col align-SSIDs-end" style={{ margin: "15px" }}>
      {/*-------------- brand-model--------------*/}
      {device.selectedDevice.deviceType === "iOS" && (
      <div className="row">
        <div className="col" id="detailsBrandModel">
          <div className="details-chart-title"> {t("BrandModelChannel")} </div>
          <div className="BoxTitleValue">
            {" "}
            {routerInfo.brand}-{routerInfo.model} <br></br>
            {routerInfo.frequency} -{" "}
            {routerInfo.channel === 0 || !routerInfo.channel
              ? ""
              : t("channel") + " " + routerInfo.channel}
          </div>
        </div>
      </div>
      )}
      {/* Hide placement and coverage progress bars for ios devices */}
      {device.selectedDevice.deviceType !== "iOS" && (
        <div className="row">
          {/*-------------- brand-model--------------*/}
          <div className="col" id="detailsBrandModelAndroid">
            <div className="details-chart-title" style={{ height: "60px" }}> {t("BrandModelChannel")} </div>
              <div className="BoxTitleValue" style={{ marginTop: "15px" }}>
              {" "}
              {routerInfo.brand}-{routerInfo.model} <br></br>
              {routerInfo.frequency} -{" "}
              {routerInfo.channel === 0 || !routerInfo.channel
              ? ""
              : t("channel") + " " + routerInfo.channel}
              </div>
          </div>
          {/*-------------- coverage --------------*/}
          <div className="col" id="detailsCoverage">
            <div className="details-chart-title" style={{ height: "60px" }}>
              {" "}
              {t("Coverage")}{" "}
            </div>
            <div>
              <ProgressBar
                progress={allHeatMaps.coverage.percentage}
                radius={100}
                strokeColor={allHeatMaps.coverage.color}
                trackStrokeWidth={14}
                cut={120}
                rotate={-210}
              />
            </div>
            <div
              className="BoxTitleValue"
              style={{
                fontSize: allHeatMaps.coverage.value === 0 ? "13px" : "24px",
                marginTop: "-65px",
                marginLeft: allHeatMaps.coverage.value === 0 ? "35%" : "",
                width: allHeatMaps.coverage.value === 0 ? "30%" : "",
              }}
            >
              {allHeatMaps.coverage.value === 0
                ? t("insufficientData")
                : document.getElementById("lngSelect").value === "tr"
                ? "%" + allHeatMaps.coverage.percentage
                : allHeatMaps.coverage.percentage + "%"}
            </div>
          </div>
          {/*-------------- placement --------------*/}
          <div className="col" id="detailsPlacement">
            <div className="details-chart-title" style={{ height: "60px" }}>
              {" "}
              {t("CPE")}{" "}
            </div>
            <div>
              <ProgressBar
                progress={allHeatMaps.placement.percentage}
                radius={100}
                strokeColor={allHeatMaps.placement.color}
                trackStrokeWidth={14}
                cut={120}
                rotate={-210}
              />
            </div>
            <div
              className="BoxTitleValue"
              style={{
                fontSize: allHeatMaps.placement.value === 0 ? "13px" : "24px",
                marginTop: "-65px",
                marginLeft: allHeatMaps.placement.value === 0 ? "35%" : "",
                width: allHeatMaps.placement.value === 0 ? "30%" : "",
              }}
            >
              {allHeatMaps.placement.percentage === 0
                ? t("insufficientData")
                : document.getElementById("lngSelect").value === "tr"
                ? "%" + allHeatMaps.placement.percentage
                : allHeatMaps.placement.percentage + "%"}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(RouterInfo);
