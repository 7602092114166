export const mystyle = {
  color: "#E6526D",
  fontFamily: "Helvetica, Arial, sans-serif",
  padding: "20px",
  fontSize: 45,
  height: "100px",
  textAlign: "center",
  fontWeight: "bold",
};
export const mystylews = {
  color: "#E6526D",
  fontFamily: "Helvetica, Arial, sans-serif",
  fontSize: 45,
  textAlign: "center",
  fontWeight: "bold",
  padding: "0px",
  margin: "0px",
};
export const lineOptions = {
  chartArea: { width: "85%", height: "95%" },
  backgroundColor: "none",
  curveType: "function",
  focusTarget: "category",
  lineWidth: 2,
  pointSize: 5,
  intervals: { style: "line" },
  legend: "none",
  colors: ["#2BB673"],
  baselineColor: "none",
  // animation: {
  //   duration: 4000,
  //   easing: 'out',
  //   startup: true,
  // },
  hAxis: {
    textPosition: "none",
    gridlines: {
      color: "none",
    },
  },
  vAxis: {
    format: "short",
    gridlineColor: "#3b4e9b",
    minorGridlines: { color: "#3b4e9b" },
    textStyle: { color: "#8C8BA6" },
    viewWindow: { min: 0 },
  },
  tooltip: {
    // isHtml: true,
    showColorCode: false,
  },
};
export const BarChartOptionsRevertedLegendColors = {
  chartArea: { width: "85%", height: "80%", redraw: true },
  lineWidth: 2,
  pointSize: 5,
  focusTarget: "category",
  isStacked: true,
  backgroundColor: "none",
  baselineColor: "none",
  colors: ["#d91e48", "#e9a227", "#007fad", "#2BB673"],
  legend: {
    position: "none",
  },
  hAxis: {
    format: "short",
    gridlineColor: "#3b4e9b",
    minorGridlines: { color: "#3b4e9b" },
    textStyle: { color: "#8C8BA6" },
    viewWindow: { min: 0 },
    showTextEvery: 2,
  },
  vAxis: {
    format: "short",
    gridlineColor: "#3b4e9b",
    minorGridlines: { color: "#3b4e9b" },
    textStyle: { color: "#8C8BA6" },
    viewWindow: { min: 0 },
  },

  annotations: {
    textStyle: {
      fontSize: 16,
    },
    stem: {
      color: "#ffffff60",
    },
    alwaysOutside: true,
  },
  tooltip: {
    showColorCode: true,
  },
  fontName: "Helvetica, Arial, sans-serif",
};
export const BarChartOptions = {
  chartArea: { width: "85%", height: "80%", redraw: true },
  lineWidth: 2,
  pointSize: 5,
  focusTarget: "category",
  isStacked: true,
  backgroundColor: "none",
  baselineColor: "none",
  colors: ["#2BB673", "#56caf5", "#f55f81", "#e9a227"],
  legend: {
    position: "none",
  },
  hAxis: {
    format: "short",
    gridlineColor: "#3b4e9b",
    minorGridlines: { color: "#3b4e9b" },
    textStyle: { color: "#8C8BA6" },
    viewWindow: { min: 0 },
    showTextEvery: 2,
  },
  vAxis: {
    format: "short",
    gridlineColor: "#3b4e9b",
    minorGridlines: { color: "#3b4e9b" },
    textStyle: { color: "#8C8BA6" },
    viewWindow: { min: 0 },
  },

  annotations: {
    textStyle: {
      fontSize: 16,
    },
    stem: {
      color: "#ffffff60",
    },
    alwaysOutside: true,
  },
  tooltip: {
    showColorCode: true,
  },
  fontName: "Helvetica, Arial, sans-serif",
};
export const HistoryLineChart = {
  chartArea: { width: "85%", height: "95%", redraw: true },
  lineWidth: 2,

  pointSize: 5,
  focusTarget: "category",
  isStacked: true,
  backgroundColor: "none",
  baselineColor: "none",
  colors: ["#2BB673", "#007fad", "#d91e48", "#e9a227"],
  legend: {
    position: "none",
  },
  hAxis: {
    textPosition: "none",
    gridlines: {
      color: "none",
    },
  },
  vAxis: {
    format: "short",
    gridlineColor: "#3b4e9b",
    minorGridlines: { color: "#3b4e9b" },
    textStyle: { color: "#8C8BA6" },
    viewWindow: { min: 0 },
  },
  // animation: {
  //   duration: 3000,
  //   easing: 'out',
  //   startup: true,
  // },
  series: {},
  tooltip: {
    showColorCode: false,
  },
  fontName: "Helvetica, Arial, sans-serif",
};
export const options = {
  is3D: false,
  isStacked: true,
  pieSliceText: "value",
  pieSliceTextStyle: {
    color: "#8C8BA6",
  },
  backgroundColor: "none",
  pieSliceBorderColor: "none",
  tooltip: {
    isHtml: true,
    showColorCode: true,
  },
  slices: [
    {
      color: "#d91e48",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#e9a227",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#007fad",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#2BB673",
      textStyle: {
        fontSize: 18,
      },
    },
  ],
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      color: "#8C8BA6",
      fontSize: 14,
    },
  },
  chartArea: {
    left: 0,
    top: 0,
    width: "100%",
    height: "80%",
    redraw: true,
  },
  fontName: "Helvetica, Arial, sans-serif",
};
export default options;
