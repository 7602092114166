import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/devices/devices.apis";
import initialStates from "../InitialStates/devices.initialStates";

class AndroidReleasePrediction {
  androidReleaseSubject;
  constructor() {
    this.androidReleasePredictionSubject = new BehaviorSubject(
      initialStates.androidReleasePrediction
    );
  }

  get get() {
    return this.androidReleasePredictionSubject.asObservable();
  }

  fetchData(companyId) {
    apis.getAndroidReleasePredictionDistribution(companyId).then((response) => {
      this.androidReleasePredictionSubject.next(response);
    });
  }

  reset() {
    this.androidReleasePredictionSubject.next(initialStates.androidReleasePrediction);
  }
}

const AndroidReleasePredictionService = (function () {
  var instance;

  function createInstance() {
    var classObj = new AndroidReleasePrediction();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default AndroidReleasePredictionService;
