import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";


class Streamings {
  streamingsSubject;
  constructor() {
    this.streamingsSubject = new BehaviorSubject(initialStates.streamings);
  }

  //get streamings object
  get get() {
    return this.streamingsSubject.asObservable();
  }

  //fetch data from /streaming/streamings?id=${ambDeviceId}&mac=${modemMac} endpoint
  fetchData(ambDeviceId, modemMac) {
    apis.getStreamings(ambDeviceId, modemMac).then((response) => {
      this.streamingsSubject.next(response);
    });
  }

  //reset streamings objects state
  reset() {
    this.streamingsSubject.next(initialStates.streamings);
  }
}

const StreamingsService = (function () {
  var instance;

  function createInstance() {
    var classObj = new Streamings();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default StreamingsService;
