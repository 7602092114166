import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Tabs from "../../Components/HelperComponents/Tabs/Tabs";
import DashboardKPI from "../../Components/DashboardKPI/DashboardKPI";

const Overview = (props) => {
  // const { t } = props;
  const [activeTab, setActiveTab] = useState("aggregatedCustomerView");
  return (
    <div>
      <DashboardKPI timeMenu={true} overview={true} hideChangeCharts={true}/>
      <Tabs
        tabs={["aggregatedCustomerView", "wifiConnection", "wifiTopology"]}
        defaultTabID={activeTab}
        activeTabCallback={setActiveTab}
        content={[
          <DashboardKPI kpi={"aggregated"} timeMenu={true}/>,
          <DashboardKPI kpi={"wifiConnection"} timeMenu={true} />,
          <DashboardKPI kpi={"wifiTopology"} timeMenu={true} />,
        ]}
      />
    </div>
  );
};

export default withTranslation()(Overview);
