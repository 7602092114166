import initialStates from "../../../Classes/InitialStates/popcount.initialStates";
const handleGetPopCountHourly = (response) => {
  // console.log(response.data);
  if (response.data) {
    var popCountData = [];
    Object.values(response.data).forEach((element, index) => {
      popCountData.push({
        id: index,
        date: element.hour + ":00 " + element.date,
        companyId: element.companyid,
        pop: element.pop,
        noProblemCount: element.noproblemcount,
        hopProblemCount: element.hopproblemcount,
        userProblemCount: element.userproblemcount,
        wifiProblemCount: element.wifiproblemcount,
        total: element.total,
      });
    });

    return { data: popCountData, isFetched: true };
  } else {
    return initialStates.popCount;
  }
};

const handleGetPopCountDaily = (response) => {
  // console.log(response.data);
  if (response.data) {
    var popCountData = [];
    Object.values(response.data).forEach((element, index) => {
      popCountData.push({
        id: index,
        date: element.year + "/" + element.month + "/" + element.day,
        companyId: element.companyid,
        pop: element.pop,
        noProblemCount: element.noproblemcount,
        hopProblemCount: element.hopproblemcount,
        userProblemCount: element.userproblemcount,
        wifiProblemCount: element.wifiproblemcount,
        total: element.total,
      });
    });

    return { data: popCountData, isFetched: true };
  } else {
    return initialStates.popCount;
  }
};

const handleGetPopCountWeekly = (response) => {
  // console.log(response.data);
  if (response.data) {
    var popCountData = [];
    Object.values(response.data).forEach((element, index) => {
      popCountData.push({
        id: index,
        date: element.week,
        companyId: element.companyid,
        pop: element.pop,
        noProblemCount: element.noproblemcount,
        hopProblemCount: element.hopproblemcount,
        userProblemCount: element.userproblemcount,
        wifiProblemCount: element.wifiproblemcount,
        total: element.total,
      });
    });

    return { data: popCountData, isFetched: true };
  } else {
    return initialStates.popCount;
  }
};

const handleGetPopCountMonthly = (response) => {
  // console.log(response.data);
  if (response.data) {
    var popCountData = [];
    Object.values(response.data).forEach((element, index) => {
      popCountData.push({
        id: index,
        date: element.year + "/" + element.month,
        companyId: element.companyid,
        pop: element.pop,
        noProblemCount: element.noproblemcount,
        hopProblemCount: element.hopproblemcount,
        userProblemCount: element.userproblemcount,
        wifiProblemCount: element.wifiproblemcount,
        total: element.total,
      });
    });

    return { data: popCountData, isFetched: true };
  } else {
    return initialStates.popCount;
  }
};

const handleGetBadPops = (response) => {
  console.log(response.data.details);
  if (response.data.details) {
    var badPopCountData = [];
    Object.values(response.data.details).forEach((element, index) => {
      badPopCountData.push({
        id: index,
        //date: element.year + "/" + element.month,
        customerId: element.CustomerId,
        pop: element.Pop,
        url: element.Url,
        city: element.City,
        modemMac: element.ModemMac,
        staMac: element.StaMac,
      });
    });

    return { data: badPopCountData, isFetched: true };
  } else {
    return initialStates.badPopCount;
  }
};

const handleError = (error) => {
  console.log(error);
  if (error.data) {
    return error.data;
  }
  //return error;
};

const responseHandler = {
  handleError,
  handleGetPopCountHourly,
  handleGetPopCountDaily,
  handleGetPopCountWeekly,
  handleGetPopCountMonthly,
  handleGetBadPops
};

export default responseHandler;
