import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { withTranslation } from "react-i18next";
import { format } from 'date-fns';


import "./HistoryTable.css";
import WifiAnalyzer from "./WifiAnalyzer/WifiAnalyzer";

// observable imports
import {
  ChannelNoiseListService,
  NeighboorModemsService,
  PingService,
  SpeedTestService,
  StasInfoService,
  StreamingsService,
  InteractionsService,
} from "../../../../Classes";
import UseObservable from "../../../../Helpers/UseObservable";
import initialStates from "../../../../Classes/InitialStates/details.InitialStates";

//time ago imports
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import tr from "javascript-time-ago/locale/tr";
// add local time ago for turkish
TimeAgo.addLocale(tr);
const timeAgoTr = new TimeAgo("tr");
// add local time ago for english
TimeAgo.addLocale(en);
const timeAgoEn = new TimeAgo("en-US");

const twoColumns = [
  {
    id: "Date",
    label: "date",
    minWidth: 150,
    align: "left",
    format: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
  },
  { id: "Value", label: "Value", minWidth: 150, align: "left" },
];

const intelColumns = [
  { id: "ssid", label: "SSID", minWidth: 100, align: "left" },
  { id: "macAdressBssid", label: "macAddress", minWidth: 100, align: "left" },
  { id: "rssiAnt1", label: "Rssi-Ant1", minWidth: 100, align: "left" },
  { id: "rssiAnt2", label: "Rssi-Ant2", minWidth: 100, align: "left" },
  { id: "frequency", label: "frequency", minWidth: 100, align: "left" },
  { id: "dataRate", label: "Data Rate", minWidth: 100, align: "left" },
  { id: "maxSupportedRate", label: "Maximum Supported Rate", minWidth: 100, align: "left" },
  { id: "channelUtilizationRate_AP", label: "Channel Utilization", minWidth: 100, align: "left" },
];

const speedTestColumns = [
  {
    id: "Date",
    label: "date",
    minWidth: 150,
    align: "left",
    format: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
  },
  { id: "Value", label: "Value", minWidth: 150, align: "left" },
  { id: "upload", label: "Upload", minWidth: 150, align: "left" },
  { id: "ping", label: "Ping", minWidth: 150, align: "left" },
];

const threeColumnsFidelity = [
  {
    id: "Date",
    label: "date",
    minWidth: 150,
    align: "left",
    format: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
  },
  { id: "fidelity", label: "Value", minWidth: 150, align: "left" },
  {
    id: "interferencePercentage",
    label: "Value",
    minWidth: 150,
    align: "left",
  },
];

const streamingColumns = [
  {
    id: "Date",
    label: "date",
    minWidth: 100,
    align: "left",
    format: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
  },
  { id: "Latency", label: "latency", minWidth: 100, align: "left" },
  { id: "Quality", label: "quality", minWidth: 100, align: "left" },
];
const connectedDevicesColumns = [
  { id: "IP", label: "IP", minWidth: 100, align: "left" },
  { id: "MacAddress", label: "macAddress", minWidth: 100, align: "left" },
  { id: "PingTime", label: "pingTime", minWidth: 100, align: "left" },
];
const neighborRoutersColumns = [
  { id: "SSID", label: "SSID", minWidth: 100, align: "left" },
  { id: "Mac Address", label: "macAddress", minWidth: 100, align: "left" },
  { id: "Brand-Model", label: "brandModel", minWidth: 100, align: "left" },
  { id: "RSSI", label: "RSSI", minWidth: 100, align: "left" },
];
const bestChannel2GColumns = [
  {
    id: "Date",
    label: "date",
    minWidth: 100,
    align: "left",
    format: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    id: "Current Channel",
    label: "currentChannel",
    minWidth: 100,
    align: "left",
  },
  {
    id: "Recommended Channel",
    label: "recommendedChannel",
    minWidth: 100,
    align: "left",
  },
];
const bestChannel5GColumns = [
  {
    id: "Date",
    label: "date",
    minWidth: 100,
    align: "left",
    format: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
  },
  {
    id: "Current Channel",
    label: "currentChannel",
    minWidth: 100,
    align: "left",
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "#182142",
    color: "rgb(250, 183, 58)",
    fontWeight: "550",
  },
  container: {
    maxHeight: 350,
    //backgroundColor:"",
    backgroundColor: "#182142",
    color: "rgb(250, 183, 58)",
    fontWeight: "550",
  },
  tablecell: {
    backgroundColor: "#182142",
    color: "rgb(250, 183, 58)",
    fontWeight: "550",
    borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
    padding: "14px",
  },
});

const HistoryTable = (props) => {
  const { t } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showWifiAnalyzer, setShowWifiAnalyzer] = React.useState(false);
  const [wifiAnalyzerId, setWifiAnalyzerId] = React.useState("");

  // time ago language state
  const [timeAgoLang, setTimeAgoLang] = useState(timeAgoEn);
  // get selected company language
  var lang = localStorage.getItem("i18nextLng");
  timeAgoLang.format(new Date("2023-12-18T09:35:11.859154Z")) // ADDED THAT TO NOT REMOVE TIMEAGO LIBRARY, TIMEAGO LIBRARY IS NOT IN USE THIS VERSION
  // changes timeago language at date column
  useEffect(() => {
    setTimeAgoLang(lang === "tr" ? timeAgoTr : timeAgoEn);
  }, [lang]);

  //get observables
  const { data: interactions } = UseObservable({
    observable: InteractionsService.getInstance().get,
    initialState: initialStates.interactions,
  });
  const { data: streaming } = UseObservable({
    observable: StreamingsService.getInstance().get,
    initialState: initialStates.streamings,
  });
  const { data: ping } = UseObservable({
    observable: PingService.getInstance().get,
    initialState: initialStates.ping,
  });

  const { data: neighboorModem } = UseObservable({
    observable: NeighboorModemsService.getInstance().get,
    initialState: initialStates.neighboorModem,
  });
  const { data: channelNoiseList } = UseObservable({
    observable: ChannelNoiseListService.getInstance().get,
    initialState: initialStates.channelNoiseList,
  });

  const { data: stasInfo } = UseObservable({
    observable: StasInfoService.getInstance().get,
    initialState: initialStates.stasInfo,
  });

  const { data: speedTest } = UseObservable({
    observable: SpeedTestService.getInstance().get,
    initialState: initialStates.speedTest,
  });

  // console.log(interactions);

  const fourColumns = [
    {
      id: "Date",
      label: "date",
      minWidth: 150,
      align: "left",
      format: (value) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss'),
    },
    { id: "val1", label: "fidelity", minWidth: 75, align: "left" },
    { id: "val2", label: "interference", minWidth: 75, align: "left" },
    { id: "WifiAnalyzer", label: "", minWidth: 150, align: "left" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function onClickWifiAnalyzer(id) {
    setShowWifiAnalyzer(true);
    setWifiAnalyzerId(id);
  }

  const hideWifiAnalyzer = () => {
    setShowWifiAnalyzer(false);
  };

  //returns length of selected categories history to use in table pagination count
  function historyLength() {
    if (interactions.detailsCategoryId === "streaming") {
      return streaming.streamingHistory.length;
    } else if (interactions.detailsCategoryId === "connectedDevice") {
      return ping.connectedDeviceHistory.length;
    } else if (interactions.detailsCategoryId === "neighbourModems") {
      return neighboorModem.neighborModemHistory.length;
    } else if (interactions.detailsCategoryId === "best Channel 2G") {
      return channelNoiseList.bestChannelHistory.length;
    } else if (interactions.detailsCategoryId === "best Channel 5G") {
      return channelNoiseList.bestChannelHistory.length;
    } else if (interactions.detailsCategoryId === "fidelity") {
      return channelNoiseList.fidelityPercentage.length;
    } else if (interactions.detailsCategoryId === "Wifispeed") {
      return stasInfo.wifiSpeedHistory.length;
    } else if (interactions.detailsCategoryId === "SpeedTest") {
      return speedTest.speedtestHistory.length;
    } else if (interactions.detailsCategoryId === "Linkspeed") {
      return stasInfo.linkSpeedHistory.length;
    } else if (interactions.detailsCategoryId === "Access Points From INTEL") {
      if(stasInfo.informationElements.ScanResults)
      return stasInfo.informationElements.ScanResults.length;
    } else {
      return 0;
    }
  }

  //returns a row template to use in rows list
  function rows(history, columns) {
    return history
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, i) => {
        // console.log(row);
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
            {columns.map((column) => {
              const value = row[column.id];
              //let background =
                //row.background !== undefined ? row.background : "";
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tablecell}
                >
                  {column.format && typeof value === "number"
                    ? format(new Date(value), "yyyy-MM-dd HH:mm:ss 'GMT'XXX")
                    : column.id === "Date"
                    ? format(new Date(value), "yyyy-MM-dd HH:mm:ss 'GMT'XXX")
                    : value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      });
  }

  //returns rows of selected categories history
  function rowsList() {
    if (interactions.detailsCategoryId === "streaming") {
      return rows(streaming.streamingHistory, streamingColumns);
    } else if (interactions.detailsCategoryId === "connectedDevice") {
      return rows(ping.connectedDeviceHistory, connectedDevicesColumns);
    } else if (interactions.detailsCategoryId === "neighbourModems") {
      return rows(neighboorModem.neighborModemHistory, neighborRoutersColumns);
    } else if (interactions.detailsCategoryId === "best Channel 2G") {
      return rows(channelNoiseList.bestChannelHistory, bestChannel2GColumns);
    } else if (interactions.detailsCategoryId === "best Channel 5G") {
      return rows(channelNoiseList.bestChannelHistory, bestChannel2GColumns);
    } else if (interactions.detailsCategoryId === "fidelity") {
      return channelNoiseList.fidelityPercentage
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, i) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
              {threeColumnsFidelity.map((column) => {
                const value = row[column.id];
                // console.log(JSON.stringify(value));
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className={classes.tablecell}
                  >
                    {column.id !== "fidelity"
                      ? column.id === "Date"
                        ? format(new Date(value), "yyyy-MM-dd HH:mm:ss 'GMT'XXX")
                        : value === null
                        ? t("nodata")
                        : value + "%"
                      : value}
                  </TableCell>
                );
              })}
              <TableCell className={classes.tablecell}>
                <button
                  className="historyButton"
                  onClick={() => onClickWifiAnalyzer(row.id)}
                >
                  Wi-Fi Analyzer
                </button>
              </TableCell>
            </TableRow>
          );
        });
    } else if (interactions.detailsCategoryId === "Wifispeed") {
      return rows(stasInfo.wifiSpeedHistory, twoColumns);
    } else if (interactions.detailsCategoryId === "SpeedTest") {
      return rows(speedTest.speedtestHistory, speedTestColumns);
    } else if (interactions.detailsCategoryId === "Linkspeed") {
      return rows(stasInfo.linkSpeedHistory, twoColumns);
    }else if (interactions.detailsCategoryId === "Access Points From INTEL") {
      if(stasInfo.informationElements.ScanResults)
      return rows(stasInfo.informationElements.ScanResults, intelColumns)
    }
  }

  //returns column names of selected history
  function columnsList() {
    if (interactions.detailsCategoryId === "streaming") {
      return streamingColumns.map((column) => (
        <TableCell
          className={classes.tablecell}
          key={column.id}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    } else if (interactions.detailsCategoryId === "connectedDevice") {
      return connectedDevicesColumns.map((column) => (
        <TableCell
          className={classes.tablecell}
          key={column.id}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    } else if (interactions.detailsCategoryId === "neighbourModems") {
      return neighborRoutersColumns.map((column) => (
        <TableCell
          key={column.id}
          className={classes.tablecell}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    } else if (interactions.detailsCategoryId === "best Channel 2G") {
      return bestChannel2GColumns.map((column) => (
        <TableCell
          key={column.id}
          className={classes.tablecell}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    } else if (interactions.detailsCategoryId === "best Channel 5G") {
      return bestChannel5GColumns.map((column) => (
        <TableCell
          key={column.id}
          className={classes.tablecell}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    } else if (interactions.detailsCategoryId === "fidelity") {
      return fourColumns.map((column) => (
        <TableCell
          key={column.id}
          className={classes.tablecell}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    } else if (interactions.detailsCategoryId === "SpeedTest") {
      return speedTestColumns.map((column) => (
        <TableCell
          key={column.id}
          className={classes.tablecell}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    } 
    else if (interactions.detailsCategoryId === "Access Points From INTEL") {
      return intelColumns.map((column) => (
        <TableCell
          key={column.id}
          className={classes.tablecell}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    } else {
      return twoColumns.map((column) => (
        <TableCell
          key={column.id}
          className={classes.tablecell}
          align={column.align}
          style={{ minWidth: column.minWidth }}
        >
          {t(column.label)}
        </TableCell>
      ));
    }
  }

  return (
    <div className="col align-SSIDs-start" id="detailsTable">
      <div className="details-chart-title">
        {" "}
        {t("History") + " " + t(interactions.detailsCategoryId)}{" "}
      </div>
      <div style={{ backgroundColor: "#43486d" }}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>{columnsList()}</TableRow>
              </TableHead>
              <TableBody>{rowsList()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={classes.root}
            rowsPerPageOptions={[5]}
            component="div"
            count={historyLength()} //historyData
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <p
          style={{
            color: "rgb(250, 183, 58)",
            fontSize: "12px",
            textAlign: "left",
            backgroundColor: "#182142",
          }}
        >
          * Background
        </p>
        {showWifiAnalyzer === true ? (
          <WifiAnalyzer
            id={wifiAnalyzerId}
            hideWifiAnalyzer={hideWifiAnalyzer}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(HistoryTable);
