const networkState = (speed) => {
  let speedValue = Math.floor(speed[0]);
  let unit = speed[1];
  var networkState =
    speedValue === -1
      ? "none"
      : speedValue <= 15
      ? "bad"
      : speedValue < 30
      ? "medium"
      : speedValue >= 30
      ? "good"
      : "none";
  if (unit === "Kbps") {
    networkState = "bad";
  }
  return networkState;
};

const modemState = (placement) => {
  let modemState =
    placement === 1
      ? "bad"
      : placement === 2
      ? "bad"
      : placement === 3
      ? "medium"
      : placement === 4
      ? "good"
      : placement === 5
      ? "good"
      : "none";
  return modemState;
};

const scoreState = (score) => {
  /*console.log(score)
  let scoreState =
  ? "bad"
  : score < 70 
  ? "medium"
  : score >= 70 
  ? "good"
  : "none";*/
  let scoreState = "none";
  return scoreState;
};

const wifiState = (placement, coverage) => {
  let wifiStateValue = coverage < placement ? coverage : placement;
  let wifiState_ =
    wifiStateValue === 1
      ? "bad"
      : wifiStateValue === 2
      ? "bad"
      : wifiStateValue === 3
      ? "medium"
      : wifiStateValue === 4
      ? "good"
      : wifiStateValue === 5
      ? "good"
      : "none";
  return wifiState_;
};

const streamingQualityState = (streaming) => {
  const goodQualities = [
   10,
   9,
   8,
   7,
  ];
  const mediumQualities = [6,5];
  const badQualities = [4,3,2,1];
  let streamingQualityState_ = goodQualities.includes(streaming)
    ? "good"
    : mediumQualities.includes(streaming)
    ? "medium"
    : badQualities.includes(streaming)
    ? "bad"
    : "none";
  return streamingQualityState_;
};

const NodeStateControllers = {
  networkState,
  modemState,
  scoreState,
  wifiState,
  streamingQualityState,
};

export default NodeStateControllers;
