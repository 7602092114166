import React from 'react';
import TextField from "@material-ui/core/TextField";

const InputField = ({label, type, id, className, setter, ...props}) => {
    return(
      <div {...props} className={className}>
        <TextField
            onChange={(e) => setter(e.target.value)}
            id={id?id:"standard-basic"}
            label={label}
            type={type}
          />
      </div>);

}

export default InputField