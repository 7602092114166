import { BehaviorSubject, combineLatest, take } from "rxjs";
import { ChannelNoiseListService } from "..";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";
import AllHeatMapsService from "./AllHeatMaps";

function replaceMultipleStrings(inputText, searchStrings, replacementStrings) {
  for (let i = 0; i < searchStrings.length; i++) {
      inputText = inputText.replace(searchStrings[i], replacementStrings[i]);
  }
  return inputText;
}

const searchStrings = [
  "Internet speed and streaming quality are low at the moment",
  "Şu anda Internet internet hızınız ve streaming quality düşüktür", 
  "Internet speed is good but streaming quality is low at the moment. ",
  "Şu anda internet hızınız iyi fakat streaming quality düşüktür. ",
];

const replacementStrings = [
  "Internet speed is low at the moment. ",
  "Şu anda Internet hızınız düşüktür. ", 
  "Internet speed is good. ",
  "Şu anda internet hızınız iyi. ",
];

class Recommendation {
  recommendationSubject;
  constructor() {
    this.recommendationSubject = new BehaviorSubject(
      initialStates.recommendation
    );
  }

  //get recommendation object
  get get() {
    return this.recommendationSubject.asObservable();
  }

  //fetch data from /recommendation/ endpoint
  fetchData({ modemMac, ambDeviceId, language }) {
    const channelNoiseListSubject = ChannelNoiseListService.getInstance().get;
    const allHeatMapsSubject = AllHeatMapsService.getInstance().get;

    const combined = {
      channelNoise: channelNoiseListSubject,
      allHeatMaps: allHeatMapsSubject,
    };

    const combinedObservables = combineLatest(combined);

    combinedObservables.pipe(take(3)).subscribe((response) => {
      if (
        response.channelNoise.isFetched === true &&
        response.allHeatMaps.isFetched === true
      ) {
        apis
          .getRecommendation({
            modemMac,
            ambDeviceId,
            language,
            bestChannelList:
              response.channelNoise.bestChannelListforRecommendation
                .bestChannels,
            interferencePercentage:
              response.channelNoise.bestChannelListforRecommendation
                .interferencePercentage,
            currentChannel:
              response.channelNoise.bestChannelListforRecommendation.curChannel,
            coverage:
              response.allHeatMaps.coverage.percentage !== 0
                ? response.allHeatMaps.coverage.percentage
                : undefined,
            placement:
              response.allHeatMaps.placement.percentage !== 0
                ? response.allHeatMaps.placement.percentage
                : undefined,
          })
          .then((response) => {
            response.recommendation = replaceMultipleStrings(response.recommendation, searchStrings, replacementStrings);
            this.recommendationSubject.next(response);
            //   console.log("recommendation fetched")
          });
      }
    });
  }

  //fetch data from /recommendation/ endpoint
  fetchDataIos({ modemMac, ambDeviceId, language }) {
    const channelNoiseListSubject = ChannelNoiseListService.getInstance().get;

    channelNoiseListSubject.pipe(take(2)).subscribe((response) => {
      if (response.isFetched === true) {
        apis
          .getRecommendation({
            modemMac,
            ambDeviceId,
            language,
            bestChannelList:
              response.bestChannelListforRecommendation.bestChannels,
            interferencePercentage:
              response.bestChannelListforRecommendation.interferencePercentage,
            currentChannel:
              response.bestChannelListforRecommendation.curChannel,
          })
          .then((response) => {
            response.recommendation = replaceMultipleStrings(response.recommendation, searchStrings, replacementStrings);
            this.recommendationSubject.next(response);
            //   console.log("recommendation fetched")
          });
      }
    });
  }

  //reset recommendation objects state
  reset() {
    // console.log("recommendation resetted")
    this.recommendationSubject.next({
      recommendation: "",
      img: "",
      isFetched: false,
    });
  }
}

const RecommendationService = (function () {
  var instance;

  function createInstance() {
    var classObj = new Recommendation();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default RecommendationService;
