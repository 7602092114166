import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/devices/devices.apis";
import initialStates from "../InitialStates/devices.initialStates";

class IOSReleaseDist {
  IOSReleaseDistSubject;
  constructor() {
    this.IOSReleaseDistSubject = new BehaviorSubject(
      initialStates.IOSReleaseDist
    );
  }

  get get() {
    return this.IOSReleaseDistSubject.asObservable();
  }

  fetchData(companyId) {
    apis.getDeviceIosReleaseDistribution(companyId).then((response) => {
      this.IOSReleaseDistSubject.next(response);
    });
  }

  reset() {
    this.IOSReleaseDistSubject.next(initialStates.IOSReleaseDist);
  }
}

const IOSReleaseDistService = (function () {
  var instance;

  function createInstance() {
    var classObj = new IOSReleaseDist();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default IOSReleaseDistService;
