import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

//components
import HeatMap from "../../../HelperComponents/HeatMap/HeatMap";
import DropdownFilter from "../../../HelperComponents/DropdownFilter";

//Observable imports
import { InteractionsService } from "../../../../Classes";
import initialStates from "../../../../Classes/InitialStates/details.InitialStates";
import UseObservable from "../../../../Helpers/UseObservable";

//Styles
import "./HeatMap.css";
import Constants from "../../../../utils/Constants";
import AllHeatMapsService from "../../../../Classes/Details/AllHeatMaps";

const stylebutton = {
  background: "#182142",
  height: "50px",
  width: "100%",
  color: "white",
  fontWeight: "bold",
  padding: "5px",
  textAlign: "left",
  marginTop: "5px",
  zIndex: "2",
  position: "relative",
};

const heatMapInitialData = {
  first: [],
  second: [],
  third: [],
  fourth: [],
};

const HeatMapContainer = (props) => {
  const { t } = props;
  const [selectedHeatMapDetailParam, setSelectedHeatMapDetailParam] =
    useState("Rssi");

  const [heatMapData, setHeatMapData] = useState(heatMapInitialData);

  const { data: interactions } = UseObservable({
    observable: InteractionsService.getInstance().get,
    initialState: initialStates.interactions,
  });

  const { data: allHeatMaps } = UseObservable({
    observable: AllHeatMapsService.getInstance().get,
    initialState: initialStates.allHeatMaps,
  });

  //update show heatmap details interaction
  const showDetails = () => {
    InteractionsService.getInstance().setShowHeatMapDetails(
      !interactions?.showHeatmapDetails
    );
  };

  //set heatmaps data according to selected parameter
  useEffect(() => {
    var foundedParamsData = allHeatMaps?.total
      ? allHeatMaps?.total.find(
          (total) => total.parameter === selectedHeatMapDetailParam
        )
      : undefined;
    setHeatMapData(foundedParamsData ? foundedParamsData : heatMapInitialData);
    // eslint-disable-next-line
  }, [selectedHeatMapDetailParam, allHeatMaps]);

  return (
    <div>
      <div className="details-chart-title"> {t("heatMap")} </div>
      <div>
        <div style={stylebutton}>
          <DropdownFilter
            setSelectedFilter={setSelectedHeatMapDetailParam}
            filterDataArray={Constants.HEATMAP_PARAMETERS}
            selectedValue={"Rssi"}
          />
        </div>
        <div style={{ width: "100%", height: "350px", marginTop: "-20px" }}>
          <HeatMap
            firstCircle={heatMapData.first}
            secondCircle={heatMapData.second}
            thirdCircle={heatMapData.third}
            fourthCircle={heatMapData.fourth}
          />
        </div>
        <div style={{ position: "relative", top: "-15px", float: "right" }}>
          <button className="showHeatMapDetailsButton" onClick={showDetails}>
            {interactions?.showHeatmapDetails === true
              ? t("hideDetails")
              : t("showDetails")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(HeatMapContainer);
