import React from "react";
import { withTranslation } from "react-i18next";

//Component imports
import StoreComponent from "./StoreComponent";
import IntegrationManuel from "./IntegrationManuel";

//Style imports
import "./ClientIntegratons.css";
import Helpers from "../../Helpers/Helpers";

// Icon imports
import msLogo from './StoreImages/msdownload.png';
import chLogo from './StoreImages/chdownload.png';
import appleLogo from './StoreImages/appledownload.png';
import googleLogo from './StoreImages/googledownload.png';
import ubuntuLogo from './StoreImages/ubuntudownload.png';

const ClientIntegrations = (props) => {
  const { t } = props;
  return (
    <div>
      <p className="clientIntegrationTitle">{t("availableOn")}</p>
      <div className="storeComps">
        <StoreComponent
          storeUrl="https://apps.apple.com/tr/app/ambeent-wi-fi/id1471986401"
          imgUrl={appleLogo}
          downladText="Download on the"
          storeName="App Store"
        />
        <StoreComponent
          storeUrl="https://play.google.com/store/apps/details?id=com.wifi.ambeent.console"
          imgUrl={googleLogo}
          downladText="GET IT ON"
          storeName="Google Play"
        />
        <StoreComponent
          storeUrl="https://apps.apple.com/us/app/wifi-console/id1571304313?mt=12"
          imgUrl={appleLogo}
          downladText="Download on the"
          storeName="Mac App Store"
        />
        <StoreComponent
          storeUrl="https://www.microsoft.com/en-us/p/wifi-console-by-ambeent/9mw51kt6lqbz?activetab=pivot:overviewtab"
          imgUrl={msLogo}
          downladText="Available on the"
          storeName="Microsoft App"
        />
        <StoreComponent
          storeUrl="https://chrome.google.com/webstore/detail/ambeent-wifi-extension-be/liebndjcenghmgnphgfkdanblkclhccc?hl=en"
          imgUrl={chLogo}
          downladText="Available in the"
          storeName="Chrome Web Store"
        />
        <StoreComponent
          storeUrl="https://ambeent-ubuntu-app.s3.eu-west-1.amazonaws.com/WiFi-Console-by-Ambeent.deb"
          imgUrl={ubuntuLogo}
          downladText="Available on the"
          storeName="Ubuntu"
        />
      </div>
      {!Helpers.isCompanyISP() && <IntegrationManuel />}

      <div className="divider"></div>
      {/* <div className="enterCodeComps">
        <p className="codeTitle" style={{ marginRight: "10px" }}>
          {t("enterCode")}
        </p>
        <input
          type="text"
          name="name"
          placeholder="Code"
          id="name"
          className="inputBox"
        />
      </div>
      <div className="divider"></div> */}
      <p className="clientIntegrationTitle">{t("clientSDK")}</p>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <input
          type="text"
          name="name"
          placeholder="Name"
          id="name"
          className="inputBox"
          style={{ marginTop: "15px" }}
        />
        <input
          type="text"
          name="name"
          placeholder="Enter Mail"
          id="name"
          className="inputBox"
          style={{ marginTop: "15px" }}
        />
        <input
          type="text"
          name="name"
          placeholder="Company Message"
          id="name"
          className="inputBox"
          style={{ marginTop: "15px" }}
        />
      </div>
      <button className="submitButton">{t("submit")}</button> */}
    </div>
  );
};

export default withTranslation()(ClientIntegrations);
