//Libraries
import React from "react";
import { withTranslation } from "react-i18next";

//Helpers
import Helpers from "../../Helpers/Helpers";

//Css
import "./Details.css";

//Components
import CustomerSearch from "./SearchComponents/CustomerSearch";
import DevicesTabs from "./SearchComponents/DevicesTabs";
import ModemTabs from "./SearchComponents/ModemTabs";
import DetailsCategories from "./SearchComponents/DetailsCategories";
// import ChromeExtensionDetails from "./DataViewerComponents/ChromeExtensionDetails";
import DetailsContainer from "./DetailsDataView";
import FlowGraph from "./DataViewerComponents/FlowGraph/FlowGraph.jsx";

const Details = (props) => {
   const { t } = props;

  return (
    <div>
      <div className="dashboard" id="searchContainer">
        <CustomerSearch />
        {/* Hide Devices and modem tabs if company type is not isp. selection will be made from flowgraph */}
        {Helpers.isCompanyISP() || localStorage.getItem("companyId") === "3c7b06b3-6acc-4245-bcd2-d0b0dd7baf51" ? (
          <>
            {" "}
            <DevicesTabs />
            <ModemTabs />{" "}
          </>
        ) : null}
      </div>


      <div className="dashboard" id="detailsCategoriesMainContainer">
      <DetailsCategories />
      </div>

      <div className="dashboard" id="detailsMainContainer">
      <div className="infoTitle">{t("IFA")}</div>
      <FlowGraph />
      <DetailsContainer />
      </div>


      {/* Hide Chrome extension table if company type is isp */}
      {/* {!Helpers.isCompanyISP() && <ChromeExtensionDetails />} */}
    </div>
  );
};

export default withTranslation()(Details);
