import React from "react";
import { Doughnut } from "react-chartjs-2";
// ChartDataLabels is needed to show datalabels of heat map (hour labels)
// eslint-disable-next-line
import ChartDataLabels from "chartjs-plugin-datalabels";

function datasetKeyProvider() {
  return Math.random();
}

const HeatMap = (props) => {
  const { fourthCircle, thirdCircle, secondCircle, firstCircle } = props;

  const data = {
    datasets: [
      {
        data: [
          2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2,
          2,
        ],
        backgroundColor: "transparent",
        borderColor: "#182142",
        borderWidth: 1,
      },
      {
        data: [
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1,
        ],
        backgroundColor: fourthCircle,
        borderColor: "#182142",
        borderWidth: 1,
      },
      {
        data: [
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1,
        ],
        backgroundColor: thirdCircle,
        borderColor: "#182142",
        borderWidth: 1,
      },
      {
        data: [
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1,
        ],
        backgroundColor: secondCircle,
        borderColor: "#182142",
        borderWidth: 1,
      },
      {
        data: [
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1,
        ],
        backgroundColor: firstCircle,
        borderColor: "#182142",
        borderWidth: 1,
      },
    ],
  };

  console.log()

  return (
    <Doughnut
      data={data}
      options={{
        cutoutPercentage: 30,
        animation: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: (ctx) => {
              return true;
            },
            font: {
              size: 8,
            },
            formatter: (ctx, data) => {
              if (data.dataset.data[0] === 2) {
                if (data.dataIndex === 0) {
                  return "00";
                } else {
                  return `${data.dataIndex}`;
                }
              } else {
                return ` `;
              }
            },
          },
        },
        tooltips: {
          enabled: false,
        },
      }}
      datasetKeyProvider={datasetKeyProvider}
    />
  );
};

export default HeatMap;
