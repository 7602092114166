import React from "react";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import RouterIcon from "@material-ui/icons/Router";
import { Handle } from "react-flow-renderer";
import DropDownRouters from "./DropDownRouters";
import "./CustomNodes.css";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import { ModemsService } from "../../../../Classes";

const CustomParentNodeComponent = ({ data }) => {
  let nodeState = data.nodeState;
  let nodeClass =
    nodeState === "bad"
      ? "parentNodeBad"
      : nodeState === "medium"
      ? "parentNodeMedium"
      : nodeState === "good"
      ? "parentNodeGood"
      : "parentNode";

  return (
    <div className={nodeClass}>
      {data.hideTop === true ? (
        <></>
      ) : (
        <Handle
          type={data.targetTop === false ? "source" : "target"}
          position="top"
        />
      )}
      {data.hideLeft === true ? (
        <></>
      ) : (
        <Handle
          type={data.targetLeft === false ? "source" : "target"}
          position="left"
        />
      )}
      <div>{data.label}</div>

      {data.hideBottom === true ? (
        <></>
      ) : (
        <Handle
          type={data.targetBottom === true ? "target" : "source"}
          position="bottom"
          id="a"
        />
      )}
      {data.hideRight === true ? (
        <></>
      ) : (
        <Handle
          type={data.targetRight === true ? "target" : "source"}
          position="right"
          id="b"
        />
      )}
    </div>
  );
};
const CustomChildNodeComponent = ({ data }) => {
  return (
    <div className="childNode" style={{ width: "140px" }}>
      {data.hideTop === true ? <></> : <Handle type="target" position="top" />}
      {data.hideLeft === true ? (
        <></>
      ) : (
        <Handle type="target" position="left" />
      )}
      <p className="childLabel">{data.label}</p>
      <p className="childValue">{data.value}</p>
      {data.hideBottom === true ? (
        <></>
      ) : (
        <Handle type="source" position="bottom" id="a" />
      )}
      {data.hideRight === true ? (
        <></>
      ) : (
        <Handle type="source" position="right" id="b" />
      )}
    </div>
  );
};

const childNodeDWM = ({ data }) => {
  return (
    <div className="childNode">
      {data.hideTop === true ? <></> : <Handle type="target" position="top" />}
      {data.hideLeft === true ? (
        <></>
      ) : (
        <Handle type="target" position="left" />
      )}
      <p className="childLabel">{data.label}</p>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="triangle">
          <span>D</span>
        </div>
        <div className="square">
          <span>W</span>
        </div>
        <div className="circle">
          <span>M</span>
        </div>
      </div>
      {data.hideBottom === true ? (
        <></>
      ) : (
        <Handle type="source" position="bottom" id="a" />
      )}
      {data.hideRight === true ? (
        <></>
      ) : (
        <Handle type="source" position="right" id="b" />
      )}
    </div>
  );
};

const deviceNode = ({ data }) => {
  return (
    <div>
      {data.type === "iOS" ? (
        <PhoneIphoneIcon style={{ fontSize: "172px" }} />
      ) : (
        <></>
      )}
      {data.type === "Android" ? (
        <PhoneAndroidIcon style={{ fontSize: "172px" }} />
      ) : (
        <></>
      )}
      {data.type === "macOS" ? (
        <DesktopMacIcon style={{ fontSize: "172px" }} />
      ) : (
        <></>
      )}

      <p className="deviceLabel">{data.label}</p>
      <p className="deviceAmbId">{data.ambId}</p>
      <Handle type="source" position="right" />
    </div>
  );
};

const routerNode = ({ data }) => {
  return (
    <div
      onClick={() => {
        // console.log(data.mac);
        //Set modem subject
        var ModemSubject = ModemsService.getInstance();
        let selectedModem = {
          modemMac: data.mac,
          label: data.label,
        };
        //sets selected modem mac of modem object
        ModemSubject.setSelectedModem(selectedModem);
      }}
    >
      <Handle type="target" position="left" />
      <RouterIcon className={data.routerClass} style={{ fontSize: "70px" }} />
      <p className="routerLabel">{data.label}</p>
      <p className="routerMac">{data.mac}</p>
      {data.others !== undefined ? (
        <DropDownRouters
          setSelectedRouter={data.setSelectedRouterDD}
          filterData={data.others}
          selectedValue={data.others[0].value}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

const RecommendationNode = ({ data }) => {
  const [activeBullet, setActiveBullet] = React.useState(0);
  if (data.hideRecom === true) {
    return <></>;
  } else {
    return (
      <div className="recommendationBox">
        {/* <p className="recomTitle">Recommendation</p> */}
        <p>{data.label}</p>
        {Array.isArray(data.recom) ? (
          <p className="recomText">{data.recom[data.recom.length - 1]}</p>
        ) : (
          <p className="recomText">{data.recom}</p>
        )}

        {data.bulletRecoms === undefined ? (
          <></>
        ) : (
          <ul className="bulletList">
            {data.bulletRecoms.map((bullet, i) => {
              return (
                <li
                  key={i}
                  className={i === activeBullet ? "openBullet" : "closedBullet"}
                  onClick={() => {
                    setActiveBullet(i);
                  }}
                >
                  {i === activeBullet ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                  {bullet}
                </li>
              );
            })}
          </ul>
        )}
        {Array.isArray(data.recom) &&
          data.recom.slice(0, data.recom.length - 1).map((recom, i) => {
            return (
              <p className="recomText" key={i + "-recom"}>
                {recom.replace("is", "").replace("are", "")}
              </p>
            );
          })}
      </div>
    );
  }
};

const CustomNodes = {
  CustomParentNodeComponent,
  CustomChildNodeComponent,
  childNodeDWM,
  deviceNode,
  routerNode,
  RecommendationNode,
};

export default CustomNodes;
