import React from "react";
import { DataGrid } from "@material-ui/data-grid";
import Snackbar from "@material-ui/core/Snackbar";

import { withTranslation } from "react-i18next";
import initialStates from "../../../Classes/InitialStates/popcount.initialStates";
import PopCountService from "../../../Classes/PopCount/PopCount";
import BadPopCountService from "../../../Classes/PopCount/BadPopCount";
import UseObservable from "../../../Helpers/UseObservable";
import { CustomerService } from "../../../Classes";

import "./PopProblems.css";

const PopProblemsTable = ({ t }) => {
  const { data: popCount } = UseObservable({
    observable: PopCountService.getInstance().get,
    initialState: initialStates.popCount,
  });
  const BadPopCountSubject = BadPopCountService.getInstance();
  const { data: badPopCount } = UseObservable({
    observable: BadPopCountSubject.get,
    initialState: initialStates.badPopCount,
  });
  
  const [open, setOpen] = React.useState(false);
  const [selectedCustomerID, setSelectedCustomerId] = React.useState();



  const handleClick = (params) => {
    setOpen(true);
    setSelectedCustomerId(params.row.customerId)
  };

  const handleClose = () => {
    setOpen(false);
  };


  const columns = [
    {
      field: "date",
      headerName: popCount.option === "weekly" ? t("week") : t("date"),
      flex: 1,
      minWidth: 100,
    },
    { field: "pop", headerName: t("pop"), flex: 2, minWidth: 130 },
    {
      field: "noProblemCount",
      headerName: t("noProblemCount"),
      flex: 2,
      minWidth: 100,
    },
    {
      field: "hopProblemCount",
      headerName: t("hopProblemCount"),
      flex: 2,
      minWidth: 100,
    },
    {
      field: "userProblemCount",
      headerName: t("userProblemCount"),
      flex: 2,
      minWidth: 100,
    },
    {
      field: "wifiProblemCount",
      headerName: t("wifiProblemCount"),
      flex: 2,
      minWidth: 100,
    },
    { field: "total", headerName: t("total"), flex: 1, minWidth: 100 },
  ];

  const columns1 = [
    /*{
      field: "date",
      headerName: badPopCount.period === "weekly" ? t("week") : t("date"),
      flex: 1,
      minWidth: 100,
    },*/
    { field: "pop", headerName: t("pop"), flex: 2, minWidth: 130 },
    {
      field: "customerId",
      headerName: "Customer Id",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "url",
      headerName: "Url",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "city",
      headerName: t("location"),
      flex: 2,
      minWidth: 100,
    },
    { field: "modemMac", headerName: t("ModemMac"), flex: 2, minWidth: 100 },
    { field: "staMac", headerName: t("stasMac"), flex: 1, minWidth: 200 },
  ];

  const noData = [
    {
      id: 0,
      date: t("noData"),
      pop: t("noData"),
      noProblemCount: t("noData"),
      hopProblemCount: t("noData"),
      userProblemCount: t("noData"),
      wifiProblemCount: t("noData"),
      total: t("noData"),
      selectedDateOption: t("noData"),
    },
  ];


  const handlePop = (params) => {
    BadPopCountSubject.fetchbadPops({
      pop: params.row.pop,
    });
  };

  const handleUser = () => {
    if (selectedCustomerID !== null) {
      //customer observable
      var CustomerSubject = CustomerService.getInstance();
      //set customer observables value (customerid)
      CustomerSubject.set(selectedCustomerID);
      //clicks EndUserAnalytics menu option then clicks customerDetails to open details
      document.getElementById("/EndUserAnalytics").click();
      setTimeout(() => {
        document.getElementById("customerDetails").click();
      }, 10);
    }
  };
  //console.log(popCount);

  return (
    <div style={{ overflowX: "scroll" }}>
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={popCount.data.length === 0 ? noData : popCount.data}
          columns={columns}
          style={{ color: "#8c8ba6 ", cursor:"default" }}
          align="center"
          onCellClick={handlePop}
        />
      </div>
      {badPopCount.isFetched === false || badPopCount.data.length === 0? 
      (console.log()) : (
        <div>
          <div style={{height:100}}></div>
          <div style={{ height: 600, width: "100%"}}>
          <DataGrid
              rows={badPopCount.data}
              columns={columns1}
              style={{ color: "#8c8ba6 " , cursor:"default"}}
              align="center"
              onCellClick={ handleClick}
            />
            {/* SNACKBAR  */}
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              message={"Customer ID: " + selectedCustomerID}
              action={
                <button className="savePSettings2" onClick={handleUser}>
                  {t("showDetails")}
                </button>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(PopProblemsTable);
