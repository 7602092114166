//Singleton services
import {
  AndroidReleaseService,
  AndroidReleasePredictionService,
  DeviceManufacturerDistService,
  IOSReleaseDistService,
  IOSReleasePredictionService
} from "../..";
import Helpers from "../../../Helpers/Helpers";

const fetchDevices = () => {
  const companyId = Helpers.getLowerCaseCompanyID();

  //Singleton Objects
  var androidReleaseSubject = AndroidReleaseService.getInstance();
  var androidReleasePrediction = AndroidReleasePredictionService.getInstance();
  var deviceManufacturerDist = DeviceManufacturerDistService.getInstance();
  var iosRelasePrediction = IOSReleasePredictionService.getInstance();
  var iosReleaseDist = IOSReleaseDistService.getInstance();

  androidReleaseSubject.fetchData(companyId);
  androidReleasePrediction.fetchData(companyId);
  deviceManufacturerDist.fetchData(companyId);
  iosRelasePrediction.fetchData(companyId);
  iosReleaseDist.fetchData(companyId);
};

export default fetchDevices;
