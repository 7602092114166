import serviceProviders from "../../serviceProvider";
import responseHandlers from "./account.responseHandlers";
import KeycloakService from "../../../Keycloak/KeycloakService";

const updateUser = ({ firstName, lastName }) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/user/${KeycloakService.getSub()}`, {
      email: localStorage.getItem("email"),
      firstName: firstName,
      lastName: lastName,
      giataId:
        "tokenGiataId" in localStorage
          ? localStorage.getItem("tokenGiataId")
          : KeycloakService.getGiataId(),
      organizationName:
        "tokenCompanyName" in localStorage
          ? localStorage.getItem("tokenCompanyName")
          : KeycloakService.getCompanyName(),
      TimeZone:
        "tokenTimeZone" in localStorage
          ? localStorage.getItem("tokenTimeZone")
          : KeycloakService.getTimeZone(),
      Type:
        "companyType" in localStorage
          ? localStorage.getItem("companyType")
          : KeycloakService.getType(),
      googleSignUpController:
        "googleSignUp" in localStorage
          ? localStorage.getItem("googleSignUp") === "Done"
            ? localStorage.getItem("googleSignUp")
            : undefined
          : KeycloakService.getGoogleSignUpController() === "Done"
          ? "Done"
          : undefined,
      profilPhoto:
        "profilPhoto" in localStorage
          ? localStorage.getItem("profilPhoto")
          : undefined,
    })
    .then((response) =>
      responseHandlers.handleUpdateUser(response, firstName, lastName)
    )
    .catch(responseHandlers.handleError);
};

const updateCompany = ({ companyName, giataId, timeZone, photo }) => {
  console.log(timeZone, photo, giataId, companyName);
  return serviceProviders.kafkaAxiosClient
    .post(`/user/${KeycloakService.getSub()}`, {
      email: localStorage.getItem("email"),
      firstName:
        "firstName" in localStorage
          ? localStorage.getItem("firstName")
          : KeycloakService.getGiataId(),
      lastName:
        "lastName" in localStorage
          ? localStorage.getItem("lastName")
          : KeycloakService.getGiataId(),
      giataId:
        giataId === "" || giataId === undefined
          ? "tokenGiataId" in localStorage
            ? localStorage.getItem("tokenGiataId")
            : KeycloakService.getGiataId()
          : giataId,
      organizationName:
        companyName === "" || companyName === undefined
          ? "tokenCompanyName" in localStorage
            ? localStorage.getItem("tokenCompanyName")
            : KeycloakService.getCompanyName()
          : companyName,
      TimeZone:
        timeZone === "" || timeZone === undefined
          ? "tokenTimeZone" in localStorage
            ? localStorage.getItem("tokenTimeZone")
            : KeycloakService.getTimeZone()
          : timeZone,
      Type:
        "companyType" in localStorage
          ? localStorage.getItem("companyType")
          : KeycloakService.getType(),
      googleSignUpController:
        "googleSignUp" in localStorage
          ? localStorage.getItem("googleSignUp") === "Done"
            ? localStorage.getItem("googleSignUp")
            : undefined
          : KeycloakService.getGoogleSignUpController() === "Done"
          ? "Done"
          : undefined,
      profilPhoto: photo
        ? photo
        : "profilPhoto" in localStorage
        ? localStorage.getItem("profilPhoto")
        : undefined,
    })
    .then((response) =>
      responseHandlers.handleUpdateCompany({
        response,
        companyName,
        giataId,
        timeZone,
        photo,
      })
    )
    .catch(responseHandlers.handleError);
};

const inviteToGroup = ({ mail, role }) => {
  return serviceProviders.ambeentCoreAxios
    .post(`/User/InviteToGroup`, {
      toEmail: mail,
      fromEmail: localStorage.getItem("email"),
      role: role,
      companyId: localStorage.getItem("defaultCompanyId"),
    })
    .then((response) => responseHandlers.handleInviteToGroup(response, mail))
    .catch(responseHandlers.handleError);
};

const getCompany = () => {
  return serviceProviders.kafkaAxiosClient
    .get(`/company/` + localStorage.getItem("defaultCompanyId"),)
    .then(responseHandlers.handleGetCompany)
    .catch(responseHandlers.handleError);
};

const getClouds = () => {
  return serviceProviders.kafkaAxiosClient
    .get(`/company/clouds/${localStorage.getItem("defaultCompanyId")}`)
    .then(responseHandlers.handleGetClouds)
    .catch(responseHandlers.handleError);
};

const addApiKey = ({ id, apiKeyCloud, timeZoneCloud }) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/akka-playground/api/companies/addApiKey`, {
      id: id,
      apiKey: apiKeyCloud,
      timeZone: timeZoneCloud,
    })
    .then(responseHandlers.handleAddApiKey)
    .catch(responseHandlers.handleError);
};

const getCloudsFromApiKey = ({ cloudApi }) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/akka-playground/api/companies/getClouds`, {
      companyId: localStorage.getItem("defaultCompanyId"),
      apiKey: cloudApi,
    })
    .then(responseHandlers.handleGetCloudsFromApiKey)
    .catch(responseHandlers.handleError);
};

const activateClouds = () => {
  return serviceProviders.kafkaAxiosClient
    .post(`/akka-playground/api/companies/activateClouds`, {
      companyId: localStorage.getItem("defaultCompanyId"),
    })
    .then(responseHandlers.handleActivateClouds)
    .catch(responseHandlers.handleError);
};

const apis = {
  updateUser,
  updateCompany,
  inviteToGroup,
  getCompany,
  getClouds,
  addApiKey,
  getCloudsFromApiKey,
  activateClouds,
};

export default apis;
