import serviceProviders from "../../serviceProvider";
import responseHandler from "./dashboard.responseHandlers";

const getTicket = () => {
  return serviceProviders.ambeentCoreAxios
    .post(`/Ticket`, {
      companyId: localStorage.getItem("companyId"),
      email: localStorage.getItem("email"),
    })
    .then(responseHandler.handleTicket)
    .catch(responseHandler.handleError);
};

const getDashboardCalculation = ({ timeCategory, wirelessCategory, date }) => {
  return serviceProviders.ambeentCoreAxios
    .post(`/DashboardCalculation`, {
      companyId: localStorage.getItem("companyId"),
      timeCategory: timeCategory,
      date: date,
      frequency: wirelessCategory,
    })
    .then((response) =>
      responseHandler.handleDashboardCalculation({ response, timeCategory })
    )
    .catch(responseHandler.handleError);
};
const apis = {
  getTicket,
  getDashboardCalculation,
};

export default apis;
