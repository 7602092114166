import React from "react";
import { withTranslation } from "react-i18next";
import ProgressBar from "react-customizable-progressbar";


const BoxList = ({ t, boxes, setBox }) => {
  return (
    <div className="row" style={{ position: "relative" }}>
      {boxes?.map((box, i) => {
        const { value, categoryId, boxName, intel, securityPercentange, securityColor } = box;
        return (
          <button
            key={`box_list_${i}`}
            className="col detailsBoxMedium"
            onClick={() => categoryId !== "Security" ? setBox(categoryId) : console.log("")}
            disabled= { categoryId === "Security" ? true : false}
          >
            
            {intel ?
            (<div className="BoxPercentage"> 
              <div className="BoxTitleValue">{value}</div>
              <div className="BoxTitle" style={{marginBottom: "-45px"}}>
                  {" "}
                  {boxName ? t(boxName) : t(categoryId)}
              </div>
              {
                securityPercentange !== -1 ?
                (<div style={{marginBottom: "-55px"}}>
                <ProgressBar
                  progress={securityPercentange}
                  radius={100}
                  strokeColor={securityColor}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
                />
                </div>) :
                (console.log(""))
              }
        
              <div className="BoxTitleValue">{securityPercentange === -1 ? "N/A" : "%" + securityPercentange}</div>
              </div>
              ):(
                
              <div>
                <div className="BoxTitleValue">{value}</div>
                <div className="BoxTitle">
                  {" "}
                  {boxName ? t(boxName) : t(categoryId)}
                </div>
              </div>
              )
            } 
          </button>
        );
      })}
    </div>
  );
};

export default withTranslation()(BoxList);
