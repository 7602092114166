//Libraries
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga";

//Components
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

//Observable services
import {
  ModemsService,
  DevicesService,
  CustomerService,
} from "../../../Classes";

//Helpers
import UseObservable from "../../../Helpers/UseObservable";
import initialStates from "../../../Classes/InitialStates/details.InitialStates";
import Helpers from "../../../Helpers/Helpers";

//styles
import { makeStyles } from "@material-ui/core/styles";

const useStylesSearch = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignSSIDs: "center",
    width: "30%",
    backgroundColor: "#182142",
    color: "#e2e2e2",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    backgroundColor: "#182142",
    color: "#e2e2e2",
  },
  iconButton: {
    padding: 10,
    color: "#e2e2e2",
  },
  divider: {
    height: 35,
    margin: 4,
    backgroundColor: "#e2e2e2",
  },
  label: {
    color: "#e2e2e2",
  },
}));

const CustomerSearch = (props) => {
  const { t } = props;

  const classesSearch = useStylesSearch();

  const companyID = Helpers.getLowerCaseCompanyID();

  //Local States
  const [customerID, setCustomerID] = useState("");
  const [wait, setWait] = React.useState(false);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    whichMessage: "whichMessage",
  });
  const { vertical, horizontal, open, whichMessage } = state;

  //Devices observable
  var DeviceSubject = DevicesService.getInstance();
  //Modem observable
  var ModemSubject = ModemsService.getInstance();

  //Observable States
  const { data: customer, isFetched: customerIsFetched } = UseObservable({
    observable: CustomerService.getInstance().get,
    initialState: initialStates.customer,
  });
  const { data: devices } = UseObservable({
    observable: DeviceSubject.get,
    initialState: initialStates.device,
  });

  // get customer observables customerid and set customerid state when its setted berfore
  React.useEffect(() => {
    setCustomerID(customer.customerId);
  }, [customer]);

  //FETCH MODEMS AND DEVICES
  //get Devices when customerid changed
  useEffect(() => {
    //reset data before fetch
    DeviceSubject.reset();
    if (customerIsFetched) {
      //fetch data
      DeviceSubject.fetchData(companyID, customer.customerId);
    }
    // eslint-disable-next-line
  }, [customer]);

  //get modems when ambDeviceId changed
  useEffect(() => {
    //reset modems data
    ModemSubject.reset();

    if (devices.isFetched) {
      //fetch modems
      setWait(false);
      if (devices.deviceList === []) {
        handleClick({
          vertical: "top",
          horizontal: "center",
          whichMessage: t("noData"),
        });
        // console.log("notfecht");
      } else {
        // console.log("fetched");
        ModemSubject.fetchData(
          devices.selectedDevice.ambDeviceId,
          companyID,
          customerID
        );
      }
    }
    // eslint-disable-next-line
  }, [devices]);

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleClick = (newState) => {
    setState({ open: true, ...newState });
    setTimeout(() => {
      handleClose();
    }, 10000);
  };

  // set customer id when input entered
  const onChange = (event) => {
    //sets customer id state
    setCustomerID(event.target.value);
  };

  // Search button onclick
  const onClickSearchButton = (event) => {
    //customer subject
    var CustomerSubject = CustomerService.getInstance();
    //set customer subjects value
    CustomerSubject.set(customerID);

    //if customer id is not null and empty fetch devices data
    if (customerID !== "" && customerID.length !== 0) {
      setWait(true);
      ReactGA.event({
        category:
          localStorage.email.split("@")[0] +
          "[at]" +
          localStorage.email.split("@")[1],
        action: "search for customerID : " + customerID,
      });

      //subscribe for no data alert
    } else {
      //customer Id is empty or  null
      handleClick({
        vertical: "top",
        horizontal: "center",
        whichMessage: t("requiredCustomerId"),
      });
    }
  };

  return (
    <Paper
      component="form"
      className="customerSearch"
      onSubmit={(e) => e.preventDefault()}
    >
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={whichMessage}
        key={vertical + horizontal}
      />
      <InputBase
        className={classesSearch.input}
        placeholder={t("CustomerID")}
        id={"CustomerID"}
        inputProps={{ "aria-label": "Customer Id" }}
        onChange={onChange}
        value={customerID}
      />
      <p style={{ display: "none" }} id="mapControl"></p>

      <Divider className={classesSearch.divider} orientation="vertical" />
      {/* ADD SPINNER DURING SEARCH  */}
      {wait === true ? (
        <IconButton
          className={classesSearch.iconButton}
          aria-label="search"
          id="buttonSearch"
          disabled
        >
          <CircularProgress disableShrink color="secondary" size="1.5rem" />
        </IconButton>
      ) : (
        <IconButton
          className={classesSearch.iconButton}
          aria-label="search"
          id="buttonSearch"
          onClick={onClickSearchButton}
        >
          <SearchIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default withTranslation()(CustomerSearch);
