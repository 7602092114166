import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import Popup from "reactjs-popup";
import { withTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-up";
import io from "socket.io-client";
import { Audio } from "react-loader-spinner";
import TimeMenu from "./TimeMenu";
import { Grid, Paper } from "@material-ui/core";
import ProgressGraph from "../HelperComponents/ProgressGraph";

import { BarChartOptions, BarChartOptionsRevertedLegendColors } from "./DashboardOptions";

import ChartCard from "./ChartCard.jsx";
import apis from "../../Services/Apis/dashboard/dashboard.apis";
import responseSocketHandler from "../../Services/Apis/dashboard/dashboard.webSocketResponseHandlers";

const socket = io("wss://backend.ambeent.ai/", {
  transports: ["websocket"],
});
/* eslint-disable  */

var ticket = "";
var myWebSocketControl = false;

var browser = (function () {
  var test = function (regexp) {
    return regexp.test(window.navigator.userAgent);
  };
  switch (true) {
    case test(/edg/i):
      return "Microsoft Edge";
    case test(/trident/i):
      return "Microsoft Internet Explorer";
    case test(/firefox|fxios/i):
      return "Mozilla Firefox";
    case test(/opr\//i):
      return "Opera";
    case test(/ucbrowser/i):
      return "UC Browser";
    case test(/samsungbrowser/i):
      return "Samsung Browser";
    case test(/chrome|chromium|crios/i):
      return "Google Chrome";
    case test(/safari/i):
      return "Apple Safari";
    default:
      return "Other";
  }
})();

if (browser != "Mozilla Firefox") {
  document.addEventListener("mousewheel", onmousewheel, { passive: true });
}

function getLocalStorageValue(localSocketId, defaultValue) {
  defaultValue = defaultValue ? defaultValue : [];

  var localSocket =
    localSocketId in localStorage
      ? JSON.parse(localStorage.getItem(localSocketId))
      : defaultValue;

  return localSocket;
}

function removeLocalItems(itemIDs) {
  itemIDs.forEach((itemId) => localStorage.removeItem(itemId));
}

const Dashboard = (props) => {
  const { t } = props;

  const initalChartDatas = {
    aggregated: [
      {
        data: {
          barData: getLocalStorageValue("speedTestLineChartSocket", []),
          pieData: getLocalStorageValue("speedDataSocket", []),
        },
        chartTitleId: "speedTestTitle",
        chartTitle: "speedtest",
        chartInfo: "speedTestInfo",
        graphId: "speedChart",
      },
      {
        data: {
          barData: getLocalStorageValue("streamingQualityLineChartSocket", []),
          pieData: getLocalStorageValue("streamingQualityDataSocket", []),
        },
        chartTitleId: "streamingQualityTitle",
        chartTitle: "streamingQuality",
        chartInfo: "streamingQualityInfo",
        graphId: "streamingQualityChart",
      },
      {
        data: {
          barData: getLocalStorageValue("streamingLatencyLineChartSocket", []),
          pieData: getLocalStorageValue("streamingLatencyDataSocket", []),
        },
        chartTitleId: "streamingLatencyTitle",
        chartTitle: "streamingLatency",
        chartInfo: "streamingLatencyInfo",
        graphId: "streamingLatencyChart",
      },
    ],
    wifiConnection: [
      {
        data: {
          barData: getLocalStorageValue("wifiSpeedLineChartSocket", []),
          pieData: getLocalStorageValue("wifiSpeeDataSocket", []),
        },
        chartTitleId: "wifispeedTitle",
        chartTitle: "wifispeed",
        chartInfo: "wifiSpeedInfo",
        graphId: "wifiSpeedChart",
      },
      {
        data: {
          barData: getLocalStorageValue("linkSpeedLineChartSocket", []),
          pieData: getLocalStorageValue("linkSpeedDataSocket", []),
        },
        chartTitleId: "linkspeedTitle",
        chartTitle: "linkspeed",
        chartInfo: "linkspeedInfo",
        graphId: "linkspeedChart",
      },
      {
        data: {
          barData: getLocalStorageValue("latencyLineChartSocket", []),
          pieData: getLocalStorageValue("latencyDataSocket", []),
        },
        chartTitleId: "latencyTitle",
        chartTitle: "localatency",
        chartInfo: "latencyInfo",
        graphId: "latencyChart",
      },
      {
        data: {
          barData: getLocalStorageValue("gwAvgPingTimeLineChartSocket", []),
          pieData: getLocalStorageValue("gwAvgPingTimeDataSocket", []),
        },
        chartTitleId: "gwAvgPingTimeTitle",
        chartTitle: "gwAvgPingTimeChart",
        chartInfo: "gwAvgPingTimeChartInfo",
        graphId: "gwAvgPingTimeChart",
      },
      {
        data: {
          barData: getLocalStorageValue("gwPacketLossLineChartSocket", []),
          pieData: getLocalStorageValue("gwPacketLossDataSocket", []),
        },
        chartTitleId: "gwPacketLossTitle",
        chartTitle: "gwPacketLossChart",
        chartInfo: "gwPacketLossChartInfo",
        graphId: "gwPacketLossChart",
      },
      {
        data: {
          barData: getLocalStorageValue("gwJitterLineChartSocket", []),
          pieData: getLocalStorageValue("gwJitterDataSocket", []),
        },
        chartTitleId: "gwJitterTitle",
        chartTitle: "gwJitterChart",
        chartInfo: "gwJitterChartInfo",
        graphId: "gwJitterChart",
      },
    ],
    wifiTopology: [
      {
        data: {
          barData: getLocalStorageValue("coverageLineChartSocket", []),
          pieData: getLocalStorageValue("coverageDataSocket", []),
        },
        chartTitleId: "coverageTitle",
        chartTitle: "coverage",
        chartInfo: "coverageInfo",
        graphId: "coverageChart",
      },
      {
        data: {
          barData: getLocalStorageValue("fidelityLineChartSocket", []),
          pieData: getLocalStorageValue("fidelityDataSocket", []),
        },
        chartTitleId: "fidelityTitle",
        chartTitle: "NEIGHBORINTERFERENCE",
        chartInfo: "fidelityInfo",
        graphId: "fidelityChart",
      },
      {
        data: {
          barData: getLocalStorageValue("placementLineChartSocket", []),
          pieData: getLocalStorageValue("cpePlacementDataSocket", []),
        },
        chartTitleId: "cpeTitle",
        chartTitle: "cpe",
        chartInfo: "cpeInfo",
        graphId: "cpeChart",
      },
    ],
    ping: [
      {
        data: {
          barData: getLocalStorageValue("avgPingTimeLineChartSocket", []),
          pieData: getLocalStorageValue("avgPingTimeDataSocket", []),
        },
        chartTitleId: "avgPingTimeTitle",
        chartTitle: "avgPingTimeChart",
        chartInfo: "avgPingTimeChartInfo",
        graphId: "avgPingTimeChart",
      },
      {
        data: {
          barData: getLocalStorageValue("packetLossLineChartSocket", []),
          pieData: getLocalStorageValue("packetLossDataSocket", []),
        },
        chartTitleId: "packetLossTitle",
        chartTitle: "packetLossChart",
        chartInfo: "packetLossChartInfo",
        graphId: "packetLossChart",
      },
      {
        data: {
          barData: getLocalStorageValue("jitterLineChartSocket", []),
          pieData: getLocalStorageValue("jitterDataSocket", []),
        },

        chartTitleId: "complainTitle",
        chartTitle: "Complain",
        chartInfo: "complainInfo",
        graphId: "complainChart",
      },
    ],
    optCom: [
      {
        data: {
          barData: getLocalStorageValue(
            "optimizationRequiredLineChartSocket",
            []
          ),
          pieData: getLocalStorageValue("optimizationRequiredDataSocket", []),
        },
        chartTitleId: "optRequiredTitle",
        chartTitle: "optRequired",
        chartInfo: "optRequiredInfo",
        graphId: "optRequiredChart",
      },
      {
        data: {
          barData: getLocalStorageValue("complainLineChartSocket", []),
          pieData: getLocalStorageValue("complainDataSocket", []),
        },
        chartTitleId: "complainTitle",
        chartTitle: "Complain",
        chartInfo: "Complain",
        graphId: "complainChart",
      },
    ],
  };

  const initialWifiHealthData = getLocalStorageValue(
    "wifiHealthDataSocketProgress",
    {
      goodTotal: 0,
      mediumTotal: 0,
      badTotal: 0,
    }
  );
  const initialCXScore = getLocalStorageValue("cxScoreDataSocketProgress", {
    goodTotal: 0,
    mediumTotal: 0,
    badTotal: 0,
  });
  const initialMeasurements = {
    measurements: 0,
    activeUser: 0,
    Modems: 0,
  };
  const initialMeasurementsData = getLocalStorageValue("measurementsSocket", 0);
  const initialActiveUserData = getLocalStorageValue("activeUserSocket", 0);
  const [timeCategory, settimecategory] = React.useState("D");
  const [wirelessCategory, setWirelessCategory] = React.useState("9");
  const [backdate, setbackdate] = useState(new Date());
  const [dashboardChartData, setDashboardChartData] =
    useState(initalChartDatas);
  const chartType = "BarChart";

  const [wifiHealthDataProgress, setWifiHealthDataProgress] = React.useState(
    initialWifiHealthData
  );
  const [cxScoreDataProgress, setCxScoreDataProgress] =
    React.useState(initialCXScore);

  const [timeframe, setTimeFrame] = useState([
    `${new Date().toISOString().slice(0, 10)}`,
    `${new Date().toISOString().slice(0, 10)}`,
  ]);

  const [spinner, setSpinner] = useState(false);
  const [spinnerLocalStorage, setSpinnerLocalStorage] = useState(
    localStorage.getItem("ticketID") !== null
      ? localStorage.getItem("spinner")
      : ""
  );

  const [selectedCost, setSelectedCost] = useState("savedCostVisibility");
  const [totalSavedCost, setTotalSavedCost] = useState(0);
  const [totalSavedCostSocket, setTotalSavedCostSocket] = useState(0);
  const [totalSavedCostCall, setTotalSavedCostCall] = useState(0);
  const [totalSavedCostCallSocket, setTotalSavedCostCallSocket] = useState(0);
  const [totalSavedCostOpt, setTotalSavedCostOpt] = useState(0);
  const [totalSavedCostOptSocket, setTotalSavedCostOptSocket] = useState(0);

  const [measurements, setMeasurements] = useState(
    getLocalStorageValue("measurementsSocket", initialMeasurements)
  );

  let stillMounted = { value: false };

  function dayisToday() {
    if (
      timeCategory === "D" &&
      new Date(backdate).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
    ) {
      return true;
    } else {
      return false;
    }
  }

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  // //wirelessCategory
  const WirelessMenu = (
    <div id="wirelessMenu" className="row mb-4">
      <ul className="buttonwrapper" id="ul1">
        {/* TODAY */}
        <li
          id="allTypes_wireless"
          className={wirelessCategory === "9" ? "active" : ""}
        >
          <label
            id="type1"
            onClick={() => {
              setWirelessCategory("9");
            }}
          >
            {t("allTypes")}
          </label>
        </li>
        {/* 2GH_wireless */}
        <li
          id="2GH_wireless"
          className={wirelessCategory === "0" ? "active" : ""}
        >
          <label
            id="type2"
            onClick={() => {
              setWirelessCategory("0");
            }}
          >
            {t("2.4 GHZ")}
          </label>
        </li>
        {/* 5GH_wireless */}
        <li
          id="5GH_wireless"
          className={wirelessCategory === "1" ? "active" : ""}
        >
          <label
            id="type3"
            onClick={() => {
              setWirelessCategory("1");
            }}
          >
            {t("5 GHZ")}
          </label>
        </li>
      </ul>
    </div>
  );

  useEffect(() => {
    // First time video
    if (localStorage.getItem("login") === null) {
      document.getElementById("helpButton").click();
      localStorage.setItem("login", "true");
    }

    //security ticket
    if (localStorage.getItem("ticketID") === null) {
      apis.getTicket().then((response) => {
        ticket = response;
      });
    }
    window.addEventListener("beforeunload", (e) => {
      if (localStorage.getItem("ticketID") !== null) {
        socket.emit("leave", { ticket: localStorage.getItem("ticketID") });

        let removeItems = [
          "ticketID",
          "complainDataSocket",
          "streamingQualityDataSocket",
          "streamingLatencyDataSocket",
          "complainLineChartSocket",
          "streamingQualityLineChartSocket",
          "streamingLatencyLineChartSocket",
          "optimizationRequiredDataSocket",
          "optimizationRequiredLineChartSocket",
          "fidelityDataSocket",
          "fidelityLineChartSocket",
          "speedDataSocket",
          "speedTestLineChartSocket",
          "linkSpeedLineChartSocket",
          "placementLineChartSocket",
          "coverageLineChartSocket",
          "modemHealthLineChartSocket",
          "wifiSpeedLineChartSocket",
          "latencyLineChartSocket",
          "linkSpeedDataSocket",
          "healthDataSocket",
          "cpePlacementDataSocket",
          "coverageDataSocket",
          "wifiSpeeDataSocket",
          "latencyDataSocket",
          "connection",
          "avgPingTimeDataSocket",
          "avgPingTimeLineChartSocket",
          "packetLossDataSocket",
          "packetLossLineChartSocket",
          "jitterDataSocket",
          "jitterLineChartSocket",
          "gwAvgPingTimeDataSocket",
          "gwAvgPingTimeLineChartSocket",
          "gwPacketLossDataSocket",
          "gwPacketLossLineChartSocket",
          "gwJitterDataSocket",
          "gwJitterLineChartSocket",
          "wifiHealthDataProgress",
          "totalCostSaved",
          "totalCostSavedSocket",
          "totalCostCallSaved",
          "totalCostCallSavedSocket",
          "totalCostOptSaved",
          "totalCostOptSavedSocket",
          "activeUserSocket",
          "measurementsSocket",
          "activeUser",
          "measurements",
          "cxScoreDataSocketProgress",
          "wifiHealthDataSocketProgress",
        ];

        removeLocalItems(removeItems);
      }
      e.preventDefault();
    });
  }, [localStorage.getItem("companyId"), localStorage.getItem("email")]);

  useEffect(() => {
    ReactGA.initialize("UA-173492242-1");
    ReactGA.pageview(localStorage.email + " is on /dashboard page");

    var newDate = new Date();
    var realDate =
      (newDate.toJSON(),
      new Date(
        newDate.getTime() - newDate.getTimezoneOffset() * 60000
      ).toJSON());
    var currentDate =
      realDate.substring(0, realDate.length - 10) + "00:00+00:00";
    var currentTime = realDate.substring(0, realDate.length - 13);
    if (
      timeCategory === "D" &&
      new Date(backdate).setHours(0, 0, 0, 0) ===
        new Date().setHours(0, 0, 0, 0)
    ) {
      setWifiHealthDataProgress(initialWifiHealthData);
      setDashboardChartData(initalChartDatas);
      setCxScoreDataProgress(initialCXScore);
      setMeasurements(initialMeasurementsData);
      var today = new Date();
      myWebSocketControl = false;
      stillMounted.value = true;
      setSpinner(true);
      if (spinnerLocalStorage === "true") {
        setSpinner(false);
      }

      var repeatInterval = setInterval(() => {
        if (localStorage.getItem("ticketID") !== null) {
          clearInterval(repeatInterval);
          if (localStorage.getItem("connection") === "false") {
            localStorage.setItem("connection", "true");
            localStorage.setItem("spinner", "true");
            setSpinnerLocalStorage("true");
            socket.emit("join", { ticket: ticket });
          }
          getSocketMessage();
        }
      }, 1000);

      function getSocketMessage() {
        socket.on(ticket, (message) => {
          setSpinner(false);
          var mm = JSON.parse(message);
          console.log(mm);

          const socketMessage = responseSocketHandler.handleSocketMessage(mm);

          if (myWebSocketControl === true || stillMounted.value === false) {
            return;
          }

          setMeasurements(socketMessage.measurements);
          setCxScoreDataProgress(socketMessage.CxScoreDataProgress);
          setWifiHealthDataProgress(socketMessage.WifiHealthDataSocketProgress);
          setTotalSavedCostSocket(socketMessage.totalSavedCostSum);
          setTotalSavedCostCallSocket(socketMessage.totalSavedCall);
          setTotalSavedCostOptSocket(socketMessage.totalSavedOpt);

          setDashboardChartData({
            aggregated: socketMessage.aggregated,
            wifiConnection: socketMessage.wifiConnection,
            wifiTopology: socketMessage.wifiTopology,
            ping: socketMessage.ping,
            optCom: socketMessage.optCom,
          });
        });
      }
      setTimeFrame([
        `${new Date().toISOString().slice(0, 10)}  00:00:00`,
        `${new Date().toISOString().slice(0, 10)} 23:59:59`,
      ]);
    } else {
      myWebSocketControl = true;
      var xx = [];
      xx.push(["Day", "Count"], [0, 0]);

      setSpinner(true);

      var firstDay;
      let lastDay;
      if (timeCategory === "D") {
        firstDay = `${backdate.toISOString().slice(0, 10)}  00:00:00`;
        lastDay = `${backdate.toISOString().slice(0, 10)} 23:59:59`;
      } else if (timeCategory === "W") {
        let firstDayofWeek = new Date(getMonday(backdate));
        firstDay = `${firstDayofWeek.toISOString().slice(0, 10)}`;
        let lastDayofWeek = new Date(
          firstDayofWeek.getFullYear(),
          firstDayofWeek.getMonth(),
          firstDayofWeek.getDate() + 7
        );
        lastDay = `${lastDayofWeek.toISOString().slice(0, 10)}`;
      } else if (timeCategory === "M") {
        firstDay = `${backdate.toISOString().slice(0, 7)}`;
        lastDay = `${backdate.toISOString().slice(0, 7)}`;
      } else if (timeCategory === "Q") {
        firstDay = `${backdate.toISOString().slice(0, 7)}`;
        let lastDayofQuarter = new Date(backdate).addDays(+90);
        lastDay = `${lastDayofQuarter.toISOString().slice(0, 7)}`;
      } else if (timeCategory === "Y") {
        firstDay = `${backdate.toISOString().slice(0, 4)}`;
        lastDay = `${backdate.toISOString().slice(0, 4)}`;
      }

      setTimeFrame([firstDay, lastDay]);

      apis
        .getDashboardCalculation({
          timeCategory: timeCategory,
          wirelessCategory: wirelessCategory,
          date: timeCategory === "W" ? getMonday(backdate) : backdate,
        })
        .then((response) => {
          setSpinner(response.spinner);

          setMeasurements(response.measurements);
          setWifiHealthDataProgress(response.wifiHealthData);
          setTotalSavedCost(response.totalSavedCostSum);
          setTotalSavedCostCall(response.totalSavedCall);
          setTotalSavedCostOpt(response.totalSavedOpt);
          setCxScoreDataProgress(response.setCxScoreDataProgress);

          setDashboardChartData({
            aggregated: response.aggregated,
            wifiConnection: response.wifiConnection,
            wifiTopology: response.wifiTopology,
            ping: response.ping,
            optCom: response.optCom,
          });
        });
      /* eslint-enable  */
    }
    return () => (stillMounted.value = false);
    /* eslint-disable  */
  }, [timeCategory, backdate, wirelessCategory]);
  /*eslint-enable*/

  const siteName =
    "siteName" in localStorage ? (
      <div style={{ marginTop: "25px", marginBottom: "25px" }}>
        <p
          style={{
            fontSize: "25px",
            color: "white",
            marginBottom: "-10px",
            textAlign: "left",
          }}
        >
          Site: {localStorage.getItem("siteName")}
        </p>
        <hr style={{ borderTop: "1px solid rgb(255,255,255,0.5)" }} />
      </div>
    ) : (
      ""
    );

  /*const savedCosts = [
    "savedCostVisibility",
    "savedCostOptimization",
  ];*/

  var dd = new Date(backdate);
  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };
  const isItToday = isToday(dd);

  return (
    <div>
      {siteName}

      {props.timeMenu === true ? (
        <>
          <TimeMenu
            settimecategory={settimecategory}
            setbackdate={setbackdate}
          />
        </>
      ) : (
        <></>
      )}

      <div className="mt-2">
        <Popup
          contentStyle={{
            backgroundColor: "transparent",
            border: "none",
          }}
          open={spinner}
          modal
        >
          <Audio
            type="ThreeDots"
            color="#FAB73A"
            height={100}
            width={100}
            timeout={100000} //3 secs
          />
        </Popup>

        {(new Date(backdate).setHours(0, 0, 0, 0) !==
          new Date().setHours(0, 0, 0, 0) ||
          timeCategory !== "D") &&
          WirelessMenu}

        {props.timeMenu === true ? (
          <>
            <div className="row mb-4" id="timeTravel">
              <button
                className="setTimeButton"
                onClick={() => {
                  //var dd = new Date(backdate);
                  if (timeCategory === "D") {
                    setbackdate(dd.addDays(-1));
                  } else if (timeCategory === "M") {
                    setbackdate(dd.addDays(-31));
                  } else if (timeCategory === "W") {
                    setbackdate(dd.addDays(-7));
                  } else if (timeCategory === "Q") {
                    setbackdate(dd.addDays(-90));
                  } else if (timeCategory === "Y") {
                    setbackdate(dd.addDays(-300));
                  }
                }}
              >
                {t("back")}
              </button>
              <label className="timeLabel">
                {timeCategory !== "D"
                  ? timeframe[0].toString().slice(0, 10)
                  : timeframe[0].toString().slice(0, 10) + " 00:00:00"}
              </label>
              {isItToday ? (
                <div style={{ display: "inline-block" }}>
                  <button className="setTimeButtonBlocked">{t("next")}</button>
                </div>
              ) : (
                <button
                  className="setTimeButton"
                  onClick={() => {
                    // var dd = new Date(backdate);
                    if (timeCategory === "D") {
                      setbackdate(dd.addDays(+1));
                    } else if (timeCategory === "M") {
                      setbackdate(dd.addDays(+31));
                    } else if (timeCategory === "W") {
                      setbackdate(dd.addDays(+7));
                    } else if (timeCategory === "Q") {
                      setbackdate(dd.addDays(+90));
                    } else if (timeCategory === "Y") {
                      setbackdate(dd.addDays(+300));
                    }
                  }}
                >
                  {t("next")}
                </button>
              )}
              <label className="timeLabel">
                {timeCategory !== "D"
                  ? timeframe[1].toString().slice(0, 10)
                  : timeframe[0].toString().slice(0, 10) + " 23:59:59"}
              </label>
            </div>
          </>
        ) : (
          <></>
        )}

        {props.overview === true ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9} md={9}>
              <Paper
                style={{
                  backgroundColor: "rgba(29, 27, 65, 0.6)",
                  borderRadius: "5px",
                  minHeight: "230px",
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <p className="chartTitle">{t("cxScore")}</p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <ProgressGraph
                      progress={cxScoreDataProgress.goodTotal}
                      color="green"
                      title={t("good")}
                      customValue={cxScoreDataProgress.goodTotal}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <ProgressGraph
                      progress={cxScoreDataProgress.mediumTotal}
                      color="orange"
                      title={t("medium")}
                      customValue={cxScoreDataProgress.mediumTotal}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <ProgressGraph
                      progress={cxScoreDataProgress.badTotal}
                      color="red"
                      title={t("bad")}
                      customValue={cxScoreDataProgress.badTotal}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Paper
              /*  style={{
                  backgroundColor: "rgba(29, 27, 65, 0.6)",
                  borderRadius: "5px",
                  height: "230px",
                }}
              >
                <select
                  style={{ marginTop: "30px" }}
                  className="dropdownFilter"
                  value={selectedCost}
                  onChange={(event) => {
                    setSelectedCost(event.target.value);
                  }}
                >
                  {savedCosts.map((item, i) => (
                    <option key={i} value={item}>
                      {t(item)}
                    </option>
                  ))}
                </select>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "100%",
                    marginTop: "-30px",
                  }}
                >
                  <div>
                    <p style={{ color: "white", fontSize: "60px" }}>
                      $
                      {selectedCost === "totalSavedCost" ? (
                        dayisToday() ? (
                          totalSavedCostSocket
                        ) : (
                          totalSavedCost
                        )
                      ) : selectedCost === "savedCostVisibility" ? (
                        dayisToday() ? (
                          totalSavedCostCallSocket
                        ) : (
                          totalSavedCostCall
                        )
                      ) : selectedCost === "savedCostOptimization" ? (
                        dayisToday() ? (
                          totalSavedCostOptSocket
                        ) : (
                          totalSavedCostOpt
                        )
                      ) : (
                        <></>
                      )}
                    </p>
                  </div>
                </div>*/
                 style={{
                  backgroundColor: "rgba(29, 27, 65, 0.6)",
                  borderRadius: "5px",
                  height: "230px",
                }}
              >
                      <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "100%",
                  }}
                >
                  <div>
                  <div style={{
                    marginBottom: '5px',
                    color : 'white',
                    fontSize: '20px'
                  }}>{t("savedCostVisibility")} 
                  </div>
                    <p style={{ color: "white", fontSize: "20px" }}>
                      $
                      {dayisToday() ? (
                          totalSavedCostCallSocket
                        ) : (
                          totalSavedCostCall
                        )
                      }
                    </p>
                    <div style={{
                    marginBottom: '5px',
                    marginTop: '30px',
                    color : 'white',
                    fontSize: '20px'
                  }}>{t("savedCostOptimization")} 
                  </div>
                    <p style={{ color: "white", fontSize: "20px" }}>
                      $
                      {dayisToday() ? (
                          totalSavedCostOptSocket
                        ) : (
                          totalSavedCostOpt
                        )
                      }
                    </p>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={9} md={9}>
              <Paper
                style={{
                  backgroundColor: "rgba(29, 27, 65, 0.6)",
                  borderRadius: "5px",
                  minHeight: "230px",
                }}
              >
                 <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {Object.entries(measurements).map((item, i) => (
                    <p 
                      className="overviewMeasurements"
                      style={{ margin: "65px", fontSize: "16px" }}
                    >
                      <span style={{ color: "#7CFC00", fontSize: "45px" }}>
                        {item[1]}
                        <br></br>
                      </span>{" "}
                      {t(item[0])}
                    </p>
                  ))}
                </div>
                
                {/*<Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    <p className="chartTitle">{t("wifiHealth")}</p>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <ProgressGraph
                      progress={wifiHealthDataProgress.goodTotal}
                      color="green"
                      title={t("good")}
                      customValue={wifiHealthDataProgress.goodTotal}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <ProgressGraph
                      progress={wifiHealthDataProgress.mediumTotal}
                      color="orange"
                      title={t("medium")}
                      customValue={wifiHealthDataProgress.mediumTotal}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <ProgressGraph
                      progress={wifiHealthDataProgress.badTotal}
                      color="red"
                      title={t("bad")}
                      customValue={wifiHealthDataProgress.badTotal}
                    />
                  </Grid>
                </Grid>*/}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Paper
                style={{
                  backgroundColor: "rgba(29, 27, 65, 0.6)",
                  borderRadius: "5px",
                  height: "230px",
                }}
              >
                      <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: "100%",
                  }}
                >
                  <div>
                  <div style={{
                    marginBottom: '5px',
                    color : 'white',
                    fontSize: '30px'
                  }}>{t("totalSavedCost")} 
                  </div>
                    <p style={{ color: "white", fontSize: "60px" }}>
                      $
                      {dayisToday() ? (
                          totalSavedCostSocket
                        ) : (
                          totalSavedCost
                        )
                      }
                    </p>
                  </div>
                </div>
                {/*<div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  {Object.entries(measurements).map((item, i) => (
                    <p
                      className="overviewMeasurements"
                      style={{ margin: "10px" }}
                    >
                      <span style={{ color: "#7CFC00", fontSize: "25px" }}>
                        {item[1]}
                      </span>{" "}
                      {t(item[0])}
                    </p>
                  ))}
                </div>*/}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Paper
                style={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
              >
                <div id="chartMenu" className="row mt-3 db-chart">
                  {/* OPT REQ & COMPLAIN */}
                  {dashboardChartData?.optCom?.map((item, i) => (
                    <ChartCard
                      key={i}
                      chartTitleId={item.chartTitleId}
                      chartTitle={t(item.chartTitle)}
                      chartInfo={t(item.chartInfo)}
                      chartType={chartType}
                      dayisToday={dayisToday()}
                      data={item.data}
                      options={item.chartTitleId === "optRequiredTitle" ? BarChartOptionsRevertedLegendColors : BarChartOptions} 
                      pcDataTestId={i}
                      graphId={item.graphId}
                      acDataTestId={i}
                      className="col-lg-6 col-xl-6"
                    />
                  ))}
                </div>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}

        <div id="chartMenu" className="row mt-3 db-chart">
          {props.kpi === "aggregated" ? (
            dashboardChartData?.aggregated?.map((item, i) => (
              <ChartCard
                key={i}
                chartTitleId={item.chartTitleId}
                chartTitle={t(item.chartTitle)}
                chartInfo={t(item.chartInfo)}
                chartType={chartType}
                dayisToday={dayisToday()}
                data={item.data}
                options={BarChartOptions}
                pcDataTestId={i}
                graphId={item.graphId}
                acDataTestId={i + 1}
              />
            ))
          ) : (
            <></>
          )}

          {props.kpi === "wifiConnection" ? (
            dashboardChartData?.wifiConnection?.map((item, i) => (
              <ChartCard
                key={i}
                chartTitleId={item.chartTitleId}
                chartTitle={t(item.chartTitle)}
                chartInfo={t(item.chartInfo)}
                chartType={chartType}
                dayisToday={dayisToday()}
                data={item.data}
                options={BarChartOptions}
                pcDataTestId={i}
                graphId={item.graphId}
                acDataTestId={i + 1}
              />
            ))
          ) : (
            <></>
          )}

          {props.kpi === "wifiTopology" ? (
            dashboardChartData?.wifiTopology?.map((item, i) => (
              <ChartCard
                key={i}
                chartTitleId={item.chartTitleId}
                chartTitle={t(item.chartTitle)}
                chartInfo={t(item.chartInfo)}
                chartType={chartType}
                dayisToday={dayisToday()}
                data={item.data}
                options={BarChartOptions}
                pcDataTestId={i}
                graphId={item.graphId}
                acDataTestId={i + 1}
              />
            ))
          ) : (
            <></>
          )}

          {props.kpi === "ping" ? (
            dashboardChartData?.ping?.map((item, i) => (
              <ChartCard
                key={i}
                chartTitleId={item.chartTitleId}
                chartTitle={t(item.chartTitle)}
                chartInfo={t(item.chartInfo)}
                chartType={chartType}
                dayisToday={dayisToday()}
                data={item.data}
                options={BarChartOptions}
                pcDataTestId={i}
                graphId={item.graphId}
                acDataTestId={i + 1}
              />
            ))
          ) : (
            <></>
          )}
        </div>
        <ScrollToTop showUnder={160}>
          <img src="https://img.icons8.com/nolan/64/circled-up-2.png" alt="" />
        </ScrollToTop>
      </div>
    </div>
  );
};

export default withTranslation()(Dashboard);
