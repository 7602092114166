import KeycloakService from "../../../Keycloak/KeycloakService";

const handleUpdateUser = (response, firstName, lastName, photo) => {
  if (response.data === true) {
    return { isUpdated: "updated", firstName, lastName, photo };
  } else {
    return { isUpdated: "notupdated" };
  }
};

const handleUpdateCompany = ({
  response,
  companyName,
  giataId,
  timeZone,
  photo,
}) => {
  if ("tokenGiataId" in localStorage && giataId !== "") {
    localStorage.setItem("tokenGiataId", giataId);
  }
  if ("tokenCompanyName" in localStorage && companyName !== "") {
    localStorage.setItem("tokenCompanyName", companyName);
  }
  if (response.data === true) {
    return {
      isUpdated: "updated",
      timeZone:
        timeZone && timeZone !== "" ? timeZone : KeycloakService.getTimeZone(),
      photo:
        photo && photo !== "" ? photo : localStorage.getItem("profilPhoto"),
      companyName:
        companyName && companyName !== ""
          ? companyName
          : KeycloakService.getCompanyName(),
    };
  } else {
    return { isUpdated: "notupdated" };
  }
};

const handleInviteToGroup = (response, mail) => {
  if (response.data === "Already registered this e-mail") {
    if (mail === "") {
      document.getElementById("inviteSend").innerHTML = "emptymail";
    } else {
      document.getElementById("inviteSend").innerHTML = "alreadyregistered";
    }
  } else {
    document.getElementById("inviteSend").innerHTML = "mailsent";
  }
};

const handleGetCompany = (response) => {
  if (response.data) {
    localStorage.setItem("sites", JSON.stringify(response.data.sites));
    return {
      companyName: response.data.name,
      sites: response.data.sites,
      companyIsFetched: true,
    };
  }
};

const handleGetClouds = (response) => {
  var cloudsList = [];
  response.data.forEach((element) => {
    cloudsList.push({
      id: element.ID,
      cloudName: element.CloudName,
      cloudId: element.CloudId,
      TimeZone: element.TimeZone,
      apiKey: element.ApiKey,
      Sites: element.Sites,
    });
  });
  if (cloudsList.length > 0) {
    return { isFetched: true, cloudsList: cloudsList };
  } else {
    return "initialState";
  }
};

const handleAddApiKey = (response) => {};

const handleGetCloudsFromApiKey = (response) => {
  alert(response.data);
};

const handleActivateClouds = (response) => {
  alert("waitForSites");
};

const responseHandlers = {
  handleUpdateUser,
  handleUpdateCompany,
  handleInviteToGroup,

  handleGetCompany,

  handleGetClouds,
  handleAddApiKey,
  handleGetCloudsFromApiKey,
  handleActivateClouds,
};

export default responseHandlers;
