const translations = {
  en: {
    translation: {
      Ambeent: "AMBEENT",
      Welcome: "Welcome to Ambeent's Wi-Fi Console",
      shortCodeExplanation:
        "Now you can use your group code in WiFi Console App to connect your mobile devices to your Wi-Fi Console in order to monitor and optimize your Wi-Fi Network entirely.",
      code: "Your group code:",
      downloadLabel: "You can download WiFi Console App by Ambeent from",
      signIn: "Sign In",
      signUp: "Sign Up",
      email: "E-mail",
      password: "Password",
      submit: "SUBMIT",
      forgot: "Forgot your password?",
      name: "First Name",
      surname: "Last Name",
      eemail: "E-mail Address",
      giata: "GIATA ID (Optional)",
      group: "Group Name",
      newpassword: "New Password",
      confirmpw: "Confirm Password",
      sbmt: "SUBMIT",
      groupInfo:
        "A group can be a home, a hotel, a company, a mall or an ISP etc.",
      giataInfo: "Put your hotel ID if applicable",
      firsnameerror: "First Name can not be empty!",
      lastnameerror: "Last Name can not be empty!",
      emailerror: "E-mail Address can not be empty!",
      groupnameerror: "Group Name can not be empty!",
      passworderror: "Password can not be empty!",
      confirmpassworderror: "Confirm Password can not be empty!",
      emailexist: "E-mail already exist!",
      groupexist: "Group already exist!",
      notsamepw: "Your password and confirmation password do not match!",
      giataexist: "GIATA ID already exist!",
      activeuser: "ACTIVE USER",
      activeUser: "ACTIVE USER",
      today: "Today",
      week: "Week",
      month: "Month",
      quarter: "Quarter",
      year: "Year",
      Modems: "ACCESS POINT DETECTED",
      modems: "ACCESS POINT DETECTED",
      MEASUREMENTS: "MEASUREMENTS",
      measurements: "MEASUREMENTS",
      FIDELITY: "NEIGHBOR INTERFERENCE",
      speedtest: "SPEED TEST",
      linkspeed: "DATA RATE",
      modemhealth: "Wi-Fi HEALTH",
      cpe: "ACCESS POINT PLACEMENT",
      coverage: "COVERAGE",
      wifispeed: "Wi-Fi SPEED",
      localatency: "LOCAL LATENCY",
      nocd: "CONNECTED DEVICES",
      fidelityInfo:
        "That is a measure that is used to show quality of your Wi-Fi connection with respect to your received signal strength against neighboring interferers.",
      speedTestInfo:
        "Ambeent’s algorithm to measure over-the-Internet end-to-end server to device speed (similar to Ookla).",
      linkSpeedInfo: "Shows the link speed value at that moment.",
      modemInfo:
        "Wi-Fi health combines how well your access point is placed and how well coverage capacity your access point has.",
      cpeInfo:
        "Ambeent’s algorithm that shows how well the access point is placed based on the received signal strength (RSSI).",
      coverageInfo:
        "Ambeent’s algorithm that calculates whether there are coverage problems based on the received signal strength (RSSI).",
      wifiSpeedInfo:
        "Ambeent’s special algorithm to measure the speed between the access point and the user device (over-the-air Wi-Fi connection speed).",
      latencyInfo:
        "Represents the delay between the device and the access point.",
      connectedDeviceInfo:
        "Connected Device info indicates how many Wi-Fi devices are connected to your access point at a given instance.",
      neighbourModems: "Neighbor Access Points",
      connectedDevice: "Connected Devices",
      bestChannel: "Best Channels",
      bestChannel5ghz: "Current Channels",
      streaming: "Streaming Quality",
      fidelity: "Fidelity",
      Wifispeed: "Wi-Fi Speed",
      SpeedTest: "Speed Test",
      Linkspeed: "Data Rate",
      Coverage: "Coverage",
      Select: "Select your access point",
      BrandModel: "Brand - Model",
      BrandModelChannel: "Brand - Model - Channel",
      CPE: "Access Point Placement",
      OPerformance: "Overall Performance",
      Dashboard: "Dashboard",
      Details: "Details",
      Filter: "Filter",
      Logout: "Logout",
      Map: "Map",
      CustomerID: "Customer ID",
      saveExplanation: "Changes will take effect in next hour. ",
      saveError: "System is not responding. Please try again later. ",
      noChange: "You have not made any changes to save! ",
      threshold: "THRESHOLD LEVELS ",
      thresholdExplanation:
        "Threshold levels are adjustable. Adjustable threshold levels provides detailed analysis. ",
      save: "SAVE",
      defaultValues: "RESET",
      Settings: "Settings",
      SelectClient: "Select Customer ID",
      chnageThemeTooltip: "Change theme",
      lastDownloadSpeed: "Last Upload Speed",
      generalSpeedTest: "General Speed Test",
      lastUploadSpeed: "Last Upload Speed",
      activate: "We have sent a confirmation e-mail to ",
      activateNote:
        " If you do not receive a confirmation message, within a few minutes of signing up, please check your Junk e-mail folder.",
      reset: "RESET",
      enterEmail: "Please enter your email adress to reset your password.",
      forgotten: "Forgot your password?",
      emailsent:
        "An email is sent. Please check your mailbox. If you do not receive a confirmation message, within a few minutes of signing up, please check your Junk e-mail folder.",
      emailsenterror: "Not found this e-mail address!",
      ResetPassword: "Reset Password",
      loginPage: "Login Page",
      permission: "You don’t have permission to access this page",
      resetPasswordError:
        "Your new password must be different from your previous password!",
      resetdataError: "Something went wrong!",
      passwordChanged: "Password Changed",
      Incorrect: "Incorrect password or e-mail!",
      Account: "Account",
      companyName: "Group Name",
      userSettings: "User Settings",
      companySettings: "Company Settings",
      changePhoto: "Change Profile Photo",
      setRole: "Click to Set Role",
      send: "SEND",
      checkData: "No data from last hour",
      getData: "Checking data from last hour",
      inviteCompany: "Invite To Group!",
      resend: "Resend My Activation Mail!",
      date: "Date",
      problemComment: "Problem Comment",
      traceDetail: "Trace Detail",
      hopNumber: "Hop Number",
      noProblem: "No problem",
      hopProblem: "Hop Problem",
      wifiProblem: "Wi-Fi problem",
      userProblem: "User usage",
      firstResponse: "First Response",
      secondResponse: "Second Response",
      thirdResponse: "Third Response",
      updateInfo: "*You can update your information on this page.",
      inviteInfo:
        "*You can invite users to console just by one click. They will receive an email with a link to set up their account and access the console.",
      newUser: "Join",
      faqs: "FAQs and HELP",
      howTo: "How to Use It?",
      didntchooserole: "You did not choose a role!",
      emptymail: "E-Mail Can Not Be Empty!",
      mailsent:
        "Mail Sent. If you do not receive a confirmation message, within a few minutes of signing up, please check your Junk e-mail folder.",
      alreadyregistered: "Already registered this e-mail!",
      Routers: "Access Points",
      clickHere: "Click here for the user guide",
      clickBrand: "Click on Brand to see its Model Distribution",
      models: " Models",
      recommendation: "Recommendation",
      calculating: "Calculating your recommendations, please wait",
      routerError: "There is no data to show",
      recommendationError:
        "There is not enough data for a successfull recommendation at the moment. Please keep using Ambeent app",
      noData: "There is no data to show",
      search: "Search",
      next: "Next",
      back: "Back",
      changecharts: "Change Charts",
      Devices: "Devices",
      DevicesAndroidRelase: "Android Releases",
      DevicesIOSRelase: "iOS Releases",
      DevicesBrand: "Device Distribution",
      DevicesAndroidBrand: "Android Device Distribution",
      RoutersBrand: "Access Point Distribution",
      RoutersBrandPercent: "Access Point Distribution (Percentage)",
      server: "Server",
      TOTAL: "TOTAL : ",
      trace: "Wired Network (BETA)",
      good: "Good",
      bad: "Bad",
      medium: "Medium",
      turkey: "Turkey",
      turkTelekom: "Turk Telekom",
      turkNet: "TurkNet",
      updated: "Updated",
      notupdated: "Not updated",
      unidentified: "Unidentified",
      identified: "Identified",
      traceDetails: "Wired Network",
      location: "Location",
      History: "History of",
      heatMap: "Heat Map",
      gridView: "Grid View",
      news: "Click for recent news!",
      Current: "Current Status",
      After3MonthPrediction: "Prediction for 3 Months Later",
      After6MonthPrediction: "Prediction for 6 Months Later",
      requiredCustomerId: "Customer Id is required!",
      invalidFirstName:
        "The first name is composed only of number, letter and _, ., - character",
      invalidLastName:
        "The last name is composed only of number, letter and _, ., - character",
      emailFormatError:
        "Please enter the email address in format: yourname@example.com",
      specialCharError:
        "Speacial characters are not allowed in group name or giata id",
      tracetable: "Problem Analysis of Wired Network",
      clickToDetails: "Click to icons to see details",
      showDetails: "Show Details",
      Name: "Name",
      employeeID: "Employee ID",
      status: "Status",
      action: "Action",
      brand: "Brand",
      macAddress: " MAC Address",
      teamMembers: "Team Members",
      Members: "Members",
      wifiHealth: "Wi-Fi Health",
      ISP: "ISP",
      add: "Add",
      backGrid: "< Back",
      apNames: "Access Point Name",
      delete: "Delete",
      roomName: "Room Name",
      roomList: "Room List",
      apList: "Access Point List",
      roomAdded: "Room added successfully",
      roomCantAdded: "Room can not be added",
      roomCantEmpty: "Room name can not be empty!",
      roomDeleted: "Room deleted successfully",
      roomCantDeleted: "Room can not be deleted",
      apAdded: "Access point added successfully",
      apCantAdded: "Access point can not be added",
      apCantEmpty: "Access point name or MAC address can not be empty!",
      apDeleted: "Access point deleted successfully",
      apCantDeleted: "Access point can not be deleted",
      allCantEmpty: "Fields can not be empty!",
      memberAdded: "Member added successfully",
      memberCantAdded: "Member can not be added",
      memberDeleted: "Member deleted successfully",
      memberCantDeleted: "Member can not be deleted",
      complainInfo:
        "Monitors customer complaints and predicts the number of customer complaints.",
      Complain: "COMPLAINT",
      streamingQuality: "STREAMING QUALITY",
      StreamingQuality: "Streaming Quality",
      streamingLatency: "STREAMING LATENCY",
      streamingLatencyInfo:
        "Measures end-to-end delay with regard to multimedia applications.",
      streamingQualityInfo:
        "Monitors the streaming quality (example: 1080p, 720p, 360p, 144p) the user can achieve when watching a video that is run in the background.",
      optRequired: "OPTIMIZATION REQUIRED",
      OptRequired: "Optimization Required",
      optRequiredInfo:
        "Measures and predicts how many access points will need to have their channel assignment optimized.",
      customerService: "Customer Service",
      worstCustomers: "Customers with Bad Experience",
      complain: "Complaint",
      model: "Model",
      neighborCount: "Neighbor Count",
      speed: "Speed",
      quality: "Quality",
      latency: "Latency",
      lastChannel: "Last Channel",
      downloadSpeed: "Download Speed",
      Speedtest: "Speed Test",
      detailsTooltip: "Click to see details",
      setTimeZone: "Click to set timezone",
      timeZone: "Time Zone",
      timeZoneError: "Time zone must be selected!",
      timeZoneChangeTitle: "Time zone",
      timeZoneChange: "You can set a different time zone.",
      timeZoneChanged: "Time zone changed",
      timeZoneCantChanged: "Time zone can not be changed",
      addAP:
        "*You can add it by entering the access point information manually or by selecting it from the list below.",
      twoAPSelected:
        "You have to add the access point either manually or from the list!",
      routerList: "Access Point List",
      score: "Score",
      Throughput: "Throughput",
      Rssi: "RSSI",
      coveragerouter: "Coverage",
      selectedModel: "Selected Model: ",
      watchWebinar:
        "Watch our webinar to see how Ambeent Wi-Fi Console is in action!",
      slides: "Slides ",
      webinar: "Webinar",
      signInTitle: "Sign in to your account",
      signUpLabel: "Don’t you have an account?",
      signUpTitle: "Create your account",
      createAccount: "Create Account",
      haveAccount: "Have an account?",
      cloudApi: "Api Key",
      nodata: "No Data!",
      Lowest: "Lowest",
      downloadFile: "File To Download",
      download: "Download",
      stasTable: "Stas Table",
      stasMac: "Device Mac Adress",
      ModemMac: "Access Point Mac Adress",
      createdAt: "Created at",
      ssid: "Ssid",
      channel: "Channel",
      channelWidth: "Channel Width",
      sessionId: "Session ID",
      modemLocation: "Access Point Location",
      deviceLocation: "Device Location",
      measurementsTable: "Measurements Table",
      count: "Count",
      avgPingTime: "Average Ping Time",
      packetLoss: "Packet Loss",
      gwAvgPingTime: "Gateway Average Ping Time",
      gwPacketLoss: "Gateway Packet Loss",
      jitter: "Jitter",
      gwJitter: "Gateway Jitter",
      pingStasTable: "Ping Stas Table",
      pingMeasurementsTable: "Ping Measurements Table",
      pingTime: "Ping Time",
      hostName: "Host Name",
      uploadSpeed: "Upload Speed",
      ping: "Ping",
      speedTestTable: "Speed Test Table",
      streamingTable: "Streaming Table",
      noRegisteredApi: "There is no registered API key!",
      cloudName: "Cloud Name",
      clouds: "Clouds",
      refreshAllClouds: "Refresh All Clouds",
      cloudId: "Cloud Id",
      Activate: "Activate",
      apiKeyAddClouds: "Enter an Api Key to add clouds.",
      siteName: "Site Name",
      siteId: "Site Id",
      companyType: "Company Type",
      enterprise: "Enterprise",
      partner: "Partner",
      isp: "Isp",
      waitForSites:
        "The request has been accepted for processing, please wait for your sites to be online.",
      addApi: "Please Add Api Key!",
      activateSites:
        "Please make sure you activated your cloud! If already did you might have no sites.",
      completeYourAccount: "Complete your account informations",
      setgroup: "Set your group name",
      homeenterprise: "Home - Enterprise",
      gmtplus1: "European Central Time",
      gmtplus2: "Eastern European Time",
      gmtplus3: "Eastern African Time",
      gmtplus4: "Near East Time",
      gmtplus5: "Pakistan Lahore Time",
      gmtplus6: "Bangladesh Standard Time",
      gmtplus7: "Vietnam Standard Time",
      gmtplus8: "China Taiwan Time",
      gmtplus9: "Japan Standard Time",
      gmtplus10: "Australia Eastern Time",
      gmtplus11: "Solomon Standard Time",
      gmtplus12: "New Zealand Standard Time",
      gmtplus13: "Fiji Summer Time",
      gmtminus11: "Midway Islands Time",
      gmtminus10: "Hawaii Standard Time",
      gmtminus9: "Alaska Standard Time",
      gmtminus8: "Pacific Standard Time",
      gmtminus7: "Phoenix Standard Time",
      gmtminus6: "Central Standard Time",
      gmtminus5: "Eastern Standard Time",
      gmtminus4: "	Puerto Rico and US Virgin Islands Time",
      gmtminus3: "Brazil Eastern Time",
      gmtminus2: "Brazil Summer Time",
      gmtminus1: "Central African Time",
      gmt0: "Greenwich Mean Time",
      resetPassword: "Reset Password",
      notAvailable: "Out of Service",
      avgPingTimeChart: "DELAY",
      packetLossChart: "PACKET LOSS",
      jitterChart: "JITTER",
      gwAvgPingTimeChart: "GATEWAY DELAY",
      gwPacketLossChart: "GATEWAY PACKET LOSS",
      gwJitterChart: "GATEWAY JITTER",
      chromeExtension: "Chrome Extension",
      TestDate: "Test Date",
      CombineTime: "Combine Time",
      VideoResponseTimes: "Video Response Times",
      ImgResponseTimes: "Image Response Times",
      TabUrl: "Tab Url",
      responseTimes: "Response Times",
      speedResults: "Speed Test Results",
      responseResults: "Response Test Results",
      hourly: "Hourly",
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      yearly: "Yearly",
      endDate: "End Date:",
      startDate: "Start Date:",
      noDataWithFilter: "No Data with this filters",
      WifiSpeed: "Wi-Fi Speed",
      Apply: "Apply",
      SortBy: "Sort by",
      TimeRange: "Time Range",
      totalSavedCost: "Total costs saved",
      savedCostVisibility: "Costs saved due to visiblity",
      savedCostOptimization: "Costs saved due to optimization",
      Network: "Network",
      wifiConnection: "Wi-Fi Connection ",
      wifiTopology: "Wi-Fi Topology Metrics",
      overview: "Overview",
      analytics: "Analytics",
      aggregatedCustomerView: "Aggregated Customer View",
      networkAnalytics: "Network Analytics",
      wiredNetwork: "Wired Network",
      wifiAnalytics: "Wi-Fi Analytics",
      endUserAnalytics: "End-User Analytics",
      wifiInfrastructre: "Wi-Fi Infrastructure Analysis",
      customerBadExp: "Customers with Bad Experience",
      mapView: "Map View",
      customerDetails: "Customer Details",
      hardwareSoftwareAnalysis: "Hardware/Software Analysis",
      customerConnectionAnalytics: "Customer Connection Analytics",
      cxScore: "CX Score",
      cxTrend: "CX Trends",
      enterCode: "Enter Code",
      incomplete: "Incomplete",
      insufficientData: "Insufficient Data!",
      fidelity_: "Fidelity",
      interference_: "Interference",
      DeviceId: "Device Id",
      type: "Type",
      heatMapDetails: "Heat Map Details",
      Score: "Score",
      StreamLatency: "Streaming Latency",
      Fidelity: "Fidelity",
      LinkSpeed: "Link Speed",
      ConnectedDevices: "Connected Devices",
      Latency: "Latency",
      Ping: "Ping",
      AvgPingTime: "Delay",
      GwAvgPingTime: "Gateway Delay",
      GwJitter: "Gateway Jitter",
      GwPacketLoss: "Gateway Packet Loss",
      Jitter: "Jitter",
      NeigborCount: "Neighbor Count",
      PacketLoss: "Packet Loss",
      Quality: "Quality",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      downloadAppEnterGroupCode:
        "Please download the Application and enter the Group Code",
      toEnterTheGroupCode: "To enter the Group Code",
      goToProfile: 'Go to "Profile"',
      pressLoginTo: 'Press "Login to Ambeent"',
      enterTheGroupCodeAndDefineUserID:
        "Enter the Group Code and define a User ID name. ",
      onceYouEnterGroupCode:
        "Once you enter the Group Code, all tests that are run on the Application will automatically be linked to the dashboard with the same Group Code.",
      total: "Total",
      companyId: "Company ID",
      pop: "Pop",
      noProblemCount: "No Problem Count",
      hopProblemCount: "Hop Problem Count",
      userProblemCount: "User Problem Count",
      wifiProblemCount: "Wi-Fi Problem Count",
      popProblems: "Pop Problems",
      gwAvgPingTimeChartInfo:
        "Represents  ping time between the access point and the end user device.",
      gwPacketLossChartInfo:
        "Shows packet loss between the access point and the end user device.",
      gwJitterChartInfo:
        "Shows jitter between the access point and the end user device.",
      avgPingTimeChartInfo:
        "Measures roundtrip ping time for the end-to end to network.",
      packetLossChartInfo: "Measures  packet loss in the end-to end network.",
      jitterChartInfo: "Measures jitter in the end-to end network.",
      NEIGHBORINTERFERENCE: "NEIGHBOR INTERFERENCE",
      Value: "Value",
      Upload: "Upload",
      brandModel: "Brand-Model",
      currentChannel: "Current Channel",
      recommendedChannel: "Recommended Channels",
      accessPoint: "Access Point",
      availableOn: "Available on:",
      clientSDK:
        "Please contact us to integrate the Ambeent SDK into an application owned by your  organization",
      traceRoute: "Trace Route",
      hideDetails: "Hide Details",
      ClientIntegrations: "Client Integrations",
      OptimizationReqAverageBound: "Optimization Required Average Bound",
      interference: "Interference",
      frequency: "Frequency",
      "best Channel 2G": "best Channel 2 Ghz",
      "best Channel 5G": "best Channel 5 Ghz",
      clickonGoWifiConsole:
        "Click on “Go to Wi-Fi Console” on the App or go to the website https://enterprise.ambeent.ai/#  and click on “Sign up.” ",
      createYourOwnPage:
        "You will be directed to the “Create your own account page.” Please fill in details and make sure to choose “Enterprise- Home” under Company type. You can also sign up with your google account by choosing “Sign up with Google.”",
      receiveConfirmationMail:
        "You will receive a confirmation email to activate your account. Once you press “Activate Account” you will be directed to the Console where you can find your personal group code on the lower left side .       ",
      goBackToAppFollowSteps:
        "You can then go back to the Application and do the following steps to link tests to your personal dashboard: ",
      userGuideSettingDashboard:
        "User guide for setting up a personal dashboard",
      allTypes: "All Types",
      day: "Day",
      disableMonth: "Data is not provided for the first day of the month. Please try again tomorrow.",
      disableWeek: "Data is not provided for the first day of the week. Please try again tomorrow.",
      resetThreshold: "Values are reseted. Changes will take effect in next hour.",
      IFD:"Information From Device",
      IFA:"Information From Access Point",
    },
  },
  tr: {
    translation: {
      Ambeent: "AMBEENT",
      Welcome: "Ambeent'e Hoşgeldiniz",
      shortCodeExplanation:
        "Artık Wi-Fi ağınızı tamamen analiz ve optimize etmeye başlamak için gurup kodunuz ile diğer mobil cihazları eşleştirebilirsiniz.",
      code: "Şirket kodunuz :",
      downloadLabel:
        "Ambeent'in sağladığı WiFi Console uygulamasını aşağıdaki uygulama pazarlarından indirebilirsiniz",
      signIn: "Giriş Yap",
      signUp: "Kayıt Ol",
      email: "E-posta",
      password: "Şifre",
      submit: "GİRİŞ",
      forgot: "Şifrenizi mi unuttunuz?",
      name: "İsim",
      surname: "Soyisim",
      eemail: "E-posta Adresi",
      giata: "GIATA ID (Opsiyonel)",
      group: "Grup İsmi",
      newpassword: "Yeni Şifre",
      confirmpw: "Şifre Onay",
      sbmt: "ONAYLA",
      groupInfo:
        "Grup ev, otel, alış veriş merkezi veya bir internet servis sağlayıcı olabilir.",
      giataInfo: "Otel ID'niz",
      firsnameerror: "İsim boş bırakılamaz!",
      lastnameerror: "Soyisim boş bırakılamaz!",
      emailerror: "E-mail Adres boş bırakılamaz!",
      groupnameerror: "Grup ismi boş bırakılamaz!",
      passworderror: "Şifre boş bırakılamaz!",
      confirmpassworderror: "Şifre Onayı boş bırakılamaz!",
      emailexist: "E-mail zaten kayıtlı!",
      groupexist: "Grup zaten kayıtlı!",
      notsamepw: "Şifreniz ve Şifre Onayı eşleşmemektedir!",
      giataexist: "GIATA ID zaten kayıtlı!",
      activeuser: "AKTİF KULLANICI",
      activeUser: "AKTİF KULLANICI",
      today: "Bugün",
      week: "Hafta",
      month: "Ay",
      quarter: "Çeyrek",
      year: "Yıl",
      Modems: "İZLENEN MODEM SAYISI",
      modems: "İZLENEN MODEM SAYISI",
      MEASUREMENTS: "ALINAN ÖLÇÜM SAYISI",
      measurements: "ALINAN ÖLÇÜM SAYISI",
      FIDELITY: "PERFORMANS",
      speedtest: "HIZ TESTİ",
      linkspeed: "BULUŞMA HIZI",
      modemhealth: "MODEM SAĞLIK BİLGİSİ",
      cpe: "MODEM LOKASYONU",
      coverage: "KAPSAMA KALİTESİ",
      wifispeed: "HESAPLANAN Wi-Fi HIZI",
      localatency: "BEKLEME SÜRESİ",
      nocd: "BAĞLI OLAN CİHAZ SAYISI",
      fidelityInfo:
        "Alınan sinyal seviyesinin komşu modemlere göre gücünü gösterir.",
      speedTestInfo:
        "Ambeent algoritması ile internet üzerinden uçtan uca (sunucudan cihaza) internet hızının ölçümlerini ve tahminlerini gösterir(Ookla'ya benzer).",
      linkSpeedInfo:
        "Modemin bağlı olduğu Wi-Fi cihazı ile kurduğu anlık buluşma hızını gösterir ve tahmin eder.",
      modemInfo:
        "Modeminizin kapsama kapasitesini ve doğru yerleştirmesini birlikte değerlendirir.",
      cpeInfo:
        "Modemin konumunun doğruluğunu kullanıcı deneyimine göre değerlendirir. Yanlış konumlanmış modemlerin yerlerlerinin değiştirilmesini önerebilirsiniz.",
      coverageInfo:
        "Ambeent'in özel algoritması ile alınan sinyal gücüne (RSSI) göre kapsama sorunları olup olmadığının gözlemler ve tahmim eder.",
      wifiSpeedInfo:
        "Ambeent'in özel algoritması sayesinde erişim noktası ile kullanıcı cihazı arasındaki hız ölçümlerini gösterir ve tahmin eder (kablosuz Wi-Fi bağlantı hızı).",
      latencyInfo:
        "Modem ile cihaz arasındaki veri iletişiminde yaşanan gecikme süresini gösterir.",
      connectedDeviceInfo:
        "Modeme aynı anda bağlı bulunan cihaz sayısını gösterir.",
      neighbourModems: "Komşu Modemler",
      connectedDevice: "Bağlı Cihazlar",
      server: "Sunucu",
      bestChannel: "En İyi Kanallar",
      bestChannel5ghz: "Kanallar",
      streaming: "Video Kalitesi",
      fidelity: "Sinyal Performansı",
      Wifispeed: "Hesaplanan Wi-Fi Hızı",
      SpeedTest: "Hız Testi",
      Linkspeed: "Buluşma Hızı",
      Coverage: "Kapsama Kalitesi",
      Select: "Modeminizi Seçin",
      BrandModel: "Marka-Model",
      BrandModelChannel: "Marka - Model - Kanal",
      CPE: "Modem Lokasyonu",
      OPerformance: "Genel Performans",
      Dashboard: "Konsol",
      Details: "Detaylar",
      Filter: "Filtre",
      Logout: "Çıkış",
      Map: "Harita",
      CustomerID: "Müşteri No",
      saveExplanation:
        "Yaptığınız değişiklikler gelecek saat içinde etki edecektir. ",
      saveError:
        "Bir aksaklıktan dolayı yeni eşik değerlerinin güncellemesi gerçekleşmedi. Lütfen daha sonra tekrar deneyiniz. ",
      noChange: "Kaydedilecek herhangi bir değişiklik yapmadınız! ",
      threshold: "EŞİK DEĞERLERİ ",
      thresholdExplanation:
        "Eşik değerlerini düzenleyerek istatistiki verilerinizi daha detaylı inceleyebilirsiniz. Değişken eşik değerleri ile dönemsel incelemeler yapılabilir. ",
      save: "KAYDET",
      defaultValues: "SIFIRLA",
      Settings: "Ayarlar",
      SelectClient: "Customer ID seçiniz",
      chnageThemeTooltip: "Tema değiştir",
      lastDownloadSpeed: "Son İndirme Hızı",
      generalSpeedTest: "Genel İndirme Hızı",
      lastUploadSpeed: "Son Yükleme Hızı",
      activate: "Şu e-posta adresine bir onay maili yolladık ",
      activateNote:
        " Kaydolduktan birkaç dakika içinde, bir onay e-postası almazsanız, lütfen Önemsiz Klasörünüzü kontrol edin.",
      reset: "SIFIRLA",
      enterEmail: "Şifrenizi sıfırlamak için lütfen e-posta adresinizi yazın",
      foractivateNotegotten: "Şifrenizi mi unuttunuz?",
      emailsent:
        "E-posta gönderildi. Posta kutunuzu kontrol edin. Kaydolduktan birkaç dakika içinde, bir onay e-postası almazsanız,lütfen Önemsiz Klasörünüzü kontrol edin.",
      emailsenterror: "Böyle bir e-posta adresi bulunamadı!",
      ResetPassword: "Şifreyi Sıfırla",
      loginPage: "Giriş sayfası",
      permission: "Bu sayfaya erişim izniniz yok",
      resetPasswordError: "Yeni şifreniz önceki şifrenizden farklı olmalıdır!",
      resetdataError: "Bir şeyler yanlış gitti!",
      passwordChanged: "Şifre değişti",
      Incorrect: "Hatalı şifre ve ya e-posta!",
      Account: "Hesap",
      companyName: "Grup İsmi",
      userSettings: "Kullanıcı Ayarları",
      companySettings: "Grup Ayarları",
      changePhoto: "Profil Resmini Değiştir",
      setRole: "Rol Seçmek İçin Tıkla",
      send: "GÖNDER",
      checkData: "Son 1 saat için data yok",
      getData: "Son 1 saat içinde gelen datalara bakılıyor",
      inviteCompany: "Gruba Davet Et!",
      resend: "Aktivasyon Mailini Tekrar Gönder!",
      updateInfo: "*Bilgilerinizi bu sayfadan güncelleyebilirsiniz.",
      traceDetail: "Şebeke Detayı",
      hopNumber: "Hop Numarası",
      firstResponse: "İlk Cevap",
      secondResponse: "İkinci Cevap",
      thirdResponse: "Üçüncü Cevap",
      noProblem: "Problem yok",
      wifiProblem: "Wi-Fi problemi",
      hopProblem: "Hop Problemi",
      userProblem: "Kullanıcı kullanımı",
      date: "Tarih",
      problemComment: "Problem Yorumu",
      inviteInfo:
        "*Kullanıcıları yalnızca bir tıklamayla konsola davet edebilirsiniz. Hesaplarını ayarlamak ve konsola erişmek için bağlantı içeren bir e-posta alacaklar.",
      newUser: "Katıl",
      faqs: "SSS ve YARDIM",
      howTo: "Nasıl Kullanırım?",
      didntchooserole: "Rol seçmediniz!",
      emptymail: "E-Posta adresi boş bırakılamaz!",
      mailsent:
        "Mail Gönderildi. Kaydolduktan birkaç dakika içinde, bir onay e-postası almazsanız,lütfen Önemsiz Klasörünüzü kontrol edin.",
      alreadyregistered: " Bu e-posta adresi zaten kayıtlı!",
      Routers: "Modemler",
      clickHere: "Kurulum bilgisi için tıklayınız",
      clickBrand: "Model dağılımını görmek için markaya tıklayın",
      models: " Modelleri",
      recommendation: "Öneri",
      calculating: "Öneriniz hesaplanıyor, lütfen bekleyin",
      routerError: "Gösterilecek veri yok",
      recommendationError:
        "Şu anda bir öneri için yeterli veri yok. Lütfen Ambeent uygulamasını kullanmaya devam edin",
      noData: "Veri yok",
      search: "Ara",
      next: "İleri",
      back: "Geri",
      changecharts: "Grafikleri Değiştir",
      Devices: "Cihazlar",
      DevicesAndroidRelase: "Android Sürümlerinin Kullanım Oranları",
      DevicesIOSRelase: "iOS Sürümlerinin Kullanım Oranları",
      DevicesAndroidBrand: "Android Cihazların Kullanım Oranları",
      DevicesBrand: "Cihaz Marka Dağılımı",
      RoutersBrand: "Modemlerin Kullanım Oranları",
      RoutersBrandPercent: "Modemlerin Kullanım Oranları (Yüzdesel)",
      TOTAL: "TOPLAM : ",
      trace: "Şebeke",
      good: "İyi",
      bad: "Kötü",
      medium: "Orta",
      turkey: "Türkiye",
      turkTelekom: "Türk Telekom",
      turkNet: "TürkNet",
      updated: "Güncellendi",
      notupdated: "Güncellenemedi",
      unidentified: "Tanımlanamayan",
      identified: "Tanımlanan",
      traceDetails: "Şebeke",
      location: "Lokasyon",
      History: "Geçmiş",
      heatMap: "Sinyal Sıcaklık Haritası",
      gridView: "Grid View",
      news: "Güncel haberler için tıkla!",
      Current: "Şu Anki Durum",
      After3MonthPrediction: "3 Ay Sonraki Öngörülen Durum",
      After6MonthPrediction: "6 Ay Sonraki Öngörülen Durum",
      requiredCustomerId: "Lütfen müşteri no yazınız!",
      invalidFirstName:
        "İsim yalnızca sayı, harf ve _, ., - karakterlerinden oluşmalıdır",
      invalidLastName:
        "Soyisim yalnızca sayı, harf ve _, ., - karakterlerinden oluşmalıdır",
      emailFormatError:
        "Lütfen eposta adresini şu formatta giriniz: isim@example.com",
      specialCharError:
        "Giata id ya da grup isminde özel karakterler kullanılamaz",
      tracetable: "Şebeke Problem Analiz Tablosu",
      clickToDetails: "Detayları görmek için ikonlara tıklayınız",
      showDetails: "Detayları göster",
      Name: "İsim",
      employeeID: "Çalışan No",
      status: "Durum",
      action: "Aksiyon",
      brand: "Marka",
      macAddress: " MAC Adresi",
      teamMembers: "Takım arkadaşları",
      Members: "Üyeler",
      wifiHealth: "Wi-Fi Sağlığı",
      ISP: "İnternet Sağlayıcı",
      add: "Ekle",
      backGrid: "< Geri",
      apNames: "Modem İsmi",
      delete: "Sil",
      roomName: "Oda İsmi",
      roomList: "Oda Listesi",
      apList: "Modem Listesi",
      roomAdded: "Oda başarıyla eklendi",
      roomCantAdded: "Oda eklenemedi",
      roomCantEmpty: "Oda ismi boş bırakılamaz!",
      roomDeleted: "Oda başarıyla silindi",
      roomCantDeleted: "Oda silinemedi",
      apAdded: "Modem başarıyla eklendi",
      apCantAdded: "Modem eklenemedi",
      apCantEmpty: "Modem ismi ya da MAC adresi boş bırakılamaz!",
      apDeleted: "Modem başarıyla silindi",
      apCantDeleted: "Modem silinemedi",
      allCantEmpty: "Alanlar boş bırakılamaz!",
      memberAdded: "Çalışan başarıyla eklendi",
      memberCantAdded: "Çalışan eklenemedi",
      memberDeleted: "Çalışan silindi",
      memberCantDeleted: "Çalışan silinemedi",
      complainInfo:
        "Kaç kullanıcının bağlantısı hakkında şikayeti olabileceğini gösterir",
      Complain: "ŞİKAYET",
      streamingQuality: "YAYIN KALİTESİ",
      StreamingQuality: "Yayın Kalitesi",
      streamingLatency: "YAYIN GECİKME SÜRESİ",
      streamingLatencyInfo:
        "Multimedya uygulamalarındaki uçtan uca gecikmeyi hesaplar ve tahmin eder.",
      streamingQualityInfo:
        "Kullanıcının ulaşabileceği video yayın kalitesini  (örneğin: 1080p, 720p, 360p, 144p) gözlemler ve tahmin eder.",
      optRequiredInfo:
        "Kanal atamasının optimize edilmesi gereken modem sayısını hesaplar ve tahmin eder.",
      optRequired: "OPTİMİZASYON GEREĞİ",
      OptRequired: "Optimizasyon Gereği",
      customerService: "Müşteri Hizmetleri",
      worstCustomers: "Kötü Deneyim Yaşayan Müşteriler",
      complain: "Şikayet",
      model: "Model",
      neighborCount: "Komşu Sayısı",
      speed: "Hız",
      quality: "Kalite",
      latency: "Gecikme",
      lastChannel: "Son Kanal",
      downloadSpeed: "İndirme Hızı",
      Speedtest: "Hız Testi",
      detailsTooltip: "Detayları görmek için tıklayınız",
      setTimeZone: "Saat dilimi seçmek için tıklayın",
      timeZone: "Saat Dilimi",
      timeZoneError: "Saat dilimi seçilmesi zorunludur!",
      timeZoneChangeTitle: "Saat dilimi",
      timeZoneChange: "Yeni bir zaman dilimi seçebilirsiniz.",
      timeZoneChanged: "Saat dilimi değişti",
      timeZoneCantChanged: "Saat dilimi değişemedi",
      addAP:
        "*Modem bilgilerini elle girerek ya da aşağıdaki listeden seçip ekleyebilirsiniz.",
      twoAPSelected: "Modemi ya manuel ya da listeden eklemeniz gerekmektedir!",
      routerList: "Modem Listesi",
      score: "Skor",
      Throughput: "Veri Aktarım Kapasitesi",
      Rssi: "Alınan Sinyal Güç Göstergesi",
      coveragerouter: "Kapsama Kalitesi",
      selectedModel: "Seçilen Model: ",
      watchWebinar:
        "Ambeent platformunun bir İnternet Servis Sağlayıcıda nasıl çalıştığını izleyin!",
      slides: "Sunum ",
      webinar: "Video",
      signInTitle: "Hesabınıza giriş yapın",
      signUpLabel: "Hesabınız yok mu?",
      signUpTitle: "Hesabınızı oluşturun",
      createAccount: "Hesap oluştur",
      haveAccount: "Bir hesabım var.",
      cloudApi: "Api Anahtarı",
      nodata: "Data Yok!",
      Lowest: "En düşük",
      downloadFile: "Dosya indir",
      download: "İndir",
      stasTable: "Stas Tablosu",
      stasMac: "Cihaz Mac Adresi",
      ModemMac: "Modem Mac Adresi",
      createdAt: "Tarihinde oluşturuldu",
      ssid: "Ssid",
      channel: "Kanal",
      channelWidth: "Kanal genişliği",
      sessionId: "Session ID",
      modemLocation: "Modem lokasyonu",
      measurementsTable: "Ölçüm tablosu",
      count: "Miktar",
      avgPingTime: "Ortalama Ping Süresi",
      packetLoss: "Paket kaybı",
      gwAvgPingTime: "Ağ geçidi Ortalama Ping Süresi",
      gwPacketLoss: "Ağ geçidi Paket kaybı",
      jitter: "Jitter",
      gwJitter: "Ağ geçidi Jitter",
      pingStasTable: "Ping Stas Tablosu",
      pingMeasurementsTable: "Ping Ölçüm Tablosu",
      pingTime: "Ping Süresi",
      hostName: "Host adı",
      uploadSpeed: "Yükleme hızı",
      ping: "Ping",
      speedTestTable: "Hız Testi Tablosu",
      streamingTable: "Yayın Tablosu",
      deviceLocation: "Cihaz lokasyonu",
      noRegisteredApi: "Kayıtlı API anahtarı yok!",
      cloudName: "Bulut Adı",
      apiKeyAddClouds: "Bulut eklemek için bir Api Anahtarı girin.",
      refreshAllClouds: "Tüm Bulutları Yenile",
      Activate: "Etkinleştir",
      cloudId: "Bulut Id",
      waitForSites:
        "İstek işlenmek üzere kabul edildi, lütfen sitelerinizin çevrimiçi olmasını bekleyin.",
      addApi: "Lütfen bir Api anahtarı ekleyin!",
      activateSites:
        "Lütfen bulutunuzu etkinleştirdiğinizden emin olun! Zaten yaptıysanız, siteniz olmayabilir.",
      completeYourAccount: "Hesap bilgilerinizi tamamlayın",
      setgroup: "Grup adınız",
      homeenterprise: "Home - Enterprise",
      gmtplus1: "Avrupa Merkez Saati",
      gmtplus2: "Doğu Avrupa Saati",
      gmtplus3: "Doğu Afrika Saati",
      gmtplus4: "Yakın Doğu Saati",
      gmtplus5: "Pakistan Lahor Saati",
      gmtplus6: "Bangladeş Standart Saati",
      gmtplus7: "Vietnam Standart Saati",
      gmtplus8: "Çin Tayvan Saati",
      gmtplus9: "Japonya Standart Saati",
      gmtplus10: "Avustralya Doğu Saati",
      gmtplus11: "Solomon Standart Saati",
      gmtplus12: "Yeni Zelanda Standart Saati",
      gmtplus13: "Fiji Yaz Saati",
      gmtminus11: "Midway Adaları Saati",
      gmtminus10: "Hawaii Standart Saati",
      gmtminus9: "Alaska Standart Saati",
      gmtminus8: "Pasifik Standart Saati",
      gmtminus7: "Phoenix Standart Saati",
      gmtminus6: "Merkezi Standart Saat",
      gmtminus5: "Doğu standart zamanı",
      gmtminus4: "Porto Riko ve ABD Virjin Adaları Saati",
      gmtminus3: "Brezilya Doğu Saati",
      gmtminus2: "Brezilya Yaz Saati",
      gmtminus1: "Orta Afrika Saati",
      gmt0: "Greenwich Ortalama Saati",
      resetPassword: "Şifre Yenileme",
      notAvailable: "Kullanım Dışı",
      TestDate: "Test Tarihi",
      CombineTime: "Birleştirilme Zamanı",
      VideoResponseTimes: "Video Yanıt Süreleri",
      ImgResponseTimes: "Görüntü Yanıt Süreleri",
      TabUrl: "Sekme URL'si",
      responseTimes: "Yanıt Süreleri",
      speedResults: "Hız Testi Sonuçları",
      responseResults: "Yanıt Testi Sonuçları",
      hourly: "Saatlik",
      daily: "Günlük",
      weekly: "Haftalık",
      monthly: "Aylık",
      yearly: "Yıllık",
      endDate: "Bitiş Tarihi:",
      startDate: "Başlangıç Tarihi:",
      noDataWithFilter: "Bu filtreler ile data yok!",
      WifiSpeed: "Wi-Fi Hızı",
      Apply: "Uygula",
      SortBy: "Sırala:",
      TimeRange: "Zaman Aralığı",
      totalSavedCost: "Kaydedilen toplam maliyetler",
      savedCostVisibility: "Görünürlük sayesinde tasarruf edilen maliyetler",
      savedCostOptimization:
        "Optimizasyon sayesinde tasarruf edilen maliyetler",
      Network: "Ağ",
      wifiConnection: "Wi-Fi Bağlantısı",
      wifiTopology: "Wi-Fi Topolojisi ",
      overview: "Genel Bakış",
      analytics: "Analitikler",
      customerConnectionAnalytics: "Müşteri Bağlantı Analitikleri",
      aggregatedCustomerView: "Toplu Müşteri Görünümü",
      networkAnalytics: "Ağ Analitikleri",
      wiredNetwork: "Wired Network",
      wifiAnalytics: "Wi-Fi Analitikleri",
      endUserAnalytics: "Son Kullanıcı Analitikleri",
      wifiInfrastructre: "Wi-Fi altyapı analizi",
      customerBadExp: "Kötü Deneyimli Müşteriler",
      mapView: "Harita",
      customerDetails: "Müşteri Detayları",
      hardwareSoftwareAnalysis: "Donanım/Yazılım Analizleri",
      cxScore: "Müşteri Deneyimi Puanı",
      cxTrend: "Müşteri Deneyimi Trendleri",
      ClientIntegrations: "Entegrasyonlar",
      enterCode: "Kod Giriniz",
      incomplete: "Tamamlanmamış",
      wifi: "Wi-Fi",
      DeviceId: "Cihaz Numarası",
      type: "Tip",
      Score: "Skor",
      StreamLatency: "Yayın Gecikme Süresi",
      Fidelity: "Sinyal Performansı",
      LinkSpeed: "Link Speed",
      ConnectedDevices: "Connected Devices",
      Latency: "Latency",
      Ping: "Ping",
      AvgPingTime: "Delay",
      GwAvgPingTime: "Gateway Delay",
      GwJitter: "Gateway Jitter",
      GwPacketLoss: "Gateway Packet Loss",
      Jitter: "Jitter",
      NeigborCount: "Neighbor Count",
      PacketLoss: "Packet Loss",
      Quality: "Quality",
      monday: "Pazartesi",
      tuesday: "Salı",
      wednesday: "Çarşamba",
      thursday: "Perşembe",
      friday: "Cuma",
      saturday: "Cumartesi",
      sunday: "Pazar",
      downloadAppEnterGroupCode:
        "Lütfen uygulamayı indirin ve grup kodunu girin",
      toEnterTheGroupCode: "Grup kodunu girmek için",
      goToProfile: '"Profil"e gidin',
      pressLoginTo: '"Ambeent\'e Giriş Yap" a basın',
      enterTheGroupCodeAndDefineUserID:
        "Grup kodunu girin ve bir kullanıcı kimliği adı tanımlayın.",
      onceYouEnterGroupCode:
        "Grup kodunu girdikten sonra, uygulama üzerinde çalıştırılan tüm testler, aynı grup kodu ile otomatik olarak kontrol paneline bağlanacaktır.",
      total: "Toplam",
      companyId: "Şirket ID",
      pop: "Pop",
      noProblemCount: "Problem Yok Sayısı",
      hopProblemCount: "Hop Problem Sayısı",
      userProblemCount: "Kullanıcı Problem Sayısı",
      wifiProblemCount: "Wi-Fi Problem Sayısı",
      popProblems: "Pop Problemleri",
      NEIGHBORINTERFERENCE: "Komşu Kanal Enterferansı",
      Value: "Değer",
      Upload: "Yükleme",
      brandModel: "Marka-Model",
      currentChannel: "Mevcut Kanal",
      recommendedChannel: "Önerilen Kanallar",
      accessPoint: "Modem",
      gwAvgPingTimeChartInfo:
        "Son kullanıcı cihazı ile modem arasındaki ping süresini temsil eder.",
      gwPacketLossChartInfo:
        "Son kullanıcı cihazı ile modem arasındaki packet loss değerini temsil eder.",
      gwJitterChartInfo:
        "Son kullanıcı cihazı ile modem arasındaki jitter değerini temsil eder.",
      packetLossChartInfo: "Uçtan uca networkteki packet loss değerini ölçer.",
      jitterChartInfo:
        "Ağdaki uçtan uca jitter ölçümlerini gösterir ve tahmin eder.",
      avgPingTimeChartInfo:
        "Ağa uçtan uca gidiş dönüş ping süresinin ölçümlerini ve tahminleriniş gösterir.",
      availableOn: "Ulaşılabilir:",
      clientSDK:
        "Ambeent SDK'yı kuruluşunuza ait bir uygulamaya entegre etmek için lütfen bizimle iletişime geçin!",
      traceRoute: "Şebeke Yolu",
      interference_: "Enterferans",
      hideDetails: "Detayları Gizle",
      heatMapDetails: "Sinyal Sıcaklık Haritası Detaylı Görünüm",
      OptimizationReqAverageBound: "Optimizasyon Gerekli Ortalama Sınırı",
      interference: "Enterferans",
      frequency: "Frekans",
      "best Channel 2G": "en iyi kanal 2 Ghz",
      "best Channel 5G": "en iyi kanal 5 Ghz",
      clickonGoWifiConsole:
        "Uygulamada “Wi-Fi Konsoluna Git”e tıklayın veya https://enterprise.ambeent.ai/# web sitesine gidin ve “Kaydol”a tıklayın. ",
      createYourOwnPage:
        "“Kendi hesabınızı oluşturun” sayfasına yönlendirileceksiniz. Lütfen ayrıntıları doldurun ve Şirket türü altında “Enterprise-Home”u seçtiğinizden emin olun. Ayrıca “Google ile Kaydol” seçeneğini seçerek google hesabınızla kaydolabilirsiniz.”",
      receiveConfirmationMail:
        "Hesabınızı etkinleştirmek için bir onay e-postası alacaksınız. “Hesabı Etkinleştir”e bastığınızda, sol alt tarafta kişisel grup kodunuzu bulabileceğiniz Konsola yönlendirileceksiniz.       ",
      goBackToAppFollowSteps:
        "Ardından Uygulamaya geri dönebilir ve testleri kişisel kontrol panelinize bağlamak için aşağıdaki adımları uygulayabilirsiniz:",
      userGuideSettingDashboard:
        "Kişisel bir kontrol paneli oluşturmak için kullanıcı kılavuzu",
      allTypes: "Tüm Tipler",
      day: "Gün",
      disableMonth: "Ayın ilk günü için veri sağlanamıyor. Lütfen yarın tekrar deneyiniz.",
      disableWeek: "Haftanın ilk günü için veri sağlanamıyor. Lütfen yarın tekrar deneyiniz.",
      resetThreshold: "Değerler sıfırlandı, etkisi gelecek saat içinde görülecektir.",
      IFD:"Cihazdan Gelen Bilgiler",
      IFA:"Modemden Gelen Bilgiler",
    },
  },
};

export default translations;
