//Singleton services
import {
  AndroidReleaseService,
  AndroidReleasePredictionService,
  IOSReleasePredictionService,
  DeviceManufacturerDistService,
} from "../..";

const resetDevices = () => {
  //Singleton Objects
  var androidReleaseSubject = AndroidReleaseService.getInstance();
  var androidReleasePrediction = AndroidReleasePredictionService.getInstance();
  var iosReleasePrediction = IOSReleasePredictionService.getInstance();
  var deviceManufacturerDist = DeviceManufacturerDistService.getInstance();

  androidReleaseSubject.reset();
  androidReleasePrediction.reset();
  iosReleasePrediction.reset();
  deviceManufacturerDist.reset();
};

export default resetDevices;
