import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Popup from "reactjs-popup";

const TimeMenu = (props) => {
  const { t } = props;
  const [activeItem, setActiveItem] = useState("today");

  function changeTime() {
    var id = document.getElementById("selecTime").value;
    document.getElementById(id).click();
  }

  return (
    <div id="timeMenu" className="row mt-3">
      <ul className="buttonwrapper" id="ul">
        {/* TODAY */}
        <li id="today" className={activeItem === "today" ? "active" : ""}>
          <label
            id="l1"
            onClick={() => {
              props.settimecategory("D");
              props.setbackdate(new Date());
              setActiveItem("today");
            }}
          >
            {t("today")}
          </label>
        </li>

        {/* WEEK */}
        {new Date().getDay() === 1 ? (
          <Popup
          trigger={<li id="week" className={activeItem === "week" ? "active" : ""}><label id="l2">{t("week")}</label></li>}
          modal
        >
          <div style={{ color: "#181745" }}>{t("disableWeek")}</div>
        </Popup>
        ) : (
          <li id="week" className={activeItem === "week" ? "active" : ""}>
          <label
            id="l2"
            onClick={() => {
              props.settimecategory("W");
              props.setbackdate(new Date());
              setActiveItem("week");
            }}
          >
            {t("week")}
          </label>
        </li>
        )}
      
        {/* MONTH */}
        {new Date().getDate() === 1 ? (
          <Popup
          trigger={<li id="month" className={activeItem === "month" ? "active" : ""}><label id="l3">{t("month")}</label></li>}
          modal
        >
          <div style={{ color: "#181745" }}>{t("disableMonth")}</div>
        </Popup>
          ) : (
          <li id="month" className={activeItem === "month" ? "active" : ""}>
            <label
              id="l3"
              onClick={() => {
                props.settimecategory("M");
                props.setbackdate(new Date());
                setActiveItem("month");
              }}
            >
              {t("month")}
            </label>
          </li>
        )}
        {/* QUARTER */}
       {/* <li id="quarter" className={activeItem === "quarter" ? "active" : ""}>
          <label
            id="l4"
            onClick={() => {
              props.settimecategory("Q");
              props.setbackdate(new Date());
              setActiveItem("quarter");
            }}
          >
            {t("quarter")}
          </label>
        </li>
        {/* YEAR */}
        {/*<li id="year" className={activeItem === "year" ? "active" : ""}>
          <label
            id="l5"
            onClick={() => {
              props.settimecategory("Y");
              props.setbackdate(new Date());
              setActiveItem("year");
            }}
          >
            {t("year")}
          </label>
        </li>*/}
      </ul>

      <select
        id="selecTime"
        name="select-native-1"
        className="col-11"
        onChange={changeTime}
      >
        <option value="l1">{t("today")}</option>
        <option value="l2">{t("week")}</option>
        <option value="l3">{t("month")}</option>
        <option value="l4">{t("quarter")}</option>
        <option value="l5">{t("year")}</option>
      </select>
    </div>
  );
};

export default withTranslation()(TimeMenu);
