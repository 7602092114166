import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { GoogleMap, Marker } from "@react-google-maps/api";
import UseObservable from "../../../Helpers/UseObservable";
import { RouterInfoService } from "../../../Classes";
import initialStates from "../../../Classes/InitialStates/details.InitialStates";

const style = {
  width: "95%",
  height: "87%",
  position: "none",
  marginRight: "10px",
  marginLeft: "10px",
};

const DetailsMap = (props) => {
  const { t } = props;
  const { data: routerInfo } = UseObservable({
    observable: RouterInfoService.getInstance().get,
    initialState: initialStates.routerInfo,
  });
  const [zoom, setZoom] = useState(4);

  // !!!if zoom state doesnt change map doesnt mark and center
  useEffect(() => {
    if (routerInfo.latitude !== 0 && routerInfo.longitude !== 0) {
      setZoom(10);
    } else {
      setZoom(4);
    }
  }, [routerInfo]);

  return (
    <div>
      <div className="details-chart-title"> {t("location")} </div>
      {routerInfo.latitude !== 0 && routerInfo.longitude !== 0 && routerInfo.latitude !== 404 && routerInfo.latitude !== 404? (
        <GoogleMap
          zoom={zoom}
          style={style}
          center={{
            lat: routerInfo.latitude,
            lng: routerInfo.longitude,
          }}
          mapContainerStyle={{
            width: "auto",
            height: "400px",
          }}
        >
          <Marker
            name={"Current location"}
            position={{
              lat: routerInfo.latitude,
              lng: routerInfo.longitude,
            }}
            icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
          ></Marker>
        </GoogleMap>
      ) : (
        <div id="noLocation">{t("noData")}</div>
      )}
    </div>
  );
};

export default withTranslation()(DetailsMap);
