import React, { memo } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OptionItem = ({t, handleSelectOption, key, pathName, optionTitleId }) => {
  return (
    <li
      className={
        window.location.pathname === pathName
          ? "left-option active"
          : "left-options"
      }
      key={key}
      onClick={() => {
        handleSelectOption(pathName);
      }}
    >
      <Link to={pathName} id={pathName}>{t(optionTitleId)}</Link>
    </li>
  );
};

export default memo(withTranslation()(OptionItem));
