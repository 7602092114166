import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";


class ChromeExtension {
  chromeExtensionSubject;
  constructor() {
    this.chromeExtensionSubject = new BehaviorSubject(initialStates.chromeExtension);
  }

  //get chrome extension object
  get get() {
    return this.chromeExtensionSubject.asObservable();
  }

  //fetch data from /extension/GetExtensionData?companyId=${companyId} endpoint
  fetchData(companyId) {
    this.reset()
    apis.getChromeExtension(companyId).then((response) => {
      this.chromeExtensionSubject.next(response);
    });
  }

  //reset chrome extension Subject objects state
  reset() {
    this.chromeExtensionSubject.next(initialStates.chromeExtension);
  }
}

const ChromeExtensionService = (function () {
  var instance;

  function createInstance() {
    var classObj = new ChromeExtension();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default ChromeExtensionService;
