import initialStates from "../../../Classes/InitialStates/customerService.initialStates";

const handleGetCustomerServiceChart = (response) => {
  if (response.data) {
    let customersDataChart = [];
    let customersDataChartTR = [];
    customersDataChart.push([
      "Day",
      "Good",
      { role: "style" },
      { role: "tooltip" },
      "Medium",
      { role: "style" },
      { role: "tooltip" },
      "Bad",
      { role: "style" },
      { role: "tooltip" },
      "Optimization Count",
      { role: "style" },
      { role: "tooltip" },
    ]);
    customersDataChartTR.push([
      "Gün",
      "İyi",
      { role: "style" },
      { role: "tooltip" },
      "Orta",
      { role: "style" },
      { role: "tooltip" },
      "Kötü",
      { role: "style" },
      { role: "tooltip" },
      "Optimizasyon Sayısı",
      { role: "style" },
      { role: "tooltip" },
    ]);
    response.data.forEach((element) => {
      const data = [
        element.date.slice(0, 10),
        element.good,
        "color: #58eda5;",
        element.good,
        element.medium,
        "color: #56caf5;",
        element.medium,
        element.bad,
        "color: #f55f81;",
        element.bad,
        element.optCount,
        "color: orange;",
        element.optCount,
      ];
      customersDataChart.push(data);
      customersDataChartTR.push(data);
    });
    return {
      customerChart: customersDataChart,
      customerChartTR: customersDataChartTR,
      isFetched: true,
    };
  } else {
    return initialStates.customerDataChart;
  }
};

const handleGetBadCustomers = (response) => {
  if (response.data) {
    let worstCustomers = [];
    // console.log("ISTENEN DATA  " + JSON.stringify(response.data));
    response.data.forEach((element) => {
      // console.log("Values    " + element.CustomerId);
      worstCustomers.push({
        customerID: element.CustomerId,
        macAddress: element.MacAddress,
        brand: element.Brand,
        model: element.Model,
        location: element.Location,
        rssi: element.Rssi,
        fidelity: element.Fidelity,
        linkSpeed: element.LinkSpeed,
        wifiSpeed: element.WifiSpeed,
        neighborCount: element.NeighborCount,
        connectedDevice: element.ConnectedDevice,
        speed: element.Speed,
        quality: element.Quality,
        latency: element.Latency,
        score: element.Score,
      });
    });
    return {
      worstCustomers: worstCustomers,
      data: response.data,
      isFetched: true,
    };
  } else {
    return initialStates.badCustomers;
  }
};

const responseHandlers = {
  handleGetCustomerServiceChart,
  handleGetBadCustomers,
};

export default responseHandlers;
