import i18next from "i18next";
import React from "react";
import ProgressBar from "react-customizable-progressbar";

const ProgressGraph = ({ progress, color, title, customValue }) => {
  let value =
    customValue === undefined
      ? Number.isNaN(progress)
        ? ""
        : Math.round(progress) + "%"
      : Math.round(customValue);

  return (
    <div>
      <ProgressBar
        progress={Number.isNaN(progress) ? "" : progress}
        radius={200}
        strokeColor={color}
        trackStrokeWidth={14}
        cut={120}
        rotate={-210}
      />
      <div
        className="BoxTitleValue"
        style={{ fontSize: "24px", marginTop: "-75px" }}
      >
        {i18next.language === "tr"
          ? Number.isNaN(value)
            ? ""
            : "%" + value
          : Number.isNaN(value)
          ? ""
          : value + "%"}
      </div>
      <p
        style={{
          color: "white",
          fontSize: "16px",
          marginBottom: "0px",
          marginTop: "15px",
        }}
      >
        {title}
      </p>
    </div>
  );
};

export default ProgressGraph;
