const androidRelease = {
  isFetched: false,
  releaseData: [],
  total: 0,
};

const androidReleasePrediction = {
  isFetched: false,
  prediction3Month: [],
  prediction6Month: [],
};

const IOSReleaseDist = {
  isFetched: false,
  releaseData: [],
  total: 0,
};
const IOSReleasePrediction = {
  isFetched: false,
  prediction3Month: [],
  prediction6Month: [],
};

const deviceManufacturerDist = {
  isFetched: false,
  brandData: [],
};

const deviceModelDist = {
  isFetched: false,
  modelData: [],
};

const initialStates = {
  androidRelease,
  androidReleasePrediction,
  IOSReleasePrediction,
  deviceManufacturerDist,
  deviceModelDist,
  IOSReleaseDist,
};

export default initialStates;
