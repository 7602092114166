import React from "react";
import { withTranslation } from "react-i18next";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import "./SetupDashboardManuel.css";
import i18n from "../../utils/i18n";

const SetupDashboardManuel = ({ t }) => {
  const [lng, setLng] = React.useState(localStorage.getItem("i18nextLng"));

  function changeLng(event) {
    setLng(event.target.value);
    i18n.changeLanguage(event.target.value);
  }
  return (
    //Container
    <div
      style={{
        backgroundColor: "#000a32",
      }}
    >
    
      {/* Navbar */}
      <div
        style={{
          background: "rgb(0, 10, 50)",
          height: " 80px",
          boxShadow: " 0 5px 32px 0 #140b20",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        {/* Logo Container */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ height: "34px", width: "34px", marginRight: "10px" }}
            alt="ambeent-logo"
            src="https://cdn.zeplin.io/5be95f75181a333cfe712602/assets/DFD27C74-9C00-4155-B5DD-193C65005EF5.png"
          ></img>
          <a id="ambeent-logo" href="https://enterprise.ambeent.ai/">
            Ambeent
          </a>
        </div>
        {/* Select Language */}
        <select
          id="lngSelect"
          className="MuiButtonBase-root"
          onChange={changeLng}
          defaultValue={lng === "en" ? "en" : "tr"}
        >
          <option className="lng" value="en">
            EN
          </option>
          <option className="lng" value="tr">
            TR
          </option>
        </select>
      </div>
      {/* Content */}
      <div
        style={{
          padding: "20%",
          paddingTop: "4%",
        }}
      >
        {/* Dashboard Setup Manuel List */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "50px",
          }}
        >
          <p
            className="integrationManuelText"
            style={{ width: "100%", fontWeight: "700", fontSize: "24px" }}
          >
            {t("userGuideSettingDashboard")}
          </p>
          <ul>
            <li
              className="integrationManuelListItem"
              style={{ marginBottom: "10px" }}
            >
              {t("clickonGoWifiConsole")}
            </li>
            <li
              className="integrationManuelListItem"
              style={{ marginBottom: "10px" }}
            >
              {t("createYourOwnPage")}
            </li>
            <li
              className="integrationManuelListItem"
              style={{ marginBottom: "10px" }}
            >
              {t("receiveConfirmationMail")}
            </li>
            <li
              className="integrationManuelListItem"
              style={{ marginBottom: "10px" }}
            >
              {t("goBackToAppFollowSteps")}
            </li>
            {/*Login Manuel List */}
            <ul>
              <li
                className="integrationManuelListItem"
                style={{ marginBottom: "10px" }}
              >
                {t("goToProfile")}
                <AccountCircleIcon
                  style={{ fontSize: "16px", marginLeft: "3px" }}
                />
              </li>
              <li
                className="integrationManuelListItem"
                style={{ marginBottom: "10px" }}
              >
                {t("pressLoginTo")}
              </li>
              <li
                className="integrationManuelListItem"
                style={{ marginBottom: "10px" }}
              >
                {t("enterTheGroupCodeAndDefineUserID")}
              </li>
            </ul>
          </ul>
          <p className="integrationManuelText" style={{ width: "100%" }}>
            {t("onceYouEnterGroupCode")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SetupDashboardManuel);
