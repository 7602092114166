import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
      backgroundColor: "#182142",
      color: "rgb(250, 183, 58)",
      fontWeight: "550",
    },
  },
  container: {
    borderBottom: "unset",
    // maxHeight: "350",
    //backgroundColor:"",
    backgroundColor: "#182142",
    color: "rgb(250, 183, 58)",
    fontWeight: "550",
  },
  tablecell: {
    backgroundColor: "#182142",
    color: "rgb(250, 183, 58)",
    fontWeight: "550",
    borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
  },
});

function Row(props) {
  const { row } = props;
  const { t } = useTranslation();
  //const { index } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  function getProblem(problem, problemed_hop) {
    var my_problem = "";
    if (problem === -1 && problemed_hop <= 2) {
      return t("noProblem");
    } else if (problem === 0) {
      my_problem += t("wifiProblem");
    } else if (problem === 1) {
      my_problem += t("userProblem");
    }
    if (problemed_hop > 2) {
      if (problem === -1) {
        return (my_problem += t("hopProblem"));
      }
      return (my_problem += ", " + t("hopProblem"));
    } else {
      return my_problem;
    }
  }

  function getStripedStyle(index, problemHop) {
    if (problemHop < 3) {
      return {};
    } else {
      if (index === problemHop) {
        // console.log('background red');
        return { background: "red", color: "blue" };
      }
    }
  }

  return (
    <React.Fragment>
      <TableRow
        style={{ backgroundColor: "#182142", color: "rgb(250, 183, 58)" }}
        className={classes.root}
      >
        <TableCell>{row.created_at}</TableCell>
        <TableCell align="right">{row.url}</TableCell>
        <TableCell align="right">
          {getProblem(row.problem, row.problemed_hop)}
        </TableCell>
        <TableCell align="right">
          <IconButton
            style={{ backgroundColor: "#182142", color: "rgb(250, 183, 58)" }}
            align="right"
            aria-label="expand row"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow
        style={{ backgroundColor: "#182142", color: "rgb(250, 183, 58)" }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit={false}
            style={{ backgroundColor: "#182142", color: "rgb(250, 183, 58)" }}
          >
            <Box margin={1}>
              <TableContainer
                className={classes.container}
                style={{
                  backgroundColor: "#182142",
                  color: "rgb(250, 183, 58)",
                }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow className={classes.root}>
                      <TableCell> {t("date")} </TableCell>
                      <TableCell>{t("hopNumber")}</TableCell>
                      <TableCell>{t("firstResponse")}</TableCell>
                      <TableCell>{t("secondResponse")}</TableCell>
                      <TableCell align="right">{t("thirdResponse")}</TableCell>
                      <TableCell align="right"> {t("server")} </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.hops_list.map((historyRow, i) => (
                      <TableRow className={classes.root} key={i}>
                        <TableCell component="th" scope="row">
                          {row.created_at}
                        </TableCell>
                        <TableCell
                          style={getStripedStyle(
                            historyRow.hop_number,
                            row.problemed_hop
                          )}
                        >
                          {historyRow.hop_number}
                        </TableCell>
                        <TableCell
                          style={getStripedStyle(
                            historyRow.hop_number,
                            row.problemed_hop
                          )}
                        >
                          {historyRow.first_response}
                        </TableCell>
                        <TableCell
                          style={getStripedStyle(
                            historyRow.hop_number,
                            row.problemed_hop
                          )}
                        >
                          {historyRow.second_response}
                        </TableCell>
                        <TableCell
                          style={getStripedStyle(
                            historyRow.hop_number,
                            row.problemed_hop
                          )}
                          align="right"
                        >
                          {historyRow.third_response}
                        </TableCell>
                        <TableCell
                          style={getStripedStyle(
                            historyRow.hop_number,
                            row.problemed_hop
                          )}
                          align="right"
                        >
                          {historyRow.server}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TraceTable(props) {
  const { t } = useTranslation();

  if (props?.data?.length > 0) {
    if (props.data[0].problem === -5) {
      return (
        <div style={{ color: "rgb(250, 183, 58)", fontWeight: "550" }}>
          {t("getData")}
        </div>
      );
    } else {
      return (
        <TableContainer
          component={Paper}
          style={{
            borderBottom: "unset",
            backgroundColor: "#182142",
            color: "rgb(250, 183, 58)",
            fontWeight: "550",
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow
                style={{
                  backgroundColor: "#182142",
                  color: "rgb(250, 183, 58)",
                  fontWeight: "550",
                }}
              >
                <TableCell
                  style={{
                    backgroundColor: "#182142",
                    color: "rgb(250, 183, 58)",
                    fontWeight: "550",
                  }}
                  align="left"
                >
                  {t("date")}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#182142",
                    color: "rgb(250, 183, 58)",
                    fontWeight: "550",
                  }}
                  align="right"
                >
                  {"Url"}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#182142",
                    color: "rgb(250, 183, 58)",
                    fontWeight: "550",
                  }}
                  align="right"
                >
                  {t("problemComment")}
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#182142",
                    color: "rgb(250, 183, 58)",
                    fontWeight: "550",
                  }}
                  align="right"
                >
                  {t("traceDetail")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, i) => (
                <Row data={row.name} key={i} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  } else {
    return (
      <div style={{ color: "rgb(250, 183, 58)", fontWeight: "550" }}>
        {t("checkData")}
      </div>
    );
  }
  // if (
  //   companyId === Constants.COMPANY_ID_M ||
  //   companyId === Constants.COMPANY_ID_T
  // ) {
  //   // console.log(props.data);

  // } else {
  //   return (
  //     <div style={{ color: "rgb(250, 183, 58)", fontWeight: "550" }}>
  //       {t("checkData")}
  //     </div>
  //   );
  // }
}
export default withTranslation()(TraceTable);
