import React, { useState } from "react";
import Popup from "reactjs-popup";
import { Chart } from "react-google-charts";

import "./Dashboard.css";
import { withTranslation } from "react-i18next";

import { BsPieChart, BsBarChartLine } from "react-icons/bs";

const ChartCard = ({
  t,
  data,
  graphId,
  chartTitleId,
  className,
  chartTitle,
  chartInfo,
  options,
  setUrl,
  url,
  acDataTestId,
  sunburstButton,
  openSunburst,
  initialChartType = "ColumnChart",
  changeChartButton = true,
}) => {
  const [chartType, setChartType] = useState(initialChartType);

  var sunImage = "https://img.icons8.com/fluent/96/000000/view-details--v1.png";

  data = chartType === "PieChart" ? data.pieData : data.barData;

  return (
    <div className={className ? className : "col-lg-6 col-xl-4"}>
      <div className="chart-card mb-4">
        <div
          className="chart-title"
          id={chartTitleId}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 0,
          }}
        >
          {chartInfo === false ? (
            <div style={{ minWidth: "25px" }}></div>
          ) : (
            <Popup
              trigger={<button className="chartInfoButton">i</button>}
              modal
            >
              <div style={{ color: "#181745" }}>{chartInfo}</div>
            </Popup>
          )}
          <p style={{ fontSize: "18px", margin: "0", color: "white" }}>
            {chartTitle}
          </p>
          {changeChartButton === true ? (
            chartType === "ColumnChart" ? (
              <BsPieChart onClick={() => setChartType("PieChart")} />
            ) : (
              <BsBarChartLine onClick={() => setChartType("ColumnChart")} />
            )
          ) : sunburstButton === true ? (
            <button
              className="changeChartButton"
              style={{ background: "url(" + sunImage + ") no-repeat" }}
              onClick={() => {
                openSunburst(true);

                setUrl(url);
              }}
            ></button>
          ) : (
            <div style={{ minWidth: "25px" }}></div>
          )}
          {/* {sunburstButton === true ? (
            <button
              className="changeChartButton"
              style={{ background: "url(" + sunImage + ") no-repeat" }}
              onClick={() => {
                openSunburst(true);

                setUrl(url);
              }}
            ></button>
          ) : (
            <></>
          )} */}
        </div>
        <div className="chart">
          {data.length === 0 ? (
            <p
              style={{
                color: "#182142",
                height: "300px",
                fontSize: "24px",
                margin: "0",
              }}
            >
              {t("nodata")}
            </p>
          ) : (
            <Chart
              height={"300px"}
              chartType={chartType}
              loader={<div>Loading Chart</div>}
              data={data}
              graph_id={graphId}
              options={options}
              rootProps={{ "data-testid": acDataTestId }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ChartCard);
