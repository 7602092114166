import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";

class CustomerTrace {
  customerTraceSubject;
  constructor() {
    this.customerTraceSubject = new BehaviorSubject(
      initialStates.customerTrace
    );
  }

  //get customer trace object
  get get() {
    return this.customerTraceSubject.asObservable();
  }

  //fetch data from /isptrace-read/customertrace endpoint
  fetchData(payload) {
    apis.getCustomerTrace(payload).then((response) => {
      this.customerTraceSubject.next(response);
    });
  }

  //reset customer trace objects state
  reset() {
    this.customerTraceSubject.next(initialStates.customerTrace);
  }
}

const CustomerTraceService = (function () {
  var instance;

  function createInstance() {
    var classObj = new CustomerTrace();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default CustomerTraceService;
