import React from "react";
import { withTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormGroup, FormLabel } from "react-bootstrap";

const axios = require("axios");


const Members = (props) => {
  const { t } = props;
  const [name, setName] = React.useState("");
  const [ID, setID] = React.useState("");
  const [macAddress, setMacaddress] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [members, setMembers] = React.useState([]);
  const [update, setUpdate] = React.useState(0);
  const classes = props;

  React.useEffect(() => {
      let memberList=[]
      axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/CompanyRemoteEmployee/GetCompanyRemoteEmployeeData?companyId=" +
        localStorage.getItem("companyId")
      )
      .then(function(response) {    
        response.data.data.forEach(element => {
          memberList.push({name: element.name, email: element.email, employeeID: element.employeeId, mac: element.macAddress, location:element.location, isp: element.isp, brand: element.brand, status: element.status, wifiHealth: element.wifiHealth }); 
        });
        setMembers(memberList);
      })
      .catch(function(error) {
        console.log(error);
      }); 
    
      /*eslint-disable */
    }, [update]);
    /*eslint-enable */

    function validateEmail(mail, e) {
      if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)){
        return true
      } else {
        return false
      }
    }

  function deleteItem(i, e) {
    axios
    .post("https://backend.ambeent.ai/ambeentcore/api/v1/CompanyRemoteEmployee/DeleteCompanyRemoteEmployee", {
      email: members[i].email,
      companyId: localStorage.getItem("companyId"),
    })
    .then((response) => {
      if (response.data.success === true) {
        setUpdate(update + 1);
        alert(t("memberDeleted"))
        e.preventDefault()
      } else {
        alert(t("memberCantDeleted"))
        e.preventDefault()
      }
    })
    .catch(function (error) {
      console.log(error);
      alert(t("memberCantDeleted"))
      e.preventDefault()
    });
  }
  
  function addMember(e) {
    if((macAddress === "") || (emailAddress === "") || (ID === "") ||(name === "")){
      alert(t("allCantEmpty"));
        e.preventDefault();
    } else {
      if(!validateEmail(emailAddress, e)){
        alert(t("emailFormatError"));
        e.preventDefault()
        return
      }
      axios
      .post("https://backend.ambeent.ai/ambeentcore/api/v1/CompanyRemoteEmployee/InsertCompanyRemoteEmployee", {
        macAddress: macAddress,
        employeeId: ID,
        name: name,
        email: emailAddress,
        companyId: localStorage.getItem("companyId"),
      })
      .then((response) => {
        if (response.data.success === true) {
          setUpdate(update + 1);
          alert(t("memberAdded"))
          e.preventDefault()
        } else {
          alert(t("memberCantAdded"))
          e.preventDefault()
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(t("memberCantAdded"))
        e.preventDefault()
      });
    }
      
  }


  return (
     <div id="membersPage" className={classes.root}>
      <div id="addMemberDiv">
      <div className= "row">
        <FormGroup className="memberFromGroup">
          <FormLabel className="addMemberLabel">{t("Name")} :</FormLabel>
          <input
          className="memberInfoName"
          placeholder="John"
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </FormGroup>
      <FormGroup className="memberFromGroup">
          <FormLabel className="addMemberLabel">{t("email")} :</FormLabel>
          <input
          className="memberInfoName"
          placeholder="John@example.com"
          onChange={(event) => {
            setEmailAddress(event.target.value);
          }}
        />
      </FormGroup>
      <FormGroup className="memberFromGroup">
      <FormLabel className="addMemberLabel">{t("employeeID")} :</FormLabel>
      <input
        className="memberInfoID"
        placeholder="1234"
        onChange={(event) => {
          setID(event.target.value);
        }}
      />
      </FormGroup>
      <FormGroup className="memberFromGroup">
        <FormLabel className="addMemberLabel">{t("macAddress")} :</FormLabel>
        <input
          className="memberInfoMacAddress"
          placeholder="12:AB:34:CD:56:EF"
          onChange={(event) => {
            setMacaddress(event.target.value);
          }}
        />
      </FormGroup>
        <button className="addMemberSubmit" onClick={addMember}>
          {t("Add")}
        </button>
    </div>
    </div>
    <Paper id="membersPaper"className={classes.root}>
    {t("teamMembers")}
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className="tableTitleMember">{t("Name")}</TableCell>
          <TableCell className="tableTitleMember">{t("email")}</TableCell>
          <TableCell className="tableTitleMember">{t("employeeID")}</TableCell>
          <TableCell className="tableTitleMember">{t("macAddress")}</TableCell>
          <TableCell className="tableTitleMember">{t("brand")}</TableCell>
          <TableCell className="tableTitleMember">{t("ISP")}</TableCell>
          <TableCell className="tableTitleMember">{t("wifiHealth")}</TableCell>
          <TableCell className="tableTitleMember">{t("location")}</TableCell>
          <TableCell className="tableTitleMember">{t("status")}</TableCell>
          <TableCell className="tableTitleMember">{t("delete")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {members.map((members, i) => {
          return (
            <TableRow key={`row-${i}`}>
              <TableCell className="membersRow">{members.name}</TableCell>
              <TableCell className="membersRow">{members.email}</TableCell>
              <TableCell className="membersRow">{members.employeeID}</TableCell>
              <TableCell className="membersRow">{members.mac}</TableCell>
              <TableCell className="membersRow">{members.brand}</TableCell>
              <TableCell className="membersRow">{members.isp}</TableCell>
              <TableCell className="membersRow">{members.wifiHealth}</TableCell>
              <TableCell className="membersRow" >{members.location}</TableCell>
              <TableCell className="membersRow">{members.status}</TableCell>
              <TableCell>
                <button style={{backgroundColor:'transparent', border: 'none'}}aria-label="delete">
                  <DeleteIcon onClick={deleteItem.bind(this, i)}  />
                </button>

              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </Paper>
  </div>
);
};

export default withTranslation()(Members);

  