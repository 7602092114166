import React from "react";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import DatePickerCustomInput from "../HelperComponents/DatePickerCustomInput";
import "./DateFilter.css";
import i18next from "i18next";

// TR Language support for date picker
registerLocale("tr", tr);


const DateFilter = (props) => {
  const { t } = props;
  const [startDate, setStartDate] = React.useState(props.startDate);
  const [endDate, setEndDate] = React.useState(props.endDate);
  const dateType = props.dateType;

  React.useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, [props.startDate, props.endDate, props.dateType]);

  const handleStartDate = (date) => {
    if (date <= endDate) {
      props.setStartDateCallBack(date);
      setStartDate(date);
    }
  };

  const handleEndDate = (date) => {
    if (date >= startDate) {
      props.setEndDateCallBack(date);
      setEndDate(date);
    }
  };

  return (
    <div className="filterArea">
      <div id="filterItem" className="filterItem" style={{ margin: "0" }}>
        <p className="dateTitle">{t("startDate")}</p>
        <DatePicker
          selected={startDate}
          locale={i18next.language === "tr" && "tr"}
          selectsStart
          onChange={(date) => {
            handleStartDate(date);
          }}
          startDate={startDate}
          endDate={endDate}
          dateFormat={
            dateType === "Hourly"
              ? "MMMM d, yyyy h:mm aa"
              : dateType === "Monthly"
              ? "MMMM, yyyy"
              : dateType === "Yearly"
              ? "yyyy"
              : "MMMM d, yyyy"
          }
          showTimeInput={dateType === "Hourly" ? true : false}
          showMonthYearPicker={
            dateType === "Monthly" || dateType === "Yearly" ? true : false
          }
          className="datePicker"
          customInput={<DatePickerCustomInput customClass="datePicker" />}
        />
      </div>

      <div
        id="filterItem"
        className="filterItem"
        style={{ margin: "0", marginLeft: "4%" }}
      >
        <p className="dateTitle">{t("endDate")}</p>
        <DatePicker
          selected={endDate}
          selectsEnd
          locale={i18next.language === "tr" && "tr"}
          onChange={(date) => {
            handleEndDate(date);
          }}
          startDate={startDate}
          endDate={endDate}
          dateFormat={
            dateType === "Hourly"
              ? "MMMM d, yyyy h:mm aa"
              : dateType === "Monthly"
              ? "MMMM, yyyy"
              : dateType === "Yearly"
              ? "yyyy"
              : "MMMM d, yyyy"
          }
          showTimeInput={dateType === "Hourly" ? true : false}
          showMonthYearPicker={
            dateType === "Monthly" || dateType === "Yearly" ? true : false
          }
          className="datePicker"
          customInput={<DatePickerCustomInput customClass="datePicker" />}
        />
      </div>
    </div>
  );
};

export default withTranslation()(DateFilter);
