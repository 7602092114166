import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import "./GoogleSignUp.css";
import { Button } from "react-bootstrap";
import KeycloakService from "../../Keycloak/KeycloakService";
import { useHistory } from "react-router-dom";
import { CompanyService } from "../../Classes";

const axios = require("axios");

const GoogleSignUp = (props) => {
  const { t, i18n } = props;
  const [timeZone, setTimeZone] = React.useState("");
  const [companyType, setCompanyType] = React.useState("");
  const [SignUpGIATAID, setSignUpGIATAID] = React.useState("");
  const [SignUpOrganizationName, setSignUpOrganizationName] =
    React.useState("");
  let history = useHistory();
  const [requestCheck, setRequestCheck] = useState(false);

  function changeLng(event) {
    i18n.changeLanguage(event.target.value);
  }
  const handleTimeZone = (event) => {
    setTimeZone(event.target.value);
  };
  const handleCompanyType = (event) => {
    setCompanyType(event.target.value);
  };

  const handleSubmit = (event) => {
    // console.log("type: " + companyType);
    if (requestCheck === false) {
      setRequestCheck(true);
      axios
        .post("https://backend.ambeent.ai/user/check", {
          keycloakUserId: KeycloakService.getSub(),
          email: KeycloakService.getEmail(),
          firstName: KeycloakService.getGivenName(),
          lastName: KeycloakService.getFamilyName(),
          giataId: SignUpGIATAID,
          organizationName: SignUpOrganizationName,
          timeZone: timeZone,
          type: companyType === "" ? "Enterprise" : companyType,
          googleSignUpController: "Done",
        })
        .then((response) => {
          // console.log("responsedata" + response.data);
          localStorage.setItem("companyId", response.data.companyId);
          localStorage.setItem("defaultCompanyId", response.data.companyId);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem(
            "companyType",
            response.data.type === "" || response.data.type === null
              ? "Enterprise"
              : response.data.type
          );
          localStorage.setItem(
            "tokenCompanyType",
            response.data.type === "" || response.data.type === null
              ? "Enterprise"
              : response.data.type
          );
          localStorage.setItem("shortCode", response.data.shortCode);
          localStorage.setItem("googleSignUp", "Done");
          localStorage.setItem("tokenGiataId", SignUpGIATAID);
          localStorage.setItem("tokenCompanyName", SignUpOrganizationName);
          localStorage.setItem("tokenTimeZone", timeZone);
          localStorage.setItem("profilPhoto", response.data.profilPhoto);
          localStorage.setItem("firstName", KeycloakService.getGivenName());
          localStorage.setItem("lastName", KeycloakService.getFamilyName());
          CompanyService.getInstance().updateCompanyImage(
            response.data.profilPhoto
          );

          let path = `/`;
          history.push(path);
        })
        .catch(function (error) {
          console.log(error);

          alert(t("Incorrect"));
        });
    }
    event.preventDefault();
  };

  return (
    <div className="login-page">
      <nav
        id="header"
        className="navbar is-primary is-fixed-top"
        style={{ background: "#000A32" }}
      >
        <div className="logo-wrapper-col" id="loginLogoWrapper">
          <img
            id="loginLogo"
            src="https://cdn.zeplin.io/5be95f75181a333cfe712602/assets/DFD27C74-9C00-4155-B5DD-193C65005EF5.png"
            alt=""
          ></img>
          <a id="ambeent" href="http://ambeent.ai/" style={{ color: "white" }}>
            {t("Ambeent")}
          </a>
        </div>
        <select id="lngSelectM" onChange={changeLng}>
          <option className="lngM" value="en">
            EN
          </option>
          <option className="lngM" value="tr">
            TR
          </option>
        </select>
      </nav>
      <div>
        <label id="signUpTitle">{t("completeYourAccount")}</label>
        <form id="signUpForm" onSubmit={handleSubmit}>
          <input
            onChange={(e) => setSignUpOrganizationName(e.target.value)}
            placeholder={t("setgroup")}
            id="signUpGroup"
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <input
              onChange={(e) => setSignUpGIATAID(e.target.value)}
              placeholder={t("giata")}
              id="signUpGiataID"
            />
            <label id="signUpGiataIDDesc">{t("giataInfo")}</label>
          </div>
          <select
            required
            id="timeZoneSelectOption"
            style={{ background: "none" }}
            onChange={handleTimeZone}
          >
            <option disabled selected className="rol" value={0}>
              {t("timeZone")}
            </option>
            <option value={"-10:00"}>GMT-10:00 - {t("gmtminus10")}</option>
            <option value={"-09:00"}>GMT-09:00 - {t("gmtminus9")}</option>
            <option value={"-08:00"}>GMT-08:00 - {t("gmtminus8")}</option>
            <option value={"-07:00"}>GMT-07:00 - {t("gmtminus7")}</option>
            <option value={"-06:00"}>GMT-06:00 - {t("gmtminus6")}</option>
            <option value={"-05:00"}>GMT-05:00 - {t("gmtminus5")}</option>
            <option value={"-04:00"}>GMT-04:00 - {t("gmtminus4")}</option>
            <option value={"-03:00"}>GMT-03:00 - {t("gmtminus3")}</option>
            <option value={"-02:00"}>GMT-02:00 - {t("gmtminus2")}</option>
            <option value={"-01:00"}>GMT-01:00 - {t("gmtminus1")}</option>
            <option value={"+00:00"}>GMT+00:00 - {t("gmt0")}</option>
            <option value={"+01:00"}>GMT+01:00 - {t("gmtplus1")}</option>
            <option value={"+02:00"}>GMT+02:00 - {t("gmtplus2")}</option>
            <option value={"+03:00"}>GMT+03:00 - {t("gmtplus3")}</option>
            <option value={"+04:00"}>GMT+04:00 - {t("gmtplus4")}</option>
            <option value={"+05:00"}>GMT+05:00 - {t("gmtplus5")}</option>
            <option value={"+06:00"}>GMT+06:00 - {t("gmtplus6")}</option>
            <option value={"+07:00"}>GMT+07:00 - {t("gmtplus7")}</option>
            <option value={"+08:00"}>GMT+08:00 - {t("gmtplus8")}</option>
            <option value={"+09:00"}>GMT+09:00 - {t("gmtplus9")}</option>
            <option value={"+10:00"}>GMT+10:00 - {t("gmtplus10")}</option>
            <option value={"+11:00"}>GMT+11:00 - {t("gmtplus11")}</option>
            <option value={"+12:00"}>GMT+12:00 - {t("gmtplus12")}</option>
            <option value={"+13:00"}>GMT+13:00 - {t("gmtplus13")}</option>
          </select>

          <select
            required
            id="companyTypeSelectOption"
            style={{ background: "none" }}
            onChange={handleCompanyType}
          >
            <option disabled selected className="rol" value={0}>
              {t("companyType")}
            </option>
            <option value={"Enterprise"}>{t("homeenterprise")}</option>
            <option value={"Partner"}>{t("partner")}</option>
            <option value={"ISP"}>{t("isp")}</option>
          </select>
          <Button id="submitButton" type="submit">
            {t("createAccount")}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default withTranslation()(GoogleSignUp);
