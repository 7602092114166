import React from "react";
import { Switch, Route } from "react-router-dom";

import Main from "./Components/MainComponents/Main.jsx";
import Invite from "./Components/SignUp&InviteComponents/Invite.jsx";
import Whitepaper from "./Components/WhitePaperComponents/Whitepaper.jsx";

import GoogleSignUp from "./Components/SignUp&InviteComponents/GoogleSignUp.jsx";

const AppRouter = (props) => {
  return (
    <Switch>
      <Route exact path="/GoogleSignUp" component={GoogleSignUp} />
      <Route path="/whitepaper" component={Whitepaper} />
      <Route path="/invite/:role/:company" component={Invite} />
      <Route path="/" component={Main} />
      <Route exact path="/Overview" component={Main} />
      <Route exact path="/Dashboard" component={Main} />
      <Route exact path="/NetworkAnalytics" component={Main} />
      <Route exact path="/WifiAnalytics" component={Main} />
      <Route exact path="/EndUserAnalytics" component={Main} />
      <Route exact path="/Routers" component={Main} />
      <Route exact path="/Details" component={Main} />
      <Route exact path="/Devices" component={Main} />
      <Route exact path="/Map" component={Main} />
      <Route exact path="/Settings" component={Main} />
      <Route exact path="/Logout" component={Main} />
      <Route exact path="/Traceroute" component={Main} />
      <Route exact path="/GridView" component={Main} />
      <Route exact path="/Sunburst" component={Main} />
      <Route exact path="/Members" component={Main} />
      <Route exact path="/CustomerService" component={Main} />
      {/* <Route path="/Main" component={Main} /> */}
    </Switch>
  );
};

export default AppRouter;
