import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";

//Components
import BoxList from "../../HelperComponents/BoxList/BoxList";
import HistoryTable from "../DataViewerComponents/HistoryTable/HistoryTable";

//Observable Services
import {
  ChannelNoiseListService,
  DevicesService,
  NeighboorModemsService,
  PingService,
  SpeedTestService,
  StasInfoService,
  //StreamingsService,
  InteractionsService,
} from "../../../Classes";
import UseObservable from "../../../Helpers/UseObservable";
import initialStates from "../../../Classes/InitialStates/details.InitialStates";

const DetailsCategories = (props) => {
  const { t } = props;

  // To ignore "ResizeObserver loop limit exceeded" error
  useEffect(() => {
    window.addEventListener('error', e => {
        if (e.message === 'ResizeObserver loop limit exceeded') {
            const resizeObserverErrDiv = document.getElementById(
                'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
                'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
                resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
        }
    });
}, []);

  //get observable states
  const { data: channelNoiseList } = UseObservable({
    observable: ChannelNoiseListService.getInstance().get,
    initialState: initialStates.channelNoiseList,
  });
  const { data: stasInfo } = UseObservable({
    observable: StasInfoService.getInstance().get,
    initialState: initialStates.stasInfo,
  });
  const { data: speedTest } = UseObservable({
    observable: SpeedTestService.getInstance().get,
    initialState: initialStates.speedTest,
  });
  const { data: neighboorModem } = UseObservable({
    observable: NeighboorModemsService.getInstance().get,
    initialState: initialStates.neighboorModem,
  });
  const { data: ping } = UseObservable({
    observable: PingService.getInstance().get,
    initialState: initialStates.ping,
  });
 /* const { data: streamings } = UseObservable({
    observable: StreamingsService.getInstance().get,
    initialState: initialStates.streamings,
  });*/
  const { data: device } = UseObservable({
    observable: DevicesService.getInstance().get,
    initialState: initialStates.device,
  });

  //sets selected category id of details category observable
  const setCategory = (detailsCategoryId) => {
    var interactionsSubject = InteractionsService.getInstance();
    interactionsSubject.setDetailsCategoryId(detailsCategoryId);
  };

  //sets default category according to selected devicetype
  useEffect(() => {
    var interactionsSubject = InteractionsService.getInstance();
    interactionsSubject.setDetailsCategoryId(
      device?.selectedDevice?.deviceType === "iOS" ? "SpeedTest" : "fidelity"
    );
  }, [device]);

  // box list not ios
  let boxList;
  if(stasInfo.informationElements !== ""){
    boxList = [
      {
        categoryId: "fidelity",
        value:
          channelNoiseList.fidelityPercentage.length === 0
            ? ""
            : channelNoiseList.fidelityPercentage[0].interferencePercentage + "%",
      },
      {
        categoryId: "Wifispeed",
        value:
          stasInfo.wifiSpeedBox === ""
            ? ""
            : parseFloat(stasInfo.wifiSpeedBox).toFixed(2) + " Mbps",
      },
  
      {
        categoryId: "Linkspeed",
        value: stasInfo.linkSpeedBox,
      },
      {
        categoryId:
          channelNoiseList.check5G === true
            ? "best Channel 5G"
            : "best Channel 2G",
        value:
          channelNoiseList.check5G === true
            ? channelNoiseList.channel <= 0 || !channelNoiseList.channel
              ? ""
              : props.channel
            : channelNoiseList.bestChannelBox === 0
            ? ""
            : channelNoiseList.bestChannelBox,
        boxName: "bestChannel",
      },
      {
        categoryId: "neighbourModems",
        value: neighboorModem.neighboorModemBox,
      },
      {
        categoryId: "connectedDevice",
        value: ping.connectedDeviceCount,
      },
      {
        categoryId: "SpeedTest",
        value: speedTest.speedTestBox,
      },
      {
        categoryId: "Security",
        intel: true,
        securityPercentange: stasInfo.informationElements.securityScore,
        securityColor: 
        stasInfo.informationElements.securityScore <= 30
          ? "red"
            : stasInfo.informationElements.securityScore > 65 ? "green"
            : "orange",
      },
      {
        categoryId: "Access Points From INTEL",
        value: stasInfo.informationElements.ScanResults.length,      
      },
    ] 
  }else{
    boxList = [
      {
        categoryId: "fidelity",
        value:
          channelNoiseList.fidelityPercentage.length === 0
            ? ""
            : channelNoiseList.fidelityPercentage[0].interferencePercentage + "%",
      },
      {
        categoryId: "Wifispeed",
        value:
          stasInfo.wifiSpeedBox === ""
            ? ""
            : parseFloat(stasInfo.wifiSpeedBox).toFixed(2) + " Mbps",
      },
  
      {
        categoryId: "Linkspeed",
        value: stasInfo.linkSpeedBox,
      },
      {
        categoryId:
          channelNoiseList.check5G === true
            ? "best Channel 5G"
            : "best Channel 2G",
        value:
          channelNoiseList.check5G === true
            ? channelNoiseList.channel <= 0 || !channelNoiseList.channel
              ? ""
              : props.channel
            : channelNoiseList.bestChannelBox === 0
            ? ""
            : channelNoiseList.bestChannelBox,
        boxName: "bestChannel",
      },
      {
        categoryId: "neighbourModems",
        value: neighboorModem.neighboorModemBox,
      },
      {
        categoryId: "connectedDevice",
        value: ping.connectedDeviceCount,
      },
      /*{
        categoryId: "streaming",
        value: streamings.streamingBox,
      },*/
      {
        categoryId: "SpeedTest",
        value: speedTest.speedTestBox,
      },
    ];
  
  }

  //ios box list
  const boxListIos = [
    {
      categoryId: "SpeedTest",
      value: speedTest.speedTestBox,
    },

    {
      categoryId: "neighbourModems",
      value: neighboorModem.neighboorModemBox,
    },
    {
      categoryId: "connectedDevice",
      value: ping.connectedDeviceCount,
    },
    /*{
      categoryId: "streaming",
      value: streamings.streamingBox,
    },*/
  ];
  return (
    <div>
      <div className="infoTitle">{t("IFD")}</div>
      <BoxList
        boxes={
          device.selectedDevice.deviceType === "iOS" ? boxListIos : boxList
        }
        setBox={setCategory}
      />
       <HistoryTable />
    </div>
  );
};

export default withTranslation()(DetailsCategories);
