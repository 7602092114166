import React from "react";
import { withTranslation } from "react-i18next";
import DateFilterPopProblems from "./Components/DateFilterPopProblems";
import PopProblemsTable from "./Components/PopProblemsTable";

const PopProblemsContainer = ({ t }) => {
  return (
    <div>
      <DateFilterPopProblems />
      <PopProblemsTable />
    </div>
  );
};

export default withTranslation()(PopProblemsContainer);
