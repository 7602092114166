import React from "react";
import { withTranslation } from "react-i18next";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const IntegrationManuel = (props) => {
  const { t } = props;
  var shortcode =
    localStorage.getItem("shortCode") !== null
      ? localStorage.getItem("shortCode")
      : "XXXX";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "50px",
      }}
    >
      <p className="integrationManuelText">
        {t("downloadAppEnterGroupCode")} "{shortcode}".
        <br />
        {t("toEnterTheGroupCode")} :{" "}
      </p>
      <ul>
        <li className="integrationManuelListItem">
          {t("goToProfile")}
          <AccountCircleIcon style={{ fontSize: "16px", marginLeft: "3px" }} />
        </li>
        <li className="integrationManuelListItem">{t("pressLoginTo")}</li>
        <li className="integrationManuelListItem">
          {t("enterTheGroupCodeAndDefineUserID")}
        </li>
      </ul>
      <p className="integrationManuelText">{t("onceYouEnterGroupCode")}</p>
    </div>
  );
};

export default withTranslation()(IntegrationManuel);
