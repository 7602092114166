import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";


class Ping {
  pingSubject;
  constructor() {
    this.pingSubject = new BehaviorSubject(initialStates.ping);
  }

  //get ping object
  get get() {
    return this.pingSubject.asObservable();
  }

  //fetch data from /ping/devices?id=${ambDeviceId}&mac=${modemMac} endpoint
  fetchData(ambDeviceId, modemMac) {
    apis.getPing(ambDeviceId, modemMac).then((response) => {
      this.pingSubject.next(response);
    });
  }

  //reset ping Subject objects state
  reset() {
    this.pingSubject.next(initialStates.ping);
  }
}

const PingService = (function () {
  var instance;

  function createInstance() {
    var classObj = new Ping();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default PingService;
