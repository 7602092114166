import React from "react";

import { InfoWindow } from "@react-google-maps/api";

export default function InfoWindowEx(props) {
  const infoWindowRef = React.createRef();
  // const contentElement = document.createElement(`div`);
  // useEffect(() => {
  //   ReactDOM.render(React.Children.only(props.children), contentElement);
  //   infoWindowRef.current.infowindow.setContent(contentElement);
  //   /*eslint-disable*/
  // }, [props.children]);
  /*eslint-enable*/
  return (
    <InfoWindow ref={infoWindowRef} {...props}>
      {props.children}
    </InfoWindow>
  );
}
