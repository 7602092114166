import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";


class RouterInfo {
  routerInfoSubject;
  constructor() {
    this.routerInfoSubject = new BehaviorSubject(initialStates.routerInfo);
  }

  //get router info object
  get get() {
    return this.routerInfoSubject.asObservable();
  }

  //fetch data from /Router/get/${modemMac} endpoint
  fetchData(modemMac) {
    apis.getRouterInfos(modemMac).then((response) => {
      this.routerInfoSubject.next(response);
    });
  }

  //reset router info objects state
  reset() {
    this.routerInfoSubject.next(initialStates.routerInfo);
  }
}

const RouterInfoService = (function () {
  var instance;

  function createInstance() {
    var classObj = new RouterInfo();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default RouterInfoService;
