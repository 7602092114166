
const handleDailyPopChart = (response) => {
  var arr = [];
  if(response.data){
      // console.log(response.data);
    arr.push(["hour", "% hop problems", { role: "tooltip" }]);
    for (var key in response.data) {
    arr.push([
      key,
      response.data[key]["percentage"],
      "Hour: " +
        String(key) +
        "\n % hop problems: " +
        String(response.data[key]["percentage"]) +
        "\n samples: " +
        String(response.data[key]["sample"]),
    ]);
    }
    return arr;
  } else{
    return arr;
  }
  
}

const handleHistorical = (response) => {
  return response;
}

const handlePercentage = (response) => {
        const pieChartDatas = [];
        Object.keys(response.data).forEach((item) => {
          const chartTitleId = item + "chartTitleId";
          const chartTitle = item
            .replace(".com", "")
            .replace("www.", "")
            .toUpperCase();
          const graphId = item + "graphId";
  
          if (
            response.data[item].no_problem.length !== 0 ||
            response.data[item].hop_problem.length !== 0 ||
            response.data[item].user_problem.length !== 0 ||
            response.data[item].wifi_problem.length !== 0
          ) {
              const data = [
              ["Status", "rate"],
              ["No Problem", response.data[item].no_problem.length],
              ["User Problem", response.data[item].user_problem.length],
              ["Hop Problem ", response.data[item].hop_problem.length],
              ["Wi-Fi Problem", response.data[item].wifi_problem.length],
            ]; 
  
              pieChartDatas.push(
                {chartTitleId,
                chartTitle,
                url: item,
                data: data,
                graphId});
          } else {
              const data = [];
              pieChartDatas.push(
                {chartTitleId,
                chartTitle,
                url: item,
                data: data,
                graphId});
          } })
  return pieChartDatas;
};

const handleError = (error) => {
  console.log(error);
  if (error.data) {
    return error.data;
  }
  return error;
};

const responseHandler = {
  handleDailyPopChart,
  handleHistorical,
  handlePercentage,
  handleError,

};
  
export default responseHandler;
