//libraries
import React, { useEffect, useState } from "react";
import ReactFlow from "react-flow-renderer";
import { withTranslation } from "react-i18next";
import "./FlowGraph.css";

//Components
import CustomNodes from "./CustomNodes";

//nodes
import Nodes from "./Nodes";

//Helpers
import NodeStateControllers from "./NodeStateControllers";
import UseObservable from "../../../../Helpers/UseObservable";
import fetchDetails from "../../../../Classes/Details/Helpers/fetchDetails";

//Singleton services
import {
  ChannelNoiseListService,
  CustomerTraceService,
  DevicesService,
  ModemsService,
  NeighboorModemsService,
  PingService,
  RecommendationService,
  RouterInfoService,
  SpeedTestService,
  StasInfoService,
  StreamingsService,
} from "../../../../Classes";
import initialStates from "../../../../Classes/InitialStates/details.InitialStates";
import resetDetails from "../../../../Classes/Details/Helpers/resetDetails";
import Helpers from "../../../../Helpers/Helpers";
import AllHeatMapsService from "../../../../Classes/Details/AllHeatMaps";

const onLoad = (reactFlowInstance) => {
  reactFlowInstance.fitView({ includeHiddenNodes: true });
};

const FlowGraph = (props) => {
  const { t } = props;

  const [elements, setElements] = useState(
    Helpers.isCompanyISP() ? Nodes.defaultElements : Nodes.b2cDefaultElements
  );
  const [selectedRouterDD, setSelectedRouterDD] = useState(0);

  //Get Observable States
  const { data: device } = UseObservable({
    observable: DevicesService.getInstance().get,
    initialState: initialStates.device,
  });

  const { data: modem, isFetched: modemIsFetched } = UseObservable({
    observable: ModemsService.getInstance().get,
    initialState: initialStates.modem,
  });
  const { data: routerInfo } = UseObservable({
    observable: RouterInfoService.getInstance().get,
    initialState: initialStates.routerInfo,
  });
  const { data: customerTrace } = UseObservable({
    observable: CustomerTraceService.getInstance().get,
    initialState: initialStates.customerTrace,
  });
  const { data: stasInfo } = UseObservable({
    observable: StasInfoService.getInstance().get,
    initialState: initialStates.stasInfo,
  });
  const { data: streamings } = UseObservable({
    observable: StreamingsService.getInstance().get,
    initialState: initialStates.streamings,
  });
  const { data: channelNoiseList } = UseObservable({
    observable: ChannelNoiseListService.getInstance().get,
    initialState: initialStates.channelNoiseList,
  });
  const { data: ping } = UseObservable({
    observable: PingService.getInstance().get,
    initialState: initialStates.ping,
  });
  const { data: speedTest } = UseObservable({
    observable: SpeedTestService.getInstance().get,
    initialState: initialStates.speedTest,
  });
  const { data: neighboorModem } = UseObservable({
    observable: NeighboorModemsService.getInstance().get,
    initialState: initialStates.neighboorModem,
  });
  const { data: recommendationState } = UseObservable({
    observable: RecommendationService.getInstance().get,
    initialState: initialStates.recommendation,
  });

  const { data: allHeatMaps } = UseObservable({
    observable: AllHeatMapsService.getInstance().get,
    initialState: initialStates.allHeatMaps,
  });

  const nodeTypes = {
    parent: CustomNodes.CustomParentNodeComponent,
    child: CustomNodes.CustomChildNodeComponent,
    device: CustomNodes.deviceNode,
    router: CustomNodes.routerNode,
    recommendation: CustomNodes.RecommendationNode,
  };

  function getProblem(problem, problemed_hop) {
    var my_problem = "";
    if (problem === -1 && problemed_hop <= 2) {
      return t("noProblem");
    } else if (problem === 0) {
      my_problem += t("wifiProblem");
    } else if (problem === 1) {
      my_problem += t("userProblem");
    }
    if (problemed_hop > 2) {
      if (problem === -1) {
        return (my_problem += t("hopProblem"));
      }
      return (my_problem += ", " + t("hopProblem"));
    } else {
      return my_problem;
    }
  }

  useEffect(() => {
    resetDetails();
    if (modemIsFetched && modem.selectedModem.modemMac !== undefined) {
      fetchDetails(
        modem.selectedModem.modemMac,
        device.selectedDevice.ambDeviceId,
        device.selectedDevice.deviceType
      );
    }
    // eslint-disable-next-line
  }, [modem]);

  var lang = localStorage.getItem("i18nextLng");

  useEffect(() => {
    // console.log(modem.selectedModem.modemMac);
    // console.log(device);
    var els = elements.map((el) => {
      if (el.id === "device") {
        el.data = {
          ...el.data,
          label: device?.selectedDevice?.label,
          type: device?.selectedDevice?.deviceType,
          ambId: device?.selectedDevice?.ambDeviceId,
        };
      }

      if (el.id === "recom") {
        // console.log(recommendationState.recommendation);
        var recommendation_ = recommendationState?.recommendation;
        var recom = "";
        var bullets = "";
        // console.log(recommendationState.recommendation);
        if (recommendation_?.includes("\n--")) {
          recom = recommendationState.recommendation.split("\n--\n--")[0];

          if (recom.includes("\n--")) {
            recom = recom.split("\n--");
          }

          bullets = recommendationState.recommendation
            .split("\n--\n--")[1]
            .split("\n--");
        } else {
          recom = recommendation_;
          bullets = undefined;
        }

        el.data = {
          ...el.data,
          label: t("recommendation"),
          recom: recom,
          bulletRecoms: bullets,
        };
        // console.log(el.data);
      }

      if (el.id === "router0") {
        if (modem?.modemList?.length >= 1) {
          el.data = {
            ...el.data,
            label: modem.modemList[0].label,
            mac: modem.modemList[0].value,
            routerClass:
              modem.selectedModem.modemMac === modem.modemList[0].value
                ? "activeRouter"
                : "passiveRouter",
          };
          // el.data.label = modemList[0].label;
          // el.data.mac = modemList[0].value;
        }
      }
      if (el.id === "router1") {
        if (modem?.modemList?.length >= 2) {
          el.data = {
            ...el.data,
            label: modem.modemList[1].label,
            mac: modem.modemList[1].value,
            routerClass:
              modem.selectedModem.modemMac === modem.modemList[1].value
                ? "activeRouter"
                : "passiveRouter",
          };
          // el.data.label = modemList[1].label;
          // el.data.mac = modemList[1].value;
        } else if (modem?.modemList?.length === 1) {
          el.data = {
            ...el.data,
            label: "",
            mac: "",
            routerClass: "passiveRouter",
          };
        }
      }
      if (el.id === "router2") {
        if (modem?.modemList?.length >= 3) {
          el.data = {
            ...el.data,
            label: modem.modemList[2].label,
            mac: modem.modemList[2].value,
            routerClass:
              modem.selectedModem.modemMac === modem.modemList[2].value
                ? "activeRouter"
                : "passiveRouter",
          };
          // el.data.label = modemList[2].label;
          // el.data.mac = modemList[2].value;
        } else if (
          modem.modemList.length === 1 ||
          modem.modemList.length === 2
        ) {
          el.data = {
            ...el.data,
            label: "",
            mac: "",
            routerClass: "passiveRouter",
          };
        }
      }

      if (el.id === "router3") {
        if (modem?.modemList?.length >= 4) {
          let modems = modem.modemList.slice(3, modem.modemList.length);
          el.data = {
            ...el.data,
            label: modems[selectedRouterDD].label,
            mac: modems[selectedRouterDD].value,
            routerClass:
              modem.selectedModem.modemMac === modems[selectedRouterDD].value
                ? "activeRouter"
                : "passiveRouter",
            others: modems,
            setSelectedRouterDD: setSelectedRouterDD,
          };
          // el.data.label = modemList[4].label;
          // el.data.mac = modemList[4].value;
          // el.data.others = modemList.splice(0,3);
        } else if (
          modem?.modemList?.length === 1 ||
          modem?.modemList?.length === 2 ||
          modem?.modemList?.length === 3
        ) {
          el.data = {
            ...el.data,
            label: "",
            mac: "",
            others: undefined,
            routerClass: "passiveRouter",
          };
        }
      }
      if (el.id === "network") {
        el.data = {
          ...el.data,
          label: t("Network"),
          nodeState: NodeStateControllers.networkState(
            routerInfo?.speed ? routerInfo?.speed : "-1,Mpbs"
          ),
        };
      }
      if (el.id === "modem") {
        el.data = {
          ...el.data,
          label: t("accessPoint"),
          nodeState: NodeStateControllers.modemState(
            allHeatMaps?.placement?.value
          ),
        };
      }

      if (el.id === "cxScore") {
        el.data = {
          ...el.data,
          label: t("cxScore"),
          nodeState: NodeStateControllers.scoreState(routerInfo?.score),
        };
      }
      if (el.id === "wifi") {
        el.data = {
          ...el.data,
          nodeState: NodeStateControllers.wifiState(
            allHeatMaps?.placement?.value,
            allHeatMaps?.coverage?.value
          ),
        };
      }
      if (el.id === "streamingQuality") {
        el.data = {
          ...el.data,
          label: t("StreamingQuality"),
          nodeState: NodeStateControllers.streamingQualityState(
            routerInfo?.streamingQuality
          ),
        };
      }

      if (el.id === "speedTest") {
        el.data = {
          ...el.data,
          label: t("Speedtest"),
          value:
          routerInfo?.speed === ""
              ? t("nodata")
              : routerInfo?.speed <= 0.1
              ? t("incomplete")
              : routerInfo?.speed,
        };
      }
      if (el.id === "coverage") {
        if (device?.selectedDevice?.deviceType === "iOS") {
          el.data = { ...el.data, hideRecom: true };
        } else {
          let covaregeRating = allHeatMaps?.coverage?.percentage + "%";
          el.data = {
            ...el.data,
            label: t("Coverage"),
            value:
              allHeatMaps?.coverage?.value === 0 ||
              allHeatMaps?.coverage?.value === ""
                ? t("insufficientData")
                : covaregeRating,
          };
        }
      }
      if (el.id === "modemPlacement") {
        if (device?.selectedDevice?.deviceType === "iOS") {
          el.data = { ...el.data, hideRecom: true };
        } else {
          let placementRating = allHeatMaps?.placement?.percentage + "%";
          el.data = {
            ...el.data,
            label: t("CPE"),
            value:
              allHeatMaps?.placement?.value === 0 ||
              allHeatMaps?.placement?.value === ""
                ? t("insufficientData")
                : placementRating,
          };
        }
      }
      if (el.id === "interference") {
        if (device?.selectedDevice?.deviceType === "iOS") {
          el.data = { ...el.data, hideRecom: true };
        } else {
          var interference =
            channelNoiseList?.fidelityPercentage?.length === 0
              ? null
              : channelNoiseList?.fidelityPercentage[0].interferencePercentage;
          el.data = {
            ...el.data,
            label: t("interference_"),
            value:
              interference === "" || interference === null
                ? t("nodata")
                : interference + "%",
          };
        }
      }
      if (el.id === "neighboorRouter") {
        el.data = {
          ...el.data,
          label: t("neighbourModems"),
          value:
          routerInfo?.neighborCount === ""
              ? t("nodata")
              : routerInfo?.neighborCount,
        };
      }
      if (el.id === "dataRate") {
        el.data = {
          ...el.data,
          label: t("Linkspeed"),
          value:
            routerInfo?.linkSpeed === ""
              ? t("nodata")
              : routerInfo?.linkSpeed,
        };
      }
      if (el.id === "connectedDevices") {
        el.data = {
          ...el.data,
          label: t("ConnectedDevices"),
          value:
          routerInfo?.connectedDeviceCount === ""
              ? t("nodata")
              : routerInfo?.connectedDeviceCount,
        };
      }
      if (el.id === "bestChannels") {
        var bestChannels =
          channelNoiseList?.check5G === true
            ? routerInfo?.channel <= 0 || !routerInfo?.channel
              ? 0
              : routerInfo?.channel
            : channelNoiseList?.bestChannelBox;
        el.data = {
          ...el.data,
          label: t("bestChannel"),
          value:
            bestChannels === 0 || bestChannels === ""
              ? t("nodata")
              : bestChannels,
        };
      }
      if (el.id === "rssi") {
        if (device?.selectedDevice?.deviceType === "iOS") {
          el.data = { ...el.data, hideRecom: true };
        } else {
          el.data = {
            ...el.data,
            label: t("Rssi"),
            value: routerInfo?.rssi === "" ? t("nodata") : routerInfo?.rssi,
          };
        }
      }
      if (el.id === "traceRoute") {
        var problem = getProblem(
          customerTrace?.traceRouteProblem?.problem,
          customerTrace?.traceRouteProblem?.problemed_hop
        );
        el.data = {
          ...el.data,
          label: t("traceRoute"),
          value: problem === "" ? t("noProblem") : problem,
        };
      }
      if (el.id === "jitter") {
        el.data = {
          ...el.data,
          label: t("jitter"),
          value:
            routerInfo?.pingStates?.jitter === "" ||
            routerInfo?.pingStates?.jitter === null
              ? t("nodata")
              : routerInfo?.pingStates?.jitter,
        };
      }
      if (el.id === "delay") {
        el.data = {
          ...el.data,
          label: t("avgPingTime"),
          value:
            routerInfo?.pingStates?.delay === "" ||
            routerInfo?.pingStates?.delay === null
              ? t("nodata")
              : routerInfo?.pingStates?.delay,
        };
      }
      if (el.id === "packetLoss") {
        el.data = {
          ...el.data,
          label: t("packetLoss"),
          value:
            routerInfo?.pingStates?.packetLoss === "" ||
            routerInfo?.pingStates?.packetLoss === null
              ? t("nodata")
              : routerInfo?.pingStates?.packetLoss,
        };
      }
      if (el.id === "gatewayJitter") {
        el.data = {
          ...el.data,
          label: t("gwJitter"),
          value:
            routerInfo?.gatewayStates?.jitter === "" ||
            routerInfo?.gatewayStates?.jitter === null
              ? t("nodata")
              : routerInfo?.gatewayStates?.jitter,
        };
      }
      if (el.id === "gatewayDelay") {
        el.data = {
          ...el.data,
          label: t("gwAvgPingTime"),
          value:
            routerInfo?.gatewayStates?.delay === "" ||
            routerInfo?.gatewayStates?.delay === null
              ? t("nodata")
              : routerInfo?.gatewayStates?.delay,
        };
      }
      if (el.id === "gatewayPacketLoss") {
        el.data = {
          ...el.data,
          label: t("gwPacketLoss"),
          value:
            routerInfo?.gatewayStates?.packetLoss === "" ||
            routerInfo?.gatewayStates?.packetLoss === null
              ? t("nodata")
              : routerInfo?.gatewayStates?.packetLoss,
        };
      }

      return el;
    });

    setElements(els);
    // eslint-disable-next-line
  }, [
    selectedRouterDD,
    modem,
    routerInfo,
    recommendationState,
    neighboorModem,
    speedTest,
    ping,
    allHeatMaps,
    channelNoiseList,
    streamings,
    stasInfo,
    customerTrace,
    routerInfo,
    device,
    lang,
  ]);

  // console.log("elementssss");
  // console.log(elements);
  // console.log("elementssss");
  // console.log("modemlist");
  // console.log(modemList);

  return (
    <div className="flowGraphContainer">
      <div className="flowGraphWrapper">
        <ReactFlow
          nodeTypes={nodeTypes}
          elements={elements}
          nodesDraggable={false}
          paneMoveable={false}
          zoomOnScroll={false}
          zoomOnPinch={false}
          zoomOnDoubleClick={false}
          panOnScroll={false}
          preventScrolling={false}
          onLoad={onLoad}
          nodesConnectable={false}
          panOnScrollMode={"free"}
          onConnect={false}
          defaultZoom={0.7}
        />
      </div>
    </div>
  );
};

export default withTranslation()(FlowGraph);
