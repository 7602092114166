import serviceProviders from "../../serviceProvider";
import responseHandler from "./details.responseHandler";

//here u can create an api call with axios instance and response handler

const getDevices = (companyId, customerId) => {
  return serviceProviders.kafkaAxiosClient
    .get(`/statistics/recognizeStamac?id=${companyId}&customer=${customerId}`)
    .then(responseHandler.handleGetDevices)
    .catch(responseHandler.handleError);
};

const getModemsFromCompanyID = (ambDeviceId, companyId, customerId) => {
  return serviceProviders.kafkaAxiosClient
    .get(
      `/statistics/modemsFromCompanyID?ambDeviceId=${ambDeviceId}&id=${companyId}&customerId=${customerId}`
    )
    .then(responseHandler.handleGetModems)
    .catch(responseHandler.handleError);
};

const getRouterInfos = (modemMac) => {
  return serviceProviders.ambeentCoreAxios
    .get(`/Router/get/${modemMac}`)
    .then(responseHandler.handleGetRouterInfos)
    .catch(responseHandler.handleError);
};

const getTracesSankey = (ambDeviceId, modemMac, count) => {
  return serviceProviders.kafkaAxiosClient
    .get(
      `/detailstrace/traces?id=${ambDeviceId}&mac=${modemMac}&count=${count}&com=${localStorage.getItem(
        "companyId"
      )}`
    )
    .then(responseHandler.handleGetTracesSankey)
    .catch(responseHandler.handleError);
};

const getCustomerTrace = (payload) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/isptrace-read/customertrace_with_modemmac`, payload)
    .then(responseHandler.handleGetCustomerTrace)
    .catch(responseHandler.handleError);
};

const getStasInfo = (ambDeviceId, modemMac) => {
  return serviceProviders.kafkaAxiosClient
    .get(
      `/statistics/stasInfo?id=${ambDeviceId}&mac=${modemMac}&com=${localStorage.getItem(
        "companyId"
      )}`
    )
    .then(responseHandler.handleGetStasInfo(modemMac))
    .catch(responseHandler.handleError);
};

const getStreamings = (ambDeviceId, modemMac) => {
  return serviceProviders.kafkaAxiosClient
    .get(
      `streaming/streamings?id=${ambDeviceId}&mac=${modemMac}&com=${localStorage.getItem(
        "companyId"
      )}`
    )
    .then(responseHandler.handleGetStreamings)
    .catch(responseHandler.handleError);
};

const getChannelNoiseList = (ambDeviceId, modemMac) => {
  return serviceProviders.kafkaAxiosClient
    .get(
      `statistics/channelNoiseList?id=${ambDeviceId}&mac=${modemMac}&com=${localStorage.getItem(
        "companyId"
      )}`
    )
    .then(responseHandler.handleGetChannelNoiseList)
    .catch(responseHandler.handleError);
};



const getHeatMapAll = (modemMac) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/router-heatmap/allheatmaps`, { modemMac: modemMac, companyId:localStorage.getItem(
      "companyId"
    )})
    .then(responseHandler.handleAllHeatMaps)
    .catch(responseHandler.handleErrorHeatMap);
};

const getPing = (ambDeviceId, modemMac) => {
  return serviceProviders.kafkaAxiosClient
    .get(
      `/ping/devices?id=${ambDeviceId}&mac=${modemMac}&com=${localStorage.getItem(
        "companyId"
      )}`
    )
    .then(responseHandler.handleGetPing)
    .catch(responseHandler.handleError);
};

const getSpeedTest = (ambDeviceId, modemMac) => {
  return serviceProviders.kafkaAxiosClient
    .get(
      `/speedtest/speeds?id=${ambDeviceId}&mac=${modemMac}&com=${localStorage.getItem(
        "companyId"
      )}`
    )
    .then(responseHandler.handleGetSpeedTest)
    .catch(responseHandler.handleError);
};

const getNeighborModems = (ambDeviceId, modemMac, deviceType) => {
  const ios = deviceType === "iOS" ? `&ios=true` : "";
  return serviceProviders.kafkaAxiosClient
    .get(
      `/statistics/neighborModems?id=${ambDeviceId}&mac=${modemMac}&com=${localStorage.getItem(
        "companyId"
      )}` + ios
    )
    .then(responseHandler.handleGetNeighborModems)
    .catch(responseHandler.handleError);
};

const getChromeExtension = (companyId) => {
  return serviceProviders.kafkaAxiosClient
    .get(`/extension/GetExtensionData?companyId=${companyId}`)
    .then(responseHandler.handleGetChromeExtension)
    .catch(responseHandler.handleError);
};

const getRecommendation = ({
  modemMac,
  ambDeviceId,
  language,
  bestChannelList,
  interferencePercentage,
  currentChannel,
  coverage,
  placement,
}) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/recommendation/`, {
      modemMac: modemMac,
      ambDeviceId: ambDeviceId,
      lang: language,
      bestChannelList,
      interferencePercentage,
      currentChannel,
      coverage,
      placement,
    })
    .then(responseHandler.handleGetRecommendation)
    .catch(responseHandler.handleError);
};

const apis = {
  getDevices,
  getModemsFromCompanyID,
  getRouterInfos,
  getTracesSankey,
  getCustomerTrace,
  getStasInfo,
  getStreamings,
  getChannelNoiseList,
  getHeatMapAll,
  getPing,
  getSpeedTest,
  getNeighborModems,
  getChromeExtension,
  getRecommendation,
};

export default apis;
