const handleGetDashboardTresholds = (response) => {
  if (response.data !== null) {
    const fetchedTresholds = [
      {
        name: "Score",
        domain: [0, 100],
        values: [
          response.data.score.bounds[0],
          response.data.score.bounds[1],
          response.data.score.bounds[2],
          response.data.score.minMax[0],
          response.data.score.minMax[1],
        ],
      },
      {
        name: "fidelity",
        domain: [0, 100],
        values: [
          response.data.fidelity.bounds[0],
          response.data.fidelity.bounds[1],
          response.data.fidelity.bounds[2],
          response.data.fidelity.minMax[0],
          response.data.fidelity.minMax[1],
        ],
      },
      {
        name: "Speedtest",
        domain: [0, 100],
        values: [
          response.data.speedtest.bounds[0],
          response.data.speedtest.bounds[1],
          response.data.speedtest.bounds[2],
          response.data.speedtest.minMax[0],
          response.data.speedtest.minMax[1],
        ],
      },
      {
        name: "LinkSpeed",
        domain: [0, 100],
        values: [
          response.data.linkSpeed.bounds[0],
          response.data.linkSpeed.bounds[1],
          response.data.linkSpeed.bounds[2],
          response.data.linkSpeed.minMax[0],
          response.data.linkSpeed.minMax[1],
        ],
      },
      {
        name: "CPE",
        domain: [-100, 0],
        values: [
          response.data.rssi.bounds[0],
          response.data.rssi.bounds[1],
          response.data.rssi.bounds[2],
          response.data.rssi.minMax[0],
          response.data.rssi.minMax[1],
        ],
      },
      {
        name: "ConnectedDevices",
        domain: [0, 100],
        values: [
          response.data.connectedDevices.bounds[0],
          response.data.connectedDevices.bounds[1],
          response.data.connectedDevices.bounds[2],
          response.data.connectedDevices.minMax[0],
          response.data.connectedDevices.minMax[1],
        ],
      },
      {
        name: "WifiSpeed",
        domain: [0, 100],
        values: [
          response.data.wifiSpeed.bounds[0],
          response.data.wifiSpeed.bounds[1],
          response.data.wifiSpeed.bounds[2],
          response.data.wifiSpeed.minMax[0],
          response.data.wifiSpeed.minMax[1],
        ],
      },
      {
        name: "StreamLatency",
        domain: [0, 10000],
        values: [
          response.data.streamLatency.bounds[0],
          response.data.streamLatency.bounds[1],
          response.data.streamLatency.bounds[2],
          response.data.streamLatency.minMax[0],
          response.data.streamLatency.minMax[1],
        ],
      },
      {
        name: "packetLoss",
        domain: [0, 10],
        values: [
          response.data.packetLoss.bounds[0],
          response.data.packetLoss.bounds[1],
          response.data.packetLoss.bounds[2],
          response.data.packetLoss.minMax[0],
          response.data.packetLoss.minMax[1],
        ],
      },
      {
        name: "GwPacketLoss",
        domain: [0, 10],
        values: [
          response.data.gwPacketLoss.bounds[0],
          response.data.gwPacketLoss.bounds[1],
          response.data.gwPacketLoss.bounds[2],
          response.data.gwPacketLoss.minMax[0],
          response.data.gwPacketLoss.minMax[1],
        ],
      },
      {
        name: "Jitter",
        domain: [0, 100],
        values: [
          response.data.jitter.bounds[0],
          response.data.jitter.bounds[1],
          response.data.jitter.bounds[2],
          response.data.jitter.minMax[0],
          response.data.jitter.minMax[1],
        ],
      },
      {
        name: "GwJitter",
        domain: [0, 100],
        values: [
          response.data.gwJitter.bounds[0],
          response.data.gwJitter.bounds[1],
          response.data.gwJitter.bounds[2],
          response.data.gwJitter.minMax[0],
          response.data.gwJitter.minMax[1],
        ],
      },
      {
        name: "AvgPingTime",
        domain: [0, 100],
        values: [
          response.data.avgPingTime.bounds[0],
          response.data.avgPingTime.bounds[1],
          response.data.avgPingTime.bounds[2],
          response.data.avgPingTime.minMax[0],
          response.data.avgPingTime.minMax[1],
        ],
      },
      {
        name: "GwAvgPingTime",
        domain: [0, 100],
        values: [
          response.data.gwAvgPingTime.bounds[0],
          response.data.gwAvgPingTime.bounds[1],
          response.data.gwAvgPingTime.bounds[2],
          response.data.gwAvgPingTime.minMax[0],
          response.data.gwAvgPingTime.minMax[1],
        ],
      },
      {
        name: "Ping",
         domain: [0, 100],
        values: [
          response.data.ping.bounds[0],
          response.data.ping.bounds[1],
          response.data.ping.bounds[2],
          response.data.ping.minMax[0],
          response.data.ping.minMax[1],
        ],
      },
      {
        name: "streaming",
        domain: [0, 10],
        values: [
          response.data.quality.bounds[0],
          response.data.quality.bounds[1],
          response.data.quality.bounds[2],
          response.data.quality.minMax[0],
          response.data.quality.minMax[1],
        ],
      },
      {
        name: "neighborCount",
        domain: [0, 10],
        values: [
          response.data.NeigborCount.bounds[0],
          response.data.NeigborCount.bounds[1],
          response.data.NeigborCount.bounds[2],
          response.data.NeigborCount.minMax[0],
          response.data.NeigborCount.minMax[1],
        ],
      },
      {
        name: "latency",
        domain: [0, 100],
        values: [
          response.data.latency.bounds[0],
          response.data.latency.bounds[1],
          response.data.latency.bounds[2],
          response.data.latency.minMax[0],
          response.data.latency.minMax[1],
        ],
      },
      /*{
        name: "OptimizationReqAverageBound",
        domain: [0, 100],
        values: [response.data.optimizationReqAverageBound],
      },*/
    ];

    return fetchedTresholds;
  }
};

const handleResetDashboardTresholds = () => {
  return;
};

const handleUpdateDashboardTresholds = (response) => {
  return response;
};


const handleError = (error) => {
  console.log(error);
  if (error.data) {
    return error.data;
  }
  return error;
};

const responseHandlers = {
  handleGetDashboardTresholds,
  handleResetDashboardTresholds,
  handleUpdateDashboardTresholds,

  handleError,
};

export default responseHandlers;
