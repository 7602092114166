import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/devices/devices.apis";
import initialStates from "../InitialStates/devices.initialStates";

class DeviceModelDist {
  deviceModelDistSubject;
  constructor() {
    this.deviceModelDistSubject = new BehaviorSubject(
      initialStates.deviceModelDist
    );
  }

  get get() {
    return this.deviceModelDistSubject.asObservable();
  }

  fetchData({ manufacturer, companyId }) {
    apis.getDeviceModelDist(manufacturer, companyId).then((response) => {
      this.deviceModelDistSubject.next(response);
    });
  }

  reset() {
    this.deviceModelDistSubject.next(initialStates.deviceModelDist);
  }
}

const DeviceModelDistService = (function () {
  var instance;

  function createInstance() {
    var classObj = new DeviceModelDist();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default DeviceModelDistService;
