import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Constants from "../../../../utils/Constants";

//Observable Services
import { InteractionsService } from "../../../../Classes";
import initialStates from "../../../../Classes/InitialStates/details.InitialStates";
import UseObservable from "../../../../Helpers/UseObservable";

//Components
import MultiSelect from "../../../HelperComponents/MultiSelectDropDown/MultiSelectDropdown";
import HeatMap from "../../../HelperComponents/HeatMap/HeatMap";

//styles
import "./HeatMap.css";
import AllHeatMapsService from "../../../../Classes/Details/AllHeatMaps";
import { day } from "javascript-time-ago/gradation";

const HeatMapDetails = (props) => {
  const { t } = props;
  const [selectedHeatMapDetailParams, setSelectedHeatMapDetailParams] =
    useState(["Fidelity"]);
  const { data: interactions } = UseObservable({
    observable: InteractionsService.getInstance().get,
    initialState: initialStates.interactions,
  });
  const { data: allHeatMaps } = UseObservable({
    observable: AllHeatMapsService.getInstance().get,
    initialState: initialStates.allHeatMaps,
  });



  // it is cleaning selected heatmap parameters,
  // so if hide details clicked only fidelity heatmap details is visible
  useEffect(() => {
    if (interactions?.showHeatmapDetails === false) {
      setSelectedHeatMapDetailParams(["Fidelity"]);
    }
  }, [interactions]);



  return (
    <>
      {interactions?.showHeatmapDetails === true && (
        <div className="col" id="heatMapDetails">
          <div style={{ float: "left", position: "absolute" }}>
            {" "}
            <MultiSelect
              options={Constants.HEATMAP_PARAMETERS}
              setSelectedOptions={setSelectedHeatMapDetailParams}
            />
          </div>
          <div className="details-chart-title">{t("heatMapDetails")}</div>

          <div>
            {selectedHeatMapDetailParams.map((parameter, i) => {
              return (
                <div
                  className={
                    allHeatMaps.daily.length !== 0
                      ? "heatMapDetailsContainer"
                      : "heatMapDetailsContainerNoData"
                  }
                  key={"heatMapDetailsContainer-" + i}
                >
                  <p className="heatMapDetailsTitle">{t(parameter)}</p>
                  <div className="heatMapDetailsChildContainer">
                    {allHeatMaps.daily.length !== 0 ? (
                      allHeatMaps?.daily
                        .find((day) => day.param === parameter)
                        ?.data?.map((data, index) => (
                          <div
                            className="heatMapDetailsChart"
                            key={"heatMapDetails-" + day.param + index}
                          >
                            <HeatMap
                              firstCircle={data.first}
                              secondCircle={data.second}
                              thirdCircle={data.third}
                              fourthCircle={data.fourth}
                            />
                            <p
                              style={{
                                color: "white",
                                fontSize: "14px",
                                marginTop: "0px",
                              }}
                            >
                              {t(Constants.WEEK_DAYS[index])}
                            </p>
                          </div>
                        ))
                    ) : (
                      <p
                        style={{
                          color: "white",
                          fontSize: "18px",
                          marginLeft: "10px",
                        }}
                      >
                        {t("nodata")}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default withTranslation()(HeatMapDetails);
