import Helpers from "../../../Helpers/Helpers";
import serviceProviders from "../../serviceProvider";
import responseHandler from "./customerService.responseHandler";

const getCustomerServiceChart = () => {
  return serviceProviders.ambeentCoreAxios
    .post(
      `/CustomerServiceAnalyses/CustomerServiceChart?companyId=${Helpers.getLowerCaseCompanyID()}`
    )
    .then(responseHandler.handleGetCustomerServiceChart)
    .catch(responseHandler.handleError);
};

const getBadCustomers = ({
  selectedRowCount,
  selectedFilter,
  selectedDateType,
  startDateFilter,
  endDateFilter,
  frequency,
}) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/badcustomers/GetBadCustomers`, {
      CompanyId: Helpers.getLowerCaseCompanyID(),
      RowsCount: parseInt(selectedRowCount),
      Filter: selectedFilter,
      TimeFilter: selectedDateType,
      StartDate: startDateFilter,
      EndDate: endDateFilter,
      Frequency: frequency,
    })
    .then(responseHandler.handleGetBadCustomers)
    .catch(responseHandler.handleError);
};

const apis = {
  getCustomerServiceChart,
  getBadCustomers,
};

export default apis;
