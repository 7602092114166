import { BehaviorSubject } from "rxjs";
import initialStates from "../InitialStates/details.InitialStates";


class Customer {
  customerSubject;
  constructor() {
    this.customerSubject = new BehaviorSubject(initialStates.customer);
  }

  //get customer object
  get get() {
    // console.log(this.customerSubject)
    return this.customerSubject.asObservable();
  }

  set(value) {
    // console.log(value)
    this.customerSubject.next({ customerId: value, isFetched:true });
  }

  //reset customer objects state
  reset() {
    this.customerSubject.next(initialStates.customer);
  }
}

const CustomerService = (function () {
  var instance;

  function createInstance() {
    var classObj = new Customer();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default CustomerService;
