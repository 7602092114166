import React from "react";

import Popup from "reactjs-popup";
import ReactGA from "react-ga";
import { withTranslation } from "react-i18next";

import { Audio } from "react-loader-spinner";

import Grid from "@material-ui/core/Grid";

import "./Account.css";
import CompanySettings from "./Components/CompanySettings";
import UserSettings from "./Components/UserSettings.jsx";
import InviteToGroup from "./Components/InviteToGroup";
import Info from "./Components/Info";
import Clouds from "./Components/Clouds";

const Account = (props) => {
  const [spinner, setSpinner] = React.useState(true);

  React.useEffect(() => {
    ReactGA.initialize("UA-173492242-1");
    ReactGA.pageview(localStorage.email + " is on /account page");

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Popup
        contentStyle={{
          backgroundColor: "transparent",
          border: "none",
        }}
        open={spinner}
        modal
      >
        <Audio
          type="ThreeDots"
          color="#FAB73A"
          height={100}
          width={100}
          timeout={100000} //3 secs
        />
      </Popup>
      <Grid container>
        <CompanySettings photoCallBack={props.photoCallBack} />

        <UserSettings />

        <InviteToGroup />

        <Info />

        <Clouds setSpinnerCallBack={setSpinner} />
      </Grid>
    </div>
  );
};

export default withTranslation()(Account);
