import React, { useEffect, /*useState*/ } from "react";
//import { Chart } from "react-google-charts";
import Paper from "@material-ui/core/Paper";
import { withTranslation } from "react-i18next";
import { ExportToExcel } from "./ExportToExcel";
import DropdownFilter from "../HelperComponents/DropdownFilter";
import DateFilter from "./DateFilter";
import CSTable from "./CSTable/CSTable";

import UseObservable from "../../Helpers/UseObservable";

import "./CustomerService.css";
import {
  BadCustomersService,
  CSInteractionsService,
  //CustomerDataChartService,
} from "../../Classes";
import initialStates from "../../Classes/InitialStates/customerService.initialStates";
//import Popup from "reactjs-popup";
//import { Audio } from "react-loader-spinner";
//import i18next from "i18next";

/*  BURASI SON */

/*var options = {
  chartArea: { width: "80%", height: "80%", redraw: true },
  backgroundColor: "none",
  focusTarget: "category",
  intervals: { style: "line" },
  legend: "none",
  baselineColor: "none",

  hAxis: {
    gridlines: {
      color: "#8c8ba6",
    },
    textStyle: { color: "#8c8ba6", fontSize: 9 },
  },
  vAxis: {
    textStyle: { color: "#8c8ba6" },
  },
  tooltip: {
    // isHtml: true,
    showColorCode: false,
  },
};*/

/* eslint-disable  */
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};
/* eslint-enable  */

const startDateEndpointParam = (startDateInput) => {
  var startDate = new Date(startDateInput);
  var startDateEndpoint = startDate.toJSON().replace("Z", "").replace("T", " ");
  return startDateEndpoint;
};

const endDateEndpointParam = (endDateInput) => {
  var endDate = new Date(endDateInput);
  var endDateEndpoint = endDate.toJSON().replace("Z", "").replace("T", " ");
  return endDateEndpoint;
};

const CustomerService = (props) => {
  const { t } = props;
  const classes = props;

  const { data: badCustomers } = UseObservable({
    observable: BadCustomersService.getInstance().get,
    initialState: initialStates.badCustomers,
  });

  /*const { data: customerChart } = UseObservable({
    observable: CustomerDataChartService.getInstance().get,
    initialState: initialStates.customerDataChart,
  });*/

  const { data: interactions } = UseObservable({
    observable: CSInteractionsService.getInstance().get,
    initialState: initialStates.interactions,
  });

  //state of spinner
  //const [spinner, setSpinner] = useState(false);

  const fileName = "myfile";

  const lowestRowCountList = [
    100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
  ];

  const categoryFilterList = [
    { value: "Score", text: t("score") },
    { value: "Rssi", text: t("Rssi") },
    { value: "Fidelity", text: t("fidelity") },
    { value: "Speed", text: t("Speedtest") },
    { value: "LinkSpeed", text: t("Linkspeed") },
    { value: "WifiSpeed", text: t("WifiSpeed") },

    { value: "Quality", text: t("quality") },
    { value: "NeighborCount", text: t("NeigborCount") },
    { value: "ConnectedDevice", text: t("connectedDevice") },
    { value: "Latency", text: t("latency") },
  ];

  const dateType = [
    { value: "Hourly", text: t("hourly") },
    { value: "Daily", text: t("daily") },
    { value: "Weekly", text: t("weekly") },
    { value: "Monthly", text: t("monthly") },
  ];
  const frequency = [
    { value: "0", text: t("2.4 GHZ") },
    { value: "1", text: t("5 GHZ") },
  ];

  const handleApply = () => {
    //starts loader
    //setSpinner(true);
    BadCustomersService.getInstance().reset();
    BadCustomersService.getInstance().fetchData({
      selectedRowCount: interactions.rowCount,
      selectedFilter: interactions.filter,
      selectedDateType: interactions.dateType,
      startDateFilter: startDateEndpointParam(interactions.startDate),
      endDateFilter: endDateEndpointParam(interactions.endDate),
      frequency: interactions.frequency,
    });
  };

  useEffect(() => {
    //CustomerDataChartService.getInstance().reset();
    //CustomerDataChartService.getInstance().fetchData();
    handleApply()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

 
  return (
    <div>
      {/*  
      <div style={{ marginTop: "10px" }} className="chart-card mb-4">
        {badCustomers.isFetched === false && (
          <Popup
            contentStyle={{
              backgroundColor: "transparent",
              border: "none",
            }}
            open={spinner}
            modal
          >
            <Audio
              type="ThreeDots"
              color="#FAB73A"
              height={100}
              width={100}
              timeout={100000} //3 secs
            />
          </Popup>
        )}
        <div className="chart-title1" id="cServiceTitle">
          {t("cxTrend")}
        </div>
        <div className="chart">
          <div className="noData"></div>
          <Chart
            height={"400px"}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={
              i18next.language === "tr"
                ? customerChart.customerChartTR
                : customerChart.customerChart
            }
            options={options}
          />
        </div>
      </div>
      */}
    

      <Paper id="cServicePaper" className={classes.root}>
        <div className="chart-title1" id="cServiceTitle">
          {t("worstCustomers")}
        </div>

        <div>
          <ExportToExcel
            text={t("download")}
            apiData={badCustomers.data}
            fileName={fileName}
          ></ExportToExcel>
        </div>

        <div className="filterArea">
          <div className="filterItem">
            <p className="filterTitle">{t("Lowest")}</p>
            <DropdownFilter
              selectedValue={interactions.rowCount}
              setSelectedFilter={(val) =>
                CSInteractionsService.getInstance().setRowCount(val)
              }
              filterDataArray={lowestRowCountList}
            />
          </div>

          <div className="filterItem">
            <p className="filterTitle">{t("SortBy")}</p>
            <DropdownFilter
              selectedValue={interactions.filter}
              setSelectedFilter={(val) =>
                CSInteractionsService.getInstance().setFilter(val)
              }
              filterData={categoryFilterList}
            />
          </div>

          <div className="filterItem">
            <p className="filterTitle">{t("TimeRange")}</p>
            <DropdownFilter
              selectedValue={interactions.dateType}
              setSelectedFilter={(val) =>
                CSInteractionsService.getInstance().setDateType(val)
              }
              filterData={dateType}
            />
          </div>
          <div className="filterItem">
            <p className="filterTitle">{t("frequency")}</p>
            <DropdownFilter
              selectedValue={interactions.frequency}
              setSelectedFilter={(val) =>
                CSInteractionsService.getInstance().setFrequency(val)
              }
              filterData={frequency}
            />
          </div>
        </div>

        <div
          id="dateFilter"
          className="filterArea"
          style={{ marginTop: "-30px" }}
        >
          <div className="filterItem">
            <DateFilter
              dateType={interactions.dateType}
              startDate={interactions.startDate}
              setStartDateCallBack={(val) =>
                CSInteractionsService.getInstance().setStartDate(val)
              }
              endDate={interactions.endDate}
              setEndDateCallBack={(val) =>
                CSInteractionsService.getInstance().setEndDate(val)
              }
            />
          </div>

          <div className="filterItemFlex">
            <button className="applyButton" onClick={handleApply}>
              {t("Apply")}
            </button>
          </div>
          <div className="filterItem" style={{ paddingTop: "1%" }}></div>
        </div>

        <CSTable tableData={badCustomers.worstCustomers} />
      </Paper>
    </div>
  );
};

export default withTranslation()(CustomerService);