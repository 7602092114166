import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Plot from "react-plotly.js";
import Snackbar from "@material-ui/core/Snackbar";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import { Chart } from "react-google-charts";
import ChartCard from "../DashboardKPI/ChartCard";
import DatePickerCustomInput from "../HelperComponents/DatePickerCustomInput";
import { CustomerService } from "../../Classes";
import Helpers from "../../Helpers/Helpers";
import apis from "../../Services/Apis/SettingSunburst/sunburst.apis";
import i18next from "i18next";

// TR Language support for date picker
registerLocale("tr", tr);

const pieOptions = {
  is3D: false,
  isStacked: true,
  pieSliceText: "value",
  pieSliceTextStyle: {
    color: "#8C8BA6",
  },
  backgroundColor: "none",
  pieSliceBorderColor: "none",
  tooltip: {
    isHtml: true,
    showColorCode: true,
  },
  slices: [
    {
      color: "#2BB673",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#007fad",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#d91e48",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#e9a227",
      textStyle: {
        fontSize: 18,
      },
    },
  ],
  legend: {
    //maxLines: 4,
    position: "bottom",
    alignment: "center",
    textStyle: {
      color: "#8C8BA6",
      fontSize: 14,
    },
  },
  chartArea: {
    left: 0,
    top: 0,
    width: "100%",
    height: "80%",
  },
  fontName: "Helvetica, Arial, sans-serif",
};

const SunburstChart = (props) => {
  const { t } = props;
  const [customerId, setCustomerId] = React.useState(null);
  const [labels, setLabels] = React.useState("");
  const [mypop, setMypop] = React.useState("");
  const [firstTime, setFirstTime] = React.useState(true);
  const [isSunBurstOpen, setIsSunBurstOpen] = React.useState(false);
  const [parents, setParents] = React.useState([]);
  const [isOpenLine, setIsOpenLine] = React.useState(false);
  const [sunburstlineData, setSunburstlineData] = React.useState([]);
  const [values, setValues] = React.useState([]);
  const [colors, setColors] = React.useState([]);
  const [samples, setSamples] = React.useState([]);
  const [url, setUrl] = useState("www.google.com");
  const [showTime, setShowTime] = useState(new Date());
  const CompanyId = Helpers.getLowerCaseCompanyID();

  const [state, setState] = React.useState({
    open: false,
    isOpenLine: false,
    vertical: "top",
    horizontal: "right",
  });

  const [pieChartData, setPieChartData] = useState([]);
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleClick = (newState) => {
    setState({ open: true, ...newState });
    setTimeout(() => {
      handleClose();
    }, 10000);
  };
  function LineChart(props) {
    if (isOpenLine) {
      return (
        <Chart
          chartType="LineChart"
          loader={<div>Loading Chart</div>}
          data={sunburstlineData}
          options={{
            hAxis: {
              title: "Time",
              color: "#FFF",
              textStyle: { color: "#FFF" },
              titleTextStyle: { color: "#FFF" },
              baselineColor: "#ccc",
              gridlineColor: "#ccc",
            },
            vAxis: {
              title: "Problem Percentage",
              color: "#FFF",
              textStyle: { color: "#FFF" },
              titleTextStyle: { color: "#FFF" },
              baselineColor: "#ccc",
              gridlineColor: "#ccc",
            },
            backgroundColor: "#182142",
            colors: ["yellow"],
            legendTextStyle: { color: "#FFF" },
            titleTextStyle: { color: "#FFF" },
          }}
          layout={{
            plot_bgcolor: "#182142",
            paper_bgcolor: "#182142",
            hoverdistance: 0,
            autosize: true,
            // sunburstcolorway:colors
          }}
          config={{ responsive: true }}
          style={{ width: "100%", height: "400px" }}
          rootProps={{ "data-testid": "1" }}
        />
      );
    }
    return <></>;
  }
  const handleShowDetails = () => {
    if (customerId !== null) {
      //customer observable
      var CustomerSubject = CustomerService.getInstance();
      //set customer observables value (customerid)
      CustomerSubject.set(customerId);
      //clicks EndUserAnalytics menu option then clicks customerDetails to open details
      document.getElementById("/EndUserAnalytics").click();
      setTimeout(() => {
        document.getElementById("customerDetails").click();
      }, 10);
    }
  };

  // const chartEvents = [
  //   {
  //     eventName: "select",
  //     callback({ chartWrapper }) {
  //       var selectedRow = chartWrapper.getChart().getSelection()[0];
  //       var selected = googlePieChartData[selectedRow + 1][0];
  //       console.log(selected)
  //     },
  //   },
  // ];

  React.useEffect(() => {
    apis.getDailyPopChart(mypop, showTime).then((response) => {
      if (response !== undefined) {
      }
      setSunburstlineData(response);
    });
  }, [mypop, showTime]);

  React.useEffect(() => {
    apis.getHistorical(CompanyId, showTime, url).then((response) => {
      setParents(response.data.parents);
      setColors(response.data.colors);
      setValues(response.data.values);
      setSamples(response.data.samples);
      setLabels(response.data.labels);

      if (response.data.labels.length === 0) {
        setFirstTime(false);
      } else {
        setFirstTime(true);
      }
    });

    apis.getPercentage(CompanyId, showTime, url).then((response) => {
      setPieChartData(response);
    });
  }, [CompanyId, showTime, url]);

  return (
    <div>
      <div>
        <DatePicker
          selected={showTime}
          onChange={(date) => setShowTime(date)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={60}
          dateFormat="MMMM d, yyyy HH:mm"
          customInput={<DatePickerCustomInput customClass="datePicker" />}
          locale={i18next.language === "tr" && "tr"}
        />

        {/* SNACKBAR  */}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
          message={"Customer ID: " + customerId}
          key={vertical + horizontal}
          action={
            <button className="savePSettings2" onClick={handleShowDetails}>
              {t("showDetails")}
            </button>
          }
        />
        {/* END OF SNACKBAR  */}
        <div style={{ marginTop: "10px" }} className="row mt-3 db-chart">
          {pieChartData.map((item, i) => (
            <ChartCard
              key={"chart-" + i}
              chartTitleId={item.chartTitleId}
              chartTitle={item.chartTitle}
              chartInfo={false}
              initialChartType={"PieChart"}
              setUrl={setUrl}
              url={item.url}
              openSunburst={setIsSunBurstOpen}
              sunburstButton={true}
              dayisToday={false}
              data={{ pieData: item.data }}
              options={pieOptions}
              pcDataTestId="1"
              graphId={item.graphId}
              className="col-lg-6 col-xl-3"
              changeChartButton={false}
              {...item.rest}
            />
          ))}
          {/* SUNBURST  */}
          {isSunBurstOpen === false ? (
            <div className="col">{t("clickToDetails")}</div>
          ) : (
            <div className="col">
              {firstTime === true ? (
                <Plot
                  data={[
                    {
                      type: "sunburst",
                      labels: labels,
                      parents: parents,
                      values: values,
                      text: samples,
                      outsidetextfont: { color: "white" },
                      leaf: { width: 10 },
                      marker: { line: { width: 2 }, colors: colors },
                    },
                  ]}
                  onClick={(data) => {
                    if (String(data.points[0].label).includes("/")) {
                      setIsOpenLine(true);
                      if (mypop !== String(data.points[0].label)) {
                        setMypop(String(data.points[0].label));
                      } else {
                        setIsOpenLine(false);
                      }
                    } else {
                      if (!isNaN(Number(data.points[0].label))) {
                      } else {
                        setIsOpenLine(false);
                      }
                    }

                    if (!isNaN(Number(data.points[0].label))) {
                      setCustomerId(data.points[0].label);
                      handleClick({ vertical: "top", horizontal: "center" });
                    }

                    //setCustomerId(data.points[1].label)
                    //handleClick({ vertical: 'top', horizontal: 'center' })
                  }}
                  layout={{
                    plot_bgcolor: "#182142",
                    paper_bgcolor: "#182142",
                    hoverdistance: 0,
                    autosize: true,
                    // sunburstcolorway:colors
                  }}
                  config={{ responsive: true }}
                  style={{ width: "100%", height: "900px" }}
                />
              ) : (
                <div id="sunburstNoData">No data</div>
              )}
            </div>
          )}
          {/* END OF SUNBURST  */}
        </div>
      </div>
      <div>
        <LineChart isOpenLine={false} />
      </div>
    </div>
  );
};
export default withTranslation()(SunburstChart);
