import React from "react";
import ReactGA from "react-ga";
import { animateScroll as scroll } from "react-scroll";
import { Chart } from "react-google-charts";
import { withTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-up";
import Helpers from "../../Helpers/Helpers";
const axios = require("axios");

const options = {
  is3D: false,
  isStacked: true,
  pieSliceTextStyle: {
    color: "#8C8BA6",
  },
  backgroundColor: "none",
  pieSliceBorderColor: "none",
  tooltip: {
    isHtml: true,
    showColorCode: true,
  },
  slices: [
    {
      color: "#2BB673",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#007fad",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#d91e48",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#e9a227",
      textStyle: {
        fontSize: 18,
      },
    },
  ],
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      color: "#8C8BA6",
      fontSize: 14,
    },
  },
  chartArea: {
    left: 0,
    top: 0,
    width: "100%",
    height: "80%",
  },
  fontName: "Helvetica, Arial, sans-serif",
};

const barOptions = {
  backgroundColor: "none",
  width: "100%",
  height: "300px",
  legend: { position: "none" },
  vAxis: {
    scaleType: "log",
    ticks: [0, 10, 100, 1000, 10000, 100000],
    textStyle: { color: "white" },
    format: "short",
    gridlineColor: "#3b4e9b",
    minorGridlines: { color: "#3b4e9b" },
  },
  hAxis: {
    gridlines: {
      color: "white",
    },
    textStyle: { color: "white", fontSize: 12 },
  },
  animation: {
    duration: 2000,
    easing: "out",
    startup: true,
  },
};
const modelOptions = {
  backgroundColor: "none",
  width: "100%",
  height: "300px",
  legend: { position: "none" },
  vAxis: {
    scaleType: "log",
    ticks: [0, 10, 100, 1000, 10000],
    textStyle: { color: "white" },
    format: "short",
    gridlineColor: "#232d54",
  },
  hAxis: {
    gridlines: {
      color: "white",
    },
    textStyle: { color: "rgb(161, 161, 15)", fontSize: 12 },
  },
  animation: {
    duration: 2000,
    easing: "out",
    startup: true,
  },
};

const channelOptions = {
  backgroundColor: "none",
  width: "100%",
  height: "300px",
  legend: { position: "bottom", textStyle: { color: "white" } },
  vAxis: {
    textStyle: { color: "white" },
    gridlineColor: "#232d54",
    format: "short",
    minorGridlines: { color: "none" },
  },
  hAxis: {
    gridlineColor: "none",
    textStyle: { color: "rgb(161, 161, 15)", fontSize: 12 },
    minorGridlines: { color: "none" },
  },
  animation: {
    duration: 2000,
    easing: "out",
    startup: true,
  },
  colors: ["green", "blue", "red"],
};

const gmbChartOptions = {
  backgroundColor: "none",
  width: "100%",
  height: "300px",
  legend: { textStyle: { color: "white" }, position: "bottom" },
  vAxis: {
    scaleType: "log",
    ticks: [0, 10, 100, 1000, 10000],
    textStyle: { color: "white" },
    format: "short",
    gridlineColor: "#232d54",
  },
  hAxis: {
    gridlines: {
      color: "white",
    },
    textStyle: { color: "rgb(161, 161, 15)", fontSize: 12 },
  },
  animation: {
    duration: 2000,
    easing: "out",
    startup: true,
  },
  slices: {
    0: { color: "green" },
    1: { color: "blue" },
    2: { color: "red" },
  },
};

const Routers = (props) => {
  const { t } = props;
  const [mode, setMode] = React.useState(0);
  const [pieMode, setPieMode] = React.useState(0);
  const [selectedRouter, setSelectedRouter] = React.useState("");
  const [selectedModel, setSelectedModel] = React.useState("");
  const [modelData, setModelData] = React.useState([]);
  const [modelRender, setModelRender] = React.useState([]);
  const [brandData, setBrandData] = React.useState([]);
  const [brandDataPie, setBrandDataPie] = React.useState([]);
  const [brandModelData, setBrandModelData] = React.useState([]);
  const [total, setTotal] = React.useState("");
  const CompanyId = Helpers.getLowerCaseCompanyID();
  const [gmbCoveragePieChartData, setGmbCoveragePieChartData] = React.useState(
    []
  );
  const [gmbCoveragePieChartRender, setGmbCoveragePieChartRender] =
    React.useState([]);
  const [gmbThroughputPieChartData, setGmbThroughputPieChartData] =
    React.useState([]);
  const [gmbThroughputPieChartRender, setGmbThroughputPieChartRender] =
    React.useState([]);
  const [gmbRssiPieChartData, setGmbRssiPieChartData] = React.useState([]);
  const [gmbRssiPieChartRender, setGmbRssiPieChartRender] = React.useState([]);
  const [twoDotFiveGhzBarChartData, setTwoDotFiveGhzBarChartData] =
    React.useState([]);
  const [twoDotFiveGhzBarChartRender, setTwoDotFiveGhzBarChartRender] =
    React.useState([]);
  const [fiveGhzBarChartData, setFiveGhzData] = React.useState([]);
  const [fiveGhzBarChartRender, setFiveGhzRender] = React.useState([]);

  var identifiedRate;
  var unidentifiedRate;
  var unidentifiedCount;

  React.useEffect(() => {
    ReactGA.initialize("UA-173492242-1");
    ReactGA.pageview(localStorage.email + " is on /routers page");
    var brandData = [];
    var brandDataPie = [];
    var totalModel = 0;

    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/RouterBrandAnalyzes/GetRouterBrandList?companyId=" +
          CompanyId
      )
      .then(function (response) {
        brandData.push([
          "Brand",
          "Good",
          { role: "style" },
          "Medium",
          { role: "style" },
          "Bad",
          { role: "style" },
        ]);
        brandDataPie.push(["Brand", "Count"]);
        var brandName;
        if (response.data === null) {
          document.getElementById("barChartDiv").innerHTML = t("routerError");
          document.getElementById("barChartDiv").style.backgroundColor =
            "transparent";
          document.getElementById("barChartDiv").style.fontSize = "20px";
          document.getElementById("barChartDiv").style.color = "red";
          document.getElementById("barChartDiv").style.fontFamily =
            "Helvetica, Arial, sans-serif";
        } else {
          response.data.forEach((element) => {
            brandName =
              element.brand.charAt(0).toUpperCase() + element.brand.slice(1);
            totalModel =
              totalModel +
              parseInt(element.count[0]) +
              parseInt(element.count[1]) +
              parseInt(element.count[2]);
            brandData.push([
              brandName,
              parseInt(element.count[0]),
              "green",
              parseInt(element.count[1]),
              "blue",
              parseInt(element.count[2]),
              "red",
            ]);
            brandDataPie.push([
              brandName,
              parseInt(element.count[0]) +
                parseInt(element.count[1]) +
                parseInt(element.count[2]),
            ]);
          });
          setBrandData(brandData);
          setBrandDataPie(brandDataPie);
          setTotal(totalModel);
        }
      })
      .then(function () {
        var model = getSelectedRoter(brandData, null, 0);
        return model;
      })
      .then(function (modelData) {
        // console.log("model" + modelData);
        getSelectedModel(modelData, null, 0);
        return 0;
      })
      .catch(function (error) {
        console.log(error);
      });

    /*eslint-disable */
  }, []);
  /*eslint-enable */

  React.useEffect(() => {
    // console.log(brandModelData);
    if (brandModelData.length !== 0 && brandModelData.length !== 1) {
      getSelectedModel(brandModelData, null, 0);
    }
    // eslint-disable-next-line
  }, [brandModelData.length]);
  // eslint-disable-next-line

  function RouterChart(props) {
    if (selectedRouter === "") {
      return <div id="routerBrand"></div>;
    }
    return (
      <div id="barChartDiv">
        <div className="chart-card mb-4">
          <div className="chart-title1" id="routerBrand">
            {selectedRouter + t("models")}
          </div>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={modelData}
            options={modelOptions}
            chartEvents={modelEvents}
            _isMounted={scroll.scrollToBottom()}
          />
          <div id="identified"></div>
        </div>
      </div>
    );
  }

  function TwoDotFiveGhzBarChart() {
    return (
      <div id="barChartDiv">
        <div className="chart-card mb-4">
          <div className="chart-title1">2.4 Ghz</div>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={twoDotFiveGhzBarChartData}
            options={channelOptions}
          />
          <div id="identified2"></div>
        </div>
      </div>
    );
  }

  function FiveGhzBarChart() {
    return (
      <div id="barChartDiv">
        <div className="chart-card mb-4">
          <div className="chart-title1">5 Ghz</div>
          <Chart
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={fiveGhzBarChartData}
            options={channelOptions}
          />
          <div id="identified2"></div>
        </div>
      </div>
    );
  }

  function GMBCoveragePieChart() {
    if (selectedRouter === "" && selectedModel === "") {
      return <div id="gmbCoveragePieChart"></div>;
    }
    return (
      <div id="barChartDiv">
        <div className="chart-card mb-4">
          <div className="chart-title1" id="gmbCoveragePieChart">
            {t("coveragerouter")}
          </div>
          <Chart
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={gmbCoveragePieChartData}
            options={gmbChartOptions}
            rootProps={{ "data-testid": "1" }}
          />
          <div id="identified2"></div>
        </div>
      </div>
    );
  }

  function GMBThroughputPieChart() {
    if (selectedRouter === "" && selectedModel === "") {
      return <div id="gmbThroughputPieChart"></div>;
    }
    return (
      <div id="barChartDiv">
        <div className="chart-card mb-4">
          <div className="chart-title1" id="gmbThroughputPieChart">
            {t("Throughput")}
          </div>
          <Chart
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={gmbThroughputPieChartData}
            options={gmbChartOptions}
            rootProps={{ "data-testid": "1" }}
          />
          <div id="identified2"></div>
        </div>
      </div>
    );
  }

  function GMBRssiPieChart() {
    if (selectedRouter === "" && selectedModel === "") {
      return <div id="gmbRssiPieChart"></div>;
    }
    return (
      <div id="barChartDiv">
        <div className="chart-card mb-4">
          <div className="chart-title1" id="gmbRssiPieChart">
            {t("Rssi")}
          </div>
          <Chart
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={gmbRssiPieChartData}
            options={gmbChartOptions}
            rootProps={{ "data-testid": "1" }}
          />
          <div id="identified2"></div>
        </div>
      </div>
    );
  }

  const getSelectedModel = (_brandModelData, chartWrapper, _selectedRow) => {
    // console.log("modell " + modelData);
    var selectedRow =
      chartWrapper === null
        ? _selectedRow
        : chartWrapper.getChart().getSelection()[0].row;
    var selectedModel = _brandModelData[selectedRow + 1][0];
    setSelectedModel(selectedModel);
    setTwoDotFiveGhzBarChartRender([]);
    setFiveGhzRender([]);
    if (selectedModel !== "Unidentified") {
      var gmbCoveragePieChartData = [["Status", "Count"]];
      var gmbThroughputPieChartData = [["Status", "Count"]];
      var gmbRssiPieChartData = [["Status", "Count"]];
      var twoDotFiveGhzBarChartData = [["Channel", "Good", "Medium", "Bad"]];
      var fiveGhzData = [["Channel", "Good", "Medium", "Bad"]];

      axios
        .post(
          "https://backend.ambeent.ai/ambeentcore/api/v1/RouterBrandAnalyzes/GetRouterModelAnalyzes",
          {
            brand:
              selectedRouter.charAt(0).toUpperCase() + selectedRouter.slice(1),
            companyId: CompanyId,
            model: selectedModel,
          }
        )
        .then(function (response) {
          if (
            response.data.coverage.good !== 0 ||
            response.data.coverage.medium !== 0 ||
            response.data.coverage.bad !== 0
          ) {
            gmbCoveragePieChartData.push(["Good", response.data.coverage.good]);
            gmbCoveragePieChartData.push([
              "Medium",
              response.data.coverage.medium,
            ]);
            gmbCoveragePieChartData.push(["Bad", response.data.coverage.bad]);
          }

          if (
            response.data.throughput.good !== 0 ||
            response.data.throughput.medium !== 0 ||
            response.data.throughput.bad !== 0
          ) {
            gmbThroughputPieChartData.push([
              "Good",
              response.data.throughput.good,
            ]);
            gmbThroughputPieChartData.push([
              "Medium",
              response.data.throughput.medium,
            ]);
            gmbThroughputPieChartData.push([
              "Bad",
              response.data.throughput.bad,
            ]);
          }

          if (
            response.data.rssi.good !== 0 ||
            response.data.rssi.medium !== 0 ||
            response.data.rssi.bad !== 0
          ) {
            gmbRssiPieChartData.push(["Good", response.data.rssi.good]);
            gmbRssiPieChartData.push(["Medium", response.data.rssi.medium]);
            gmbRssiPieChartData.push(["Bad", response.data.rssi.bad]);
          }

          setGmbCoveragePieChartData(gmbCoveragePieChartData);
          setGmbCoveragePieChartRender(gmbCoveragePieChartData);

          setGmbThroughputPieChartData(gmbThroughputPieChartData);
          setGmbThroughputPieChartRender(gmbThroughputPieChartData);

          setGmbRssiPieChartData(gmbRssiPieChartData);
          setGmbRssiPieChartRender(gmbRssiPieChartData);
        });

      axios
        .post(
          "https://backend.ambeent.ai/ambeentcore/api/v1/RouterBrandAnalyzes/GetRouterChannelAnalyzes",
          {
            brand:
              selectedRouter.charAt(0).toUpperCase() + selectedRouter.slice(1),
            companyId: CompanyId,
            model: selectedModel,
          }
        )
        .then(function (response) {
          response.data.twoDotFiveGhz.forEach((element) => {
            twoDotFiveGhzBarChartData.push([
              element.channel.toString(),
              element.count.good,
              element.count.medium,
              element.count.bad,
            ]);
          });

          setTwoDotFiveGhzBarChartData(twoDotFiveGhzBarChartData);
          if (twoDotFiveGhzBarChartData.length === 1) {
            twoDotFiveGhzBarChartData = [];
          }
          setTwoDotFiveGhzBarChartRender(twoDotFiveGhzBarChartData);

          response.data.fiveGhz.forEach((element) => {
            fiveGhzData.push([
              element.channel.toString(),
              element.count.good,
              element.count.medium,
              element.count.bad,
            ]);
          });

          setFiveGhzData(fiveGhzData);
          if (fiveGhzData.length === 1) {
            fiveGhzData = [];
          }
          setFiveGhzRender(fiveGhzData);
        });

      setPieMode(pieMode + 1);
    } else {
      setGmbCoveragePieChartRender([]);
      setGmbThroughputPieChartRender([]);
      setGmbRssiPieChartRender([]);
    }
  };

  const modelEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        getSelectedModel(brandModelData, chartWrapper, 0);
      },
    },
  ];

  const getSelectedRoter = (brandData, _chartWrapper, _selectedRow) => {
    document.getElementById("empty").style.height = "300px";
    var selectedRow =
      _chartWrapper === null
        ? _selectedRow
        : _chartWrapper.getChart().getSelection()[0].row;
    var selected = brandData[selectedRow + 1][0];
    var brandCount =
      brandData[selectedRow + 1][1] +
      brandData[selectedRow + 1][3] +
      brandData[selectedRow + 1][5];
    setSelectedRouter(selected);
    var modelData = [];
    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/RouterBrandAnalyzes/GetRouterModelList",
        {
          brand: selected.charAt(0).toUpperCase() + selected.slice(1),
          companyId: CompanyId,
        }
      )
      .then(function (response) {
        modelData.push([
          "Model",
          "Good",
          { role: "style" },
          "Medium",
          { role: "style" },
          "Bad",
          { role: "style" },
        ]);
        response.data.forEach((element) => {
          if (element.model === "Others") {
            unidentifiedCount =
              element.count[0] + element.count[1] + element.count[2];
            modelData.push([
              "Unidentified",
              parseInt(element.count[0]),
              "green",
              parseInt(element.count[1]),
              "blue",
              parseInt(element.count[2]),
              "red",
            ]);
          } else {
            unidentifiedCount = 0;
            modelData.push([
              element.model,
              parseInt(element.count[0]),
              "green",
              parseInt(element.count[1]),
              "blue",
              parseInt(element.count[2]),
              "red",
            ]);
          }
        });
        setModelData(modelData);
        setModelRender(modelData);
        setBrandModelData(modelData);
        unidentifiedRate = Math.round((unidentifiedCount * 100) / brandCount);
        identifiedRate = 100 - unidentifiedRate;
        document.getElementById("identified").innerHTML =
          t("unidentified") +
          ": %" +
          unidentifiedRate +
          "\u000A" +
          t("identified") +
          ": %" +
          identifiedRate;
      })
      .catch(function (error) {
        console.log(error);
      });
    setMode(mode + 1);
    return modelData;
  };

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        setSelectedModel("");
        setGmbCoveragePieChartRender([]);
        setGmbThroughputPieChartRender([]);
        setGmbRssiPieChartRender([]);
        setTwoDotFiveGhzBarChartRender([]);
        setFiveGhzRender([]);
        setModelRender([]);
        getSelectedRoter(brandData, chartWrapper, 0);
      },
    },
  ];

  return (
    <div>
      <div style={{ height: "50px" }}></div>
      <div className="chart-card mb-4">
        <div className="chart-title1">{t("RoutersBrandPercent")}</div>
        <div className="chart">
          <Chart
            height={"300px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={brandDataPie}
            options={options}
            rootProps={{ "data-testid": "1" }}
          />
          {total === 0 || total === "" ? (
            <div></div>
          ) : (
            <div id="total">
              {t("TOTAL")}
              {total}
            </div>
          )}
        </div>
      </div>

      <div id="barChartDiv">
        <div className="chart-title1">{t("RoutersBrand")}</div>
        <div id="routerBrand">{t("clickBrand")}</div>
        <Chart
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={brandData}
          options={barOptions}
          chartEvents={chartEvents}
        />
      </div>
      <div style={{ height: "50px" }}></div>
      {modelRender !== modelData ? (
        <div id="empty"></div>
      ) : (
        <RouterChart mode={mode} />
      )}
      <div style={{ height: "20px" }}></div>
      {selectedModel === "" ? (
        <div id="selectedModelDiv"></div>
      ) : (
        <div
          style={{
            backgroundColor: "#172d5b",
            textStyle: { color: "white" },
            fontSize: "20px",
          }}
        >
          {t("selectedModel") + selectedModel}
        </div>
      )}
      <div style={{ height: "10px" }}></div>
      <div className="row">
        <div className="col-md-4">
          {gmbCoveragePieChartRender !== gmbCoveragePieChartData ? (
            <div id="gmbCoverageEmpty"></div>
          ) : (
            <GMBCoveragePieChart mode={pieMode} />
          )}
        </div>
        <div className="col-md-4">
          {gmbThroughputPieChartRender !== gmbThroughputPieChartData ? (
            <div id="gmbThroughputEmpty"></div>
          ) : (
            <GMBThroughputPieChart mode={pieMode} />
          )}
        </div>
        <div className="col-md-4">
          {gmbRssiPieChartRender !== gmbRssiPieChartData ? (
            <div id="gmbRssiEmpty"></div>
          ) : (
            <GMBRssiPieChart mode={pieMode} />
          )}
        </div>
      </div>

      <div style={{ height: "20px" }}></div>
      <div>
        {twoDotFiveGhzBarChartRender !== twoDotFiveGhzBarChartData ? (
          <div id="twoDotFiveGhzEmpty"></div>
        ) : (
          <TwoDotFiveGhzBarChart />
        )}
        {fiveGhzBarChartRender !== fiveGhzBarChartData ? (
          <div id="twoDotFiveGhzEmpty"></div>
        ) : (
          <FiveGhzBarChart />
        )}
      </div>

      <div style={{ height: "20px" }}></div>
      <ScrollToTop showUnder={160}>
        <img src="https://img.icons8.com/nolan/64/circled-up-2.png" alt="" />
      </ScrollToTop>
    </div>
  );
};

export default withTranslation()(Routers);
