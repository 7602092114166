import { CompanyService } from "../Classes";

const axios = require("axios");
const Keycloak = window.Keycloak;
const _kc = new Keycloak({
  realm: "new_realm",
  "auth-server-url": "https://backend.ambeent.ai/keycloak/",
  "ssl-required": "external",
  resource: "dashboard_local", //dashboard_local for enterprise.ambeent.ai, local to work with local host, dashboard_test for test link
  "public-client": true,
  "confidential-port": 0,
  clientId: "dashboard_local", //dashboard_local for enterprise.ambeent.ai, local to work with local host, dashboard_test for test link
});

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
    })
    .then(function (authenticated) {
      // console.log("authenticated"+authenticated)
      if (authenticated) {
        // console.log(
        //   "googleSignUpcontroller: " + _kc.tokenParsed.googleSignUpController
        // );
        localStorage.setItem("email", _kc.tokenParsed.email);
        if (_kc.tokenParsed.googleSignUpController === "googleSignUp") {
          localStorage.setItem("companyId", "googleLogin");
          onAuthenticatedCallback();
        } else {
          // console.log("*****")
          axios
            .post("https://backend.ambeent.ai/user/check", {
              keycloakUserId: _kc.tokenParsed.sub,
              email: _kc.tokenParsed.email,
              firstName: _kc.tokenParsed.given_name,
              lastName: _kc.tokenParsed.family_name,
              giataId: _kc.tokenParsed.giataId,
              organizationName: _kc.tokenParsed.companyName,
              timeZone: _kc.tokenParsed.timeZone,
              type:
                _kc.tokenParsed.type === undefined ||
                _kc.tokenParsed.type === null ||
                _kc.tokenParsed.type === ""
                  ? "Enterprise"
                  : _kc.tokenParsed.type,
              googleSignUpController:
                _kc.tokenParsed.googleSignUpController === "Done"
                  ? "Done"
                  : undefined,
            })
            .then((response) => {
              // console.log("response: " + response.data);
              if ("sitesChange" in localStorage) {
              } else {
                // console.log("companyid: " + response.data.companyId);
                localStorage.setItem("companyId", response.data.companyId);
              }
              localStorage.setItem("i18nextLng", response.data.language);
              localStorage.setItem("defaultCompanyId", response.data.companyId);
              localStorage.setItem(
                "companyType",
                response.data.type === "" || response.data.type === null
                  ? "Enterprise"
                  : response.data.type
              );
              localStorage.setItem("shortCode", response.data.shortCode);
              localStorage.setItem("profilPhoto", response.data.profilPhoto);
              localStorage.setItem("firstName", _kc.tokenParsed.given_name);
              localStorage.setItem("lastName", _kc.tokenParsed.family_name);
              CompanyService.getInstance().updateCompanyImage(
                response.data.profilPhoto
              );
              // console.log("companyid: " + response.data.companyId);
              // console.log("type" + localStorage.getItem("companyType"))
              onAuthenticatedCallback();
            });
        }
      } else {
        doLogin();
      }
    })
    .catch(function () {
      alert("failed to initialize");
      doLogout();
    });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getSub = () => _kc.tokenParsed?.sub;

const getEmail = () => _kc.tokenParsed?.email;

const getGivenName = () => _kc.tokenParsed?.given_name;

const getFamilyName = () => _kc.tokenParsed?.family_name;

const getGiataId = () => _kc.tokenParsed?.giataId;

const getCompanyName = () => _kc.tokenParsed?.companyName;

const getTimeZone = () => _kc.tokenParsed?.timeZone;

const getType = () => (_kc.tokenParsed?.type + "").toUpperCase();

const getGoogleSignUpController = () => _kc.tokenParsed?.googleSignUpController;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const refreshToken = (_kc.onTokenExpired = () => {
  // console.log("expired " + new Date());
  _kc
    .updateToken(50)
    .then((refreshed) => {
      if (refreshed) {
        // console.log("refreshed " + new Date() + "Token: " + _kc.token);
        return true;
      } else {
        // console.log("not refreshed " + new Date() + "Token: " + _kc.token);

        return false;
      }
    })
    .catch(() => {
      console.error("Failed to refresh token " + new Date());
      doLogout();
    });
});

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  refreshToken,
  getSub,
  getEmail,
  getGivenName,
  getFamilyName,
  getGiataId,
  getCompanyName,
  getTimeZone,
  getType,
  getGoogleSignUpController,
};

export default KeycloakService;
