const optionswithLegend = {
  is3D: false,
  isStacked: true,
  pieSliceTextStyle: {
    color: "#8C8BA6",
  },
  backgroundColor: "none",
  pieSliceBorderColor: "none",
  tooltip: {
    isHtml: true,
    showColorCode: true,
  },
  slices: [
    {
      color: "#2BB673",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#007fad",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#d91e48",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#e9a227",
      textStyle: {
        fontSize: 18,
      },
    },
  ],
  legend: {
    position: "labeled",
    // maxLines: 2,
    // alignment: "center",
    textStyle: {
      color: "#8C8BA6",
      fontSize: 11,
    },
  },
  chartArea: {
    left: 20,
    top: 0,
    width: "100%",
    height: "80%",
  },
  fontName: "Helvetica, Arial, sans-serif",
};
const optionswithLegendWithoutPercentage = {
  is3D: false,
  isStacked: true,
  pieSliceText: "value",
  pieSliceTextStyle: {
    color: "#8C8BA6",
  },
  backgroundColor: "none",
  pieSliceBorderColor: "none",
  tooltip: {
    isHtml: true,
    showColorCode: true,
  },
  slices: [
    {
      color: "#2BB673",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#007fad",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#d91e48",
      textStyle: {
        fontSize: 18,
      },
    },
    {
      color: "#e9a227",
      textStyle: {
        fontSize: 18,
      },
    },
  ],
  legend: {
    position: "labeled",
    // maxLines: 2,
    // alignment: "center",
    textStyle: {
      color: "#8C8BA6",
      fontSize: 11,
    },
  },
  chartArea: {
    left: 20,
    top: 0,
    width: "100%",
    height: "80%",
  },
  fontName: "Helvetica, Arial, sans-serif",
};
const barOptions = {
  backgroundColor: "none",
  width: "100%",
  height: "300px",
  legend: { position: "none" },
  vAxis: {
    scaleType: "log",
    ticks: [0, 10, 100, 1000, 10000, 100000],
    textStyle: { color: "white" },
    format: "short",
  },
  hAxis: {
    gridlines: {
      color: "white",
    },
    textStyle: { color: "white", fontSize: 12 },
  },
  animation: {
    duration: 2000,
    easing: "out",
    startup: true,
  },
};
const modelOptions = {
  backgroundColor: "none",
  width: "100%",
  height: "350px",
  legend: { position: "none" },
  vAxis: {
    scaleType: "log",
    ticks: [0, 10, 100, 1000, 10000],
    textStyle: { color: "white" },
    format: "short",
  },
  hAxis: {
    gridlines: {
      color: "white",
    },
    textStyle: { color: "rgb(161, 161, 15)", fontSize: 12 },
    slantedText: true,
    slantedTextAngle: 90,
  },
  animation: {
    duration: 2000,
    easing: "out",
    startup: true,
  },
};

const chartOptions = {
  optionswithLegend,
  optionswithLegendWithoutPercentage,
  barOptions,
  modelOptions,
};

export default chartOptions;
