const company = {
  image: undefined,
  timeZone: undefined,
  imageIsFetched: false,
  isUpdated: undefined,
  companyName: undefined,
  sites: [],
  companyIsFetched: false,
};

const user = {
  firstName: "",
  lastName: "",
  isUpdated: undefined,
};

const initialStates = {
  company,
  user,
};

export default initialStates;
