import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const GridView = (props) => {
  return (
    <TableBody>
      <TableRow>
        {props.Rows.map((item, i) => {
          // console.log("item length" + item.length + item);
          return <TableCell className="collapsibleTableCell">{item}</TableCell>;
        })}
      </TableRow>
    </TableBody>
  );
};

export default GridView;
