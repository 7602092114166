import React from "react";
import { withTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./CSTable.css";
import { CustomerService } from "../../../Classes";

function showDetails(e) {
  //customer observable
  var CustomerSubject = CustomerService.getInstance();
  //set customer observables value (customerid)
  CustomerSubject.set(e.currentTarget.id);
  //clicks EndUserAnalytics menu option then clicks customerDetails to open details
  setTimeout(() => {
    document.getElementById("customerDetails").click();
  }, 10);
}

const CSTable = (props) => {
  const { t, tableData } = props;

  const tableColumnHeadList = [
    t("CustomerID"),
    t("macAddress"),
    t("brand"),
    t("model"),
    t("Score"),
    t("location"),
    t("RSSI"),
    t("fidelity"),
    t("Linkspeed"),
    t("Wifispeed"),
    t("neighborCount"),
    t("connectedDevice"),
    t("speed"),
    t("quality"),
    t("latency"),
  ];
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {tableColumnHeadList.map((cell, i) => (
              <TableCell className="tableTitle" key={"tableColumnHeaderTableCell"+i}>
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length === 0 ? (
            <TableRow>
              {tableColumnHeadList.map((cell, i) => (
                <TableCell className="tableTitle" key={"csNoDataTableCell-"+i}>
                  {t("noDataWithFilter")}
                </TableCell>
              ))}
            </TableRow>
          ) : (
            tableData.map((row, i) => {
              const rowCellList = [
                row.macAddress,
                row.brand,
                row.model,
                row.score,
                row.location,
                row.rssi,
                row.fidelity,
                row.linkSpeed,
                row.wifiSpeed,
                row.neighborCount,
                row.connectedDevice,
                row.speed,
                row.quality,
                row.latency,
              ];

              return (
                <TableRow key={`row-${i}`}>
                  <TableCell
                    onClick={showDetails.bind(this)}
                    id={row.customerID}
                    className="rowIDCell"
                  >
                    {row.customerID}
                    <span className="tooltiptext">{t("detailsTooltip")}</span>
                  </TableCell>
                  {rowCellList.map((cellItem,i) => (
                    <TableCell className="csRow" key={`csCell-${i}`}>{cellItem}</TableCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default withTranslation()(CSTable);
