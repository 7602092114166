import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./ClientsTable.css";

import { CustomerService } from "../../Classes";
//time ago imports
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import tr from "javascript-time-ago/locale/tr";
// add local time ago for turkish
TimeAgo.addLocale(tr);
const timeAgoTr = new TimeAgo("tr");
// add local time ago for english
TimeAgo.addLocale(en);
const timeAgoEn = new TimeAgo("en-US");

function showDetails(e) {
  //customer observable
  var CustomerSubject = CustomerService.getInstance();
  //set customer observables value (customerid)
  CustomerSubject.set(e.currentTarget.id);
  //clicks customerDetails tab
  setTimeout(() => {
    document.getElementById("customerDetails").click();
  }, 500);
}

const ClientsTable = (props) => {
  const { t, tableData } = props;

  // time ago language state
  const [timeAgoLang, setTimeAgoLang] = useState(timeAgoEn);
  // get selected company language
  var lang = localStorage.getItem("i18nextLng");

  // changes timeago language at date column
  useEffect(() => {
    setTimeAgoLang(lang === "tr" ? timeAgoTr : timeAgoEn);
  }, [lang]);

  const tableColumnHeadList = [
    t("CustomerID"),
    t("date"),
    t("DeviceId"),
    t("brand"),
    t("model"),
    t("type"),
  ];
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            {tableColumnHeadList.map((cell, i) => (
              <TableCell
                className="ctableTitle"
                key={"tableColumnHeadList" + i}
              >
                {cell}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length === 0 ? (
            <TableRow>
              {tableColumnHeadList.map((cell, i) => (
                <TableCell className="ctableTitle" key={"nodata" + i}>
                  {t("noDataWithFilter")}
                </TableCell>
              ))}
            </TableRow>
          ) : (
            tableData.map((row, i) => {
              const rowCellList = [
                timeAgoLang.format(new Date(row.createdAt), "round"),
                row.ambDeviceId,
                row.brand,
                row.model,
                row.type,
              ];

              return (
                <TableRow key={`row-${i}`}>
                  <TableCell
                    onClick={showDetails.bind(this)}
                    id={row.customerId}
                    className="crowIDCell"
                  >
                    {row.customerId}
                    <span className="tooltiptext">{t("detailsTooltip")}</span>
                  </TableCell>
                  {rowCellList.map((cellItem, i) => (
                    <TableCell key={cellItem + i} className="ccsRow">
                      {cellItem}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default withTranslation()(ClientsTable);
