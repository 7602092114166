import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/PopCount/popcount.apis";
import initialStates from "../InitialStates/popcount.initialStates";

class PopCount {
  popCountSubject;
  constructor() {
    this.popCountSubject = new BehaviorSubject(initialStates.popCount);
  }

  //get pop count object
  get get() {
    return this.popCountSubject.asObservable();
  }

  //fetch popcount hourly data from endpoint
  fetchDataHourly({ year, month, day, first_hour, last_hour, option }) {
    apis
      .getPopCountHourly({ year, month, day, first_hour, last_hour })
      .then((response) => {
        this.popCountSubject.next({ ...response, option });
      });
  }
  //fetch popcount daily data from endpoint
  fetchDataDaily({ year, month, first_day, last_day, option }) {
    apis
      .getPopCountDaily({ year, month, first_day, last_day })
      .then((response) => {
        this.popCountSubject.next({ ...response, option });
      });
  }
  //fetch popcount weekly data from endpoint
  fetchDataWeekly({ year, first_week, last_week, option }) {
    apis.getPopCountWeekly({ year, first_week, last_week }).then((response) => {
      this.popCountSubject.next({ ...response, option });
    });
  }
  //fetch popcount monthly  data from endpoint
  fetchDataMonthly({ year, first_month, last_month, option }) {
    apis
      .getPopCountMonthly({ year, first_month, last_month })
      .then((response) => {
        this.popCountSubject.next({ ...response, option });
      });
  }

  //reset popcount Subject objects state
  reset() {
    this.popCountSubject.next(initialStates.popCount);
  }
}

const PopCountService = (function () {
  var instance;

  function createInstance() {
    var classObj = new PopCount();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default PopCountService;
