import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/account/account.apis";
import initialStates from "../InitialStates/account.initialStates";

class User {
  userSubject;
  constructor() {
    this.userSubject = new BehaviorSubject(initialStates.user);
  }

  get get() {
    return this.userSubject.asObservable();
  }

  updateUser({ firstName, lastName }) {
    apis.updateUser({ firstName, lastName }).then((response) => {

      this.userSubject.next((value) => {
        return response.isUpdated === "updated"
          ? {
              ...value,
              firstName: response.firstName,
              lastName: response.lastName,
              isUpdated: response.isUpdated,
            }
          : { ...value, isUpdated: response.isUpdated };
      });
    });
  }

  fetchData() {}

  reset() {
    this.userSubject.next(initialStates.company);
  }
}

const UserService = (function () {
  var instance;

  function createInstance() {
    var classObj = new User();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default UserService;
