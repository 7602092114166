import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import enGB from "date-fns/locale/en-GB/index";
import { format } from "date-fns";
import { Audio } from "react-loader-spinner";
import InfoWindowEx from "./InfoWindowEx";
import ReactGA from "react-ga";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { withTranslation } from "react-i18next";
import Constants from "../../utils/Constants";
import DatePickerCustomInput from "../HelperComponents/DatePickerCustomInput";
import ClientsTable from "./ClientsTable";
import { CustomerService } from "../../Classes";
import Helpers from "../../Helpers/Helpers";
import i18next from "i18next";

// TR Language support for date picker
registerLocale("tr", tr);
registerLocale("enGB", enGB);

const axios = require("axios");

let initialValue = [{ a: 0, b: 0, c: "", d: "", e: 0, f: 0, g: 0 }];
const style = {
  width: "100%",
  height: "90%",
  position: "relative",
};

/* eslint-disable  */
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
/* eslint-enable  */

function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [
    " Kbps",
    " Mbps",
    " Gbps",
    " Tbps",
    "Pbps",
    "Ebps",
    "Zbps",
    "Ybps",
  ];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

const MapContainer = (props) => {
  const { t } = props;
  const [clients, setclients] = useState([]);
  const [getlocation, setlocation] = useState(initialValue);
  const [backTime, setBackTime] = useState(new Date().addDays(-1));
  const [nextTime, setNextTime] = useState(new Date().addDays(+1));
  const [firstDate, setFirstDate] = useState(new Date());
  const [showTime, setShowTime] = useState(firstDate);
  const [selectedFilter, setSelectedFilter] = useState("Fidelity");
  const [spinner, setspinner] = useState(true);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [open, setopen] = useState(false);
  const [infoData, setInfoData] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [latitude, setLatitude] = useState(0);
  const [logitute, setLongtitude] = useState(0);

  const itemFidelity = getlocation.map((l, i) => (
    <Marker
      name={"Current location"}
      position={{ lat: l.a, lng: l.b }}
      key={i}
      icon={
        l.e < 35
          ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          : l.e > 65
          ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      }
      onClick={() => {
        setSelectedCenter([l.a, l.b]);
        setopen(true);
        setInfoData([l.c, l.d, l.e, l.f, l.g, l.h, l.q, l.l, l.s, l.o]);
      }}
    ></Marker>
  ));
  const itemLinkspeed = getlocation.map((l, i) => (
    <Marker
      name={"Current location"}
      position={{ lat: l.a, lng: l.b }}
      key={i}
      icon={
        l.g < 30
          ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          : l.g > 60
          ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      }
      onClick={() => {
        setSelectedCenter([l.a, l.b]);
        setopen(true);
        setInfoData([l.c, l.d, l.e, l.f, l.g, l.h, l.q, l.l, l.s, l.o]);
      }}
    ></Marker>
  ));
  const itemWifiSpeed = getlocation.map((l, i) => (
    <Marker
      name={"Current location"}
      position={{ lat: l.a, lng: l.b }}
      key={i}
      icon={
        l.f < 32
          ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          : l.f > 74
          ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      }
      onClick={() => {
        setSelectedCenter([l.a, l.b]);
        setopen(true);
        setInfoData([l.c, l.d, l.e, l.f, l.g, l.h, l.q, l.l, l.s, l.o]);
      }}
    ></Marker>
  ));
  const itemDownloadSpeed = getlocation.map((l, i) => (
    <Marker
      name={"Current location"}
      position={{ lat: l.a, lng: l.b }}
      key={i}
      icon={
        l.h < 15 * 1024 * 1024
          ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          : l.h > 30 * 1024 * 1024
          ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      }
      onClick={() => {
        setSelectedCenter([l.a, l.b]);
        setopen(true);
        setInfoData([l.c, l.d, l.e, l.f, l.g, l.h, l.q, l.l, l.s, l.o]);
      }}
    ></Marker>
  ));
  const itemQuality = getlocation.map((l, i) => (
    <Marker
      name={"Current location"}
      position={{ lat: l.a, lng: l.b }}
      key={i}
      icon={
        l.q < 4
          ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          : l.q > 7
          ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      }
      onClick={() => {
        setSelectedCenter([l.a, l.b]);
        setopen(true);
        setInfoData([l.c, l.d, l.e, l.f, l.g, l.h, l.q, l.l, l.s, l.o]);
      }}
    ></Marker>
  ));
  const itemLatency = getlocation.map((l, i) => (
    <Marker
      name={"Current location"}
      position={{ lat: l.a, lng: l.b }}
      key={i}
      icon={
        l.l < 10
          ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          : l.l > 20
          ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      }
      onClick={() => {
        setSelectedCenter([l.a, l.b]);
        setopen(true);
        setInfoData([l.c, l.d, l.e, l.f, l.g, l.h, l.q, l.l, l.s, l.o]);
      }}
    ></Marker>
  ));
  const itemScore = getlocation.map((l, i) => (
    <Marker
      name={"Current location"}
      position={{ lat: l.a, lng: l.b }}
      key={i}
      icon={
        l.s < 35
          ? "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
          : l.s > 60
          ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
          : "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
      }
      onClick={() => {
        setSelectedCenter([l.a, l.b]);
        setopen(true);
        setInfoData([l.c, l.d, l.e, l.f, l.g, l.h, l.q, l.l, l.s, l.o]);
      }}
    ></Marker>
  ));
  React.useEffect(() => {
    ReactGA.initialize("UA-173492242-1");
    ReactGA.pageview(localStorage.email + " is on /map page");
    let location = [];
    setspinner(false);
    var companyId = Helpers.getLowerCaseCompanyID();

    axios
      .get(
        "https://backend.ambeent.ai/statistics/map?id=" +
          companyId +
          "&date=" +
          showTime.toJSON()
      )
      .then(function (response) {
        var firstTime = false;
        response.data.forEach((element) => {
          if (response.data.length === 0) {
            setLatitude(44.0082);
            setLongtitude(18.9784);
          }

          if (
            (element.latitude !== 0 && element.longitude !== 0) ||
            (element.latitude !== 404 && element.longitude !== 404)
          ) {
            location.push({
              a: element.latitude,
              b: element.longitude,
              c: element.customerId,
              d: element.macAddress,
              e: element.fidelity,
              f: element.wifiSpeed,
              g: element.linkspeed,
              h: element.speed,
              o: element.ambDeviceId,
              q: element.quality,
              l: element.latency,
              s: element.score,
            });
            if (
              firstTime === false &&
              element.date !== "0001-01-01T00:00:00+00:00"
            ) {
              setFirstDate(new Date(element.createdAt));
              firstTime = true;
            }
          }
        });
        setlocation(location);
        var i;
        for (i = 0; i < response.data.length; i++) {
          if (
            (response.data[i].longitude !== 0 &&
              response.data[i].latitude !== 0) ||
            (response.data[i].longitude !== 404 &&
              response.data[i].latitude !== 404)
          ) {
            setLatitude(response.data[i].latitude);
            setLongtitude(response.data[i].longitude);
          }
        }
        setspinner(true);
        // console.log("finish")
      })
      .catch(function (error) {
        console.log(error);
        if(localStorage.getItem("redirectToDowload") === null){
          document.getElementById("/ClientIntegration").click()
          localStorage.setItem("redirectToDowload", "false")
        }
      });

    if (Helpers.isCompanyISP() === false) {
      // clients endpoint

      let clientLists = [];

      axios
        .get("https://backend.ambeent.ai/statistics/clients?id=" + companyId)
        .then(function (response) {
          // console.log(response.data)
          if (response.data === null) {
          } else {
            response.data.forEach((element) => {
              clientLists.push({
                ambDeviceId: element.ambDeviceId,
                customerId: element.customerId,
                type: element.type,
                brand: element.brand,
                model: element.model,
                createdAt: element.createdAt,
              });
            });
          }
          setclients(clientLists);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [showTime]);

  return (
    <div>
      <div className="chart1-em col-lg-12 col-xl-12 col-12 col-sm-12 col-md-12">
        {/*----------- Time Menü---------- */}
        <div id="mapSelection" className="row">
          <div
            id="timeTravelMap"
            className="row"
            style={{ position: "relative" }}
          >
            <label className="timeLabelMap">
              {format(new Date(backTime), "MMMM d, yyyy", {
                locale: i18next.language === "tr" ? tr : enGB,
              }).toString()}
            </label>

            <button
              className="setTimeButtonMap"
              onClick={() => {
                setFirstDate(firstDate.addDays(-1));
                setShowTime(showTime.addDays(-1));
                setBackTime(backTime.addDays(-1));
                setNextTime(nextTime.addDays(-1));
              }}
            >
              {t("back")}
            </button>

            <DatePicker
              selected={firstDate}
              onChange={(date) => {
                setFirstDate(date);
                setShowTime(date);
                setBackTime(date.addDays(-1));
                setNextTime(date.addDays(+1));
              }}
              dateFormat="MMMM d, yyyy"
              customInput={<DatePickerCustomInput customClass="setCalendar" />}
              locale={i18next.language === "tr" && "tr"}
            />

            <button
              className="setTimeButtonMap"
              onClick={() => {
                setFirstDate(firstDate.addDays(+1));
                setShowTime(showTime.addDays(+1));
                setBackTime(backTime.addDays(+1));
                setNextTime(nextTime.addDays(+1));
              }}
            >
              {t("next")}
            </button>
            <label className="timeLabelMap">
              {format(new Date(nextTime), "MMMM d, yyyy", {
                locale: i18next.language === "tr" ? tr : enGB,
              }).toString()}
            </label>
          </div>
          <div style={{ textAlign: "center" }}>
            <select
              id="mapFilterSelect"
              value={selectedFilter}
              onChange={(event) => {
                setSelectedFilter(event.target.value);
              }}
            >
              <option value="Fidelity">{t("fidelity")}</option>
              <option value="LinkSpeed">{t("Linkspeed")}</option>
              <option value="WifiSpeed">{t("Wifispeed")}</option>
              <option value="DownloadSpeed">{t("downloadSpeed")}</option>
              <option value="Quality">{t("quality")}</option>
              <option value="Latency">{t("latency")}</option>
              <option value="Score">{t("score")}</option>
            </select>
          </div>
        </div>

        <div
          id="mapDiv"
          style={{ height: "700px", width: "100%", position: "relative" }}
        >
          {spinner === true ? (
            <GoogleMap
              zoom={5}
              style={style}
              center={{
                lat: latitude === 0 ? 44.0082 : latitude,
                lng: logitute === 0 ? 18.9784 : logitute,
              }}
              mapContainerStyle={{
                width: "auto",
                height: "600px",
              }}
            >
              {/*-----------Marker according to colors ---------- */}
              {selectedFilter === "Fidelity"
                ? itemFidelity
                : selectedFilter === "LinkSpeed"
                ? itemLinkspeed
                : selectedFilter === "WifiSpeed"
                ? itemWifiSpeed
                : selectedFilter === "DownloadSpeed"
                ? itemDownloadSpeed
                : selectedFilter === "Quality"
                ? itemQuality
                : selectedFilter === "Latency"
                ? itemLatency
                : itemScore}

              {/*----------- InfoWindow---------- */}
              {selectedCenter && (
                <InfoWindowEx
                  visible={open}
                  onCloseClick={() => {
                    setSelectedCenter(null);
                    setopen(false);
                  }}
                  position={{
                    lat: parseFloat(selectedCenter[0]),
                    lng: parseFloat(selectedCenter[1]),
                  }}
                >
                  <div>
                    {/*----------- CustomerId---------- */}
                    <p className="mapInfo">
                      <b className="mapInfoTitle">{t("CustomerID")}</b>
                      <b className="mapInfoInfo">{infoData[0]}</b>
                    </p>
                    {/*----------- Fidelity---------- */}
                    <p className="mapInfo">
                      <b className="mapInfoTitle">{t("fidelity")}</b>
                      {infoData[2] < 35 ? (
                        <b className="mapInfoInfoRed">
                          {infoData[2] === 0 || infoData[2] === null
                            ? "No Data"
                            : infoData[2]}
                        </b>
                      ) : infoData[2] > 65 ? (
                        <b className="mapInfoInfoGreen">{infoData[2]}</b>
                      ) : (
                        <b className="mapInfoInfoBlue">{infoData[2]}</b>
                      )}
                    </p>
                    {/*----------- Link Speed---------- */}
                    <p className="mapInfo">
                      <b className="mapInfoTitle">{t("Linkspeed")}</b>
                      {infoData[4] < 30 ? (
                        <b className="mapInfoInfoRed">
                          {infoData[4] === 0 || infoData[4] === null
                            ? "No Data"
                            : infoData[2]}
                        </b>
                      ) : infoData[4] > 60 ? (
                        <b className="mapInfoInfoGreen">{infoData[4]}</b>
                      ) : (
                        <b className="mapInfoInfoBlue">{infoData[4]}</b>
                      )}
                    </p>
                    {/*----------- Wi-Fi Speed---------- */}
                    <p className="mapInfo">
                      <b className="mapInfoTitle">{t("Wifispeed")}</b>
                      {infoData[3] < 32 ? (
                        <b className="mapInfoInfoRed">
                          {infoData[3] === 0 || infoData[3] === null
                            ? "No Data"
                            : parseFloat(infoData[3]).toFixed(2)}
                        </b>
                      ) : infoData[3] > 74 ? (
                        <b className="mapInfoInfoGreen">
                          {parseFloat(infoData[3]).toFixed(2)}
                        </b>
                      ) : (
                        <b className="mapInfoInfoBlue">
                          {parseFloat(infoData[3]).toFixed(2)}
                        </b>
                      )}
                    </p>
                    {/*----------- Download Speed---------- */}
                    <p className="mapInfo">
                      <b className="mapInfoTitle">{t("downloadSpeed")}</b>
                      {infoData[5] < 15 * 1024 * 1024 ? (
                        <b className="mapInfoInfoRed">
                          {infoData[5] === 0 || infoData[5] === null
                            ? "No Data"
                            : getReadableFileSizeString(infoData[5])}
                        </b>
                      ) : infoData[5] > 30 * 1024 * 1024 ? (
                        <b className="mapInfoInfoGreen">
                          {getReadableFileSizeString(infoData[5])}
                        </b>
                      ) : (
                        <b className="mapInfoInfoBlue">
                          {getReadableFileSizeString(infoData[5])}
                        </b>
                      )}
                    </p>
                    {/*----------- Quality---------- */}
                    <p className="mapInfo">
                      <b className="mapInfoTitle">{t("quality")}</b>
                      {infoData[6] < 4 ? (
                        <b className="mapInfoInfoRed">
                          {infoData[6] === 0 || infoData[6] === null
                            ? "No Data"
                            : infoData[6]}
                        </b>
                      ) : infoData[6] > 7 ? (
                        <b className="mapInfoInfoGreen">{infoData[6]}</b>
                      ) : (
                        <b className="mapInfoInfoBlue">{infoData[6]}</b>
                      )}
                    </p>
                    {/*----------- Latency---------- */}
                    <p className="mapInfo">
                      <b className="mapInfoTitle">{t("latency")}</b>
                      {infoData[7] < 10 ? (
                        <b className="mapInfoInfoRed">
                          {infoData[7] === 0 || infoData[7] === null
                            ? "No Data"
                            : infoData[7]}
                        </b>
                      ) : infoData[7] > 20 ? (
                        <b className="mapInfoInfoGreen">{infoData[7]}</b>
                      ) : (
                        <b className="mapInfoInfoBlue">{infoData[7]}</b>
                      )}
                    </p>
                    {/*----------- Score---------- */}
                    <p className="mapInfo">
                      <b className="mapInfoTitle">{t("score")}</b>
                      {infoData[8] < 35 ? (
                        <b className="mapInfoInfoRed">
                          {infoData[8] === 0 || infoData[8] === null
                            ? "No Data"
                            : infoData[8]}
                        </b>
                      ) : infoData[8] > 60 ? (
                        <b className="mapInfoInfoGreen">{infoData[8]}</b>
                      ) : (
                        <b className="mapInfoInfoBlue">{infoData[8]}</b>
                      )}
                    </p>
                    {localStorage.getItem("companyType") ===
                      Constants.ISP_COMPANY_TYPE && infoData[0] === "0" ? (
                      <></>
                    ) : (
                      <button
                        type="button"
                        className="showDetailsfromMap"
                        onClick={() => {
                          //customer observable
                          var CustomerSubject = CustomerService.getInstance();
                          //set customer observables value (customerid)
                          CustomerSubject.set(infoData[0]);
                          //clicks customerDetails tab
                          setTimeout(() => {
                            document.getElementById("customerDetails").click();
                          }, 500);
                        }}
                      >
                        Show details
                      </button>
                    )}
                  </div>
                </InfoWindowEx>
              )}
            </GoogleMap>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <Audio
                type="ThreeDots"
                color="#FAB73A"
                height={100}
                width={100}
                timeout={100000} //3 secs
              />
            </div>
          )}
        </div>

        {Helpers.isCompanyISP() ? (
          <></>
        ) : (
          <div
            style={{
              width: "100%",
              overflow: "auto",
              backgroundColor: "#182142",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <ClientsTable tableData={clients} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(MapContainer);
