import serviceProviders from "../../serviceProvider";
import responseHandler from "./sunburst.responseHandlers";

var months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
var hours = [
    21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
    18, 19, 20,
  ];



const getDailyPopChart = (mypop,showTime) => {
    return serviceProviders.kafkaAxiosClient
      .post(`/isptrace-read/dailypopchart`, {
        pop: mypop,
        year: showTime.getFullYear(),
        month: months[showTime.getMonth()],
        day: showTime.getDate(),

      })
      .then(responseHandler.handleDailyPopChart)
      .catch(responseHandler.handleError);
  };

  const getHistorical = (CompanyId,showTime,url) => {
    return serviceProviders.kafkaAxiosClient
      .post(`/isptrace-read/historical`, {
        companyId: CompanyId.toLowerCase(),
        year: showTime.getFullYear(),
        month: months[showTime.getMonth()],
        day: showTime.getDate(),
        hour: hours[showTime.getHours()],
        url: url, 
      })
      .then(responseHandler.handleHistorical)
      .catch(responseHandler.handleError);
  };

  const getPercentage = (CompanyId,showTime,url) => {
    return serviceProviders.kafkaAxiosClient
      .post(`/isptrace-read/percentage`, {
        companyId: CompanyId.toLowerCase(),
        year: showTime.getFullYear(),
        month: months[showTime.getMonth()],
        day: showTime.getDate(),
        hour: hours[showTime.getHours()],
      })
      .then(responseHandler.handlePercentage)
      .catch(responseHandler.handleError);
  };

  const apis = {
    getDailyPopChart,
    getHistorical,
    getPercentage,
  };
  
  export default apis;
