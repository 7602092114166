import React from "react";
import { withTranslation } from "react-i18next";

function SliderWidget({
  t,
  name,
  values,
}) {

  const inputStyle = {
      fontSize: "1rem",
      fontWeight: "400",
      color: "white",
      backgroundColor: "#000a32",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      marginLeft:"10px",
      textAlign:"center",
      width:"150px"
  }
  const labelStyle = {
    marginLeft:"10px"
  }

  return (
    <div className="slidecontainer">
      <div id="settingsTitle">{t(name)}</div>
        <div id="container"  className="row"style={{marginTop:"20px", marginBottom:"10px"}}>
          <div style={{marginLeft:"30px"}}>Boundaries :
            <input id={name + "bound0"} style={inputStyle} defaultValue={values[0]} type="number" />
            <label style={labelStyle}>:</label>
            <input id={name + "bound1"} style={inputStyle} defaultValue={values[1]} type="number"/>
            <label style={labelStyle}>:</label>
            <input id={name + "bound2"} style={inputStyle} defaultValue={values[2]} type="number"/>
          </div>
          <div style={{marginLeft:"60px"}}>
            <label style={labelStyle}>Min :</label>
            <input id={name + "min"} style={inputStyle} defaultValue={values[3]} type="number" />
            <label style={labelStyle}>Max :</label>
            <input id={name + "max"} style={inputStyle} defaultValue={values[4]} type="number" />
          </div>
          </div>
     
    </div>
  );
}

export default withTranslation()(SliderWidget);
