import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import { withTranslation } from "react-i18next";

import DropdownFilter from "../../HelperComponents/DropdownFilter";
import "./PopProblems.css";

//Observable imports
import PopCountService from "../../../Classes/PopCount/PopCount";
import BadPopCountService from "../../../Classes/PopCount/BadPopCount";
import UseObservable from "../../../Helpers/UseObservable";
import initialStates from "../../../Classes/InitialStates/popcount.initialStates";

// imports for loader
import Popup from "reactjs-popup";
import { Audio } from "react-loader-spinner";
import Helpers from "../../../Helpers/Helpers";
import i18next from "i18next";

// TR Language support for date picker
registerLocale("tr", tr);

// Returns the ISO week of the date.
// eslint-disable-next-line
Date.prototype.getWeekNumber = function () {
  var onejan = new Date(this.getFullYear(), 0, 1);
  var today = new Date(this.getFullYear(), this.getMonth(), this.getDate());
  var dayOfYear = (today - onejan + 86400000) / 86400000;
  return Math.ceil(dayOfYear / 7);
};

/* eslint-disable  */
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
/* eslint-enable  */

const DateFilterPopProblems = ({ t }) => {
  //state of spinner
  const [spinner, setSpinner] = useState(false);
  //state of date type dropdown filter
  const [selectedFilter, setSelectedFilter] = useState("daily");
  //state of datepicker
  const [startDate, setStartDate] = useState(new Date().addDays(-1));
  const [endDate, setEndDate] = useState(new Date().addDays(-1));

  //observable
  const PopCountSubject = PopCountService.getInstance();

  //get popcount observable value
  const { data: popCount } = UseObservable({
    observable: PopCountSubject.get,
    initialState: initialStates.popCount,
  });

  const BadPopCountSubject = BadPopCountService.getInstance();


  useEffect(() => {
    handleApply();
    // eslint-disable-next-line
  }, []);

  // Date Picker handle change start
  const onChangeStart = (startDate) => {
    setStartDate(startDate);
  };
  // Date Picker handle change end
  const onChangeEnd = (endDate) => {
    setEndDate(endDate);
  };

  const handleApply = () => {
    //starts loader
    setSpinner(true);
    //resets
    PopCountSubject.reset();
    BadPopCountSubject.reset();
    // when filters applied popcount observable fetches data according to selected filters
    if (endDate !== null) {
      switch (selectedFilter) {
        case "hourly":
          if (
            startDate.getDate() === endDate.getDate() &&
            startDate.getMonth() === endDate.getMonth() &&
            startDate.getFullYear() === endDate.getFullYear()
          ) {
            PopCountSubject.fetchDataHourly({
              year: startDate.getFullYear(),
              month: startDate.getMonth(),
              day: startDate.getDate(),
              first_hour: startDate.getHours(),
              last_hour: endDate.getHours(),
              option: "hourly",
            },
            localStorage.setItem("popTime",  JSON.stringify({
              year: startDate.getFullYear(),
              month: startDate.getMonth(),
              day: startDate.getDate(),
              first_hour: startDate.getHours(),
              last_hour: endDate.getHours(),
              period: "hourly",
            })));
          } else {
            alert(
              "If hourly option selected, start and end date should be at same day."
            );
          }

          break;
        case "daily":
          if (
            startDate.getMonth() === endDate.getMonth() &&
            startDate.getFullYear() === endDate.getFullYear()
          ) {
            PopCountSubject.fetchDataDaily({
              year: startDate.getFullYear(),
              month: startDate.getMonth(),
              first_day: startDate.getDate(),
              last_day: endDate.getDate(),
              option: "daily",
            },
            localStorage.setItem("popTime",  JSON.stringify({
              year: startDate.getFullYear(),
              month: startDate.getMonth(),
              first_day: startDate.getDate(),
              last_day: endDate.getDate(),
              period: "daily",
            })));
          } else {
            alert(
              "If daily option selected, start and end date should be at same month."
            );
          }
          break;
        case "weekly":
          if (startDate.getFullYear() === endDate.getFullYear()) {
            PopCountSubject.fetchDataWeekly({
              year: startDate.getFullYear(),
              first_week: startDate.getWeekNumber(),
              last_week: endDate.getWeekNumber(),
              option: "weekly",
            },
            localStorage.setItem("popTime",  JSON.stringify({
              year: startDate.getFullYear(),
              first_week: startDate.getWeekNumber(),
              last_week: endDate.getWeekNumber(),
              period: "weekly",
            })));
          } else {
            alert(
              "If weekly option selected, start and end date should be at same year."
            );
          }
          break;
        case "monthly":
          if (startDate.getFullYear() === endDate.getFullYear()) {
            PopCountSubject.fetchDataMonthly({
              year: startDate.getFullYear(),
              first_month: startDate.getMonth(),
              last_month: endDate.getMonth(),
              option: "monthly",
            },
            localStorage.setItem("popTime",  JSON.stringify({
              year: startDate.getFullYear(),
              first_month: startDate.getMonth(),
              last_month: endDate.getMonth(),
              period: "monthly",
            })));
          } else {
            alert(
              "If monthly option selected, start and end date should be at same year."
            );
          }
          break;
        default:
      }
    } else {
      alert("Choose an end date!");
    }
  };

  useEffect(() => {
    // if selected filter equals hourly set start and end date to today
    // because hourly end point accepts range at same day
    if (selectedFilter === "hourly") {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }, [selectedFilter]);
  return (
    <div
     className="dateClass"
    >
      {popCount.isFetched === false && (
        <Popup
          contentStyle={{
            backgroundColor: "transparent",
            border: "none",
          }}
          open={spinner}
          modal
        >
          <Audio
            type="ThreeDots"
            color="#FAB73A"
            height={100}
            width={100}
            timeout={100000} //3 secs
          />
        </Popup>
      )}

      <div style={{ margin: "10px" }}>
        <DropdownFilter
          filterDataArray={["hourly", "daily", "weekly", "monthly"]}
          setSelectedFilter={setSelectedFilter}
          selectedValue={selectedFilter}
        />
      </div>
      <div style={{ margin: "10px" }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => onChangeStart(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat={Helpers.getDateFormat(selectedFilter)}
          showTimeSelect={selectedFilter === "hourly" ? true : false}
          showFullMonthYearPicker={selectedFilter === "monthly" ? true : false}
          showMonthYearPicker={selectedFilter === "monthly" ? true : false}
          timeIntervals={60}
          locale={i18next.language === "tr" && "tr"}
        />
      </div>
      <div style={{ margin: "10px" }}>
        <DatePicker
          selected={endDate}
          onChange={(date) => onChangeEnd(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          dateFormat={Helpers.getDateFormat(selectedFilter)}
          showTimeSelect={selectedFilter === "hourly" ? true : false}
          showFullMonthYearPicker={selectedFilter === "monthly" ? true : false}
          showMonthYearPicker={selectedFilter === "monthly" ? true : false}
          timeIntervals={60}
          locale={i18next.language === "tr" && "tr"}
        />
      </div>
      <div style={{ margin: "10px" }}>
        <button className="applyButton" onClick={handleApply}>
          {t("Apply")}
        </button>
      </div>
    </div>
  );
};

export default withTranslation()(DateFilterPopProblems);