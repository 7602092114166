import React, { useState, useEffect, useRef  } from 'react';
import ProgressBar from "react-customizable-progressbar";
import { withTranslation } from "react-i18next";
import { Bars } from "react-loader-spinner";
import { getColorFromLinkSpeed, getColorFromLatency, getColorFromConnectedDevice, getColorFromJitter, getColorFromgwJitter, getColorFromStreamLatency } from "./ThresholdColors";

import './Comdata.css';

const axios = require('axios');


const Comdata = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [agentData, setAgentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [placement, setPlacement] = useState(0); 
  const [coverage, setCoverage] = useState(0); 
  const [coverageColor, setCoverageColor] = useState(); 
  const [placementColor, setPlacementColor] = useState(); 
  const [recommendation, setRecommendation] = useState();
  const [bestChannelList, setBestChannelList] = useState([]);
  const [currentChannel, setCurrentChannel] = useState();
  const [interferencePercentageList, setInterferencePercentage] = useState();
  const [modemMac, setModemMac] = useState();
  const [ambDeviceId, setAmbDeviceId] = useState();
  const [heatMapFetch, setHeatMapFetch] = useState(false);
  const [noiseListFecth, setNoiseListFecth] = useState(false);


  //const [infoElements, setInfoElements] = useState([]); 

  const companyId = localStorage.getItem("companyId"); 
  const userChartRef = useRef(null); 

  const handleUserClick = (user) => {
    setSelectedUser(user);
    getHeatmap(user.modemMac)
    getModemNoiseList(user.modemMac, user.ambDeviceId)
    /*setInfoElements([])
    getStasInfo(user.ambDeviceId, user.modemMac, companyId)
    .then(data => {
      if (data) {
        console.log(data[0]);
        console.log(data[0].informationElements);
        setInfoElements(JSON.parse(data[0].informationElements))
      } else {
        console.log('Veri alınamadı.');
      }
    });*/
    scrollToChart();
  };

  /*async function getStasInfo(id,mac,com){
    try {
      const response = await fetchData(`https://backend.ambeent.ai/statistics/stasInfo?id=${id}&mac=${mac}&com=${com}`);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  }*/

  function getSmallestValuesIndices(arr) {
    if (!Array.isArray(arr) || arr.length === 0) {
      return []; // Eğer dizi boş veya geçersiz ise boş bir liste döndür.
    }
  
    const smallestValue = Math.min(...arr); // Dizideki en küçük değeri bul.
    const indices = [];
  
    arr.forEach((value, index) => {
      if (value === smallestValue) {
        indices.push(index + 1); // En küçük değerin sırasını 1 artırarak ekle.
      }
    });
  
    return indices;
  }

  function getHeatmap (modemMac){
    setPlacement(0)
    setCoverage(0)
    setHeatMapFetch(false)
    axios
    .post(
      "https://backend.ambeent.ai/router-heatmap/allheatmaps",
      {
        companyId: companyId,
        modemMac: modemMac,
      }
    )
    .then((response) => {
      const rssiData = response.data.find(item => item.parameter === 'Rssi')
      setPlacement(rssiData.heatMaps.total_add_prm.applacement.percentage)
      setCoverage(rssiData.heatMaps.total_add_prm.coverage.percentage)
      setCoverageColor(rssiData.heatMaps.total_add_prm.coverage.color)
      setPlacementColor(rssiData.heatMaps.total_add_prm.applacement.color)
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(() => {
      setHeatMapFetch(true); 
    });
  }

  function getModemNoiseList (modemMac,ambDeviceId){
    setBestChannelList([])
    setCurrentChannel(0)
    setInterferencePercentage(0)
    setModemMac(modemMac)
    setAmbDeviceId(ambDeviceId)
    setNoiseListFecth(false)
    axios
    .get(
      `https://backend.ambeent.ai/statistics/channelNoiseList?id=${ambDeviceId}&mac=${modemMac}&com=${companyId}`
    )
    .then((response) => {
      setBestChannelList(getSmallestValuesIndices(response.data[0].noiseList))
      setCurrentChannel(response.data[0].currentChannel)
      if (response.data[0].interferencePercentageList) {
        setInterferencePercentage(response.data[0].interferencePercentageList[response.data[0].currentChannel -1])
      }
      setRecommendation("")
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(() => {
      setNoiseListFecth(true); 
    });
  }

  useEffect(() => {
    if (heatMapFetch === true && noiseListFecth === true) {
      getRecommendation(
        modemMac, 
        ambDeviceId,
        bestChannelList,
        interferencePercentageList,
        currentChannel,
        coverage,
        placement,
      );
    }
    else{ console.log("")}
  }, [
    heatMapFetch, 
    noiseListFecth,    
    modemMac, 
    ambDeviceId,
    bestChannelList,
    interferencePercentageList,
    currentChannel,
    coverage,
    placement,
  ]);

  function getRecommendation (modemMac,ambDeviceId, bestChannelList, interferencePercentage, currentChannel, coverage, placement){
    axios
    .post(
      "https://backend.ambeent.ai/recommendation/",
      {
        modemMac: modemMac,
        ambDeviceId: ambDeviceId,
        lang: "EN",
        bestChannelList: bestChannelList,
        interferencePercentage: interferencePercentage,
        currentChannel : currentChannel,
        coverage : coverage,
        placement : placement,
      }
    )
    .then((response) => {
      const formattedMessage = response.data.message_eng
      .replace(/--/g, '') 
      .replace(/Current channel is : \d+\n|Best channels are : \d+\n/g, '')
      .split('\n') 
      .map(line => line.trim()) 

      setRecommendation(formattedMessage)

    })
    .catch(function (error) {
      console.log(error);
    });

  }
  const scrollToChart = () => {
    if (userChartRef.current) {
      userChartRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

async function fetchData(url) {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    return null;
  }
}



useEffect(() => {
  async function fetchModemData(ambDeviceId, customerId, companyId) {
    try {
      // Get modem mac 
      const modemResponse = await fetchData(`https://backend.ambeent.ai/statistics/modemsFromCompanyID?ambDeviceId=${ambDeviceId}&id=${companyId}&customerId=${customerId}`);
      const modemData = modemResponse[0]; 
  
      // Get modem details
      const routerResponse = await fetchData(`https://backend.ambeent.ai/ambeentcore/api/v1/Router/get/${modemData.modemMac}`);
      const routerData = routerResponse;
  
      const result = {
        ambDeviceId,
        customerId,
        ...modemData,
        routerData,
      };
  
      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async function fetchDataAndProcess() {
    try {
      const users = await fetchData(`https://backend.ambeent.ai/statistics/clients?id=${companyId}`);
  
      const results = await Promise.all(users.map((user) => fetchModemData(user.ambDeviceId, user.customerId, companyId)));
  
      setAgentData(results);
      setIsLoading(false);
      console.log(results);
    } catch (error) {
      console.log(error);
    }
  }
  
  fetchDataAndProcess();
}, [companyId]);


  return (
    <div className="Comdata">
      {isLoading ? ( 
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
        >
      <Bars
        type="ThreeDots"
        color="#FAB73A"
        height={100}
        width={100}
      />
      </div>
      ) : (
      <h2 style={{color:"white", marginTop:"10px"}}>Agent List</h2>
      )}
      <ul className="user-list">
        {agentData.map((user) => (
          <li
            style={{ color: "white" }}
            key={user.ambDeviceId}
            onClick={() => handleUserClick(user)}
            className={`user-item ${user.routerData.score < 40 ? 'red' : user.routerData.score >= 60 ? 'green' : 'yellow'}`}
          >
          <div className="row" style={{ 
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            marginLeft: "20px",
            marginRight: "20px",
          }} 
          >
            {Object.entries(user).map(([key, value]) => (
                <b>
                  {key === "customerId" || key === "modemMac"  || key === "ambDeviceId" || key === "ssid" ? (
                <div key={key} className="col detailsBoxMediumCom" style={{color:"white"}}>
                  <div style={{marginTop:"20px"}}>
                  <div className="BoxTitleValueCom">{value}</div>
                  <div className="BoxTitleCom">
                      {" "}
                      {key === "customerId" ? ("Agent Name") : ( key === "ambDeviceId" ? ("Device ID") : ( key=== "modemMac" ? ("Modem Mac") : ("SSID")))}
                  </div>
                  </div>
                </div>
                ):
                key === "routerData" ? (
                  <div className="col detailsBoxMediumCom" style={{color:"white"}}>
                    <div style={{marginTop:"20px",marginLeft:"10px"}}>
                      <div className="BoxTitleValueCom">{value.score}</div>
                      <div className="BoxTitleCom">
                        {" "}
                        Score
                      </div>
                    </div>
                  </div>
                ) : null}
              </b>
            ))}
          </div>
          </li>
        ))}
      </ul>
      {selectedUser && (
        <div ref={userChartRef} id="userChart" className="user-chart">
          <h2 className="workerName">{selectedUser.customerId + "'s Details"}</h2>
          <div className="progress-barsCom">
            <div className="progress-barCom">
              Wifi Speed
          <ProgressBar
                  progress={Math.min((selectedUser.routerData.wifiSpeed), 100)}
                  radius={100}
                  strokeColor={selectedUser.routerData.wifiSpeed < 32 ? 'red' : selectedUser.routerData.wifiSpeed >= 70 ? 'green' : 'yellow'}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
              >
                <div className="indicatorCom">
              <span>{(selectedUser.routerData.wifiSpeed).toFixed(2)}</span>
                </div>
          </ProgressBar>
          </div>
          <div className="progress-barCom">
            RSSI
          <ProgressBar
                  progress={100+selectedUser.routerData.rssi}
                  radius={100}
                  strokeColor={selectedUser.routerData.rssi < -60 ? 'red' : selectedUser.routerData.rssi >= -40 ? 'green' : 'yellow'}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
              >
                <div className="indicatorCom">
              <span>{selectedUser.routerData.rssi}</span>
                </div>
          </ProgressBar>
          </div>
          <div className="progress-barCom">
          Download Speed
          <ProgressBar
                  progress={Math.min((selectedUser.routerData.speed / 1024 / 1024), 100)}
                  radius={100}
                  strokeColor={selectedUser.routerData.speed < 15 ? 'red' : selectedUser.routerData.speed >= 30 ? 'green' : 'yellow'}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
              >
                <div className="indicatorCom">
              <span>{((selectedUser.routerData.speed/1024)/1024).toFixed(2) + " Mbps"}</span>
                </div>
          </ProgressBar>
          </div>
          <div className="progress-barCom">
          SCORE
          <ProgressBar
                  progress={selectedUser.routerData.score}
                  radius={100}
                  strokeColor={selectedUser.routerData.score < 40 ? 'red' : selectedUser.routerData.score >= 60 ? 'green' : 'yellow'}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
              >
                <div className="indicatorCom">
              <span>{selectedUser.routerData.score}</span>
                </div>
          </ProgressBar>
          </div>
          </div>
          <div className="progress-barsCom">
            <div className="progress-barCom">
            Quality
          <ProgressBar
                  progress={selectedUser.routerData.quality*10}
                  radius={100}
                  strokeColor={selectedUser.routerData.wifiSpeed < 4 ? 'red' : selectedUser.routerData.wifiSpeed >= 7 ? 'green' : 'yellow'}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
              >
                <div className="indicatorCom">
              <span>{selectedUser.routerData.quality}</span>
                </div>
          </ProgressBar>
          </div>
          <div className="progress-barCom">
            Fidelty
          <ProgressBar
                  progress={selectedUser.routerData.fidelity}
                  radius={100}
                  strokeColor={selectedUser.routerData.fidelity < -40 ? 'red' : selectedUser.routerData.fidelity >= 60 ? 'green' : 'yellow'}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
              >
                <div className="indicatorCom">
              <span>{selectedUser.routerData.fidelity}</span>
                </div>
          </ProgressBar>
          </div>
          <div className="progress-barCom">
          Placement
          <ProgressBar
                  progress={placement}
                  radius={100}
                  strokeColor={placementColor}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
              >
                <div className="indicatorCom">
              <span>{placement === 0 ? "N/A" : placement + "%"}</span>
                </div>
          </ProgressBar>
          </div>
          <div className="progress-barCom">
          Coverage
          <ProgressBar
                  progress={coverage}
                  radius={100}
                  strokeColor={coverageColor}
                  trackStrokeWidth={14}
                  cut={100}
                  rotate={-217}
              >
                <div className="indicatorCom">
              <span>{coverage === 0 ? "N/A" : coverage + "%" }</span>
                </div>
          </ProgressBar>
          </div>
          </div>
          <div className="progress-barsCom" style={{marginTop:"100px"}}>
            <div className="progressString-barCom">
            Streaming Latency
              <div className="indicatorStringCom">
              <span style={{ color: getColorFromStreamLatency(selectedUser.routerData.streamLatency) }}>
              {selectedUser.routerData.streamLatency}
              </span>
            </div>
          </div>
          <div className="progressString-barCom">
          Latency
              <div className="indicatorStringCom">
              <span style={{ color: getColorFromLatency(selectedUser.routerData.latency) }}>
                {selectedUser.routerData.latency}
              </span>
              </div>
          </div>
          <div className="progressString-barCom">
          Connected Device
              <div className="indicatorStringCom">
              <span style={{ color: getColorFromConnectedDevice(selectedUser.routerData.connectedDevice) }}>
                {selectedUser.routerData.connectedDevice}
              </span>
              </div>
          </div>
          <div className="progressString-barCom">
          Link Speed
                <div className="indicatorStringCom">
                <span style={{ color: getColorFromLinkSpeed(selectedUser.routerData.linkSpeed) }}>
                {selectedUser.routerData.linkSpeed}
              </span>
                </div>
          </div>
          </div>
          <div className="progress-barsCom" style={{marginTop:"100px"}}>
            <div className="progressString-barCom">
            Jitter
                <div className="indicatorStringCom">
                <span style={{ color: getColorFromJitter(selectedUser.routerData.jitter) }}>
                {selectedUser.routerData.jitter}
              </span>
                </div>
          </div>
          <div className="progressString-barCom">
          Gateway Jitter
                <div className="indicatorStringCom">
                <span style={{ color: getColorFromgwJitter(selectedUser.routerData.gwJitter) }}>
                {selectedUser.routerData.gwJitter}
              </span>
                </div>
          </div>
          <div className="progressString-barCom">
            Channel
                <div className="indicatorStringCom">
                {currentChannel !== 0 ? currentChannel : ""}
                </div>
          </div>
          <div className="progressString-barCom">
            Best Channel
                <div className="indicatorStringCom">
                {bestChannelList !== [] ? bestChannelList : ""}
                </div>
          </div>
          </div>
          <div className="progress-barsCom" style={{marginTop:"100px"}}>
          <div className="progressStringRecommendation-barCom">
          Recommendation
                <div className="indicatorStringComRecommendation">
                {recommendation ? recommendation : ""}
                </div>
          </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Comdata);;