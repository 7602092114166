import React, { useState } from "react";
import { withTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import InputField from "../InputField.jsx";
import apis from "../../../Services/Apis/account/account.apis.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  checked: {},
}));

const InviteToGroup = ({ t }) => {
  const [mail, setMail] = useState(null);
  const [role, setRole] = useState(null);
  const classes = useStyles();

  function handleInviteSubmit(event) {
    if (mail === null) {
      document.getElementById("inviteSend").innerHTML = t("emptymail");
    } else if (!mail.includes("@")) {
      document.getElementById("inviteSend").innerHTML = t("emailFormatError");
    } else if (role === null) {
      // console.log("mail: ");
      document.getElementById("inviteSend").innerHTML = t("didntchooserole");
    } else {
      apis.inviteToGroup({ mail, role });
    }
    event.preventDefault();
  }

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Paper className="accountPaper">
        <div className="accountInviteToGroup">
          <label className="accountSettingsHeader">{t("inviteCompany")}</label>
          <form
            id="accountForm"
            onSubmit={handleInviteSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <div className="accountInviteToGroupContentArea">
              <InputField
                className="invitEmail"
                label={t("email")}
                id="invitEmailText"
                setter={setMail}
              />
              <div>
                <select id="roleSelect" onChange={handleRoleChange}>
                  <option disabled defaultValue={0} className="rol" value={0}>
                    {t("setRole")}
                  </option>
                  <option className="rol" value={"Super Admin"}>
                    Super Admin
                  </option>
                  <option className="rol" value={"Admin"}>
                    Admin
                  </option>
                  <option className="rol" value={"Call Center"}>
                    Call Center
                  </option>
                </select>
              </div>
              <div id="inviteSend"></div>
            </div>
            <div id="sendDiv" className="settingsSaveButon">
              <Button
                // type="submit"
                onClick={(e) => alert(t("notAvailable"))}
                id="sendInviteButton"
                variant="contained"
                color="secondary"
              >
                {t("send")}
              </Button>
            </div>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default withTranslation()(InviteToGroup);
