import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";

class Devices {
  deviceSubject;
  constructor() {
    this.deviceSubject = new BehaviorSubject(initialStates.device);
  }

  get get() {
    return this.deviceSubject.asObservable();
  }

  setSelectedDevice(selectedAmbDeviceId, deviceType, label) {
    this.deviceSubject.next((device) => {
      return {
        ...device,
        selectedDevice: {
          ambDeviceId: selectedAmbDeviceId,
          deviceType: deviceType,
          label: label,
        },
      };
    });
  }

  fetchData(companyId, customerId) {
    apis.getDevices(companyId, customerId).then((response) => {
      this.deviceSubject.next(response);
    });
  }

  reset() {
    this.deviceSubject.next(initialStates.device);
  }
}

const DevicesService = (function () {
  var instance;

  function createInstance() {
    var classObj = new Devices();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default DevicesService;
