//libraries
import React from "react";
import ReactGA from "react-ga";
import { Chart } from "react-google-charts";
import { withTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-up";
import CircularProgress from "@material-ui/core/CircularProgress";

//Utils
import Helpers from "../../Helpers/Helpers";

//Components
import ModelChart from "./Components/ModelChart";
import DevicesPieChart from "./Components/DevicesPieChart";

//observable imports
import UseObservable from "../../Helpers/UseObservable";
import fetchDevices from "../../Classes/Devices/Helpers/fetchDevices";
import {
  AndroidReleasePredictionService,
  AndroidReleaseService,
  DeviceManufacturerDistService,
  DeviceModelDistService,
  IOSReleaseDistService,
  IOSReleasePredictionService,
} from "../../Classes";
import initialStates from "../../Classes/InitialStates/devices.initialStates";

//chart option imports
import chartOptions from "./devices.chartOptions";
import resetDevices from "../../Classes/Devices/Helpers/resetDevices";
import Constants from "../../utils/Constants";


const Devices  = (props) => {
  const { t } = props;
  const [selectedRouter, setSelectedRouter] = React.useState("");

  // Device Manufacturer Observable used in Device Manufacturer Chart
  const { data: deviceManufacturers } = UseObservable({
    observable: DeviceManufacturerDistService.getInstance().get,
    initialState: initialStates.deviceManufacturerDist,
  });
  // Device Model Distrubition Observable used in Model chart
  const { data: modelDist } = UseObservable({
    observable: DeviceModelDistService.getInstance().get,
    initialState: initialStates.deviceModelDist,
  });
  // Android Release Dist Observable
  const { data: androidReleaseDist } = UseObservable({
    observable: AndroidReleaseService.getInstance().get,
    initialState: initialStates.androidRelease,
  });
  // Android Release Prediction Dist Observable
  const { data: androidReleasePredictionDist } = UseObservable({
    observable: AndroidReleasePredictionService.getInstance().get,
    initialState: initialStates.androidReleasePrediction,
  });
  // IOS Release Dist
  const { data: iosReleaseDist } = UseObservable({
    observable: IOSReleaseDistService.getInstance().get,
    initialState: initialStates.IOSReleaseDist,
  });
  // IOS Release Prediction Dist
  const { data: iosReleasePredictionDist } = UseObservable({
    observable: IOSReleasePredictionService.getInstance().get,
    initialState: initialStates.IOSReleaseDist,
  });

  React.useEffect(() => {
    ReactGA.initialize("UA-173492242-1");
    ReactGA.pageview(localStorage.email + " is on /devices page");

    resetDevices();
    fetchDevices();
    /*eslint-disable */
  }, []);
  /*eslint-enable */

  function getDeviceModel(selected) {
    DeviceModelDistService.getInstance().reset();
    DeviceModelDistService.getInstance().fetchData({
      manufacturer: selected.charAt(0).toUpperCase() + selected.slice(1),
      companyId: Helpers.getLowerCaseCompanyID(),
    });
  }

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        var selectedRow = chartWrapper.getChart().getSelection()[0].row;
        var selected = deviceManufacturers?.brandData[selectedRow + 1][0];
        setSelectedRouter(selected);
        getDeviceModel(selected)
      
      },
    },
  ];
  if (deviceManufacturers.isFetched && selectedRouter === ""){
    setSelectedRouter(deviceManufacturers?.brandData[1][0]);
    getDeviceModel(deviceManufacturers?.brandData[1][0])
  }

  return (
    <div>
      <div style={{ height: "50px" }}></div>
      <div className="chart-card mb-4">
        <div className="chart-title1">{t("DevicesIOSRelase")}</div>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          {/* ----------Current Device Chart----------- */}
          <DevicesPieChart
            chartTitle={t("Current")}
            waitProgress={iosReleaseDist.isFetched}
            data={iosReleaseDist.releaseData}
            options={chartOptions.optionswithLegendWithoutPercentage}
            total={iosReleaseDist.total}
            type="current"
          />
          {localStorage.getItem("companyType") === "Enterprise" ? ( 
          console.log("")
           ) : (
            <>
            {/* ----------Prediction Device Chart 1----------- */}
            <DevicesPieChart
            chartTitle={t("After3MonthPrediction")}
            data={iosReleasePredictionDist.prediction3Month}
            options={chartOptions.optionswithLegend}
            waitProgress={iosReleasePredictionDist.isFetched}
            />
            {/* ----------Prediction Device Chart 2----------- */}
            <DevicesPieChart
            chartTitle={t("After6MonthPrediction")}
            data={iosReleasePredictionDist.prediction6Month}
            options={chartOptions.optionswithLegend}
            arrow={false}
            waitProgress={iosReleasePredictionDist.isFetched}
            />
            </>
          )}
        </div>
      </div>
      <div className="chart-card mb-4">
        <div className="chart-title1">{t("DevicesAndroidRelase")}</div>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          {/* ----------Current Device Chart----------- */}
          <DevicesPieChart
            chartTitle={t("Current")}
            waitProgress={androidReleaseDist.isFetched}
            data={androidReleaseDist.releaseData}
            options={chartOptions.optionswithLegendWithoutPercentage}
            total={androidReleaseDist.total}
            type="current"
          />
          {localStorage.getItem("companyType") === "Enterprise" ? ( 
          console.log("")
           ) : (
            <>
          {/* ----------Prediction Device Chart 1----------- */}
          <DevicesPieChart
            chartTitle={t("After3MonthPrediction")}
            data={androidReleasePredictionDist.prediction3Month}
            options={chartOptions.optionswithLegend}
            waitProgress={androidReleasePredictionDist.isFetched}
          />

          {/* ----------Prediction Device Chart 2----------- */}
          <DevicesPieChart
            chartTitle={t("After6MonthPrediction")}
            data={androidReleasePredictionDist.prediction6Month}
            options={chartOptions.optionswithLegend}
            arrow={false}
            waitProgress={androidReleasePredictionDist.isFetched}
          />
           </>
          )}
        </div>
      </div>

      {/* Device Manufacturer Chart */}
      <div id="barChartDiv">
        <div className="chart-card mb-4">
          <div className="chart-title1">
            {t(
              Helpers.getLowerCaseCompanyID() === Constants.COMPANY_ID_T
                ? "DevicesBrand"
                : "DevicesAndroidBrand"
            )}
          </div>
          <div id="routerBrand">{t("clickBrand")}</div>
          {deviceManufacturers.isFetched ? (
            <Chart
              chartType="ColumnChart"
              loader={<div>Loading Chart</div>}
              data={deviceManufacturers?.brandData}
              options={chartOptions.barOptions}
              chartEvents={chartEvents}
            />
          ) : (
            <div style={{ marginTop: "150px", marginBottom: "150px" }}>
              <CircularProgress disableShrink color="secondary" size="1.5rem" />
            </div>
          )}
        </div>{" "}
      </div>

      <div style={{ height: "50px" }}></div>

      {/* Model Dist Chart */}
      {modelDist.modelData?.length === 0 ? (
        <div id="empty">{t("noData")}</div>
      ) : (
        <ModelChart
          selectedRouter={selectedRouter}
          modelData={modelDist.modelData}
        />
      )}
      <div style={{ height: "20px" }}></div>
      <ScrollToTop showUnder={160}>
        <img src="https://img.icons8.com/nolan/64/circled-up-2.png" alt="" />
      </ScrollToTop>
    </div>
  );
};

export default withTranslation()(Devices);
