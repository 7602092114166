import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/CustomerService/customerService.apis";
import initialStates from "../InitialStates/customerService.initialStates";

//NOT COMPLETE
class BadCustomers {
  badCustomersServiceSubject;
  constructor() {
    this.badCustomersServiceSubject = new BehaviorSubject(
      initialStates.badCustomers
    );
  }

  //get router heat map object
  get get() {
    return this.badCustomersServiceSubject.asObservable();
  }

  //fetch data from GetBadCustomers endpoint
  fetchData({
    selectedRowCount,
    selectedFilter,
    selectedDateType,
    startDateFilter,
    endDateFilter,
    frequency,
  }) {
    apis
      .getBadCustomers({
        selectedRowCount,
        selectedFilter,
        selectedDateType,
        startDateFilter,
        endDateFilter,
        frequency,
      })
      .then((response) => {
        this.badCustomersServiceSubject.next(response);
      });
  }

  //reset GetBadCustomers Subject objects state
  reset() {
    this.badCustomersServiceSubject.next(initialStates.badCustomers);
  }
}

const BadCustomersService = (function () {
  var instance;

  function createInstance() {
    var classObj = new BadCustomers();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default BadCustomersService;
