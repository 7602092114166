import React, {  useState } from "react";
import { withTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from '@material-ui/core/Typography';

//Observable Services
import DevicesService from "../../../Classes/Details/Device";
import initialStates from "../../../Classes/InitialStates/details.InitialStates";

//Helpers
import UseObservable from "../../../Helpers/UseObservable";

//Styles
import { makeStyles } from "@material-ui/core/styles";

const stylebutton = {
  background: "var(--details-color)",
  height: "100%",
  width: "100%",
  color: "white",
  fontWeight: "bold",
  marginTop: "10px",
  padding: "5px",
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: '100%',
    minWidth: "120px",
    backgroundColor: "#182142",
  },
  tabs: {
    backgroundColor: "#182142",
    color: "#e2e2e2",
    minWidth: "120px",
    minHeight:"80px",
    maxHeight:"100px",
  },
  tab: {
    backgroundColor: "#182142",
    color: "#e2e2e2",
    minHeight:"80px",
    maxHeight:"100px",
  },
  emptyTab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#182142",
    color: "#e2e2e2",
    minWidth: "120px",
    minHeight: "80px",
    maxHeight:"100px",
    opacity: "0.5",
  }
}));

const DevicesTabs = (props) => {
  const { t } = props;


  const classes = useStyles();

  //Observable Subject
  var DevicesSubject = DevicesService.getInstance();

  //Observable States
  const { data: devices } = UseObservable({
    observable: DevicesSubject.get,
    initialState: initialStates.device,
  });


  //Local states
  const [ambDeviceIdTabIndex, setAmbDeviceIdTabIndex] = useState(0);

 

  const handleChangeAmbDeviceId = (event, index) => {
    //sets selected devices tab index
    setAmbDeviceIdTabIndex(index);

    let selectedAmbDeviceId = devices.deviceList[index].value;
    let selectedDeviceType = devices.deviceList[index].type;
    let selectedDeviceLabel = devices.deviceList[index].label;

    //sets selected device id of devices object
    DevicesSubject.setSelectedDevice(
      selectedAmbDeviceId,
      selectedDeviceType,
      selectedDeviceLabel
    );

    // resetStates();
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  React.useEffect(() => {
    setAmbDeviceIdTabIndex(0)
    /*eslint-disable */
  }, [devices.deviceList]);

  return (
    <div
      className="chart1-em col-lg-12 col-xl-12 col-12 col-sm-12 col-md-12"
      style={stylebutton}
    >
      <div className={classes.root}>
        <div className="titleOfList"> {t("Devices")} </div>
        <AppBar position="static" color="transparent">
         {devices?.deviceList?.length === 0 ? 
          <Typography className={classes.emptyTab}> {t("nodata")} </Typography>
          :
         <Tabs
            value={ambDeviceIdTabIndex}
            className={classes.tabs}
            onChange={handleChangeAmbDeviceId}
            indicatorColor="secondary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {devices?.deviceList?.map((item, i) => {
              return (
                <Tab
                  className={classes.tab}
                  style={{
                     background: "linear-gradient(to bottom, #182142, #1e2953)",
                    }}
                  label={
                    item?.label === null ? (
                      <span>{item?.value}</span>
                    ) : (
                      <span>
                        {item?.label}
                        <br></br>
                        {item?.value}
                      </span>
                    )
                  }
                  {...a11yProps(i)}
                  key={i}
                />
              );
            })}
          </Tabs>}
        </AppBar>
      </div>
    </div>
  );
};

export default withTranslation()(DevicesTabs);
