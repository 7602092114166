import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";

class StasInfo {
  stasInfoSubject;
  constructor() {
    this.stasInfoSubject = new BehaviorSubject(initialStates.stasInfo);
  }

  //get stas info object
  get get() {
    return this.stasInfoSubject.asObservable();
  }

  //fetch data from /statistics/stasInfo?id=${ambDeviceId}&mac=${modemMac} endpoint
  fetchData(ambDeviceId, modemMac) {
    apis.getStasInfo(ambDeviceId, modemMac).then((response) => {
      this.stasInfoSubject.next(response);
    });
  }

  //reset stas info objects state
  reset() {
    this.stasInfoSubject.next(initialStates.stasInfo);
  }
}

const StasInfoService = (function () {
  var instance;

  function createInstance() {
    var classObj = new StasInfo();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default StasInfoService;
