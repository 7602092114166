import ChartAdapters from "../../../Components/DashboardKPI/ChartAdapters";
import { BarChartOptionsRevertedLegendColors } from "../../../Components/DashboardKPI/DashboardOptions";

const handleSocketMessage = (response) => {
  var mm = response;
  // console.log(mm);

  var newDate = new Date();

  var realDate =
    (newDate.toJSON(),
    new Date(newDate.getTime() - newDate.getTimezoneOffset() * 60000).toJSON());

  var currentDate = realDate.substring(0, realDate.length - 10) + "00:00+00:00";
  var currentTime = realDate.substring(0, realDate.length - 13);

  var today = new Date();

  const measurements = {
    measurements: mm.Measurements_total,
    activeUser: mm.Active_User_total,
    Modems: mm.Modems_total,
  };

  localStorage.setItem("measurementsSocket", JSON.stringify(measurements));

  const complainDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_status,
      mediumData: mm.medium_status,
      badData: mm.bad_status,
      goodTotal: mm.good_status_total,
      mediumTotal: mm.medium_status_total,
      badTotal: mm.bad_status_total,
      localSocket: "complainDataSocket",
      localSocketLine: "complainLineChartSocket",
      chartId: "complainChart",
      chartTitleId: "complainTitle",
      chartLabels: ["Low", "Medium", "High", "Treshold"],
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "complainTitle",
    chartTitle: "Complain",
    chartInfo: "complainInfo",
    graphId: "complainChart",
  };

  const cxScoreDataSum =
    mm.good_status_total + mm.medium_status_total + mm.bad_status_total;

  const cxScoreDataGoodPercentage =
    (100 * mm.good_status_total) / (cxScoreDataSum === 0 ? 1 : cxScoreDataSum);
  const cxScoreDataMedPercentage =
    (100 * mm.medium_status_total) /
    (cxScoreDataSum === 0 ? 1 : cxScoreDataSum);
  const cxScoreDataBadPercentage =
    (100 * mm.bad_status_total) / (cxScoreDataSum === 0 ? 1 : cxScoreDataSum);

  const CxScoreDataProgress = {
    goodTotal: cxScoreDataGoodPercentage,
    mediumTotal: cxScoreDataMedPercentage,
    badTotal: cxScoreDataBadPercentage,
  };

  localStorage.setItem(
    "cxScoreDataSocketProgress",
    JSON.stringify(CxScoreDataProgress)
  );

  // MODEM HEALTH
  const wifiHealthSum =
    mm.good_health_total + mm.medium_health_total + mm.bad_health_total;

  // console.log(wifiHealthSum);
  const wifiHealthGoodPercentage =
    (100 * mm.good_health_total) / (wifiHealthSum === 0 ? 1 : wifiHealthSum);
  const wifiHealthMedPercentage =
    (100 * mm.medium_health_total) / (wifiHealthSum === 0 ? 1 : wifiHealthSum);
  const wifiHealthBadPercentage =
    (100 * mm.bad_health_total) / (wifiHealthSum === 0 ? 1 : wifiHealthSum);
  // console.log(
  //   mm.good_health_total +
  //     " " +
  //     mm.medium_health_total +
  //     " " +
  //     mm.bad_health_total
  // );

  const WifiHealthDataSocketProgress = {
    goodTotal: wifiHealthGoodPercentage,
    mediumTotal: wifiHealthMedPercentage,
    badTotal: wifiHealthBadPercentage,
  };

  localStorage.setItem(
    "wifiHealthDataSocketProgress",
    JSON.stringify(WifiHealthDataSocketProgress)
  );

  // OPT REQUIRED

  const optimizationRequiredDispatch = {
    data: ChartAdapters.chartWebSocketAdapter4Parametre(null, {
      goodData: mm.critic_optimization,
      mediumData: mm.normal_optimization,
      badData: mm.low_optimization,
      noData: mm.good_optimization,
      goodTotal: mm.critic_optimization_total,
      mediumTotal: mm.normal_optimization_total,
      badTotal: mm.low_optimization_total,
      noTotal: mm.good_optimization_total,
      localSocket: "optimizationRequiredDataSocket",
      localSocketLine: "optimizationRequiredLineChartSocket",
      chartId: "optRequiredChart",
      chartTitleId: "optRequiredTitle",
      chartLabels: ["Critic", "Normal", "Low", "No"],
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "optRequiredTitle",
    chartTitle: "optRequired",
    chartInfo: "optRequiredInfo",
    graphId: "optRequiredChart",
    options: BarChartOptionsRevertedLegendColors,
  };

  var totalCostSavedCalculation = Math.round(
    mm.critic_optimization_total +
      mm.normal_optimization_total / 2 +
      mm.low_optimization_total * 0.2
  );
  var totalSavedOpt = totalCostSavedCalculation * 50;
  var totalSavedCall = totalCostSavedCalculation * 30;
  var totalSavedCostSum = totalSavedOpt + totalSavedCall;

  localStorage.setItem(
    "totalCostSavedSocket",
    JSON.stringify(totalSavedCostSum)
  );

  localStorage.setItem(
    "totalCostCallSavedSocket",
    JSON.stringify(totalSavedCall)
  );

  localStorage.setItem(
    "totalCostOptSavedSocket",
    JSON.stringify(totalSavedOpt)
  );

  // STREAMING QUALITY

  const streamingQualityDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.Streaming_Quality_High,
      mediumData: mm.Streaming_Quality_Medium,
      badData: mm.Streaming_Quality_Low,
      goodTotal: mm.Streaming_Quality_High_total,
      mediumTotal: mm.Streaming_Quality_Medium_total,
      badTotal: mm.Streaming_Quality_Low_total,
      localSocket: "streamingQualityDataSocket",
      localSocketLine: "streamingQualityLineChartSocket",
      chartId: "streamingQualityChart",
      chartTitleId: "streamingQualityTitle",
      chartLabels: ["High", "Medium", "Low"],
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "streamingQualityTitle",
    chartTitle: "streamingQuality",
    chartInfo: "streamingQualityInfo",
    graphId: "streamingQualityChart",
  };

  // STREAMING LATENCY

  const streamingLatencyDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.Streaming_Latency_Good,
      mediumData: mm.Streaming_Latency_Medium,
      badData: mm.Streaming_Latency_Bad,
      goodTotal: mm.Streaming_Latency_Good_total,
      mediumTotal: mm.Streaming_Latency_Medium_total,
      badTotal: mm.Streaming_Latency_Bad_total,
      localSocket: "streamingLatencyDataSocket",
      localSocketLine: "streamingLatencyLineChartSocket",
      chartId: "streamingLatencyChart",
      chartTitleId: "streamingLatencyTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "streamingLatencyTitle",
    chartTitle: "streamingLatency",
    chartInfo: "streamingLatencyInfo",
    graphId: "streamingLatencyChart",
  };

  // SPEED

  const speedDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_speed_test,
      mediumData: mm.medium_speed_test,
      badData: mm.bad_speed_test,
      goodTotal: mm.good_speed_test_total,
      mediumTotal: mm.medium_speed_test_total,
      badTotal: mm.bad_speed_test_total,
      localSocket: "speedDataSocket",
      localSocketLine: "speedTestLineChartSocket",
      chartId: "speedChart",
      chartTitleId: "speedTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "speedTitle",
    chartTitle: "speedtest",
    chartInfo: "speedTestInfo",
    graphId: "speedChart",
  };

  //WIFI SPEED

  const wifiSpeedDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_wifi_speed,
      mediumData: mm.medium_wifi_speed,
      badData: mm.bad_wifi_speed,
      goodTotal: mm.good_wifi_speed_total,
      mediumTotal: mm.medium_wifi_speed_total,
      badTotal: mm.bad_wifi_speed_total,
      localSocket: "wifiSpeeDataSocket",
      localSocketLine: "wifiSpeedLineChartSocket",
      chartId: "wifispeedChart",
      chartTitleId: "wifispeedTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "wifispeedTitle",
    chartTitle: "wifispeed",
    chartInfo: "wifiSpeedInfo",
    graphId: "wifiSpeedChart",
  };

  // LINK SPEED

  const linkSpeedDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_linkspeed,
      mediumData: mm.medium_linkspeed,
      badData: mm.bad_linkspeed,
      goodTotal: mm.good_linkspeed_total,
      mediumTotal: mm.medium_linkspeed_total,
      badTotal: mm.bad_linkspeed_total,
      localSocket: "linkSpeedDataSocket",
      localSocketLine: "linkSpeedLineChartSocket",
      chartId: "linkspeedChart",
      chartTitleId: "linkspeedTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "linkspeedTitle",
    chartTitle: "linkspeed",
    chartInfo: "linkspeedInfo",
    graphId: "linkspeedChart",
  };

  // LATENCY
  const latencyDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_latency,
      mediumData: mm.medium_latency,
      badData: mm.bad_latency,
      goodTotal: mm.good_latency_total,
      mediumTotal: mm.medium_latency_total,
      badTotal: mm.bad_latency_total,
      localSocket: "latencyDataSocket",
      localSocketLine: "latencyLineChartSocket",
      chartId: "latencyChart",
      chartTitleId: "latencyTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "latencyTitle",
    chartTitle: "localatency",
    chartInfo: "latencyInfo",
    graphId: "latencyChart",
  };
  // PLACEMENT

  const cpePlacementDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_placement,
      mediumData: mm.medium_placement,
      badData: mm.bad_placement,
      goodTotal: mm.good_placement_total,
      mediumTotal: mm.medium_placement_total,
      badTotal: mm.bad_placement_total,
      localSocket: "cpePlacementDataSocket",
      localSocketLine: "placementLineChartSocket",
      chartId: "cpeChart",
      chartTitleId: "cpeTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "cpeTitle",
    chartTitle: "cpe",
    chartInfo: "cpeInfo",
    graphId: "cpeChart",
  };

  // COVERAGE

  const coverageDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_coverage,
      mediumData: mm.medium_coverage,
      badData: mm.bad_coverage,
      goodTotal: mm.good_coverage_total,
      mediumTotal: mm.medium_coverage_total,
      badTotal: mm.bad_coverage_total,
      localSocket: "coverageDataSocket",
      localSocketLine: "coverageLineChartSocket",
      chartId: "coverageChart",
      chartTitleId: "coverageTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "coverageTitle",
    chartTitle: "coverage",
    chartInfo: "coverageInfo",
    graphId: "coverageChart",
  };

  // MODEM HEALTH

  const healthDataSocketDispatch = {
    goodData: mm.good_health,
    mediumData: mm.medium_health,
    badData: mm.bad_health,
    goodTotal: mm.good_health_total,
    mediumTotal: mm.medium_health_total,
    badTotal: mm.bad_health_total,
    localSocket: "healthDataSocket",
    localSocketLine: "modemHealthLineChartSocket",
    chartId: "modemhealthChart",
    chartTitleId: "modemhealthTitle",
    currentDate: currentDate,
    today: today,
    currentTime: currentTime,
  };

  // FIDELTY

  const fidelityDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_fidelity,
      mediumData: mm.medium_fidelity,
      badData: mm.bad_fidelity,
      goodTotal: mm.good_fidelity_total,
      mediumTotal: mm.medium_fidelity_total,
      badTotal: mm.bad_fidelity_total,
      localSocket: "fidelityDataSocket",
      localSocketLine: "fidelityLineChartSocket",
      chartId: "fidelityChart",
      chartTitleId: "fidelityTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "fidelityTitle",
    chartTitle: "NEIGHBORINTERFERENCE",
    chartInfo: "fidelityInfo",
    graphId: "fidelityChart",
  };

  // Avg Ping Time

  const avgPingTimeDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_avg_ping_time,
      mediumData: mm.medium_avg_ping_time,
      badData: mm.bad_avg_ping_time,
      goodTotal: mm.good_avg_ping_time_total,
      mediumTotal: mm.medium_avg_ping_time_total,
      badTotal: mm.bad_avg_ping_time_total,
      localSocket: "avgPingTimeDataSocket",
      localSocketLine: "avgPingTimeLineChartSocket",
      chartId: "avgPingTimeChart",
      chartTitleId: "avgPingTimeTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "avgPingTimeTitle",
    chartTitle: "avgPingTimeChart",
    chartInfo: "avgPingTimeChartInfo",
    graphId: "avgPingTimeChart",
  };

  // Packet Loss

  const packetLossDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_packet_loss,
      mediumData: mm.medium_packet_loss,
      badData: mm.bad_packet_loss,
      goodTotal: mm.good_packet_loss_total,
      mediumTotal: mm.medium_packet_loss_total,
      badTotal: mm.bad_packet_loss_total,
      localSocket: "packetLossDataSocket",
      localSocketLine: "packetLossLineChartSocket",
      chartId: "packetLossChart",
      chartTitleId: "packetLossTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "packetLossTitle",
    chartTitle: "packetLossChart",
    chartInfo: "packetLossChartInfo",
    graphId: "packetLossChart",
  };

  // Jitter

  const jitterDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_jitter,
      mediumData: mm.medium_jitter,
      badData: mm.bad_jitter,
      goodTotal: mm.good_jitter_total,
      mediumTotal: mm.medium_jitter_total,
      badTotal: mm.bad_jitter_total,
      localSocket: "jitterDataSocket",
      localSocketLine: "jitterLineChartSocket",
      chartId: "jitterChart",
      chartTitleId: "jitterTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "jitterTitle",
    chartTitle: "jitterChart",
    chartInfo: "jitterChartInfo",
    graphId: "jitterChart",
  };

  // Gw Avg Ping Time

  const gwAvgPingTimeDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_gw_avg_ping_time,
      mediumData: mm.medium_gw_avg_ping_time,
      badData: mm.bad_gw_avg_ping_time,
      goodTotal: mm.good_gw_avg_ping_time_total,
      mediumTotal: mm.medium_gw_avg_ping_time_total,
      badTotal: mm.bad_gw_avg_ping_time_total,
      localSocket: "gwAvgPingTimeDataSocket",
      localSocketLine: "gwAvgPingTimeLineChartSocket",
      chartId: "gwAvgPingTimeChart",
      chartTitleId: "gwAvgPingTimeTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "gwAvgPingTimeTitle",
    chartTitle: "gwAvgPingTimeChart",
    chartInfo: "gwAvgPingTimeChartInfo",
    graphId: "gwAvgPingTimeChart",
  };

  // Gw Packet Loss

  const gwPacketLossDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_gw_packet_loss,
      mediumData: mm.medium_gw_packet_loss,
      badData: mm.bad_gw_packet_loss,
      goodTotal: mm.good_gw_packet_loss_total,
      mediumTotal: mm.medium_gw_packet_loss_total,
      badTotal: mm.bad_gw_packet_loss_total,
      localSocket: "gwPacketLossDataSocket",
      localSocketLine: "gwPacketLossLineChartSocket",
      chartId: "gwPacketLossChart",
      chartTitleId: "gwPacketLossChart",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "gwPacketLossTitle",
    chartTitle: "gwPacketLossChart",
    chartInfo: "gwPacketLossChartInfo",
    graphId: "gwPacketLossChart",
  };

  // Gw Jitter

  const gwJitterDispatch = {
    data: ChartAdapters.chartWebSocketAdapter(null, {
      goodData: mm.good_gw_jitter,
      mediumData: mm.medium_gw_jitter,
      badData: mm.bad_gw_jitter,
      goodTotal: mm.good_gw_jitter_total,
      mediumTotal: mm.medium_gw_jitter_total,
      badTotal: mm.bad_gw_jitter_total,
      localSocket: "gwJitterDataSocket",
      localSocketLine: "gwJitterLineChartSocket",
      chartId: "gwJitterChart",
      chartTitleId: "gwJitterTitle",
      currentDate: currentDate,
      today: today,
      currentTime: currentTime,
    }),
    chartTitleId: "gwJitterTitle",
    chartTitle: "gwJitterChart",
    chartInfo: "gwJitterChartInfo",
    graphId: "gwJitterChart",
  };

  return {
    measurements,
    CxScoreDataProgress,
    WifiHealthDataSocketProgress,

    healthDataSocketDispatch,

    totalCostSavedCalculation,
    totalSavedOpt,
    totalSavedCall,
    totalSavedCostSum,

    aggregated: [
      speedDispatch,
      streamingQualityDispatch,
      streamingLatencyDispatch,
    ],
    wifiConnection: [
      wifiSpeedDispatch,
      linkSpeedDispatch,
      latencyDispatch,
      gwAvgPingTimeDispatch,
      gwPacketLossDispatch,
      gwJitterDispatch,
    ],
    wifiTopology: [coverageDispatch, fidelityDispatch, cpePlacementDispatch],
    ping: [avgPingTimeDispatch, packetLossDispatch, jitterDispatch],
    optCom: [optimizationRequiredDispatch, complainDispatch],
  };
};

const responseSocketHandler = {
  handleSocketMessage,
};

export default responseSocketHandler;
