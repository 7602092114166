import { BehaviorSubject } from "rxjs";
import initialStates from "../InitialStates/details.InitialStates";

class Interactions {
  interactionsSubject;
  constructor() {
    this.interactionsSubject = new BehaviorSubject(initialStates.interactions);
  }

  get get() {
    return this.interactionsSubject.asObservable();
  }

  setShowHeatMapDetails(showHeatmapDetails) {
    this.interactionsSubject.next((interactions) => {
      return {
        ...interactions,
        showHeatmapDetails,
      };
    });
  }

  setDetailsCategoryId(detailsCategoryId) {
    this.interactionsSubject.next((interactions) => {
      return interactions === undefined
        ? {
            showHeatmapDetails: false,
            detailsCategoryId: detailsCategoryId,
          }
        : {
            ...interactions,
            detailsCategoryId: detailsCategoryId,
          };
    });
  }

  //reset router heat map Subject objects state
  reset() {
    this.interactionsSubject.next(initialStates.interactionsSubject);
  }
}

const InteractionsService = (function () {
  var instance;

  function createInstance() {
    var classObj = new Interactions();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default InteractionsService;
