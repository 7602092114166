import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Tabs from "../../Components/HelperComponents/Tabs/Tabs";
import SunburstChart from "../../Components/SunburstComponents/Sunburst-Chart";
import Traceroute from "../../Components/WiredNetworkComponents/Traceroute";
import DashboardKPI from "../../Components/DashboardKPI/DashboardKPI";
import PopProblemsContainer from "../../Components/PopProblems/PopProblemsContainer";

const NetworkAnalytics = (props) => {
  // const { t } = props;
  const [activeTab, setActiveTab] = useState("aggregatedCustomerView");
  return (
    <Tabs
      tabs={["aggregatedCustomerView", "networkAnalytics", "wiredNetwork","popProblems"]}
      defaultTabID={activeTab}
      activeTabCallback={setActiveTab}
      content={[<DashboardKPI kpi={"aggregated"} timeMenu={true}/>, <SunburstChart />, <Traceroute />, <PopProblemsContainer/>]}
    />
  );
};

export default withTranslation()(NetworkAnalytics);
