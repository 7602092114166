//libraries
import React, { useState } from "react";
import { withTranslation } from "react-i18next";

//Components
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from '@material-ui/core/Typography';

//helpers
import UseObservable from "../../../Helpers/UseObservable";

//Observable services
import { ModemsService } from "../../../Classes";
import initialStates from "../../../Classes/InitialStates/details.InitialStates";

//Helpers

//Styles
import { makeStyles } from "@material-ui/core/styles";

const stylebutton = {
  background: "var(--details-color)",
  height: "100%",
  width: "100%",
  color: "white",
  fontWeight: "bold",
  marginTop: "10px",
  padding: "5px",
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // maxWidth: '100%',
    minWidth: "120px",
    backgroundColor: "#182142",
  },
  tabs: {
    backgroundColor: "#182142",
    color: "#e2e2e2",
    minWidth: "120px",
    minHeight: "80px",
    maxHeight:"100px",
    textAlign: "center",
  },
  tab: {
    backgroundColor: "#182142",
    color: "#e2e2e2",
    minHeight: "80px",
    maxHeight:"100px",
  },

  emptyTab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#182142",
    color: "#e2e2e2",
    minWidth: "120px",
    minHeight: "80px",
    maxHeight:"100px",
    opacity: "0.5",
  }
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const ModemTabs = (props) => {
  const { t } = props;
  const classes = useStyles();

  //Observable subject
  var ModemSubject = ModemsService.getInstance();

  //Local States
  const [modemTabIndex, setModemTabIndex] = useState(0);

  //Observable States
  const { data: modem} = UseObservable({
    observable: ModemSubject.get,
    initialState: initialStates.modem,
  });

  const handleChangeModem = (event, index) => {
    //sets selected modems tab index
    setModemTabIndex(index);
    let selectedModem = {
      modemMac: modem.modemList[index].value,
      label: modem.modemList[index].label,
    };
    //sets selected modem mac of modem observable
    ModemSubject.setSelectedModem(selectedModem);

  };

  React.useEffect(() => {
    setModemTabIndex(0)
    /*eslint-disable */
  }, [modem.modemList]);

  return (
    <div
      className="chart1-em col-lg-12 col-xl-12 col-12 col-sm-12 col-md-12"
      style={stylebutton}
    >
      <div className={classes.root}>

        <div className="titleOfList"> {t("Routers")} </div>

        <AppBar position="static" color="transparent">
          {modem?.modemList?.length === 0 ? 
          <Typography className={classes.emptyTab}> {t("nodata")} </Typography>
           :
          <Tabs
            value={modemTabIndex}
            className={classes.tabs}
            onChange={handleChangeModem}
            indicatorColor="secondary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {modem?.modemList?.map((item, i) => (
              <Tab
                className={classes.tab}
                style={{
                     background: "linear-gradient(to bottom, #182142, #1e2953)",
                    }}
                label={
                  <span id={"ap-"+ item.value}>
                    {item.label}
                    <br></br>
                    {item.value}
                  </span>
                }
                {...a11yProps(i)}
                key={i}
              />
            ))}
          </Tabs>}
        </AppBar>
      </div>
    </div>
  );
};

export default withTranslation()(ModemTabs);
