import React, { useState } from "react";
import { withTranslation } from "react-i18next";

import Popup from "reactjs-popup";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

import InputField from "../InputField.jsx";

//Observable Services
import { CompanyService } from "../../../Classes/index";
import UseObservable from "../../../Helpers/UseObservable";
import initialStates from "../../../Classes/InitialStates/account.initialStates.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  checked: {},
}));

const CompanySettings = ({ t, photoCallBack }) => {
  const [giataId, setgiataId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [selectedImage, setSelectedImage] = useState();

  // Observables
  const { data: company } = UseObservable({
    observable: CompanyService.getInstance().get,
    initialState: initialStates.company,
  });

  const classes = useStyles();

  const handleImageChange = (event) => {
    setSelectedImage(event.target.value);
  };

  const profileLogoOptionList = [
    "https://speedtest-cdn.ambeent.ai/logo/apartment.jpeg",
    "https://speedtest-cdn.ambeent.ai/logo/building.jpeg",
    "https://speedtest-cdn.ambeent.ai/logo/hospital.jpeg",
    "https://speedtest-cdn.ambeent.ai/logo/house.jpeg",
    "https://speedtest-cdn.ambeent.ai/logo/office.jpeg",
    "https://speedtest-cdn.ambeent.ai/logo/school.jpeg",
    "https://speedtest-cdn.ambeent.ai/logo/shop.jpeg",
  ];

  const profileLogoOption = profileLogoOptionList.map((logoLink, i) => (
    <div key={`logo-${i}`}>
      <Radio
        checked={selectedImage === logoLink}
        onChange={handleImageChange}
        value={logoLink}
      />
      <img src={logoLink} alt="" class="chooselogo" />
    </div>
  ));

  function saveImage() {
    CompanyService.getInstance().updateCompany({ photo: selectedImage });
  }

  function handleCompanySubmit(event) {
    var regex = /^[A-Za-z0-9 ]*$/;
    var isValidName = regex.test(companyName);
    var isValidGiata = regex.test(giataId);
    if (!isValidName || !isValidGiata) {
      event.preventDefault();
      document.getElementById("updatedCompany").innerHTML =
        t("specialCharError");
      return;
    }

    CompanyService.getInstance().updateCompany({ companyName, giataId });

    event.preventDefault();
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Paper className="accountPaper">
        <div className="accountCompanySettings">
          <label className="accountSettingsHeader">
            {t("companySettings")}
          </label>
          <form
            id="accountForm"
            onSubmit={handleCompanySubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <div className="accountCompanySettingsContentArea">
              <label
                htmlFor="icon-button-file"
                id="changePP"
                //onClick={clickIMG}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <Popup
                    trigger={
                      <div style={{ color: "white", fontSize: "16px" }}>
                        <img src={company?.image} alt="" id="imgShowLogo"></img>
                        {t("changePhoto")}
                      </div>
                    }
                    modal
                    position="left center"
                  >
                    {(close) => (
                      <div>
                        <div className="popupLogoWrapper">
                          {profileLogoOption}
                        </div>
                        <div className="popupSaveButton" onClick={close}>
                          <Button
                            onClick={saveImage}
                            id="saveImage"
                            variant="contained"
                            color="secondary"
                          >
                            {t("save")}
                          </Button>
                        </div>
                      </div>
                    )}
                  </Popup>
                </IconButton>

                <div id="changePhotoUpt"></div>
              </label>
              <InputField
                className="accountCompanySettingsCompanyName"
                label={t("companyName")}
                setter={setCompanyName}
              />
              <InputField
                className="accountCompanySettingsGiataID"
                label="GIATA ID"
                setter={setgiataId}
              />
              <div id="updatedCompany">{t(company?.isUpdated)}</div>
            </div>

            <div id="mrg" className="settingsSaveButon">
              <Button
                type="submit"
                id="savePSettings"
                variant="contained"
                color="secondary"
              >
                {t("save")}
              </Button>
            </div>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default withTranslation()(CompanySettings);
