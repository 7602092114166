function setBlur(chartName, chartTitle) {
  var checkValues = setInterval(() => {
    var chartElement = document.getElementById(chartName);
    // console.log("chart name: " + chartElement);
    if (typeof chartElement !== "undefined" && chartElement !== null) {
      chartElement.style.filter = "blur(15px)";
      var noDataDiv =
        document.getElementById(chartName).parentElement.parentElement
          .children[0];
      noDataDiv.innerHTML = "NO DATA";
      // console.log("no data");

      // ///// ACTIVE USER - ACCESS POINT DETECTED - MEASUREMENTS  To Change Color
      // var activeUserColor = document.getElementById("activeUserColor");
      // activeUserColor.style.color = "rgb(230, 82, 109)";

      // var modemColor = document.getElementById("modemColor");
      // modemColor.style.color = "rgb(230, 82, 109)";

      // var changeMeasurementsColor = document.getElementById(
      //   "changeMeasurementsColor"
      // );
      // changeMeasurementsColor.style.color = "rgb(230, 82, 109)";

      // if (
      //   document.getElementsByClassName("noData")[11].innerHTML.length === 7
      // ) {
      //   document.getElementsByClassName("changeChartButton")[11].click();
      //   document.getElementsByClassName("changeChartButton")[11].click();
      //   document.getElementsByClassName(
      //     "changeChartButton"
      //   )[11].disabled = true;
      // }
    }
    var chartTitleElement = document.getElementById(chartTitle);
    if (typeof chartTitleElement != "undefined" && chartTitleElement !== null) {
      chartTitleElement.style.color = "rgb(230, 82, 109)";

      clearInterval(checkValues);
    }
  }, 1000);
}

function removeBlur(chartName, chartTitle) {
  var checkValues = setInterval(() => {
    var chartElement = document.getElementById(chartName);
    if (typeof chartElement != "undefined" && chartElement !== null) {
      chartElement.style.filter = "blur(0px)";
      var noDataDiv =
        document.getElementById(chartName).parentElement.parentElement
          .children[0];
      noDataDiv.innerHTML = "";

      //   ///// ACTIVE USER - ACCESS POINT DETECTED - MEASUREMENTS  To Change Color
      //   var activeUserColor = document.getElementById("activeUserColor");
      //   activeUserColor.style.color = "white";

      //   var modemColor = document.getElementById("modemColor");
      //   modemColor.style.color = "white";

      //   var changeMeasurementsColor = document.getElementById(
      //     "changeMeasurementsColor"
      //   );
      //   changeMeasurementsColor.style.color = "white";
    }
    var chartTitleElement = document.getElementById(chartTitle);
    if (typeof chartTitleElement != "undefined" && chartTitleElement !== null) {
      chartTitleElement.style.color = "white";

      clearInterval(checkValues);
    }
  }, 1000);
}

function chartWebSocketAdapter(state, action) {
  var badData = action.badData;
  var mediumData = action.mediumData;
  var goodData = action.goodData;
  var goodTotal = action.goodTotal;
  var mediumTotal = action.mediumTotal;
  var badTotal = action.badTotal;
  var localSocket = action.localSocket;
  var localSocketLine = action.localSocketLine;
  var chartLabels = action.chartLabels;
  let splitTime = "";
  var today = action.today;
  var currentDate = action.currentDate;
  var currentTime = action.currentTime;

  var newDataLineChartSocket = [];
  var newDataLineChartSocketList = [];
  var labelGood = "Good",
    labelMedium = "Medium",
    labelBad = "Bad";

  if (chartLabels) {
    labelGood = chartLabels[0];
    labelMedium = chartLabels[1];
    labelBad = chartLabels[2];
  }
  var dataSocket = [];

  if (goodTotal === 0 && mediumTotal === 0 && badTotal === 0) {
    dataSocket = [];
  } else {
    dataSocket = [
      ["Status", "rate"],
      [labelGood, goodTotal],
      [labelMedium, mediumTotal],
      [labelBad, badTotal],
    ];
  }
  if (goodData.length === 0) {
    newDataLineChartSocket = [];
  } else {
  

    for (const [key, value] of Object.entries(goodData)) {
      today.setHours(key, 0, 0);
      splitTime = today.toString().slice(16, 24);
      newDataLineChartSocketList.push({
        hour: splitTime,
        good: value,
        bad: 0,
        medium: 0,
      });
    }
    for (const [key, value] of Object.entries(mediumData)) {
      today.setHours(key, 0, 0);
      let splitTime = "";
      splitTime = today.toString().slice(16, 24);
      newDataLineChartSocketList.forEach((element) => {
        if (element.hour === splitTime) element.medium = value;
      });
    }
    for (const [key, value] of Object.entries(badData)) {
      today.setHours(key, 0, 0);
      let splitTime = "";
      splitTime = today.toString().slice(16, 24);
      newDataLineChartSocketList.forEach((element) => {
        if (element.hour === splitTime) element.bad = value;
      });
    }

    newDataLineChartSocket.push([
      "Day",
      labelGood,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelMedium,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelBad,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      { role: "annotation", type: "string" },
    ]);

    var predictionLineControl = 0;

    newDataLineChartSocketList.forEach((element) => {
      if (currentTime + element.hour + "+00:00" <= currentDate) {
        newDataLineChartSocket.push([
          element.hour.substring(0, 5),
          element.good,
          "color: #58eda5; stroke-color: #58eda5; stroke-width: 5;",
          true,
          element.good + "",
          element.medium,
          "color: #56caf5; stroke-color: #56caf5; stroke-width: 5;",
          true,
          element.medium + "",
          element.bad,
          "color: #f55f81; stroke-color: #f55f81; stroke-width: 5;",
          true,
          element.bad + "",
          null,
        ]);
      } else {
        newDataLineChartSocket.push([
          element.hour.substring(0, 5),
          element.good,
          "stroke-width:0;color:#2BB673;fill-opacity:0.5;",
          false,
          element.good + " (Predicted)",
          element.medium,
          "stroke-width:0;color:#007fad;fill-opacity:0.5;",
          false,
          element.medium + " (Predicted)",
          element.bad,
          "stroke-width:0;color:#d91e48;fill-opacity:0.5;",
          false,
          element.bad + " (Predicted)",
          predictionLineControl === 0 ? "Predicted" : null,
        ]);
        predictionLineControl = 1;
      }
    });

    localStorage.setItem(
      localSocket,
      JSON.stringify([
        ["Status", "rate"],
        [labelGood, goodTotal],
        [labelMedium, mediumTotal],
        [labelBad, badTotal],
      ])
    );
    localStorage.setItem(
      localSocketLine,
      JSON.stringify(newDataLineChartSocket)
    );
  }
  return { barData: newDataLineChartSocket, pieData: dataSocket };
}

function chartWebSocketAdapter4Parametre(state, action) {
  var noData = action.noData;
  var badData = action.badData;
  var mediumData = action.mediumData;
  var goodData = action.goodData;
  var goodTotal = action.goodTotal;
  var mediumTotal = action.mediumTotal;
  var badTotal = action.badTotal;
  var noTotal = action.noTotal;
  var localSocket = action.localSocket;
  var chartLabels = action.chartLabels;
  let splitTime = "";
  var today = action.today;
  var currentDate = action.currentDate;
  var currentTime = action.currentTime;
  var newDataLineChartSocket = [];
  var newDataLineChartSocketList = [];
  var labelGood = "Critic",
    labelMedium = "Normal",
    labelBad = "Low",
    labelNo = "No";
  var localSocketLine = action.localSocketLine;

  if (chartLabels) {
    labelGood = chartLabels[0];
    labelMedium = chartLabels[1];
    labelBad = chartLabels[2];
    labelNo = chartLabels[3];
  }

  var dataSocket = [];

  if (goodTotal === 0 && mediumTotal === 0 && badTotal === 0 && noTotal === 0) {
    dataSocket = [];
  } else {
    dataSocket = [
      ["Status", "rate"],
      [labelGood, goodTotal],
      [labelMedium, mediumTotal],
      [labelBad, badTotal],
      [labelNo, noTotal],
    ];
  }

  if (goodData.length === 0) {
    newDataLineChartSocket = [];
  } else {
    newDataLineChartSocket.push([
      "Day",
      labelGood,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelMedium,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelBad,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelNo,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      { role: "annotation", type: "string" },
    ]);

    for (const [key, value] of Object.entries(goodData)) {
      today.setHours(key, 0, 0);
      splitTime = today.toString().slice(16, 24);
      newDataLineChartSocketList.push({
        hour: splitTime,
        good: value,
        bad: 0,
        medium: 0,
        no: 0,
      });
    }
    for (const [key, value] of Object.entries(mediumData)) {
      today.setHours(key, 0, 0);
      let splitTime = "";
      splitTime = today.toString().slice(16, 24);
      newDataLineChartSocketList.forEach((element) => {
        if (element.hour === splitTime) element.medium = value;
      });
    }
    for (const [key, value] of Object.entries(badData)) {
      today.setHours(key, 0, 0);
      let splitTime = "";
      splitTime = today.toString().slice(16, 24);
      newDataLineChartSocketList.forEach((element) => {
        if (element.hour === splitTime) element.bad = value;
      });
    }

    for (const [key, value] of Object.entries(noData)) {
      today.setHours(key, 0, 0);
      let splitTime = "";
      splitTime = today.toString().slice(16, 24);
      newDataLineChartSocketList.forEach((element) => {
        if (element.hour === splitTime) element.no = value;
      });
    }
    var predictionLineControl = 0;
    newDataLineChartSocketList.forEach((element) => {
      if (currentTime + element.hour + "+00:00" <= currentDate) {
        newDataLineChartSocket.push([
          element.hour.substring(0, 5),
          element.good,
          "color: #f55f81; stroke-color: #f55f81; stroke-width: 5;",
          true,
          element.good + "",
          element.medium,
          "color: orange; stroke-color: orange; stroke-width: 5;",
          true,
          element.medium + "",
          element.bad,
          "color: #56caf5; stroke-color: #56caf5; stroke-width: 5;",
          true,
          element.bad + "",
          element.no,
          "color: #58eda5; stroke-color: #58eda5; stroke-width: 5;",
          true,
          element.no + "",
          null,
        ]);
      } else {
        newDataLineChartSocket.push([
          element.hour.substring(0, 5),
          element.good,
          "stroke-width:0;color: #f55f81; fill-opacity: 0.5",
          false,
          element.good + " (Predicted)",
          element.medium,
          "stroke-width:0;color: orange; fill-opacity: 0.5",
          false,
          element.medium + " (Predicted)",
          element.bad,
          "stroke-width:0;color:#56caf5; fill-opacity: 0.5",
          false,
          element.bad + " (Predicted)",
          element.no,
          "stroke-width:0;color: #58eda5;fill-opacity: 0.5",
          false,
          element.no + " (Predicted)",
          predictionLineControl === 0 ? "Predicted" : null,
        ]);
        predictionLineControl = 1;
      }
    });

    localStorage.setItem(
      localSocket,
      JSON.stringify([
        ["Status", "rate"],
        [labelGood, goodTotal],
        [labelMedium, mediumTotal],
        [labelBad, badTotal],
        [labelNo, noTotal],
      ])
    );
    localStorage.setItem(
      localSocketLine,
      JSON.stringify(newDataLineChartSocket)
    );
  }
  return { barData: newDataLineChartSocket, pieData: dataSocket };
}

function chartEndPointAdapter(state, action) {
  var countList = action.countList;
  var chartLabels = action.chartLabels;
  let splitTime = "";
  var timeCategory = action.timeCategory;
  var currentDate = action.currentDate;
  var total = action.total;

  var newData = [];
  var newDataLineChart = [];

  var labelGood = "Good",
    labelMedium = "Medium",
    labelBad = "Bad";

  if (chartLabels) {
    labelGood = chartLabels[0];
    labelMedium = chartLabels[1];
    labelBad = chartLabels[2];
  }
  if (total.good !== 0 || total.medium !== 0 || total.bad !== 0) {
    newData = [
      ["Status", "rate"],
      [labelGood, total.good],
      [labelMedium, total.medium],
      [labelBad, total.bad],
    ];
  }
  if (countList.length !== 0) {
    newDataLineChart.push([
      "Day",
      labelGood,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelMedium,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelBad,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
    ]);
    countList.forEach((element) => {
      if (timeCategory === "D") {
        splitTime = element.date.slice(11, 19);
      }
      if (element.date <= currentDate) {
        newDataLineChart.push([
          element.date.slice(0, 10) + " " + splitTime,
          element.goodCount === undefined
            ? element.shortCount === undefined
              ? element.lightCount === undefined
                ? element.highCount === undefined
                  ? null
                  : element.highCount
                : element.lightCount
              : element.shortCount
            : element.goodCount,
          "color: #58eda5; stroke-color: #58eda5; stroke-width: 5;",
          true,
          element.goodCount === undefined
            ? element.shortCount === undefined
              ? element.lightCount === undefined
                ? element.highCount === undefined
                  ? null
                  : element.highCount
                : element.lightCount
              : element.shortCount
            : element.goodCount + "",
          element.mediumCount === undefined
            ? element.normalCount === undefined
              ? null
              : element.normalCount
            : element.mediumCount,
          "color: #56caf5; stroke-color: #56caf5; stroke-width: 5;",
          true,
          element.mediumCount === undefined
            ? element.normalCount === undefined
              ? null
              : element.normalCount
            : element.mediumCount + "",
          element.badCount === undefined
            ? element.longCount === undefined
              ? element.heavyCount === undefined
                ? element.lowCount === undefined
                  ? null
                  : element.lowCount
                : element.heavyCount
              : element.longCount
            : element.badCount,
          "color: #f55f81; stroke-color: #f55f81; stroke-width: 5;",
          true,
          element.badCount === undefined
            ? element.longCount === undefined
              ? element.heavyCount === undefined
                ? element.lowCount === undefined
                  ? null
                  : element.lowCount
                : element.heavyCount
              : element.longCount
            : element.badCount + "",
        ]);
      } else {
        newDataLineChart.push([
          element.date.slice(0, 10) + " " + splitTime,
          element.goodCount === undefined
            ? element.shortCount === undefined
              ? element.lightCount === undefined
                ? element.highCount === undefined
                  ? null
                  : element.highCount
                : element.lightCount
              : element.shortCount
            : element.goodCount,
          "#2BB673",
          false,
          element.goodCount === undefined
            ? element.shortCount === undefined
              ? element.lightCount === undefined
                ? element.highCount === undefined
                  ? null
                  : element.highCount
                : element.lightCount
              : element.shortCount
            : element.goodCount + " (Predicted)",
          element.mediumCount === undefined
            ? element.normalCount === undefined
              ? null
              : element.normalCount
            : element.mediumCount,
          "#007fad",
          false,
          element.mediumCount === undefined
            ? element.normalCount === undefined
              ? null
              : element.normalCount
            : element.mediumCount + " (Predicted)",
          element.badCount === undefined
            ? element.longCount === undefined
              ? element.heavyCount === undefined
                ? element.lowCount === undefined
                  ? null
                  : element.lowCount
                : element.heavyCount
              : element.longCount
            : element.badCount,
          "#d91e48",
          false,
          element.badCount === undefined
            ? element.longCount === undefined
              ? element.heavyCount === undefined
                ? element.lowCount === undefined
                  ? null
                  : element.lowCount
                : element.heavyCount
              : element.longCount
            : element.badCount + " (Predicted)",
        ]);
      }
    });
  }
  return { barData: newDataLineChart, pieData: newData };
}

function chartEndPointAdapter4Parametre(state, action) {
  var newData = [];
  var newDataLineChart = [];

  var countList = action.countList;
  var chartLabels = action.chartLabels;
  let splitTime = "";
  var timeCategory = action.timeCategory;
  var currentDate = action.currentDate;
  var total = action.total;

  var labelGood = "Critic",
    labelMedium = "Normal",
    labelBad = "Low",
    labelNo = "No";

  if (chartLabels) {
    labelGood = chartLabels[0];
    labelMedium = chartLabels[1];
    labelBad = chartLabels[2];
    labelNo = chartLabels[3];
  }

  if (
    total.good !== 0 ||
    total.medium !== 0 ||
    total.bad !== 0 ||
    total.no !== 0
  ) {
    newData = [
      ["Status", "rate"],
      [labelGood, total.good],
      [labelMedium, total.medium],
      [labelBad, total.bad],
      [labelNo, total.no],
    ];
  }

  if (countList !== 0) {
    newDataLineChart.push([
      "Day",
      labelGood,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelMedium,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelBad,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
      labelNo,
      { role: "style" },
      { role: "certainty" },
      { role: "tooltip" },
    ]);
    countList.forEach((element) => {
      if (timeCategory === "D") {
        splitTime = element.date.slice(11, 19);
      }
      if (element.date <= currentDate) {
        newDataLineChart.push([
          element.date.slice(0, 10) + " " + splitTime,
          element.highCount,
          "color: #f55f81; stroke-color: #f55f81; stroke-width: 5;",
          true,
          element.highCount + "",
          element.mediumCount,
          "color: orange; stroke-color: orange; stroke-width: 5;",
          true,
          element.mediumCount + "",
          element.lowCount,
          "color: #56caf5; stroke-color: #56caf5; stroke-width: 5;",
          true,
          element.lowCount + "",
          element.noCount,
          "color: #58eda5; stroke-color: #58eda5; stroke-width: 5;",
          true,
          element.noCount + "",
        ]);
      } else {
        newDataLineChart.push([
          element.date.slice(0, 10) + " " + splitTime,
          element.highCount,
          "#f55f81",
          false,
          element.highCount + " (Predicted)",
          element.mediumCount,
          "orange",
          false,
          element.mediumCount + " (Predicted)",
          element.lowCount,
          "#56caf5",
          false,
          element.lowCount + " (Predicted)",
          element.noCount,
          "#58eda5",
          false,
          element.noCount + " (Predicted)",
        ]);
      }
    });
  }
  return { barData: newDataLineChart, pieData: newData };
}

const ChartAdapters = {
  chartEndPointAdapter,
  chartEndPointAdapter4Parametre,
  chartWebSocketAdapter,
  chartWebSocketAdapter4Parametre,
  setBlur,
  removeBlur,
};

export default ChartAdapters;
