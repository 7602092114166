import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";


class NeighboorModems {
  neighboorModemsSubject;
  constructor() {
    this.neighboorModemsSubject = new BehaviorSubject(initialStates.neighboorModem);
  }

  //get neighboor modems object
  get get() {
    return this.neighboorModemsSubject.asObservable();
  }

  //fetch data from /statistics/neighborModems?id=${ambDeviceId}&mac=${modemMac} endpoint
  fetchData(ambDeviceId, modemMac,ios) {
    apis.getNeighborModems(ambDeviceId, modemMac,ios).then((response) => {
      this.neighboorModemsSubject.next(response);
    });
  }

  //reset neighboor modems Subject objects state
  reset() {
    this.neighboorModemsSubject.next(initialStates.neighboorModem);
  }
}

const NeighboorModemsService = (function () {
  var instance;

  function createInstance() {
    var classObj = new NeighboorModems();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default NeighboorModemsService;
