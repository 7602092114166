import axios from "axios";

//Here u can create an axios instance
//The axios instance is going to used by api calls
const BASE_URL_KAFKA = "https://backend.ambeent.ai";
const BASE_URL_AMBEENT_CORE = "https://backend.ambeent.ai/ambeentcore/api/v1";

const ambeentCoreAxios = axios.create({
  baseURL: BASE_URL_AMBEENT_CORE,
});

const kafkaAxiosClient = axios.create({
  baseURL: BASE_URL_KAFKA,
});

const serviceProviders = {
  ambeentCoreAxios,
  kafkaAxiosClient,
};
export default serviceProviders;
