import React from "react";
import { withTranslation } from "react-i18next";

const DropdownFilter = ({
  t,
  filterData,
  filterDataArray,
  setSelectedFilter,
  selectedValue,
  style,
}) => {
  const [selected, setSelected] = React.useState(selectedValue);

  React.useEffect(() => {
    setSelected(selectedValue);
  }, [selectedValue]);

  return (
    <select
      style={style}
      className="dropdownFilter"
      value={selected}
      onChange={(event) => {
        setSelected(event.target.value);
        setSelectedFilter(event.target.value);
      }}
    >
      {/* if data is consist from only array */}
      {filterData
        ? filterData.map((type, i) => (
            <option key={i} value={type.value}>
              {type.text}
            </option>
          ))
        : filterDataArray.map((filter, i) => (
            <option key={i} value={filter}>
              {t(filter)}
            </option>
          ))}
    </select>
  );
};

export default withTranslation()(DropdownFilter);
