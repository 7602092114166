import React from "react";
import { Button, FormGroup, FormLabel } from "react-bootstrap";
import { withTranslation } from "react-i18next";
//const axios = require("axios");
var clickCount = 0;
var rotateInterval = "";
var deg = 1;

function logoRotate() {
  if (clickCount === 0) {
    var loginLogo = document.getElementById("loginLogo");
    rotateInterval = setInterval(function () {
      loginLogo.style.transform = "rotate(" + deg + "deg)";
      deg = (deg + 1) % 360;
    }, 10);
    clickCount++;
  } else {
    clearInterval(rotateInterval);
    clickCount = 0;
  }
}

function Invite(props) {
  // const [firstName, setFirstName] = React.useState("");
  // const [lastName, setLastName] = React.useState("");
  // const [email, setEmail] = React.useState("");
  // const [password, setPassword] = React.useState("");
  // const [confirmPassword, setConfirmPassword] = React.useState("");

  const { t, i18n } = props;
  // console.log(props.match.params.company);
  // console.log(props.match.params.role);

  function handleSubmit(event) {
      // console.log(firstName);
      // console.log(lastName);
      // console.log(email);
      // console.log(password);
      // console.log(confirmPassword);

  }

  function changeLng(event) {
    i18n.changeLanguage(event.target.value);
  }

  return (
    <div>
      <nav id="header" className="navbar is-primary is-fixed-top">
        <div className="logo-wrapper-col" id="loginLogoWrapper">
          <img
            id="loginLogo"
            onClick={logoRotate}
            src="https://cdn.zeplin.io/5be95f75181a333cfe712602/assets/DFD27C74-9C00-4155-B5DD-193C65005EF5.png"
            alt=""
          ></img>
          <a id="ambeent" href="http://ambeent.ai/" style={{ color: "white" }}>
            {t("Ambeent")}
          </a>
        </div>
        <select id="lngSelectM" onChange={changeLng}>
          <option className="lngM" value="en">
            EN
          </option>
          <option className="lngM" value="tr">
            TR
          </option>
        </select>
      </nav>
      <div>
        <div className="col" style={{ textAlign: "center" }}>
          <label id="rpassword">{t("newUser")}</label>
        </div>
        <div id="changes">
          <form onSubmit={handleSubmit}>
            <FormGroup controlId="email">
              <FormLabel className="emailLabel">{t("name")}</FormLabel>
              <input
                className="password"
                // onChange={(e) => setFirstName(e.target.value)}
                placeholder="John"
              />
            </FormGroup>
            <FormGroup controlId="password">
              <FormLabel className="passwordLabel">{t("surname")}</FormLabel>
              <input
                className="password"
                // onChange={(e) => setLastName(e.target.value)}
                placeholder="Doe"
              />
            </FormGroup>
            <FormGroup controlId="password">
              <FormLabel className="passwordLabel">{t("eemail")}</FormLabel>
              <input
                className="email"
                type="email"
                // onChange={(e) => setEmail(e.target.value)}
                placeholder="example@example.com"
              />
            </FormGroup>
            <FormGroup controlId="password">
              <FormLabel className="passwordLabel">
                {t("newpassword")}
              </FormLabel>
              <input
                className="password"
                type="password"
                // onChange={(e) => setPassword(e.target.value)}
                placeholder={t("password")}
              />
            </FormGroup>
            <FormGroup controlId="password">
              <FormLabel className="passwordLabel">{t("confirmpw")}</FormLabel>
              <input
                className="password"
                type="password"
                // onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t("password")}
              />
            </FormGroup>
            <Button id="submitButton" type="submit">
              {t("sbmt")}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Invite);
