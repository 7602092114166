import React, { useState } from "react";
import ReactGA from "react-ga";
import SliderWidget from "./SliderWidget";
import { Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import ScrollToTop from "react-scroll-up";

import apis from "../../Services/Apis/SettingsService/settings.apis";
import { CompanyService } from "../../Classes";
import KeycloakService from "../../Keycloak/KeycloakService";

const textStyle = {
  position: "relative",
  width: "80%",
  marginLeft: "10%",
  marginRight: "10%",
};

const Settings = (props) => {
  const { t } = props;
  const [timeZone, setTimeZone] = React.useState("");
  const [tresholdsData, setTresholdsData] = useState([]);



  React.useEffect(() => {
    ReactGA.initialize("UA-173492242-1");
    ReactGA.pageview(localStorage.email + " is on /settings page");

    //fetchs tresholds
    apis.getDashboardTresholds().then((response) => {
      console.log(response);
      if (response !== undefined) {
        setTresholdsData(response);
      }
    });
    //fetchs time zone
    // apis.getTimeZone().then((response) => setTimeZone(response));
    setTimeZone(KeycloakService.getTimeZone());
  }, []);

  const handleTimeZone = (event) => {
    setTimeZone(event.target.value);
  };

  function setCustomerTimeZone(e) {
    if (timeZone === "") {
      alert(t("timeZoneError"));
      e.preventDefault();
      return;
    }
    CompanyService.getInstance().updateCompany({ timeZone });
  }

  function onClick() {
   apis
      .updateDashboardTresholds({
        scoreBounds : [document.getElementById("Scorebound0").value, document.getElementById("Scorebound1").value, document.getElementById("Scorebound2").value].map(i=>Number(i)),
        streamLatencyBounds : [document.getElementById("StreamLatencybound0").value, document.getElementById("StreamLatencybound1").value, document.getElementById("StreamLatencybound2").value ].map(i=>Number(i)),
        fidelityBounds : [document.getElementById("fidelitybound0").value, document.getElementById("fidelitybound1").value, document.getElementById("fidelitybound2").value ].map(i=>Number(i)),
        linkSpeedBounds : [document.getElementById("LinkSpeedbound0").value, document.getElementById("LinkSpeedbound1").value, document.getElementById("LinkSpeedbound2").value ].map(i=>Number(i)),
        wifiSpeedBounds : [document.getElementById("WifiSpeedbound0").value, document.getElementById("WifiSpeedbound1").value, document.getElementById("WifiSpeedbound2").value ].map(i=>Number(i)),
        connectedDevicesBounds : [document.getElementById("ConnectedDevicesbound0").value, document.getElementById("ConnectedDevicesbound1").value, document.getElementById("ConnectedDevicesbound2").value ].map(i=>Number(i)),
        latencyBounds : [document.getElementById("latencybound0").value, document.getElementById("latencybound1").value, document.getElementById("latencybound2").value ].map(i=>Number(i)),
        pingBounds : [document.getElementById("Pingbound0").value, document.getElementById("Pingbound1").value, document.getElementById("Pingbound2").value ].map(i=>Number(i)),
        rssiBounds : [document.getElementById("CPEbound0").value, document.getElementById("CPEbound1").value, document.getElementById("CPEbound2").value ].map(i=>Number(i)),
        speedtestBounds : [document.getElementById("Speedtestbound0").value, document.getElementById("Speedtestbound1").value, document.getElementById("Speedtestbound2").value ].map(i=>Number(i)),
        avgPingTimeBounds : [document.getElementById("AvgPingTimebound0").value, document.getElementById("AvgPingTimebound1").value, document.getElementById("AvgPingTimebound2").value ].map(i=>Number(i)),
        gwAvgPingTimeBounds : [document.getElementById("GwAvgPingTimebound0").value, document.getElementById("GwAvgPingTimebound1").value, document.getElementById("GwAvgPingTimebound2").value ].map(i=>Number(i)),
        jitterBounds : [document.getElementById("Jitterbound0").value, document.getElementById("Jitterbound1").value, document.getElementById("Jitterbound2").value ].map(i=>Number(i)),
        gwJitterBounds : [document.getElementById("GwJitterbound0").value, document.getElementById("GwJitterbound1").value, document.getElementById("GwJitterbound2").value ].map(i=>Number(i)),
        packetLossBounds : [document.getElementById("packetLossbound0").value, document.getElementById("packetLossbound1").value, document.getElementById("packetLossbound2").value ].map(i=>Number(i)),
        gwPacketLossBounds : [document.getElementById("GwPacketLossbound0").value, document.getElementById("GwPacketLossbound1").value, document.getElementById("GwPacketLossbound2").value ].map(i=>Number(i)),
        neighborCountBounds : [document.getElementById("neighborCountbound0").value, document.getElementById("neighborCountbound1").value, document.getElementById("neighborCountbound2").value ].map(i=>Number(i)),
        qualityBounds : [document.getElementById("streamingbound0").value, document.getElementById("streamingbound1").value, document.getElementById("streamingbound2").value ].map(i=>Number(i)),
        scoreMinMax : [document.getElementById("Scoremin").value, document.getElementById("Scoremax").value].map(i=>Number(i)),
        streamLatencyMinMax : [document.getElementById("StreamLatencymin").value, document.getElementById("StreamLatencymax").value].map(i=>Number(i)),
        fidelityMinMax : [document.getElementById("fidelitymin").value, document.getElementById("fidelitymax").value].map(i=>Number(i)),
        linkSpeedMinMax : [document.getElementById("LinkSpeedmin").value, document.getElementById("LinkSpeedmax").value].map(i=>Number(i)),
        wifiSpeedMinMax : [document.getElementById("WifiSpeedmin").value, document.getElementById("WifiSpeedmax").value].map(i=>Number(i)),
        connectedDevicesMinMax : [document.getElementById("ConnectedDevicesmin").value, document.getElementById("ConnectedDevicesmax").value].map(i=>Number(i)),
        latencyMinMax : [document.getElementById("latencymin").value, document.getElementById("latencymax").value].map(i=>Number(i)),
        pingMinMax : [document.getElementById("Pingmin").value, document.getElementById("Pingmax").value].map(i=>Number(i)),
        rssiMinMax : [document.getElementById("CPEmin").value, document.getElementById("CPEmax").value].map(i=>Number(i)),
        speedtestMinMax : [document.getElementById("Speedtestmin").value, document.getElementById("Speedtestmax").value].map(i=>Number(i)),
        avgPingTimeMinMax : [document.getElementById("AvgPingTimemin").value, document.getElementById("AvgPingTimemax").value].map(i=>Number(i)),
        gwAvgPingTimeMinMax : [document.getElementById("GwAvgPingTimemin").value, document.getElementById("GwAvgPingTimemax").value].map(i=>Number(i)),
        jitterMinMax : [document.getElementById("Jittermin").value, document.getElementById("Jittermax").value].map(i=>Number(i)),
        gwJitterMinMax : [document.getElementById("GwJittermin").value, document.getElementById("GwJittermax").value].map(i=>Number(i)),
        packetLossMinMax : [document.getElementById("packetLossmin").value, document.getElementById("packetLossmax").value].map(i=>Number(i)),
        gwPacketLossMinMax : [document.getElementById("GwPacketLossmin").value, document.getElementById("GwPacketLossmax").value].map(i=>Number(i)),
        neighborCountMinMax : [document.getElementById("neighborCountmin").value, document.getElementById("neighborCountmax").value].map(i=>Number(i)),
        qualityMinMax : [document.getElementById("streamingmin").value, document.getElementById("streamingmax").value].map(i=>Number(i)),
     
      })
      .then((response) => {
        // console.log(response);
        if (response.data === "Ok") {
          alert(t("saveExplanation"));
          ReactGA.event({
            category:
              localStorage.email.split("@")[0] +
              "[at]" +
              localStorage.email.split("@")[1],
            action:
              "changed treshold settings for " +
              document.getElementById("companyName").innerHTML,
          });
        } else {
          if (response.status === 200) {
            alert(t("noChange"));
          } else {
            // console.log(response.status);
            alert(t("saveError"));
          }
        }
      });
  }
  function onclickReset() {
    apis.resetThresholds().then(() => alert(t("resetThreshold")))
  }



  return (
    <div>
      <h5 id="timeZoneTitle">{t("timeZoneChange")}</h5>
      <h6>{t("timeZoneChange")}</h6>
      <div>
        <select id="timeZoneSelect" onChange={handleTimeZone} value={timeZone}>
          <option disabled value={""}></option>
          <option value={"-10:00"}>GMT-10:00 - {t("gmtminus10")}</option>
          <option value={"-09:00"}>GMT-09:00 - {t("gmtminus9")}</option>
          <option value={"-08:00"}>GMT-08:00 - {t("gmtminus8")}</option>
          <option value={"-07:00"}>GMT-07:00 - {t("gmtminus7")}</option>
          <option value={"-06:00"}>GMT-06:00 - {t("gmtminus6")}</option>
          <option value={"-05:00"}>GMT-05:00 - {t("gmtminus5")}</option>
          <option value={"-04:00"}>GMT-04:00 - {t("gmtminus4")}</option>
          <option value={"-03:00"}>GMT-03:00 - {t("gmtminus3")}</option>
          <option value={"-02:00"}>GMT-02:00 - {t("gmtminus2")}</option>
          <option value={"-01:00"}>GMT-01:00 - {t("gmtminus1")}</option>
          <option value={"+00:00"}>GMT+00:00 - {t("gmt0")}</option>
          <option value={"+01:00"}>GMT+01:00 - {t("gmtplus1")}</option>
          <option value={"+02:00"}>GMT+02:00 - {t("gmtplus2")}</option>
          <option value={"+03:00"}>GMT+03:00 - {t("gmtplus3")}</option>
          <option value={"+04:00"}>GMT+04:00 - {t("gmtplus4")}</option>
          <option value={"+05:00"}>GMT+05:00 - {t("gmtplus5")}</option>
          <option value={"+06:00"}>GMT+06:00 - {t("gmtplus6")}</option>
          <option value={"+07:00"}>GMT+07:00 - {t("gmtplus7")}</option>
          <option value={"+08:00"}>GMT+08:00 - {t("gmtplus8")}</option>
          <option value={"+09:00"}>GMT+09:00 - {t("gmtplus9")}</option>
          <option value={"+10:00"}>GMT+10:00 - {t("gmtplus10")}</option>
          <option value={"+11:00"}>GMT+11:00 - {t("gmtplus11")}</option>
          <option value={"+12:00"}>GMT+12:00 - {t("gmtplus12")}</option>
          <option value={"+13:00"}>GMT+13:00 - {t("gmtplus13")}</option>
        </select>
        <button id="setTimeZone" onClick={setCustomerTimeZone}>
          Set
        </button>
      </div>
      <div id="thresholdTitle">
        <b>
          <h5>{t("threshold")}</h5>
        </b>
        <h6 style={textStyle}>{t("thresholdExplanation")}</h6>
      </div>

      {tresholdsData.map((data, i) => (
        <SliderWidget
          key={i + "-slider"}
          name={data.name}
          domain={data.domain}
          values={data.values}
          revertRailBackground={data.revertRailBackground}
         // onChangeCallBack={onChange}
        />
      ))}
      <div>
        <Button id="saveSettings" onClick={onClick}>
          {t("save")}
        </Button>
      </div>
      <Button id="saveSettings" onClick={onclickReset}>
        {t("defaultValues")}
      </Button>
      <div style={{ height: "20px" }}></div>
      <ScrollToTop showUnder={160}>
        <img src="https://img.icons8.com/nolan/64/circled-up-2.png" alt="" />
      </ScrollToTop>
    </div>
  );
};

export default withTranslation()(Settings);
