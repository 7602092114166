import React from "react";
import { withTranslation } from "react-i18next";
import { animateScroll as scroll } from "react-scroll";
import { Chart } from "react-google-charts";

import chartOptions from "../devices.chartOptions";

const ModelChart = ({t,selectedRouter,modelData}) => {
  if (selectedRouter === "") {
    return <div id="routerBrand"></div>;
  }
  return (
    <div id="barChartDiv">
      <div className="chart-card mb-4">
        <div className="chart-title1" id="routerBrand">
          {selectedRouter + t("models")}
        </div>
        <Chart
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={modelData}
          options={chartOptions.modelOptions}
          _isMounted={scroll.scrollToBottom()}
        />
      </div>
    </div>
  );
};

export default withTranslation()(ModelChart);
