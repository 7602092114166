

//initial state of channel noise list
const channelNoiseList = {
  bestChannelHistory: [],
  fidelityPercentage: [],
  bestChannelListforRecommendation: {
    bestChannels: [],
    curChannel: "",
    interferencePercentage: "",
  },
  check5G: false,
  bestChannelBox: "",
  isFetched: false,
};

//initial state of chrome extension
const chromeExtension = {
  chromeExtSpeedResults: [],
  chromeExtResponseResults: [],
  isFetched: false,
};

//initial state of customer
const customer = {
  customerId: "",
  isFetched: false,
};

//initial state of customer trace
const customerTrace = {
  traceRouteTableData: [
    { url: "", created_at: "", hops_list: [], problem: -5, problemed_hop: -1 },
  ],
  traceRouteProblem: {
    problem: "",
    problemed_hop: "",
  },
  isFetched: false,
};

//initial state of device
const device = {
  deviceList: [{ value: "", label: "", type: "" }],
  deviceTypes: [],
  selectedDevice: {
    ambDeviceId: "",
    deviceType: "",
    label: "",
  },
  isFetched: false,
};

//initial state of modem
const modem = {
  modemList: [{ modemMac: "", label: "" }],
  selectedModem: { modemMac: "", label: "" },
  isFetched: true,
};

//initial state of neighboor modems
const neighboorModem = {
  neighborModemHistory: [],
  neighboorModemBox: "",
  isFetched: false,
};

//initial state of ping
const ping = {
  connectedDeviceHistory: [],
  connectedDeviceCount: "",
  isFetched: false,
};

//initial state of recommendation
const recommendation = {
  recommendation: "",
  img: "",
  isFetched: false,
};



//initial state of router infos
const routerInfo = {
  model: "",
  brand: "",
  channel: "",
  score: "",
  rssi: "",
  pingStates: {
    jitter: "",
    delay: "",
    packetLoss: "",
  },
  gatewayStates: {
    jitter: "",
    delay: "",
    packetLoss: "",
  },
  frequency: "",
  latitude:404,
  longitude:404,
  isFetched: false,
};

//initial state of speed test
const speedTest = {
  speedTestBox: "",
  speedTestFlow: "",
  speedtestHistory: [],
  isFetched: false,
};

//initial state of stas info
const stasInfo = {
  wifiSpeedHistory: [],
  linkSpeedHistory: [],
  location: { latitude: 0, longitude: 0 },
  linkSpeedBox: "",
  wifiSpeedBox: "",
  informationElements: "",
  isFetched: false,
};

//initial state of streamings
const streamings = {
  streamingBox: "",
  streamingHistory: [],
  isFetched: false,
};

//initial state of traces sankey
const tracesSankey = {
  target: [],
  value: [],
  source: [],
  label: [],
  color: [],
  isFetched: false,
};

//initial state of details category
const detailsCategory = {
  id: "",
};

const interactions = {
  detailsCategoryId: "",
  showHeatmapDetails: false,
};

const allHeatMaps = {
  coverage: {
    value: "",
    color: "",
    percentage: "",
  },
  placement: {
    value: "",
    color: "",
    percentage: "",
  },
  daily: [],
  total: [],
  params: [],
  isFetched: false,
};

const initialStates = {
  channelNoiseList,
  chromeExtension,
  customer,
  customerTrace,
  device,
  modem,
  neighboorModem,
  ping,
  recommendation,
  routerInfo,
  speedTest,
  stasInfo,
  streamings,
  tracesSankey,
  detailsCategory,
  interactions,
  allHeatMaps,
};

export default initialStates;
