import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";

class Modems {
  modemSubject;
  constructor() {
    this.modemSubject = new BehaviorSubject(initialStates.modem);
  }

  get get() {
    return this.modemSubject.asObservable();
  }

  setSelectedModem(selectedModem) {
    // console.log(selectedModem)
    this.modemSubject.next((modem) => {
      return { ...modem, selectedModem: selectedModem };
    });
  }

  fetchData(ambDeviceId, companyId, customerId) {
    if(ambDeviceId){
      apis
        .getModemsFromCompanyID(ambDeviceId, companyId, customerId)
        .then((response) => {
          this.modemSubject.next(response);
        });
    }
  }

  reset() {
    this.modemSubject.next(initialStates.modem);
  }
}

const ModemsService = (function () {
  var instance;

  function createInstance() {
    var classObj = new Modems();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default ModemsService;
