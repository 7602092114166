import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/devices/devices.apis";
import initialStates from "../InitialStates/devices.initialStates";

class DeviceManufacturerDist {
    deviceManufacturerDistSubject;
  constructor() {
    this.deviceManufacturerDistSubject = new BehaviorSubject(
      initialStates.androidReleadeviceManufacturerDistse
    );
  }

  get get() {
    return this.deviceManufacturerDistSubject.asObservable();
  }

  fetchData(companyId) {
    apis.getDeviceManufacturerDistribution(companyId).then((response) => {
      this.deviceManufacturerDistSubject.next(response);
    });
  }

  reset() {
    this.deviceManufacturerDistSubject.next(initialStates.deviceManufacturerDist);
  }
}

const DeviceManufacturerDistService = (function () {
  var instance;

  function createInstance() {
    var classObj = new DeviceManufacturerDist();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default DeviceManufacturerDistService;
