import initialStates from "../../../Classes/InitialStates/details.InitialStates";
import Helpers from "../../../Helpers/Helpers";

// here u can create response handlers to prepare the response data for using in components
// return values of details api calls is going to store by Singleton observables
let intelSearchInterval;
const handleGetDevices = (response) => {
  // console.log(response);
  if (response.data) {
    if (response.data.length > 0) {
      let deviceList = [];
      let deviceTypes = [];
      if (response.data.length !== 0) {
        response.data.forEach((element) => {
          deviceList.push({
            value: element.ambDeviceId,
            label: element.brand,
            type: element.type,
          });
          if (!deviceTypes.includes(element.type)) {
            deviceTypes.push(element.type);
          }
        });
      }
      return {
        deviceList: deviceList,
        deviceTypes: deviceTypes,
        selectedDevice: {
          ambDeviceId: deviceList[0].value,
          deviceType: deviceTypes[0],
          label: deviceList[0].label,
        },
        isFetched: true,
      };
    }
    return { ...initialStates.device, isFetched: true, deviceList: [] };
  } else {
    return { ...initialStates.device, isFetched: true, deviceList: [] };
  }
};
const handleGetModems = (response) => {
  if (response.data) {
    if (response.data.length > 0) {
      let modemList = [];
      response.data.forEach((element) => {
        // some of the modem data is duplicate(ssids coming with quote mark) this check is to prevent that
        if (modemList.some((el) => el.value === element.modemMac) === false) {
          modemList.push({ value: element.modemMac, label: element.ssid });
        }
      });
      return {
        modemList: modemList,
        selectedModem: {
          modemMac: modemList.length > 0 ? modemList[0].value : "",
          label: modemList.length > 0 ? modemList[0].ssid : "",
        },
        isFetched: true,
      };
    }
    return {...initialStates.modem, isFetched : true, modemList: []};
  }

  return {...initialStates.modem, isFetched : true, modemList: []};
};

const handleGetRouterInfos = (response) => {
  if (response.data) {
    return {
      model: response.data.model,
      brand: response.data.brand,
      channel: response.data.channel,
      score: response.data.score,
      rssi: response.data.rssi,
      connectedDeviceCount:response.data.connectedDevice,
      speed: Helpers.getReadableFileSizeInt(response.data.speed),
      linkSpeed: response.data.linkSpeed,
      neighborCount: response.data.neighborCount,
      streamingQuality: response.data.quality,
      pingStates: {
        jitter: response.data.jitter,
        delay: response.data.delay,
        packetLoss: response.data.packetLossRate,
      },
      gatewayStates: {
        jitter: response.data.gwJitter,
        delay: response.data.gwDelay,
        packetLoss: response.data.gwPacketLossRate,
      },
      latitude:response.data.latitude,
      longitude:response.data.longitude,
      frequency: response.data.channel > 13 ? "5 GHz" : "2.4 GHz",
      isFetched: true,
    };
  }
  return response;
};

const handleGetTracesSankey = (response) => {
  if (response.data) {
    return {
      target: response.data.Target === null ? [] : response.data.Target,
      value: response.data.Value === null ? [] : response.data.Value,
      source: response.data.Source === null ? [] : response.data.Source,
      label: response.data.Label === null ? [] : response.data.Label,
      color: response.data.LinkColor === null ? [] : response.data.LinkColor,
      isFetched: true,
    };
  }
  return initialStates.tracesSankey;
};

const handleGetCustomerTrace = (response) => {
  if (response.data) {
    if (response.data.length > 0) {
      return {
        traceRouteTableData: response.data,
        traceRouteProblem: {
          problem: response.data[0].problem,
          problemed_hop: response.data[0].problemed_hop,
        },
        isFetched: true,
      };
    }
    return initialStates.customerTrace;
  }
  return initialStates.customerTrace;
};

const handleGetStasInfo = (modemMac) => {
  return (response) => {
  if (response.data) {
    var infoJson;
    var firstrow = true;
    var linkSpeedBox = "";
    var wifiSpeedBox = "";
    var informationElements = "";
    var intelSearchIntervalBool = false;
    var location = {
      latitude: 0,
      longitude: 0,
    };
    let wifiSpeedHistory = [];
    let linkSpeedHistory = [];
    if(intelSearchInterval){
      clearInterval(intelSearchInterval)
      intelSearchInterval = undefined
    }
    response.data.forEach((element) => {
      if (firstrow === true) {
        if (element.linkSpeed > 0) linkSpeedBox = element.linkSpeed;
        if (element.informationElements !== ""){
          intelSearchIntervalBool = true;
          try {
            infoJson = JSON.parse(element.informationElements)
            if(infoJson.securityScore === null || infoJson.securityScore === undefined)
            infoJson.securityScore = -1
            for (let i = 0; i < infoJson.ScanResults.length; i++) {
              if(infoJson.ScanResults[i].channelUtilizationRate_AP === null || infoJson.ScanResults[i].channelUtilizationRate_AP === undefined)
                infoJson.ScanResults[i].channelUtilizationRate_AP = "N/A"
              if(infoJson.ScanResults[i].maxSupportedRate === null || infoJson.ScanResults[i].maxSupportedRate === undefined)
              infoJson.ScanResults[i].maxSupportedRate = "N/A"
            }
            informationElements = infoJson;
            console.log("intelSearchInterval : " + intelSearchInterval)
            if (intelSearchInterval === undefined){
              intelSearchInterval = setInterval(() => {
                console.log("intelSearchIntervalBool : " + intelSearchIntervalBool)
                console.log("intelSearchInterval : " + intelSearchInterval)
                if(document.getElementById('buttonSearch') && intelSearchIntervalBool === true){
                  console.log("in intelSearchInterval interval")
                  document.getElementById("ap-" + modemMac).click();
                }
                else{
                  clearInterval(intelSearchInterval)
                  intelSearchInterval = undefined
                  console.log("out intelSearchInterval interval")
                }
              }, 60000);
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          clearInterval(intelSearchInterval)
          intelSearchIntervalBool = false;
          intelSearchInterval = undefined
          console.log("informationElements empty")
        }
        if (parseFloat(element.wifiSpeed).toFixed(2) > 0)
          wifiSpeedBox = parseFloat(element.wifiSpeed).toFixed(2);
        location = {
          latitude: element.latitude,
          longitude: element.longitude,
        };
        firstrow = false;
      }

      if (parseFloat(element.wifiSpeed).toFixed(2) > 0) {
        wifiSpeedHistory.push({
          Date: element.createdAt,
          Value: parseFloat(element.wifiSpeed).toFixed(2) + " Mbps",
          background: Helpers.historyBackgroundControl(element.background),
        });
      }
      if (element.linkSpeed > 0) {
        linkSpeedHistory.push({
          Date: element.createdAt,
          Value: element.linkSpeed,
          background: Helpers.historyBackgroundControl(element.background),
        });
      }
    });
    return {
      wifiSpeedHistory: wifiSpeedHistory,
      linkSpeedHistory: linkSpeedHistory,
      location: location,
      linkSpeedBox: linkSpeedBox,
      wifiSpeedBox: wifiSpeedBox,
      informationElements: informationElements,
      isFetched: true,
    };
  }
  return response;
};
}

const handleGetStreamings = (response) => {
  if (response.data) {
    var firstrow = true;
    var streamingBox = "";
    var streamingHistory = [];

    response.data.forEach((element) => {
      if (firstrow === true) {
        streamingBox = element.Quality;
        firstrow = false;
      }

      streamingHistory.push({
        Date: element.createdAt,
        Latency: element.Latency,
        Quality: element.Quality,
        background: Helpers.historyBackgroundControl(element.Background),
      });
    });
    return {
      streamingBox: streamingBox,
      streamingHistory: streamingHistory,
      isFetched: true,
    };
  }
  return initialStates.streamings;
};

const handleGetChannelNoiseList = (response) => {
  if (response.data) {
    var firstrow = true;
    var fidelityPercentage = [];
    let bestchannelHistory = [];
    let bestChannelListforRecommendation = {
      bestChannels: [],
      curChannel: "",
      interferencePercentage: "",
    };
    var check5G = false;
    var bestchannelBox = "";
    var curChannel = 0;

    response.data.forEach((element, index) => {
      var channel = [];
      var min = Helpers.arrayMin(element.noiseList);
      channel = Helpers.getAllIndexes(element.noiseList, min);
      // console.log("channel: " + channel + channel.length);
      curChannel = element.currentChannel - 1;

      //Start prioritiized best channels
      var prioritzedBestChannelList = [];
      var bestChannelList = [];
      const prioritizedChannels = (element) =>
        element === 0 || element === 5 || element === 10;
      if (channel.some(prioritizedChannels) === true) {
        prioritzedBestChannelList = channel.filter(prioritizedChannels);
      }
      bestChannelList =
        channel.some(prioritizedChannels) === true
          ? prioritzedBestChannelList
          : channel;
      //end prioritiized best channels

      let interferencePercentage =
        element.interferencePercentageList !== null
          ? element.interferencePercentageList[curChannel]
          : 0;

      if (index === 0) {
        bestChannelListforRecommendation.bestChannels = bestChannelList.map(
          (bestChannelList) => ++bestChannelList
        );
        bestChannelListforRecommendation.curChannel = ++curChannel;
        bestChannelListforRecommendation.interferencePercentage =
          interferencePercentage;
      }

      fidelityPercentage.push({
        id: element.id,
        Date: element.createdAt,
        interferencePercentage: interferencePercentage,
        fidelity: element.fidelity,
        background: Helpers.historyBackgroundControl(element.background),
      });

      if (firstrow === true) {
        if (element.currentChannel > 13) {
          check5G = true;
        } else {
          check5G = false;
        }
        let lengthChannel = 0;
        bestchannelBox = bestChannelList.map(function (val) {
          lengthChannel++;
          if (val < 14) {
            if (bestChannelList.length === lengthChannel) {
              return ++val + " ";
            } else {
              return ++val + ", ";
            }
          } else {
            --val;
            return ++val + "(5GHZ)";
          }
        });
        firstrow = false;
      }

      let subLengthChannel = 0;
      bestchannelHistory.push({
        background: Helpers.historyBackgroundControl(element.background),
        Date: element.createdAt,
        "Current Channel": element.currentChannel,
        "Recommended Channel": bestChannelList.map(function (val) {
          subLengthChannel++;
          if (val < 14) {
            if (bestChannelList.length === subLengthChannel) {
              return ++val + " ";
            } else {
              return ++val + ", ";
            }
          } else {
            --val;
            return ++val + "(5GHZ)";
          }
        }),
      });
    });
    // console.log(bestchannelBox);
    return {
      bestChannelHistory: bestchannelHistory,
      fidelityPercentage: fidelityPercentage,
      check5G: check5G,
      bestChannelBox: bestchannelBox,
      bestChannelListforRecommendation: bestChannelListforRecommendation,
      isFetched: true,
    };
  }
  return initialStates.channelNoiseList;
};



const handleAllHeatMaps = (response) => {
  if (response.data !== undefined) {
    if (response.data?.length > 0) {
      const addParams = {
        placement: {
          value: "",
          color: "",
          percentage: "",
        },
        coverage: {
          value: "",
          color: "",
          percentage: "",
        },
      };
      var daily = response.data.map((param) => {
        return {
          param: param.parameter,
          data: param.heatMaps.daily.map((day) => {
            return {
              first: day[0].first_circle,
              second: day[1].second_circle,
              third: day[2].third_circle,
              fourth: day[3].fourth_circle,
            };
          }),
        };
      });

      var total = response.data.map((param) => {
        return {
          parameter: param.parameter,
          first: param.heatMaps.total[0].first_circle,
          second: param.heatMaps.total[1].second_circle,
          third: param.heatMaps.total[2].third_circle,
          fourth: param.heatMaps.total[3].fourth_circle,
        };
      });

      var params = response.data.map((param) => {
        if (param.parameter === "Rssi") {
          addParams.placement = param.heatMaps.total_add_prm.applacement;
          addParams.coverage = param.heatMaps.total_add_prm.coverage;
        }
        return param.parameter;
      });

      // console.log(daily);
      // console.log(total);
      // console.log(params);

      return {
        placement: addParams.placement,
        coverage: addParams.coverage,
        daily,
        total,
        params,
        isFetched: true,
      };
    } else {
      return initialStates.allHeatMaps;
    }
  } else {
    return initialStates.allHeatMaps;
  }
};

const handleGetPing = (response) => {
  if (response.data) {
    var connectedDeviceHistory = [];
    var count = response.data.Count;
    if (response.data.PingMeasurements !== null) {
      response.data.PingMeasurements.forEach((element) => {
        connectedDeviceHistory.push({
          IP: Helpers.historyBackgroundControl(element.background) + element.ip,
          MacAddress: element.macAddress,
          PingTime: parseFloat(element.PingTime / 1000000).toFixed(2),
          background: Helpers.historyBackgroundControl(element.background),
        });
      });
    }

    return {
      connectedDeviceHistory: connectedDeviceHistory,
      connectedDeviceCount: count,
      isFetched: true,
    };
  }
  return initialStates.ping;
};

const handleGetSpeedTest = (response) => {
  if (response.data) {
    //initial state
    var speedTest = {
      speedTestBox: "",
      speedTestFlow: "",
      speedtestHistory: [],
      isFetched: true,
    };

    var firstrow = true;
    response.data.forEach((element) => {
      if (firstrow === true) {
        speedTest.speedTestBox =
          Helpers.getReadableFileSizeInt(element.speed)[0] <= 0.1
            ? "incomplete"
            : Helpers.getReadableFileSizeString(element.speed);

        speedTest.speedTestFlow = Helpers.getReadableFileSizeInt(element.speed);

        firstrow = false;
      }

      speedTest.speedtestHistory.push({
        Date: element.createdAt,
        Value:
          Helpers.getReadableFileSizeInt(element.speed)[0] <= 0.1
            ? "incomplete"
            : Helpers.getReadableFileSizeString(element.speed),
        upload:
          Helpers.getReadableFileSizeInt(element.uploadSpeed)[0] <= 0.1
            ? "incomplete"
            : Helpers.getReadableFileSizeString(element.uploadSpeed),
        ping: element.ping,
        background: Helpers.historyBackgroundControl(element.background),
      });
    });
    return speedTest;
  }
  return initialStates.speedTest;
};

const handleGetNeighborModems = (response) => {
  if (response.data) {
    var neighborModemHistory = [];
    response.data.forEach((element) => {
      neighborModemHistory.push({
        SSID: element.ssid,
        "Mac Address": element.macAddress.toUpperCase(),
        "Brand-Model": element.brandModel,
        RSSI: element.rssi,
      });
    });
    return {
      neighborModemHistory: neighborModemHistory,
      neighboorModemBox: response.data.length,
      isFetched: true,
    };
  }
  return initialStates.neighboorModem;
};

const handleGetChromeExtension = (response) => {
  var chromeExtSpeedResults = [];
  response.data.SpeedTestResult !== null
    ? response.data.SpeedTestResult.forEach((element) => {
        chromeExtSpeedResults.push({
          CustomerId: element.CustomerId,
          TestDate: element.TestDate,
          Ping: element.Ping,
          DownloadSpeed: element.DownloadSpeed,
          UploadSpeed: element.UploadSpeed,
          Jitter: element.Jitter,
          CombineTime: element.CombineTime,
        });
      })
    : console.log();

  var chromeExtResponseResults = [];
  response.data.ResponseTestResult !== null
    ? response.data.ResponseTestResult.forEach((element) => {
        chromeExtResponseResults.push({
          CustomerId: element.CustomerId,
          TestDate: element.TestDate,
          TabUrl: element.TabUrl,
          VideoResponseTimes: element.VideoResponseTimes,
          ImgResponseTimes: element.ImgResponseTimes,
        });
      })
    : console.log();

  return {
    chromeExtSpeedResults: chromeExtSpeedResults,
    chromeExtResponseResults: chromeExtResponseResults,
    isFetched: true,
  };
};

const handleGetRecommendation = (response) => {
  // console.log(response.data.message);
  return {
    recommendation: response.data.message,
    img: response.data.img,
    isFetched: true,
  };
};

const handleError = (error) => {
  console.log(error);
  if (error.data) {
    console.log(error.data);
    //return error.data;
  }
  //return error;
};
const handleErrorHeatMap = (error) => {
  console.log(error);
};

const responseHandler = {
  handleError,
  handleGetDevices,
  handleGetModems,
  handleGetRouterInfos,
  handleGetTracesSankey,
  handleGetCustomerTrace,
  handleGetStasInfo,
  handleGetStreamings,
  handleGetChannelNoiseList,
  handleAllHeatMaps,
  handleGetPing,
  handleGetSpeedTest,
  handleGetNeighborModems,
  handleGetChromeExtension,
  handleGetRecommendation,
  handleErrorHeatMap,
};

export default responseHandler;
