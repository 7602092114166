import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";

class TracesSankey {
  tracesSankeySubject;
  constructor() {
    this.tracesSankeySubject = new BehaviorSubject(initialStates.tracesSankey);
  }

  //get traces sankey object
  get get() {
    return this.tracesSankeySubject.asObservable();
  }

  //fetch data from /detailstrace/traces?id=${ambDeviceId}&mac=${modemMac} endpoint
  fetchData(ambDeviceId, modemMac,count) {
    apis.getTracesSankey(ambDeviceId, modemMac,count).then((response) => {
      this.tracesSankeySubject.next(response);
    });
  }

  //reset traces sankey objects state
  reset() {
    this.tracesSankeySubject.next(initialStates.tracesSankey);
  }
}

const TracesSankeyService = (function () {
  var instance;

  function createInstance() {
    var classObj = new TracesSankey();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default TracesSankeyService;
