import React from "react";
import ForceGraph2D from "react-force-graph-2d";
import ForceGraph3D from "react-force-graph-3d";
import DatePicker from "react-datepicker";
import ReactGA from "react-ga";
import { withTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import Helpers from "../../Helpers/Helpers";

const { useState, useCallback, useRef } = React;
const axios = require("axios");
//var msgpack = require("msgpack-lite");

const NODE_R = 8;

function Traceroute(props) {
  const { t } = props;
  const [chartType, setChartType] = useState("2D");
  const [tracedata, setTraceData] = useState();
  const [displayData, setDisplayData] = useState("all");
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );
  const [company, setCompany] = useState("");
  const [hoverNode] = useState(null);
  const fgRef = useRef();
  const CompanyId = Helpers.getLowerCaseCompanyID();

  React.useEffect(() => {
    ReactGA.initialize("UA-173492242-1");
    ReactGA.pageview(localStorage.email + " is on /traceroute page");
    const fg = fgRef.current;

    fg.d3Force("charge").strength(-100);

    axios
      .get("https://backend.ambeent.ai/company/"+ localStorage.getItem("defaultCompanyId"),)
      .then((response) => {
        setCompany(response.data.name);
      })
      .catch(function (error) {
        console.log(error);
      });

    axios
      .post(
        "https://backend.ambeent.ai/wirednetwork/",
        {
          companyId: CompanyId.toUpperCase(),
          m: displayData,
          year: selectedDate.getFullYear(),
          month: selectedDate.getMonth() + 1,
          day: selectedDate.getDate(),
        },
        { responseType: "Array" }
      )
      .then(function (response) {
        console.log(response.data)
        // console.log("resp: " + JSON.stringify(response))
        // var realData = msgpack.decode(Buffer.from(response.data));
        var realData = response.data;
        if (realData.links.length !== 0) {
          setTraceData(realData);
          document.getElementById("noDataTrace").innerHTML = "";
        } else {
          document.getElementById("noDataTrace").innerHTML = t("noData");
          setTraceData();
        }
      })
      .catch(function (error) {
        document.getElementById("noDataTrace").innerHTML = t("noData");
        console.log(error);
        setTraceData();
      });

    /*eslint-disable */
  }, [selectedDate, displayData]);

  const updateHighlight = () => {
    setHighlightNodes(highlightNodes);
    setHighlightLinks(highlightLinks);
  };

  const handleLinkHover = (link) => {
    highlightNodes.clear();
    highlightLinks.clear();

    if (link) {
      highlightLinks.add(link);
      highlightNodes.add(link.source);
      highlightNodes.add(link.target);
    }

    updateHighlight();
  };

  const paintRing = useCallback(
    (node, ctx) => {
      // add ring just for highlighted nodes
      ctx.beginPath();
      ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
      ctx.fillStyle = node === hoverNode ? "red" : "orange";
      ctx.fill();
    },
    [hoverNode]
  );

  return (
    <div id="traceContainer">
      <div className="col" id="traceMenu">
        <div id="traceButtonDiv">
          <button
            className="col"
            id="changeTrace"
            onClick={() => {
              setChartType(chartType === "2D" ? "3D" : "2D");
            }}
          >
            Click to {chartType === "2D" ? "3D" : "2D"}
          </button>
        </div>
        <div className="col" id="changeTrace">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
          />
        </div>
        <button
          className="col"
          id="changeTrace"
          onClick={() => {
            setDisplayData("all");
          }}
        >
          <div id="allText">{t("All")}</div>
        </button>
        <button
          className="col"
          id="changeTrace"
          onClick={() => {
            setDisplayData("good");
          }}
        >
          {/* <ColoredLine color="green" /> */}
          <div id="goodText">{t("good")}</div>
        </button>
        <button
          className="col"
          id="changeTrace"
          onClick={() => {
            setDisplayData("medium");
          }}
        >
          {/* <ColoredLine color="orange" /> */}
          <div id="mediumText">{t("medium")}</div>
        </button>
        <button
          className="col"
          id="changeTrace"
          onClick={() => {
            setDisplayData("bad");
          }}
        >
          {/* <ColoredLine color="red" /> */}
          <div id="badText">{t("bad")}</div>
        </button>
        <div>
          <div className="row" id="ispExp">
            <div className="circleWhite"></div>
            <div className="circleExp">{t("turkey")}</div>
          </div>
          {company === "TurkNet" ? (
            <div>
              <div className="row" id="ispExp">
                <div className="circleBlue"></div>
                <div className="circleExp">{t("turkTelekom")}</div>
              </div>
              <div className="row" id="ispExp">
                <div className="circleOrange"></div>
                <div className="circleExp">{t("turkNet")}</div>
              </div>
            </div>
          ) : (
            <div>
              {company === "Millenicom" ? (
                <div>
                  <div className="row" id="ispExp">
                    <div className="circleBlue"></div>
                    <div className="circleExp">{t("turkTelekom")}</div>
                  </div>
                  <div className="row" id="ispExp">
                    <div className="circleYellow"></div>
                    <div className="circleExp">{t("Millenicom")}</div>
                  </div>
                </div>
              ) : (
                <div>
                  {company === "Vodafone" ? (
                    <div>
                      <div className="row" id="ispExp">
                        <div className="circleBlue"></div>
                        <div className="circleExp">{t("turkTelekom")}</div>
                        <div className="row" id="ispExp">
                          <div className="circleRed"></div>
                          <div className="circleExp">{t("Vodafone")}</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="row" id="ispExp">
                        <div className="circleBlue"></div>
                        <div className="circleExp">{t("turkTelekom")}</div>
                      </div>
                      <div className="row" id="ispExp">
                        <div className="circleOrange"></div>
                        <div className="circleExp">{t("turkNet")}</div>
                      </div>
                      <div className="row" id="ispExp">
                        <div className="circleYellow"></div>
                        <div className="circleExp">{t("Millenicom")}</div>
                      </div>
                      <div className="row" id="ispExp">
                        <div className="circleRed"></div>
                        <div className="circleExp">{t("Vodafone")}</div>
                      </div>
                      <div className="row" id="ispExp">
                        <div className="circleGreen"></div>
                        <div className="circleExp">{t("Superonline")}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div id="noDataTrace"></div>
      {chartType === "2D" ? (
        <div style={{ display: "contents" }} className="row">
          <ForceGraph2D
            ref={fgRef}
            graphData={tracedata}
            linkDirectionalArrowLength={10}
            backgroundColor="#0e0e18"
            nodeId="id"
            nodeRelSize={8}
            linkDirectionalParticles={4}
            linkDirectionalParticleWidth={(link) =>
              highlightLinks.has(link) ? 4 : 0
            }
            nodeCanvasObjectMode={(node) =>
              highlightNodes.has(node) ? "before" : undefined
            }
            onLinkHover={handleLinkHover}
            nodeCanvasObject={paintRing}
            nodeLabel={(node) => {
              if (
                node.city === null ||
                node.district === null ||
                node.district === undefined ||
                node.city === undefined
              ) {
                return node.id;
              } else {
                return (
                  node.id +
                  "-Weight:" +
                  node.weight +
                  "-" +
                  node.city +
                  "-" +
                  node.district
                );
              }
            }}
          />
        </div>
      ) : (
        <div style={{ display: "contents" }} className="row">
          <ForceGraph3D
            ref={fgRef}
            graphData={tracedata}
            backgroundColor="#0e0e18"
            linkDirectionalArrowLength={10}
            nodeRelSize={8}
            nodeId="id"
            linkDirectionalParticles={4}
            linkDirectionalParticleWidth={(link) =>
              highlightLinks.has(link) ? 4 : 0
            }
            nodeCanvasObjectMode={(node) =>
              highlightNodes.has(node) ? "before" : undefined
            }
            onLinkHover={handleLinkHover}
            nodeCanvasObject={paintRing}
            nodeLabel={(node) => {
              if (node.city === null || node.district === null) {
                return node.id;
              } else {
                return (
                  node.id +
                  "-Weight:" +
                  node.weight +
                  "-" +
                  node.city +
                  "-" +
                  node.district
                );
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

export default withTranslation()(Traceroute);
