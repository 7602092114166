import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";

class ChannelNoiseList {
  channelNoiseListSubject;
  constructor() {
    this.channelNoiseListSubject = new BehaviorSubject(
      initialStates.channelNoiseList
    );
  }

  //get channel noise list object
  get get() {
    return this.channelNoiseListSubject.asObservable();
  }

  //fetch data from /statistics/channelNoiseList?id=${ambDeviceId}&mac=${modemMac} endpoint
  fetchData(ambDeviceId, modemMac) {
    apis
      .getChannelNoiseList(ambDeviceId, modemMac)
      .then((response) => {
        this.channelNoiseListSubject.next(response);
        return response;
      })
      
  }

  //reset channel noise list objects state
  reset() {
    this.channelNoiseListSubject.next(initialStates.channelNoiseList);
  }
}

const ChannelNoiseListService = (function () {
  var instance;

  function createInstance() {
    var classObj = new ChannelNoiseList();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default ChannelNoiseListService;
