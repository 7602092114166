import React from "react";
import ReactGA from 'react-ga';
import { Button, FormGroup, FormLabel } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const axios = require("axios");

var clickCount = 0;
var rotateInterval = "";
var deg = 1;

function logoRotate() {
  if (clickCount === 0) {
    var loginLogo = document.getElementById("loginLogo");
    rotateInterval = setInterval(function() {
      loginLogo.style.transform = "rotate(" + deg + "deg)";
      deg = (deg + 1) % 360;
    }, 10);
    clickCount++;
  } else {
    clearInterval(rotateInterval);
    clickCount = 0;
  }
}

function Whitepaper(props) {
  const { t } = props;
  const [FirstName, setFirstName] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [OrganizationName, setOrganizationName] = React.useState("");

  function handleSubmit(event) {
    if (Email.length === 0){
        alert(t("emailerror"))
    } else if (OrganizationName.length === 0){
        alert(t("groupnameerror"))
    } else if (FirstName.length === 0){
        alert(t("firsnameerror"))
    } else {
        axios
        .post(
          "https://backend.ambeent.ai/ambeentcore/api/v1/WhitePaper/SendWhitePaper",
          {
            email: Email,
            fullName: FirstName,
            company: OrganizationName,
          }
        )
        .then(function(response) {
        alert("E-mail sent to your mail address!")
        ReactGA.event({
          category: 'User',  
          action: 'Whitepaper send to' + Email + '-' + OrganizationName
        });
        })
        .catch(function(error) {
          console.log(error);
          alert("E-mail could not send")
        });
    }
    event.preventDefault();
  }

  return (
    <div>
      <nav id="header" className="navbar is-primary is-fixed-top">
        <div className="logo-wrapper-col" id="loginLogoWrapper">
          <img
            id="loginLogo"
            onClick={logoRotate}
            src="https://cdn.zeplin.io/5be95f75181a333cfe712602/assets/DFD27C74-9C00-4155-B5DD-193C65005EF5.png"
            alt=""
          ></img>
          <a id="ambeent" href="http://ambeent.ai/" style={{ color: "white" }}>
            {t("Ambeent")}
          </a>
        </div>
      </nav>
      <div>
            <hr className="pathRating" />
            <div className="Login">
              <form onSubmit={handleSubmit}>
                <FormGroup controlId="password">
                  <FormLabel className="passwordLabel">{t("eemail")}</FormLabel>
                  <input
                    className="email"
                    type="email"
                    placeholder="example@example.com"
                    onChange={e => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup controlId="password">
                  <FormLabel className="passwordLabel">Company Name</FormLabel>
                  <input
                    className="password"
                    placeholder="Your company name"
                    onChange={e => setOrganizationName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup controlId="email">
                  <FormLabel className="emailLabel">Full Name</FormLabel>
                  <input
                    className="password"
                    placeholder="John Doe"
                    onChange={e => setFirstName(e.target.value)}
                  />
                </FormGroup>
                <Button id="submitButton" type="submit">
                  {t("sbmt")}
                </Button>
              </form>
            </div>
          </div>
    </div>
  );
}

export default withTranslation()(Whitepaper);
