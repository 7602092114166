const defaultElements = [
  {
    id: "recom",
    type: "recommendation",
    data: { recom: "" },
    position: { x: 1100, y: 50 },
  },
  {
    id: "cxScore",
    type: "parent",
    data: { label: "CX Score", hideLeft: true, hideRight: true, hideTop: true },
    position: { x: 450, y: 0 },
  },
  // default node
  {
    id: "wifi",
    type: "parent",
    data: { label: "Wi-Fi", hideLeft: true, hideRight: true },
    position: { x: 350, y: 100 },
  },
  {
    id: "modem",
    type: "parent",
    data: { label: "Access Point",hideLeft: true, hideRight: true },
    position: { x: 550, y: 100 },
  },
  {
    id: "network",
    type: "parent",
    data: { label: "Network", hideLeft: true, hideRight: true },
    position: { x: 750, y: 100 },
  },

  {
    id: "streamingQuality",
    type: "parent",
    data: {
      label: "Streaming Quality",
      value: "No Data!",
      hideLeft: true, 
      hideRight: true,
    },
    position: { x: 150, y: 100 },
  },
  {
    id: "speedTest",
    type: "child",
    data: {
      label: "Speed Test",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 155, y: 200 },
  },
  {
    id: "coverage",
    type: "child",
    data: {
      label: "Coverage",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 355, y: 200 },
  },
  {
    id: "interference",
    type: "child",
    data: {
      label: "Interference",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 355, y: 300 },
  },
  {
    id: "dataRate",
    type: "child",
    data: {
      label: "Data Rate",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 355, y: 400 },
  },
  {
    id: "connectedDevices",
    type: "child",
    data: {
      label: "Connected Devices",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 355, y: 500 },
  },
  {
    id: "rssi",
    type: "child",
    data: {
      label: "RSSI",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 355, y: 600 },
  },
  {
    id: "gatewayJitter",
    type: "child",
    data: {
      label: "Gateway Jitter",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 555, y: 565 },
  },
  {
    id: "gatewayDelay",
    type: "child",
    data: {
      label: "Gateway Delay",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 555, y: 665 },
  },
  {
    id: "gatewayPacketLoss",
    type: "child",
    data: {
      label: "Gateway Packet Loss",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 555, y: 765 },
  },
  {
    id: "modemPlacement",
    type: "child",
    data: {
      label: "Access Point Placement",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 555, y: 200 },
  },
  {
    id: "bestChannels",
    type: "child",
    data: {
      label: "Best Channels",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 555, y: 335 },
  },
  {
    id: "neighboorRouter",
    type: "child",
    data: {
      label: "Neighbor Access Points",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 555, y: 455 },
  },
  {
    id: "traceRoute",
    type: "child",
    data: {
      label: "Trace Route",
      value: "No Problem!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 755, y: 200 },
  },
  {
    id: "jitter",
    type: "child",
    data: {
      label: "Jitter",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 755, y: 300 },
  },
  {
    id: "delay",
    type: "child",
    data: {
      label: "Delay",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 755, y: 400 },
  },
  {
    id: "packetLoss",
    type: "child",
    data: {
      label: "Packet lost rate",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 755, y: 500 },
  },
  {
    id: "cxScore-wifi",
    source: "cxScore",
    target: "wifi",
    sourceHandle: "a",
    animated: true,
  },
  {
    id: "cxScore-streamingQuality",
    source: "cxScore",
    target: "streamingQuality",
    sourceHandle: "a",
    animated: true,
  },
  {
    id: "cxScore-modem",
    source: "cxScore",
    target: "modem",
    sourceHandle: "a",
    animated: true,
  },
  {
    id: "cxScore-network",
    source: "cxScore",
    target: "network",
    sourceHandle: "a",
    animated: true,
  },
  {
    id: "streamingQuality-speedTest",
    source: "streamingQuality",
    target: "speedTest",
    sourceHandle: "a",
    animated: true,
  },
  { id: "jitter-delay", source: "jitter", target: "delay", animated: true },
  {
    id: "delay-packetLoss",
    source: "delay",
    target: "packetLoss",
    animated: true,
  },
  {
    id: "wifi-coverage",
    source: "wifi",
    target: "coverage",
    sourceHandle: "a",
    animated: true,
  },
  {
    id: "coverage-interference",
    source: "coverage",
    target: "interference",
    animated: true,
  },
  {
    id: "interference-dataRate",
    source: "interference",
    target: "dataRate",
    animated: true,
  },
  {
    id: "dataRate-connectedDevices",
    source: "dataRate",
    target: "connectedDevices",
    animated: true,
  },
  {
    id: "connectedDevices-rssi",
    source: "connectedDevices",
    target: "rssi",
    animated: true,
  },
  {
    id: "gatewayJitter-gatewayDelay",
    source: "gatewayJitter",
    target: "gatewayDelay",
    animated: true,
  },
  {
    id: "gatewayDelay-gatewayPacketLoss",
    source: "gatewayDelay",
    target: "gatewayPacketLoss",
    animated: true,
  },
  {
    id: "modem-gatewayJitter",
    source: "modem",
    sourceHandle: "a",
    target: "gatewayJitter",
    animated: true,
  },
  {
    id: "modemPlacement-bestChannels",
    source: "modemPlacement",
    target: "bestChannels",
    animated: true,
  },
  {
    id: "bestChannels-neighboorRouter",
    source: "bestChannels",
    target: "neighboorRouter",
    animated: true,
  },
  {
    id: "network-traceRoute",
    source: "network",
    target: "traceRoute",
    animated: true,
  },
  {
    id: "traceRoute-jitter",
    source: "traceRoute",
    target: "jitter",
    animated: true,
  },
];

const b2cDefaultElements = [
  {
    id: "device",
    type: "device",
    data: { label: "", ambId: "" },
    position: { x: 0, y: 150 },
  },
  {
    id: "router0",
    type: "router",
    data: { label: "", mac: "" },
    position: { x: 200, y: 0 },
  },
  {
    id: "router1",
    type: "router",
    data: { label: "", mac: "" },
    position: { x: 200, y: 150 },
  },
  {
    id: "router2",
    type: "router",
    data: { label: "", mac: "" },
    position: { x: 200, y: 300 },
  },
  {
    id: "router3",
    type: "router",
    data: { label: "", mac: "", others: [{ label: "", mac: "" }] },
    position: { x: 200, y: 450 },
  },
  {
    id: "cxScore",
    type: "parent",
    data: { label: "CX Score", hideLeft: true, hideTop: true },
    position: { x: 400, y: 25 },
  },
  // default node
  {
    id: "wifi",
    type: "parent",
    data: { label: "Wi-Fi", hideTop: true },
    position: { x: 600, y: 25 },
  },
  {
    id: "modem",
    type: "parent",
    data: { label: "Access Point", hideTop: true },
    position: { x: 800, y: 25 },
  },
  {
    id: "network",
    type: "parent",
    data: { label: "Network", hideTop: true, hideRight: true },
    position: { x: 1000, y: 25 },
  },

  {
    id: "streamingQuality",
    type: "parent",
    data: {
      label: "Streaming Quality",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 400, y: 200 },
  },
  {
    id: "speedTest",
    type: "child",
    data: {
      label: "Speed Test",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 405, y: 300 },
  },
  {
    id: "coverage",
    type: "child",
    data: {
      label: "Coverage",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 605, y: 100 },
  },
  {
    id: "interference",
    type: "child",
    data: {
      label: "Interference",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 605, y: 200 },
  },
  {
    id: "dataRate",
    type: "child",
    data: {
      label: "Data Rate",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 605, y: 300 },
  },
  {
    id: "connectedDevices",
    type: "child",
    data: {
      label: "Connected Devices",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 605, y: 400 },
  },
  {
    id: "rssi",
    type: "child",
    data: {
      label: "RSSI",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 605, y: 500 },
  },
  {
    id: "gatewayJitter",
    type: "child",
    data: {
      label: "Gateway Jitter",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 805, y: 465 },
  },
  {
    id: "gatewayDelay",
    type: "child",
    data: {
      label: "Gateway Delay",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 805, y: 565 },
  },
  {
    id: "gatewayPacketLoss",
    type: "child",
    data: {
      label: "Gateway Packet Loss",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 805, y: 665 },
  },
  {
    id: "modemPlacement",
    type: "child",
    data: {
      label: "Access Point Placement",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 805, y: 100 },
  },
  {
    id: "bestChannels",
    type: "child",
    data: {
      label: "Best Channels",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 805, y: 235 },
  },
  {
    id: "neighboorRouter",
    type: "child",
    data: {
      label: "Neighbor Access Points",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 805, y: 355 },
  },
  {
    id: "traceRoute",
    type: "child",
    data: {
      label: "Trace Route",
      value: "No Problem!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 1005, y: 100 },
  },
  {
    id: "jitter",
    type: "child",
    data: {
      label: "Jitter",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 1005, y: 200 },
  },
  {
    id: "delay",
    type: "child",
    data: {
      label: "Delay",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
    },
    position: { x: 1005, y: 300 },
  },
  {
    id: "packetLoss",
    type: "child",
    data: {
      label: "Packet lost rate",
      value: "No Data!",
      hideLeft: true,
      hideRight: true,
      hideBottom: true,
    },
    position: { x: 1005, y: 400 },
  },
  {
    id: "recom",
    type: "recommendation",
    data: { recom: "" },
    position: { x: 1250, y: 50 },
  },
  {
    id: "cxScore-wifi",
    source: "cxScore",
    sourceHandle: "b",
    target: "wifi",
    animated: true,
  },
  {
    id: "cxScore-streamingQuality",
    source: "cxScore",
    target: "streamingQuality",
    animated: true,
  },
  {
    id: "streamingQuality-speedTest",
    source: "streamingQuality",
    target: "speedTest",
    animated: true,
  },
  { id: "jitter-delay", source: "jitter", target: "delay", animated: true },
  {
    id: "delay-packetLoss",
    source: "delay",
    target: "packetLoss",
    animated: true,
  },
  {
    id: "wifi-modem",
    source: "wifi",
    target: "modem",
    sourceHandle: "b",
    animated: true,
  },
  {
    id: "wifi-coverage",
    source: "wifi",
    target: "coverage",
    sourceHandle: "a",
    animated: true,
  },
  {
    id: "coverage-interference",
    source: "coverage",
    target: "interference",
    animated: true,
  },
  {
    id: "interference-dataRate",
    source: "interference",
    target: "dataRate",
    animated: true,
  },
  {
    id: "dataRate-connectedDevices",
    source: "dataRate",
    target: "connectedDevices",
    animated: true,
  },
  {
    id: "connectedDevices-rssi",
    source: "connectedDevices",
    target: "rssi",
    animated: true,
  },
  {
    id: "gatewayJitter-gatewayDelay",
    source: "gatewayJitter",
    target: "gatewayDelay",
    animated: true,
  },
  {
    id: "gatewayDelay-gatewayPacketLoss",
    source: "gatewayDelay",
    target: "gatewayPacketLoss",
    animated: true,
  },
  {
    id: "modem-network",
    source: "modem",
    sourceHandle: "b",
    target: "network",
    animated: true,
  },
  {
    id: "modem-gatewayJitter",
    source: "modem",
    sourceHandle: "a",
    target: "gatewayJitter",
    animated: true,
  },
  {
    id: "modemPlacement-bestChannels",
    source: "modemPlacement",
    target: "bestChannels",
    animated: true,
  },
  {
    id: "bestChannels-neighboorRouter",
    source: "bestChannels",
    target: "neighboorRouter",
    animated: true,
  },
  {
    id: "network-traceRoute",
    source: "network",
    target: "traceRoute",
    animated: true,
  },
  {
    id: "traceRoute-jitter",
    source: "traceRoute",
    target: "jitter",
    animated: true,
  },
  { id: "device-router0", source: "device", target: "router0" },
  { id: "device-router1", source: "device", target: "router1" },
  { id: "device-router2", source: "device", target: "router2" },
  { id: "device-router3", source: "device", target: "router3" },
];
const Nodes = {
  defaultElements,
  b2cDefaultElements,
};

export default Nodes;
