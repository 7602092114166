import React from "react";
import { withTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  checked: {},
}));

const Info = ({ t }) => {
  const classes = useStyles();

  function clickFaqs(e) {
    e.preventDefault();
    document.getElementById("helpButton").click();
  }

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Paper className="accountPaper">
        <div className="accountInformation">
          <div className="accountInfoContentArea">
            <form
              id="accountForm"
              className={classes.root}
              noValidate
              autoComplete="off"
            >
              <div id="accountInviteExplanation">{t("updateInfo")}</div>
              <div style={{ marginTop: "25px" }}></div>
              <div id="accountInviteExplanation">{t("inviteInfo")}</div>
              <div style={{ marginTop: "25px" }}>
                <button id="hrefHelp" onClick={clickFaqs}>
                  {t("faqs")}
                </button>
                {/* <Popup
                  contentStyle={{
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  trigger={<button id="hrefHelp">{t("faqs")}</button>}
                  modal
                >
                  <iframe
                    title="videoFrame"
                    frameborder="0"
                    width="948"
                    height="533"
                    src="https://www.youtube.com/embed/QTkQMRVWsvE"
                  ></iframe>
                </Popup> */}
              </div>
            </form>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

export default withTranslation()(Info);
