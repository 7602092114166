import KeycloakService from "../Keycloak/KeycloakService";
import Constants from "../utils/Constants";

//FUNCTION FOR BEST CHANNEL
function arrayMin(arr) {
  var len = arr.length,
    min = Infinity;
  while (len--) {
    if (Number(arr[len]) < min) {
      min = Number(arr[len]);
    }
  }
  return min;
}
//FUNCTION FOR BEST CHANNEL
function getAllIndexes(arr, val) {
  var indexes = [],
    i = -1;
  while ((i = arr.indexOf(val, i + 1)) !== -1) {
    indexes.push(i);
  }
  return indexes;
}

//FUNCTION TO FORMAT ROUTER HEATMAP ENDPOINTS RESPONSE
function heatMapResponseFormatter(response) {
  let row1 = [];
  let row2 = [];
  let row3 = [];
  let row4 = [];
  response.First_circle.forEach((element) => {
    row1.push(element);
  });
  response.Second_Circle.forEach((element) => {
    row2.push(element);
  });
  response.Third_Circle.forEach((element) => {
    row3.push(element);
  });
  response.Fourth_Circle.forEach((element) => {
    row4.push(element);
  });
  return { first: row1, second: row2, third: row3, fourth: row4 };
}

//FUNCTION FOR UNIT
function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [
    " Kbps",
    " Mbps",
    " Gbps",
    " Tbps",
    "Pbps",
    "Ebps",
    "Zbps",
    "Ybps",
  ];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(2) + byteUnits[i];
}

//FUNCTION FOR UNIT
function getReadableFileSizeInt(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [
    "Kbps",
    "Mbps",
    "Gbps",
    "Tbps",
    "Pbps",
    "Ebps",
    "Zbps",
    "Ybps",
  ];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return [Math.max(fileSizeInBytes, 0.1).toFixed(2), byteUnits[i]];
}

const historyBackgroundControl = (background) => {
  return background === true ? "* " : "";
};

const isCompanyISP = () => {
  return KeycloakService.getType() === Constants.ISP_COMPANY_TYPE;
};

const getLowerCaseCompanyID = () => {
  const CompanyId =
    localStorage.getItem("companyId") == null
      ? Constants.COMPANY_ID_EMPTY
      : localStorage.getItem("companyId").toLocaleLowerCase() ===
        Constants.COMPANY_ID_DEMO_1.toLocaleLowerCase()
      ? Constants.COMPANY_ID_T
      : localStorage.getItem("companyId").toLocaleLowerCase() ===
        Constants.COMPANY_ID_DEMO_2.toLocaleLowerCase()
      ? Constants.COMPANY_ID_M
      : localStorage.getItem("companyId");

  return CompanyId;
};

const getDateFormat = (selectedFilter) => {
  return selectedFilter === "hourly"
    ? "dd/MM/yyyy HH:mm"
    : selectedFilter === "monthly"
    ? "MM/yyyy"
    : "dd/MM/yyyy";
};

const getFirstLastDaysOfMonth = (date) => {
  var y = date.getFullYear();
  var m = date.getMonth();
  var firstDay = new Date(y, m, 1);
  var lastDay = new Date(y, m + 1, 0);
  return { firstDay, lastDay };
};

const Helpers = {
  arrayMin,
  getAllIndexes,
  heatMapResponseFormatter,
  getReadableFileSizeInt,
  getReadableFileSizeString,
  historyBackgroundControl,
  isCompanyISP,
  getLowerCaseCompanyID,
  getDateFormat,
  getFirstLastDaysOfMonth,
};

export default Helpers;
