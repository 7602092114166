import initialStates from "../../../Classes/InitialStates/devices.initialStates";

const handleGetAndroidReleaseDistribution = (response) => {
  if (response.data) {
    var totalModel = 0;
    var androidReleaseData = [];

    if (response.data !== null) {
      androidReleaseData.push(["Release", "Count"]);
      response.data.forEach((element) => {
        totalModel = totalModel + parseInt(element.Count);
        androidReleaseData.push([
          "Android \u00A0" + element.Data,
          element.Count,
        ]);
      });
    }

    return {
      isFetched: true,
      releaseData: androidReleaseData,
      total: totalModel,
    };
  } else {
    return { ...initialStates.androidRelease, isFetched: true };
  }
};

const handleGetAndroidReleasePredictionDistribution = (response) => {
  if (response.data) {
    var androidReleasePrediction3MonthLaterData = [];
    var androidReleasePrediction6MonthLaterData = [];
    // console.log(response.data);
    if (response.data.PredictionFor3MonthLater !== null) {
      androidReleasePrediction3MonthLaterData.push(["Release", "Percentage"]);
      response.data.PredictionFor3MonthLater.forEach((element) => {
        androidReleasePrediction3MonthLaterData.push([
          element.Data,
          element.Count,
        ]);
      });
    }

    if (response.data.PredictionFor6MonthLater !== null) {
      androidReleasePrediction6MonthLaterData.push(["Release", "Percentage"]);
      response.data.PredictionFor6MonthLater.forEach((element) => {
        androidReleasePrediction6MonthLaterData.push([
          element.Data,
          element.Count,
        ]);
      });
    }

    return {
      isFetched: true,
      prediction3Month: androidReleasePrediction3MonthLaterData,
      prediction6Month: androidReleasePrediction6MonthLaterData,
    };
  } else {
    return { ...initialStates.androidReleasePrediction, isFetched: true };
  }
};

const handleGetIosReleasePredictionDistribution = (response) => {
  if (response.data) {
    var iosReleasePrediction3MonthLaterData = [];
    var iosReleasePrediction6MonthLaterData = [];
    // console.log(response.data);
    if (response.data.PredictionFor3MonthLater !== null) {
      iosReleasePrediction3MonthLaterData.push(["Release", "Percentage"]);
      response.data.PredictionFor3MonthLater.forEach((element) => {
        iosReleasePrediction3MonthLaterData.push([element.Data, element.Count]);
      });
    }

    if (response.data.PredictionFor6MonthLater !== null) {
      iosReleasePrediction6MonthLaterData.push(["Release", "Percentage"]);
      response.data.PredictionFor6MonthLater.forEach((element) => {
        iosReleasePrediction6MonthLaterData.push([element.Data, element.Count]);
      });
    }

    return {
      isFetched: true,
      prediction3Month: iosReleasePrediction3MonthLaterData,
      prediction6Month: iosReleasePrediction6MonthLaterData,
    };
  } else {
    return { ...initialStates.IOSReleasePrediction, isFetched: true };
  }
};

const handleGetIosReleaseDistribution = (response) => {
  if (response.data) {
    var totalModel = 0;
    var iosReleaseData = [];

    if (response.data !== null) {
      iosReleaseData.push(["Release", "Count"]);
      response.data.forEach((element) => {
        totalModel = totalModel + parseInt(element.Count);
        iosReleaseData.push(["IOS \u00A0" + element.Data, element.Count]);
      });
    }

    return {
      isFetched: true,
      releaseData: iosReleaseData,
      total: totalModel,
    };
  } else {
    return { ...initialStates.IOSReleaseDist, isFetched: true };
  }
};

const handleGetDeviceManufacturerDist = (response) => {
  if (response.data) {
    var brandData = [];
    //  console.log(response.data);

    var brandName;
    if (response.data === null) {
      // document.getElementById("barChartDiv").innerHTML = t("routerError");
      // document.getElementById("barChartDiv").style.backgroundColor =
      //   "transparent";
      // document.getElementById("barChartDiv").style.fontSize = "20px";
      // document.getElementById("barChartDiv").style.color = "red";
      // document.getElementById("barChartDiv").style.fontFamily =
      //   "Helvetica, Arial, sans-serif";
    } else {
      brandData.push(["Manifacturer", "Count"]);
      response.data.forEach((element) => {
        brandName =
          element.Data.charAt(0).toUpperCase() + element.Data.slice(1);
        brandData.push([brandName, parseInt(element.Count)]);
      });
    }
    return {
      isFetched: true,
      brandData: brandData,
    };
  } else {
    return { ...initialStates.deviceManufacturerDist, isFetched: true };
  }
};

const handleGetDeviceModel = (response) => {
  if (response.data) {
    var modelData = [];
    if (response?.data.length > 0) {
      modelData.push(["Model", "Count"]);
      response.data.forEach((element) => {
        modelData.push([element.Data, parseInt(element.Count)]);
      });
    }
    return {
      isFetched: true,
      modelData: modelData,
    };
  } else {
    return { ...initialStates.deviceModelDist, isFetched: true };
  }
};

const handleError = (error) => {
  console.log(error);
  if (error.data) {
    return error.data;
  }
  return error;
};

const responseHandler = {
  handleError,
  handleGetAndroidReleaseDistribution,
  handleGetAndroidReleasePredictionDistribution,
  handleGetIosReleaseDistribution,
  handleGetIosReleasePredictionDistribution,
  handleGetDeviceManufacturerDist,
  handleGetDeviceModel,
};

export default responseHandler;
