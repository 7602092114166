import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/details/details.apis";
import initialStates from "../InitialStates/details.InitialStates";

class AllHeatMaps {
    allHeatMapsSubject;
  constructor() {
    this.allHeatMapsSubject = new BehaviorSubject(
      initialStates.allHeatMaps
    );
  }

  //get router heat map object
  get get() {
    return this.allHeatMapsSubject.asObservable();
  }

  //fetch data from /router-heatmap/ endpoint
  fetchData(modemMac) {
    apis.getHeatMapAll(modemMac).then((response) => {
      this.allHeatMapsSubject.next(response);
    });
  }

  //reset router heat map Subject objects state
  reset() {
    this.allHeatMapsSubject.next(initialStates.allHeatMaps);
  }
}

const AllHeatMapsService = (function () {
  var instance;

  function createInstance() {
    var classObj = new AllHeatMaps();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default AllHeatMapsService;
