import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";

import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import RefreshIcon from "@material-ui/icons/Refresh";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const axios = require("axios");

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  checked: {},
}));

const Clouds = ({ t, setSpinnerCallBack }) => {
  const [spinner, setSpinner] = React.useState(true);

  const classes = useStyles();

  const [clouds, setClouds] = React.useState(null);
  const [cloudApi, setCloudApi] = React.useState(null);
  const [apiVisibility, setApiVisibility] = React.useState(false);
  const [apiVisibilityIndex, setApiVisibilityIndex] = React.useState(0);
  const [openTable, setOpenTable] = React.useState(false);
  const [collapseID, setcollapseID] = React.useState(0);

  useEffect(() => {
    axios
      .get(
        "https://backend.ambeent.ai/company/clouds/" +
          localStorage.getItem("defaultCompanyId")
      )
      .then((response) => {
        setSpinner(false);
        setSpinnerCallBack(false)
        var cloudsList = [];
        response.data.forEach((element) => {
          cloudsList.push({
            id: element.ID,
            cloudName: element.CloudName,
            cloudId: element.CloudId,
            TimeZone: element.TimeZone,
            apiKey: element.ApiKey,
            Sites: element.Sites,
          });
        });
        if (cloudsList.length > 0) {
          setClouds(cloudsList);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
      // eslint-disable-next-line
  }, []);

  const handleVisibility = (index, visibility) => {
    setApiVisibility(visibility);
    setApiVisibilityIndex(index);
  };

  function handleCloudSubmit(event, id) {
    var activeElement = document.activeElement;
    if (activeElement.name === "edit") {
      const form = new FormData(event.target);
      const apiKeyCloud = form.get("apiKey");
      const timeZoneCloud = form.get("timeZone");

      axios
        .post(
          "https://backend.ambeent.ai/akka-playground/api/companies/addApiKey",
          {
            id: id,
            apiKey: apiKeyCloud,
            timeZone: timeZoneCloud,
          }
        )
        .then((response) => {})
        .catch(function (error) {
          console.log(error);
        });
    } else if (activeElement.name === "refresh") {
      axios
        .get(
          "https://backend.ambeent.ai/company/clouds/" +
            localStorage.getItem("defaultCompanyId")
        )
        .then((response) => {
          var cloudsList = [];

          response.data.forEach((element) => {
            cloudsList.push({
              id: element.ID,
              cloudName: element.CloudName,
              cloudId: element.CloudId,
              TimeZone: element.TimeZone,
              apiKey: element.ApiKey,
              Sites: element.Sites,
            });
          });
          setClouds(cloudsList);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (activeElement.name === "add") {
      axios
        .post(
          "https://backend.ambeent.ai/akka-playground/api/companies/getClouds",
          {
            companyId: localStorage.getItem("defaultCompanyId"),
            apiKey: cloudApi,
          }
        )
        .then((response) => {
          alert(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (activeElement.name === "activate") {
      axios
        .post(
          "https://backend.ambeent.ai/akka-playground/api/companies/activateClouds",
          {
            companyId: localStorage.getItem("defaultCompanyId"),
          }
        )
        .then((response) => {
          alert(t("waitForSites"));
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    event.preventDefault();
  }

  function openCollapse(index) {
    if (collapseID !== index) {
      setOpenTable(false);
      setcollapseID(index);
      setOpenTable(true);
    } else {
      setOpenTable(!openTable);
      setcollapseID(index);
    }
  }

  const cloudList =
    clouds !== null ? (
      clouds.map((item, index) => {
        const sitesList = item.Sites.map((item, index) => {
          return (
            <TableRow
              className="accountCollapsibleTableRow"
              style={{ borderBottom: "1.5px solid #A9A9A990" }}
              key={`sites-row-${index}`}
            >
              <TableCell className="accountCollapsibleTableCell">
                {item.SiteName}
              </TableCell>
              <TableCell className="accountCollapsibleTableCell">
                {item.SiteId}
              </TableCell>
              <TableCell className="accountCollapsibleTableCell">
                GMT{item.TimeZone}
              </TableCell>
            </TableRow>
          );
        });

        return (
          <div key={`clouds-${index}`}>
            <form
              onSubmit={(e) => handleCloudSubmit(e, item.id)}
              noValidate
              autoComplete="off"
            >
              <table style={{ width: "100%", tableLayout: "fixed" }}>
                <tbody>
                  <tr className="accountSavedApiKeysRow">
                    <td className="accountSavedApiKeysColumn1">
                      {item.cloudName}
                    </td>
                    <td className="accountSavedApiKeysColumn2">
                      {item.cloudId}
                    </td>
                    <td className="accountSavedApiKeysColumn3">
                      <div className="accountSavedApiKeysInput">
                        {item.apiKey ? (
                          <div style={{ display: "flex" }}>
                            <TextField
                              name="apiKey"
                              id="standard-basic"
                              label={t("cloudApi")}
                              defaultValue={item.apiKey}
                              type={
                                apiVisibility && apiVisibilityIndex === index
                                  ? ""
                                  : "password"
                              }
                            />

                            {apiVisibility ? (
                              apiVisibilityIndex === index ? (
                                <Button
                                  className="accountEditButton"
                                  onClick={(e) =>
                                    handleVisibility(index, false)
                                  }
                                >
                                  <VisibilityOffIcon />
                                </Button>
                              ) : (
                                <>
                                  <Button
                                    className="accountEditButton"
                                    onClick={(e) =>
                                      handleVisibility(index, true)
                                    }
                                  >
                                    <VisibilityIcon />
                                  </Button>
                                </>
                              )
                            ) : (
                              <Button
                                className="accountEditButton"
                                onClick={(e) => handleVisibility(index, true)}
                              >
                                <VisibilityIcon />
                              </Button>
                            )}
                          </div>
                        ) : (
                          <TextField
                            id="standard-basic"
                            label={t("cloudApi")}
                            name="apiKey"
                          />
                        )}
                      </div>
                    </td>
                    <td className="accountSavedApiKeysColumn4">
                      <select
                        id="timeZoneSelectOptionCloud"
                        style={{ background: "none" }}
                        defaultValue={item.TimeZone}
                        name="timeZone"
                      >
                        <option value={"-10:00"} className="cloudTimeOption">
                          GMT-10:00 - {t("gmtminus10")}
                        </option>
                        <option value={"-09:00"} className="cloudTimeOption">
                          GMT-09:00 - {t("gmtminus9")}
                        </option>
                        <option value={"-08:00"} className="cloudTimeOption">
                          GMT-08:00 - {t("gmtminus8")}
                        </option>
                        <option value={"-07:00"} className="cloudTimeOption">
                          GMT-07:00 - {t("gmtminus7")}
                        </option>
                        <option value={"-06:00"} className="cloudTimeOption">
                          GMT-06:00 - {t("gmtminus6")}
                        </option>
                        <option value={"-05:00"} className="cloudTimeOption">
                          GMT-05:00 - {t("gmtminus5")}
                        </option>
                        <option value={"-04:00"} className="cloudTimeOption">
                          GMT-04:00 - {t("gmtminus4")}
                        </option>
                        <option value={"-03:00"} className="cloudTimeOption">
                          GMT-03:00 - {t("gmtminus3")}
                        </option>
                        <option value={"-02:00"} className="cloudTimeOption">
                          GMT-02:00 - {t("gmtminus2")}
                        </option>
                        <option value={"-01:00"} className="cloudTimeOption">
                          GMT-01:00 - {t("gmtminus1")}
                        </option>
                        <option value={"+00:00"} className="cloudTimeOption">
                          GMT+00:00 - {t("gmt0")}
                        </option>
                        <option value={"+01:00"} className="cloudTimeOption">
                          GMT+01:00 - {t("gmtplus1")}
                        </option>
                        <option value={"+02:00"} className="cloudTimeOption">
                          GMT+02:00 - {t("gmtplus2")}
                        </option>
                        <option value={"+03:00"} className="cloudTimeOption">
                          GMT+03:00 - {t("gmtplus3")}
                        </option>
                        <option value={"+04:00"} className="cloudTimeOption">
                          GMT+04:00 - {t("gmtplus4")}
                        </option>
                        <option value={"+05:00"} className="cloudTimeOption">
                          GMT+05:00 - {t("gmtplus5")}
                        </option>
                        <option value={"+06:00"} className="cloudTimeOption">
                          GMT+06:00 - {t("gmtplus6")}
                        </option>
                        <option value={"+07:00"} className="cloudTimeOption">
                          GMT+07:00 - {t("gmtplus7")}
                        </option>
                        <option value={"+08:00"} className="cloudTimeOption">
                          GMT+08:00 - {t("gmtplus8")}
                        </option>
                        <option value={"+09:00"} className="cloudTimeOption">
                          GMT+09:00 - {t("gmtplus9")}
                        </option>
                        <option value={"+10:00"} className="cloudTimeOption">
                          GMT+10:00 - {t("gmtplus10")}
                        </option>
                        <option value={"+11:00"} className="cloudTimeOption">
                          GMT+11:00 - {t("gmtplus11")}
                        </option>
                        <option value={"+12:00"} className="cloudTimeOption">
                          GMT+12:00 - {t("gmtplus12")}
                        </option>
                        <option value={"+13:00"} className="cloudTimeOption">
                          GMT+13:00 - {t("gmtplus13")}
                        </option>
                      </select>
                    </td>
                    <td
                      className="accountSavedApiKeysColumn5"
                      style={{ display: "flex" }}
                    >
                      <Button
                        className="accountEditButton"
                        type="submit"
                        name="edit"
                      >
                        <SaveIcon />
                      </Button>
                      <Button
                        type="submit"
                        id="accountSaveCloudApiKey"
                        variant="contained"
                        color="secondary"
                        name="activate"
                        style={{ marginLeft: "15px", minWidth: "80px" }}
                      >
                        {t("Activate")}
                      </Button>
                    </td>
                    <td className="accountSavedApiKeysColumn6">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => openCollapse(index)}
                      >
                        {index === collapseID ? (
                          openTable ? (
                            <KeyboardArrowUpIcon className="mpUpArrow" />
                          ) : (
                            <KeyboardArrowDownIcon className="mpDownArrow" />
                          )
                        ) : (
                          <KeyboardArrowDownIcon className="mpDownArrow" />
                        )}
                      </IconButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>

            <Collapse
              in={index === collapseID ? openTable : false}
              timeout="auto"
              unmountOnExit
            >
              <Table>
                <TableHead>
                  <TableRow className="accountCollapsibleTableHeaderRow">
                    <TableCell className="accountCollapsibleTableCell">
                      {t("siteName")}
                    </TableCell>
                    <TableCell className="accountCollapsibleTableCell">
                      {t("siteId")}
                    </TableCell>
                    <TableCell className="accountCollapsibleTableCell">
                      {t("timeZone")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.apiKey === "" ? (
                    <TableRow className="accountCollapsibleTableRow">
                      <TableCell className="accountCollapsibleTableCell">
                        {t("addApi")}
                      </TableCell>
                      <TableCell className="accountCollapsibleTableCell"></TableCell>
                      <TableCell className="accountCollapsibleTableCell"></TableCell>
                    </TableRow>
                  ) : sitesList.length === 0 ? (
                    <TableRow className="accountCollapsibleTableRow">
                      <TableCell className="accountCollapsibleTableCell">
                        {t("activateSites")}
                      </TableCell>
                      <TableCell className="accountCollapsibleTableCell"></TableCell>
                      <TableCell className="accountCollapsibleTableCell"></TableCell>
                    </TableRow>
                  ) : (
                    sitesList
                  )}
                </TableBody>
              </Table>
            </Collapse>
          </div>
        );
      })
    ) : (
      <></>
    );

  var apiCount = 1;
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Paper className="accountPaper">
        <div className="accountCloudArea">
          <div className="accountCloudContentArea">
            <label className="accountSettingsHeader">{t("clouds")}</label>

            {spinner === true ? (
              ""
            ) : clouds === null ? (
              <form
                id="accountForm"
                onSubmit={handleCloudSubmit}
                className={classes.root}
                noValidate
                autoComplete="off"
              >
                <div id="accountInviteExplanation">{t("apiKeyAddClouds")}</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="accountCloudApi">
                    <TextField
                      onChange={(e) => setCloudApi(e.target.value)}
                      id="standard-basic"
                      className="cloudApiInput"
                      label={t("cloudApi")}
                    />

                    <Button
                      style={{ marginLeft: "25px" }}
                      type="submit"
                      id="accountSaveCloudApiKey"
                      variant="contained"
                      color="secondary"
                      name="add"
                    >
                      {t("add")}
                    </Button>
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <form
                  id="accountForm"
                  onSubmit={handleCloudSubmit}
                  className={classes.root}
                  noValidate
                  autoComplete="off"
                >
                  <div style={{ display: "flex", flexDirection: "Column" }}>
                    <div className="accountCloudApi"></div>
                    <div style={{ display: "flex", justifyContent: "left" }}>
                      <p id="refreshCloudText">{t("refreshAllClouds")}</p>
                      <Button
                        id="cloudRefreshButton"
                        className="accountRefreshButton"
                        type="submit"
                        name="refresh"
                      >
                        <RefreshIcon />
                      </Button>
                      <Button
                        type="submit"
                        id="accountSaveCloudApiKey"
                        variant="contained"
                        color="secondary"
                        name="activate"
                        style={{ marginLeft: "30px" }}
                      >
                        {t("Activate")}
                      </Button>
                    </div>
                  </div>
                </form>
                <div className="accountTableWrapper">
                  {apiCount === 0 ? (
                    <div className="accountSavedApiKeysTable">
                      <p className="accountNoSavedApiKey">
                        {t("noRegisteredApi")}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <table
                        className="accountSavedApiKeysTable"
                        style={{
                          borderBottom: "2px solid #ffffff40",
                          tableLayout: "fixed",
                        }}
                      >
                        <thead className="accountSavedApiKeysThead">
                          <tr>
                            <td className="accountSavedApiKeysColumn1">
                              {t("cloudName")}
                            </td>
                            <td className="accountSavedApiKeysColumn2">
                              {t("cloudId")}
                            </td>
                            <td className="accountSavedApiKeysColumn3">
                              {t("cloudApi")}
                            </td>
                            <td
                              className="accountSavedApiKeysColumn4"
                              style={{ paddingLeft: "33px" }}
                            >
                              {t("timeZone")}
                            </td>
                            <td className="accountSavedApiKeysColumn5"> </td>
                            <td className="accountSavedApiKeysColumn6"> </td>
                          </tr>
                        </thead>
                      </table>
                      {cloudList}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

export default withTranslation()(Clouds);
