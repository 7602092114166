import React from "react";

const DropDownRouters = (props) => {

  const [selectedValue, setSelectedValue] = React.useState(props.selectedValue);

  return (
    
      <select
        className="dropdownFilter"
        value={selectedValue}
        onChange={(event) => {
            setSelectedValue(event.target.value);
            props.setSelectedRouter(event.target.value);
        }}
      >
        {props.filterData.map((data,i) => (
          <option key={i} value={i}>{data.label} - {data.value}</option>
        ))}
      </select>

  );
};

export default DropDownRouters;
