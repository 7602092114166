import serviceProviders from "../../serviceProvider";
import responseHandler from "./settings.responseHandlers";

const getDashboardTresholds = () => {
  return serviceProviders.kafkaAxiosClient
    .get(`/company/thresholds/`+
     localStorage.getItem("companyId"),
     )
    .then(responseHandler.handleGetDashboardTresholds)
    .catch(responseHandler.handleError);
};

const updateDashboardTresholds = ({
  scoreBounds,
  streamLatencyBounds,
  fidelityBounds,
  linkSpeedBounds,
  wifiSpeedBounds,
  connectedDevicesBounds,
  latencyBounds,
  pingBounds,
  rssiBounds,
  speedtestBounds,
  avgPingTimeBounds,
  gwAvgPingTimeBounds,
  jitterBounds,
  gwJitterBounds,
  packetLossBounds,
  gwPacketLossBounds,
  neighborCountBounds,
  qualityBounds,
  scoreMinMax,
  streamLatencyMinMax,
  fidelityMinMax,
  linkSpeedMinMax,
  wifiSpeedMinMax,
  connectedDevicesMinMax,
  latencyMinMax,
  pingMinMax,
  rssiMinMax,
  speedtestMinMax,
  avgPingTimeMinMax,
  gwAvgPingTimeMinMax,
  jitterMinMax,
  gwJitterMinMax,
  packetLossMinMax,
  gwPacketLossMinMax,
  neighborCountMinMax,
  qualityMinMax
}) => {
  return serviceProviders.kafkaAxiosClient
    .post(`/company/thresholds/` + localStorage.getItem("companyId"),
      {
      scoreBounds : scoreBounds,
      streamLatencyBounds : streamLatencyBounds,
      fidelityBounds: fidelityBounds,
      linkSpeedBounds : linkSpeedBounds,
      wifiSpeedBounds : wifiSpeedBounds,
      connectedDevicesBounds : connectedDevicesBounds,
      latencyBounds : latencyBounds,
      pingBounds : pingBounds,
      rssiBounds : rssiBounds,
      speedtestBounds : speedtestBounds,
      avgPingTimeBounds : avgPingTimeBounds,
      gwAvgPingTimeBounds : gwAvgPingTimeBounds,
      jitterBounds : jitterBounds,
      gwJitterBounds : gwJitterBounds,
      packetLossBounds : packetLossBounds,
      gwPacketLossBounds : gwPacketLossBounds,
      neigborCountBounds : neighborCountBounds,
      qualityBounds : qualityBounds,
      scoreMinMax : scoreMinMax,
      streamLatencyMinMax : streamLatencyMinMax,
      fidelityMinMax : fidelityMinMax,
      linkSpeedMinMax : linkSpeedMinMax,
      wifiSpeedMinMax : wifiSpeedMinMax,
      connectedDevicesMinMax : connectedDevicesMinMax,
      latencyMinMax : latencyMinMax,
      pingMinMax : pingMinMax,
      rssiMinMax : rssiMinMax,
      speedtestMinMax : speedtestMinMax,
      avgPingTimeMinMax : avgPingTimeMinMax,
      gwAvgPingTimeMinMax : gwAvgPingTimeMinMax,
      jitterMinMax : jitterMinMax,
      gwJitterMinMax : gwJitterMinMax,
      packetLossMinMax : packetLossMinMax,
      gwPacketLossMinMax : gwPacketLossMinMax,
      neigborCountMinMax : neighborCountMinMax,
      qualityMinMax : qualityMinMax
    })
    .then(responseHandler.handleUpdateDashboardTresholds)
    .catch(responseHandler.handleError);
};

const resetThresholds = () => {
  return serviceProviders.kafkaAxiosClient
    .get(`/company/thresholds/reset/` +
      localStorage.getItem("companyId"),
    )
    .then(responseHandler.handleResetDashboardTresholds)
    .catch(responseHandler.handleError);
};

const apis = {
  getDashboardTresholds,
  resetThresholds,
  updateDashboardTresholds,
};

export default apis;
