import React from "react";
import { withTranslation } from "react-i18next";

//Components
import RouterInfo from "./DataViewerComponents/RouterInfo";
import DetailsMap from "./DataViewerComponents/DetailsMap";
import DetailsWiredNetwork from "./DataViewerComponents/DetailsWiredNetwork";
import HeatMapContainer from "./DataViewerComponents/HeatMap/HeatMapContainer";
import HeatMapDetails from "./DataViewerComponents/HeatMap/HeatMapDetails";

import UseObservable from "../../Helpers/UseObservable";
import { DevicesService } from "../../Classes";
import initialStates from "../../Classes/InitialStates/details.InitialStates";

const DetailsContainer = (props) => {
  // const { t } = props;

  const { data: device } = UseObservable({
    observable: DevicesService.getInstance().get,
    initialState: initialStates.device,
  });
  return (
    <div>
      <div className="dashboard" id="detailsMainContainer">
        <div className="row">
          <RouterInfo />
        </div>

        {/* Hide heat map and map for ios devices */}
        {device.selectedDevice.deviceType !== "iOS" && (
          <>
            <div className="row">
              <div className="col  align-SSIDs-start" id="heatMapTable">
                <HeatMapContainer />
              </div>
              <div className="col" id="detailsHeatMap">
                <DetailsMap />
              </div>
            </div>
            <div>
              <HeatMapDetails />
            </div>
          </>
        )}
          {device.selectedDevice.deviceType === "iOS" && (
          <>
            <div className="row">
              <div className="col" id="detailsHeatMap">
                <DetailsMap />
              </div>
            </div>
          </>
        )}

        <DetailsWiredNetwork />
      </div>
    </div>
  );
};

export default withTranslation()(DetailsContainer);
