import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import "./Tabs.css";

const Tabs = (props) => {
  const { t } = props;
  var tabs = props.tabs;
  var defaultTabID = props.defaultTabID;
  var activeTabCallback = props.activeTabCallback;
  var content = props.content;
  const [activeTab, setActiveTab] = useState(defaultTabID);

  return (
    <div className="tabWrapper" key={defaultTabID}>
      <div className="tabButtonWrapper">
        {tabs.map((tabID,i) => {
          return (
            <button
              key={i}
              className={activeTab === tabID ? "activeTabButton" : "tabButton"}
              id={tabID}
              onClick={() => {
                setActiveTab(tabID);
                activeTabCallback();
              }}
            >
              {t(tabID)}
            </button>
          );
        })}
      </div>
      {content[tabs.indexOf(activeTab)]}
    </div>
  );
};

export default withTranslation()(Tabs);
