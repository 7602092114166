
export function getColorFromLinkSpeed(linkspeed) {
  if (linkspeed < 30) {
    return 'red';
  } else if (linkspeed >= 60) {
    return 'green';
  } else {
    return 'yellow';
  }
}
export function getColorFromLatency(latency) {
  if (latency > 20) {
    return 'red';
  } else if (latency <= 10) {
    return 'green';
  } else {
    return 'yellow';
  }
}
export function getColorFromConnectedDevice(ConnectedDevice) {
  if (ConnectedDevice > 11) {
    return 'red';
  } else if (ConnectedDevice <= 6) {
    return 'green';
  } else {
    return 'yellow';
  }
}

export function getColorFromJitter(Jitter) {
  if (Jitter > 30 ) {
    return 'red';
  } else if (Jitter < 10) {
    return 'green';
  } else {
    return 'yellow';
  }
}

export function getColorFromgwJitter(gwJitter) {
  if (gwJitter > 20 ) {
    return 'red';
  } else if (gwJitter < 8) {
    return 'green';
  } else {
    return 'yellow';
  }
}
export function getColorFromStreamLatency(streamLatency) {
  if (streamLatency > 6500 ) {
    return 'red';
  } else if (streamLatency < 3500) {
    return 'green';
  } else {
    return 'yellow';
  }
}


