const COMPANY_ID_EMPTY = "00000000-0000-0000-0000-000000000000";
const COMPANY_ID_DEMO_1 = "8FC57F37-0626-4E94-B994-DFE3442CC15D";
const COMPANY_ID_DEMO_2 = "77395F36-4CA2-4394-ABC2-9DEB1EB3F0B3";
const COMPANY_ID_T = "a4d05dd5-79c7-49df-80d5-2b682f7b459f";
const COMPANY_ID_M = "1d294960-4153-4910-8ac0-24797132f75c";
const ISP_COMPANY_TYPE = "ISP";
const HEATMAP_PARAMETERS = [
  "Fidelity",
  "Score",
  "StreamLatency",
  "LinkSpeed",
  "WifiSpeed",
  "ConnectedDevices",
  "Latency",
  "Ping",
  "Rssi",
  "Speedtest",
  "AvgPingTime",
  "GwAvgPingTime",
  "GwJitter",
  "GwPacketLoss",
  "Jitter",
  "NeigborCount",
  "PacketLoss",
  "Quality",
];

const WEEK_DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const ISP_OPTION_LIST = [
  { pathName: "/Overview", optionTitleId: "overview" },
  { pathName: "/NetworkAnalytics", optionTitleId: "networkAnalytics" },
  { pathName: "/WifiAnalytics", optionTitleId: "wifiAnalytics" },
  { pathName: "/EndUserAnalytics", optionTitleId: "endUserAnalytics" },
  { pathName: "/Settings", optionTitleId: "Settings" },
  { pathName: "/ClientIntegration", optionTitleId: "ClientIntegrations" },
];

const HOME_OPTION_LIST = [
  { pathName: "/EndUserAnalytics", optionTitleId: "endUserAnalytics" },
  { pathName: "/GridView", optionTitleId: "gridView" },
  { pathName: "/Members", optionTitleId: "Members" },
  { pathName: "/Settings", optionTitleId: "Settings" },
  { pathName: "/ClientIntegration", optionTitleId: "ClientIntegrations" },
];

const Constants = {
  COMPANY_ID_EMPTY,
  COMPANY_ID_DEMO_1,
  COMPANY_ID_DEMO_2,
  COMPANY_ID_T,
  COMPANY_ID_M,
  ISP_COMPANY_TYPE,
  HEATMAP_PARAMETERS,
  WEEK_DAYS,
  ISP_OPTION_LIST,
  HOME_OPTION_LIST,
};

export default Constants;
