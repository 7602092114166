import React from "react";
import { Chart } from "react-google-charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { withTranslation } from "react-i18next";

const DevicesPieChart = ({
  t,
  waitProgress,
  chartTitle,
  data,
  options,
  total,
  type,
  arrow,
}) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div className="col">
        <div className="chart-title1">{chartTitle}</div>
        <div className="chart">
          {waitProgress ? (
            data.length > 0 ? (
              <Chart
                height={"300px"}
                width={"450px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={data}
                options={options}
                rootProps={{ "data-testid": "1" }}
              />
            ) : (
              <p>{t("nodata")}</p>
            )
          ) : type === "current" ? (
            <div style={{ marginTop: "150px" }}>
              <CircularProgress disableShrink color="secondary" size="1.5rem" />
            </div>
          ) : (
            <></>
          )}

          {type === "current" ? (
            total === 0 || total === "" ? (
              <div></div>
            ) : (
              <div id="total">
                {t("TOTAL")}
                {total}
              </div>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      {arrow === false ? (
        <></>
      ) : (
        <div style={{ marginTop: "auto", marginBottom: "auto" }}>
          {" "}
          <ArrowForwardIcon />{" "}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(DevicesPieChart);
