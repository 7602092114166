import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import Helpers from "../../Helpers/Helpers";
import Constants from "../../utils/Constants";
import OptionItem from "./OptionItem";

const OptionList = (props) => {
  // const { t } = props;

  function select(pathName) {
    props.onSelectOption(pathName);
  }

  var shortcode = localStorage.getItem("shortCode");

  // Checks current path and directs to valid page according to company type
  useEffect(() => {
    if (Helpers.isCompanyISP()) {
      if (
        window.location.pathname === "/GridView" ||
        window.location.pathname === "/Members" ||
        window.location.pathname === "/"
      ) {
        select("/Overview");
        window.history.pushState(null, null, "Overview");
      }
    } else {
      if (
        window.location.pathname === "/Overview" ||
        window.location.pathname === "/NetworkAnalytics" ||
        window.location.pathname === "/WifiAnalytics" ||
        window.location.pathname === "/"
      ) {
        select("/EndUserAnalytics");
        window.history.pushState(null, null, "EndUserAnalytics");
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="option-list-container">
      {Helpers.isCompanyISP()
        ? Constants.ISP_OPTION_LIST.map((option, i) => (
            <ul className="left-options" key={"option-item" + i}>
              <OptionItem
                handleSelectOption={select}
                pathName={option.pathName}
                optionTitleId={option.optionTitleId}
              />
            </ul>
          ))
        : Constants.HOME_OPTION_LIST.map((option, i) => (
            <ul className="left-options" key={"option-item" + i}>
              <OptionItem
                handleSelectOption={select}
                pathName={option.pathName}
                optionTitleId={option.optionTitleId}
              />
            </ul>
          ))}
      {shortcode === "" ? (
        <b></b>
      ) : (
        <ul style={{ listStyle: "none", margin: "20%", padding: "0" }}>
          <li>
            {/* SHORTCODE */}

            <b className="left-option" id={"scode"}>
              {shortcode}
            </b>
          </li>
        </ul>
      )}
    </div>
  );
};

export default withTranslation()(OptionList);
