const popCount = {
  data: [
    {
      id: 0,
      date: "",
      companyId: "",
      pop: "",
      noProblemCount: "",
      hopProblemCount: "",
      userProblemCount: "",
      wifiProblemCount: "",
      total: "",
      selectedDateOption: "",
    },
  ],
  isFetched: false,
};

const badPopCount = {
  data: [
    {
      id: 0,
      //date: "",
      customerId: "",
      pop: "",
      url: "",
      city: "",
      modemMac: "",
      staMac: "",
      //total: "",
    },
  ],
  isFetched: false,
};

const initialStates = {
  popCount,
  badPopCount
};

export default initialStates;
