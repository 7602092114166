import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./AppRouter";
import i18n from "./utils/i18n";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import KeycloakService from "./Keycloak/KeycloakService";
import HttpService from "./Keycloak/HttpService";
import "./App.css";
import AppRouterWithoutLogin from "./AppRouterWithoutLogin";

const x = window.location.pathname.includes("/info/") ? (
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <AppRouterWithoutLogin />
    </BrowserRouter>
  </I18nextProvider>
) : (
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </I18nextProvider>
);

const appRender = () => ReactDOM.render(x, document.getElementById("root"));


if (window.location.pathname.includes("/info/")) {
  appRender();
} else {
  KeycloakService.initKeycloak(appRender);
}

HttpService.configure();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
