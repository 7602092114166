import Helpers from "../../Helpers/Helpers";

const badCustomers = {
  worstCustomers: [],
  data: [],
  isFetched: false,
};


const customerDataChart = {
  customerChart: [],
  customerChartTR: [],
  isFetched: false,
};

const interactions = {
  filter: "Score",
  rowCount: 100,
  dateType: "Monthly",
  startDate: Helpers.getFirstLastDaysOfMonth(new Date()).firstDay,
  endDate: Helpers.getFirstLastDaysOfMonth(new Date()).lastDay,
  frequency: "0",
  isFetched: false,
};

const initialStates = { badCustomers, customerDataChart, interactions };

export default initialStates;
