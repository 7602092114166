//Singleton services
import {
  ChannelNoiseListService,
  CustomerTraceService,
  NeighboorModemsService,
  PingService,
  RouterInfoService,
  SpeedTestService,
  StasInfoService,
  StreamingsService,
  TracesSankeyService,
  RecommendationService,
} from "../..";
import AllHeatMapsService from "../AllHeatMaps";

const macToLowerCase = (modemMac) => {
  if(modemMac){
    return modemMac.toString().toLowerCase();
  } else {
    return modemMac;
  }
}

const fetchDetails = (modemMac, ambDeviceId, deviceType) => {
  const lowerModemMac= macToLowerCase(modemMac)
  //Singleton Objects
  var RouterInfoSubject = RouterInfoService.getInstance();
  var TracesSankeySubject = TracesSankeyService.getInstance();
  var CustomerTraceSubject = CustomerTraceService.getInstance();
  var StasInfoSubject = StasInfoService.getInstance();
  var StreamingsSubject = StreamingsService.getInstance();
  var ChannelNoiseListSubject = ChannelNoiseListService.getInstance();
  var PingSubject = PingService.getInstance();
  var SpeedTestSubject = SpeedTestService.getInstance();
  var NeighboorModemsSubject = NeighboorModemsService.getInstance();
  // var ChromeExtensionSubject = ChromeExtensionService.getInstance();
  var RecommendationSubject = RecommendationService.getInstance();
  var HeatMapAllSubject = AllHeatMapsService.getInstance();

  // Endpoint calls with modem mac and amb device id

  if (modemMac !== "") {
    //fetch router info data
    RouterInfoSubject.fetchData(lowerModemMac);

    //fetch all heatmaps
    if (deviceType !== "iOS") {
      HeatMapAllSubject.fetchData(lowerModemMac);
    }

    //fetch customer trace data
    CustomerTraceSubject.fetchData({
      ModemMac: lowerModemMac,
      companyId: localStorage.getItem("companyId")
    });
  }
  if (modemMac !== "" && ambDeviceId !== "") {
    //fetch traces sankey data
    TracesSankeySubject.fetchData(ambDeviceId, lowerModemMac);

    //fetch stas info data
    StasInfoSubject.fetchData(ambDeviceId, lowerModemMac);

    //fetch streaming data
    StreamingsSubject.fetchData(ambDeviceId, lowerModemMac);

    //fetch channel noise list data
    ChannelNoiseListSubject.fetchData(ambDeviceId, lowerModemMac);

    //fetch ping data
    PingSubject.fetchData(ambDeviceId, lowerModemMac);

    //fetch speed test data
    SpeedTestSubject.fetchData(ambDeviceId, lowerModemMac);

    //fetch Neighboor Modems data
    NeighboorModemsSubject.fetchData(ambDeviceId, lowerModemMac, deviceType);
  }

  //fetch recommendation data

  if (modemMac !== "" && ambDeviceId !== "") {
    if (deviceType === "iOS") {
      RecommendationSubject.fetchDataIos({
        modemMac : lowerModemMac,
        ambDeviceId : ambDeviceId,
        language: document.getElementById("lngSelect").value.toUpperCase(),
      });
    }else{
      RecommendationSubject.fetchData({
        modemMac : lowerModemMac,
        ambDeviceId: ambDeviceId,
        language: document.getElementById("lngSelect").value.toUpperCase(),
      });
    }
  }

  // if (companyID !== null) {
  //   //fetch chrome ext data
  //   ChromeExtensionSubject.fetchData(companyID);
  // }
};

export default fetchDetails;
