import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import "./MultiSelectDropDown.css";
import { withTranslation } from "react-i18next";

const MultiSelect = ({ t, options, setSelectedOptions }) => {
  const [selected, setSelected] = useState([options[0]]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(value);
    setSelected(value);
  };

  return (
    <FormControl className="multiSelect">
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        disableUnderline
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={t(option)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withTranslation()(MultiSelect);
