import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { withTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";

import MappedTableBody from "./MappedTableBody";

import "./GridView.css";

//time ago imports
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import tr from "javascript-time-ago/locale/tr";
// add local time ago for turkish
TimeAgo.addLocale(tr);
const timeAgoTr = new TimeAgo("tr");
// add local time ago for english
TimeAgo.addLocale(en);
const timeAgoEn = new TimeAgo("en-US");

const axios = require("axios");

const stylebutton = {
  background: "#182142",
  height: "100%",
  width: "100%",
  color: "#8c8ba",
  fontWeight: "bold",
  marginTop: "10px",
  padding: "5px",
  textAlign: "left",
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      //   minWidth: "120px",
      backgroundColor: "#182142",
      color: "#e2e2e2",
      textAlign: "center",
    },
  },
  tabs: {
    backgroundColor: "#182142",
    color: "#e2e2e2",
  },
  head: {
    backgroundColor: "#182142",
    color: "#e2e2e2",
  },
});

const GridView = (props) => {
  const { t } = props;
  const classes = useRowStyles();
  const [columns, setColumns] = React.useState([{ ssid: "", mac: "" }]);
  const [columnsSpeed, setColumnsSpeed] = React.useState([
    { ssid: "", mac: "" },
  ]);
  const [rowsFidelity, setRowsFidelity] = React.useState([[]]);
  const [rowsWifispeed, setRowsWifispeed] = React.useState([[]]);
  const [rowsSpeed, setRowsSpeed] = React.useState([[]]);
  const [rowsLinkspeed, setRowsLinkspeed] = React.useState([[]]);
  const [selectedFilter, setSelectedFilter] = React.useState("SpeedTest");
  const [fidelityThreshold] = React.useState([65, 35]);
  const [speedTestThreshold] = React.useState([30, 15]);
  const [linkSpeedThreshold] = React.useState([60, 30]);
  const [wifiSpeedThreshold] = React.useState([74, 32]);
  const [settings, setSettings] = React.useState(false);
  const [modemPlacement, setmodemPlacement] = React.useState(false);
  const [settingsLabel, setSettingsLabel] = React.useState("Settings");

  const [rooms, setRooms] = React.useState([]);
  const [routers, setRouters] = React.useState([]);
  const [roomName, setRoomName] = React.useState("");
  const [mac, setMac] = React.useState("");
  const [routerName, setRouterName] = React.useState("");
  const [updatePage, setUpdatePage] = React.useState(1);
  const [options, setOptions] = React.useState([]);
  const [modemPlacementData, setmodemPlacementData] = React.useState([]);
  const [stasData, setStasData] = React.useState([]);
  const [measurementsData, setMeasurementsData] = React.useState([]);
  const [pingStasData, setPingStas] = React.useState([]);
  const [pingMeasurementsData, setPingMeasurements] = React.useState([]);
  const [streamingData, setStreaming] = React.useState([]);
  const [speedTestData, setSpeedTest] = React.useState([]);
  const [openTable, setOpenTable] = React.useState(false);
  const [collapseID, setcollapseID] = React.useState(0);
  var router = [];

  // get selected company language
  var lang = localStorage.getItem("i18nextLng");
  // time ago language state
  const [timeAgoLang, setTimeAgoLang] = useState(
    lang === "tr" ? timeAgoTr : timeAgoEn
  );

  // changes timeago language at date column
  useEffect(() => {
    // there is a bug!!!!  lang === "tr" ? timeAgoEn : timeAgoTr language choose is oppposite of it should be but its work
    setTimeAgoLang(lang === "tr" ? timeAgoEn : timeAgoTr);
  }, [lang]);

  function getAPForList() {
    let bezz = [];
    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/CompanyAccessPoint/GetExistedAccessPoint?CompanyId=" +
          localStorage.getItem("companyId")
      )
      .then((response) => {
        response.data.forEach((element) => {
          if (element.location === "") {
            bezz.push({
              ssid: element.ssid,
              macAddress: element.macAddress,
              location: "Unknown Location",
            });
          } else {
            bezz.push({
              ssid: element.ssid,
              macAddress: element.macAddress,
              location: element.location,
            });
          }
        });
        setOptions(bezz);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const renderOptions = (options) => {
    return options.map((option) => {
      return (
        <option
          key={option.value}
          value={JSON.stringify({
            macAddress: option.value,
            ssid: option.text,
          })}
        >
          {option.text + " - " + option.value}
        </option>
      );
    });
  };

  const SelectInputRound = ({ value, groupedOptions }) => {
    return (
      <select
        id={"companyRoutersSelect"}
        value={value}
        onChange={setCompanyRouters}
      >
        <option selected disabled>
          {t("routerList")}
        </option>
        {Object.keys(groupedOptions).map((group, index) => {
          return (
            <optgroup key={index} label={group}>
              {renderOptions(groupedOptions[group])}
            </optgroup>
          );
        })}
      </select>
    );
  };
  const groupedOptions = {};
  options.forEach((option) => {
    if (!groupedOptions[option.location]) groupedOptions[option.location] = [];
    groupedOptions[option.location].push({
      value: option.macAddress,
      text: option.ssid,
    });
  });

  function setCompanyRouters(event) {
    router = JSON.parse(event.target.value);
  }
  function getRouters() {
    let acpList = [];
    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/CompanyAccessPoint/GetAccessPoint?companyId=" +
          localStorage.getItem("companyId")
      )
      .then(function (response) {
        response.data.accessPointList.forEach((element) => {
          acpList.push({
            name: element.accessPointName,
            mac: element.macAddress,
          });
        });
        setRouters(acpList);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function getRooms() {
    let roomList = [];
    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/OfficePart/GetOfficeParts?companyId=" +
          localStorage.getItem("companyId")
      )
      .then(function (response) {
        response.data.partsList.forEach((element) => {
          roomList.push({ name: element.partName });
        });
        setRooms(roomList);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function openSettings() {
    if (settings === true) {
      setSettings(false);
      setSettingsLabel("Settings");
      setUpdatePage(updatePage + 1);
    } else {
      setSettings(true);
      setSettingsLabel("backGrid");
      getRouters();
      getRooms();
      getAPForList();
    }
  }

  function addRoom(e) {
    if (roomName !== "") {
      axios
        .post(
          "https://backend.ambeent.ai/ambeentcore/api/v1/OfficePart/InsertOfficePart",
          {
            partName: roomName,
            companyId: localStorage.getItem("companyId"),
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            getRooms();
            alert(t("roomAdded"));
            e.preventDefault();
          } else {
            alert(t("roomCantAdded"));
            e.preventDefault();
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(t("roomCantAdded"));
          e.preventDefault();
        });
    } else {
      alert(t("roomCantEmpty"));
      e.preventDefault();
    }
  }

  function deleteRoom(z, e) {
    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/OfficePart/DeleteOfficePart",
        {
          partName: rooms[z].name,
          companyId: localStorage.getItem("companyId"),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          getRooms();
          alert(t("roomDeleted"));
          e.preventDefault();
        } else {
          alert(t("roomCantDeleted"));
          e.preventDefault();
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(t("roomCantDeleted"));
        e.preventDefault();
      });
  }

  function addRouter(e) {
    if (mac !== "" && routerName !== "" && router.length === 0) {
      axios
        .post(
          "https://backend.ambeent.ai/ambeentcore/api/v1/CompanyAccessPoint/InsertAccessPoint",
          {
            macAddress: mac,
            accessPointName: routerName,
            companyId: localStorage.getItem("companyId"),
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            getRouters();
            alert(t("apAdded"));
            e.preventDefault();
          } else {
            alert(t("apCantAdded"));
            e.preventDefault();
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(t("apCantAdded"));
          e.preventDefault();
        });
    } else if (mac === "" && routerName === "" && router.length !== 0) {
      axios
        .post(
          "https://backend.ambeent.ai/ambeentcore/api/v1/CompanyAccessPoint/InsertAccessPoint",
          {
            macAddress: router.macAddress,
            accessPointName: router.ssid,
            companyId: localStorage.getItem("companyId"),
          }
        )
        .then((response) => {
          if (response.data.success === true) {
            getRouters();
            alert(t("apAdded"));
            e.preventDefault();
          } else {
            alert(t("apCantAdded"));
            e.preventDefault();
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(t("apCantAdded"));
          e.preventDefault();
        });
    } else if (mac !== "" && routerName !== "" && router.length !== 0) {
      alert(t("twoAPSelected"));
      e.preventDefault();
    } else {
      alert(t("apCantEmpty"));
      e.preventDefault();
    }
  }

  function deleteRouter(x, e) {
    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/CompanyAccessPoint/DeleteAccessPoint",
        {
          macAddress: routers[x].mac,
          companyId: localStorage.getItem("companyId"),
        }
      )
      .then((response) => {
        if (response.data.success === true) {
          getRouters();
          alert(t("apDeleted"));
          e.preventDefault();
        } else {
          alert(t("apCantDeleted"));
          e.preventDefault();
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(t("apCantDeleted"));
        e.preventDefault();
      });
  }

  React.useEffect(() => {
    let columns = [];
    let rowsFidelity = [];
    let rowsWifispeed = [];
    let rowsLinkspeed = [];
    let rowFidelity = [];
    let rowWifispeed = [];
    let rowLinkspeed = [];
    let customerList = [];
    let columnsSpeed = [];
    let rowsSpeed = [];
    let rowSpeed = [];
    let customerListSpeed = [];

    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/DashboardThresholds/GetThresholds",
        {
          companyId: localStorage.getItem("companyId"),
        }
      )
      .then((responseFirst) => {
        axios
          .post(
            "https://backend.ambeent.ai/ambeentcore/api/v1/OfficePart/GetOfficePartSpeedData?companyId=" +
              localStorage.getItem("companyId")
          )
          .then(function (response) {
            // console.log(localStorage.getItem("companyId"));
            var count = 0;
            columnsSpeed.push({ ssid: "Router", mac: "ID" });
            columnsSpeed.push({ ssid: "Date", mac: "" });

            // console.log("response: " + JSON.stringify(response.data.data));

            response.data.data.forEach((element) => {
              count++;
              if (
                columnsSpeed.findIndex(
                  (obj) =>
                    obj.mac === element.macAddress &&
                    obj.ssid === element.apName
                ) === -1
              ) {
                columnsSpeed.push({
                  ssid: element.apName,
                  mac: element.macAddress,
                });
              }
              if (customerListSpeed.indexOf(element.partName) === -1) {
                customerListSpeed.push(element.partName);

                if (rowSpeed.length !== 0) {
                  rowsSpeed.push(rowSpeed);
                }
                rowSpeed = [];
                rowSpeed.push(element.partName);

                if (element.createdAt === "0001-01-01T00:00:00+00:00") {
                  rowSpeed.push("-");
                } else {
                  rowSpeed.push(
                    timeAgoLang.format(new Date(element.createdAt), "round")
                  );
                }
              }
              if (element.speed === -1) {
                rowSpeed.push("-");
              } else if (
                element.speed >
                (responseFirst.data === null
                  ? speedTestThreshold[1] * 1024 * 1024
                  : responseFirst.data.speedTestUpperBound * 1024 * 1024)
              ) {
                rowSpeed.push(t("good"));
              } else if (
                element.speed <
                (responseFirst.data === null
                  ? speedTestThreshold[0] * 1024 * 1024
                  : responseFirst.data.speedTestLowerBound * 1024 * 1024)
              ) {
                rowSpeed.push(t("bad"));
              } else {
                rowSpeed.push(t("medium"));
              }
            });

            if (response.data.data.length === 1) {
              rowsSpeed.push(rowSpeed);
            }

            if (count === response.data.data.length && count !== 0) {
              rowsSpeed.push(rowSpeed);
            }
            setColumnsSpeed(columnsSpeed);
            setRowsSpeed(rowsSpeed);
            // console.log("1 " + JSON.stringify(rowsSpeed));
            // console.log("2 " + JSON.stringify(columnsSpeed));
          })
          .catch(function (error) {
            console.log(error);
          });
        axios
          .post(
            "https://backend.ambeent.ai/ambeentcore/api/v1/OfficePart/GetOfficePartData?companyId=" +
              localStorage.getItem("companyId")
          )
          .then(function (response) {
            columns.push({ ssid: "Router", mac: "ID" });
            columns.push({ ssid: "Date", mac: "" });
            // console.log("fidelity " + JSON.stringify(response.data.data));
            var count = 0;
            response.data.data.forEach((element) => {
              count++;
              if (
                columns.findIndex(
                  (obj) =>
                    obj.mac === element.macAddress &&
                    obj.ssid === element.apName
                ) === -1
              ) {
                columns.push({ ssid: element.apName, mac: element.macAddress });
              }
              if (customerList.indexOf(element.partName) === -1) {
                customerList.push(element.partName);
                if (rowFidelity.length !== 0) {
                  rowsFidelity.push(rowFidelity);
                }
                if (rowLinkspeed.length !== 0) {
                  rowsLinkspeed.push(rowLinkspeed);
                }
                if (rowWifispeed.length !== 0) {
                  rowsWifispeed.push(rowWifispeed);
                }
                rowFidelity = [];
                rowLinkspeed = [];
                rowWifispeed = [];
                rowFidelity.push(element.partName);
                rowLinkspeed.push(element.partName);
                rowWifispeed.push(element.partName);
                // console.log("11" + JSON.stringify(rowFidelity));

                if (element.createdAt === "0001-01-01T00:00:00+00:00") {
                  rowWifispeed.push("-");
                  rowFidelity.push("-");
                  rowLinkspeed.push("-");
                } else {
                  rowWifispeed.push(
                    timeAgoLang.format(new Date(element.createdAt), "round")
                  );
                  rowFidelity.push(
                    timeAgoLang.format(new Date(element.createdAt), "round")
                  );
                  rowLinkspeed.push(
                    timeAgoLang.format(new Date(element.createdAt), "round")
                  );
                }
              }

              // console.log("22" + JSON.stringify(rowFidelity));
              if (element.fidelity === 0) {
                rowFidelity.push("-");
              } else if (
                element.fidelity >
                (responseFirst.data === null
                  ? fidelityThreshold[1]
                  : responseFirst.data.fidelityUpperBound)
              ) {
                rowFidelity.push(t("good"));
              } else if (
                element.fidelity <
                (responseFirst.data === null
                  ? fidelityThreshold[0]
                  : responseFirst.data.fidelityLowerBound)
              ) {
                rowFidelity.push(t("bad"));
              } else {
                rowFidelity.push(t("medium"));
              }
              // console.log("33" + JSON.stringify(rowFidelity));
              if (element.wifiSpeed === null || element.wifiSpeed === 0) {
                rowWifispeed.push("-");
              } else if (
                element.wifiSpeed >
                (responseFirst.data === null
                  ? wifiSpeedThreshold[1]
                  : responseFirst.data.wifiSpeedUpperBound)
              ) {
                rowWifispeed.push(t("good"));
              } else if (
                element.wifiSpeed <
                (responseFirst.data === null
                  ? wifiSpeedThreshold[0]
                  : responseFirst.data.wifiSpeedLowerBound)
              ) {
                rowWifispeed.push(t("bad"));
              } else {
                rowWifispeed.push(t("medium"));
              }

              if (element.linkSpeed === 0) {
                rowLinkspeed.push("-");
              } else if (
                element.linkSpeed >
                (responseFirst.data === null
                  ? linkSpeedThreshold[1]
                  : responseFirst.data.linkSpeedUpperBound)
              ) {
                rowLinkspeed.push(t("good"));
              } else if (
                element.linkSpeed <
                (responseFirst.data === null
                  ? linkSpeedThreshold[0]
                  : responseFirst.data.linkSpeedLowerBound)
              ) {
                rowLinkspeed.push(t("bad"));
              } else {
                rowLinkspeed.push(t("medium"));
              }
            });
            if (response.data.data.length === 1) {
              // console.log("39" + JSON.stringify(rowFidelity));
              rowsFidelity.push(rowFidelity);
              rowsLinkspeed.push(rowLinkspeed);
              rowsWifispeed.push(rowWifispeed);
            }
            if (count === response.data.data.length && count !== 0) {
              // console.log("40" + JSON.stringify(rowFidelity));
              rowsFidelity.push(rowFidelity);
              rowsLinkspeed.push(rowLinkspeed);
              rowsWifispeed.push(rowWifispeed);
            }
            // console.log("41" + JSON.stringify(rowFidelity));
            // console.log("42" + JSON.stringify(rowsFidelity));
            setColumns(columns);
            setRowsFidelity(rowsFidelity);
            // console.log("fidrow " + JSON.stringify(rowsFidelity));
            setRowsWifispeed(rowsWifispeed);
            setRowsLinkspeed(rowsLinkspeed);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });

    /*eslint-disable*/
  }, [
    localStorage.getItem("companyId"),
    localStorage.getItem("i18nextLng"),
    updatePage,
  ]);
  /*eslint-enable*/

  // ROWS FROM DATA
  const rowsLinkSpeedFromData = rowsLinkspeed.map((row, i) => (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row[0]}
        </TableCell>
        {row.map((x, i) =>
          i > 0 ? (
            x === "-" || "" || x.includes("ago") || x.includes("now") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "transparent" }}
              >
                {x}
              </TableCell>
            ) : x === t("medium") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#007fad" }}
              >
                {x}
              </TableCell>
            ) : x === t("good") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#2BB673" }}
              >
                {x}
              </TableCell>
            ) : (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#d91e48" }}
              >
                {x}
              </TableCell>
            )
          ) : (
            console.log()
          )
        )}
      </TableRow>
    </React.Fragment>
  ));

  // ROWS FROM DATA
  const rowsWifiSpeedFromData = rowsWifispeed.map((row, i) => (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row[0]}
        </TableCell>
        {row.map((x, i) =>
          i > 0 ? (
            x === "-" || "" || x.includes("ago") || x.includes("now") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "transparent" }}
              >
                {x}
              </TableCell>
            ) : x === t("medium") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#007fad" }}
              >
                {x}
              </TableCell>
            ) : x === t("good") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#2BB673" }}
              >
                {x}
              </TableCell>
            ) : (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#d91e48" }}
              >
                {x}
              </TableCell>
            )
          ) : (
            console.log()
          )
        )}
      </TableRow>
    </React.Fragment>
  ));
  const rowsSpeedTestFromData = rowsSpeed.map((row, i) => {
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell component="th" scope="row">
            {row[0]}
          </TableCell>
          {row.map((x, i) =>
            i > 0 ? (
              x === "-" || "" || x.includes("ago") || x.includes("now") ? (
                <TableCell
                  align="right"
                  key={i}
                  style={{ backgroundColor: "transparent" }}
                >
                  {x}
                </TableCell>
              ) : x === t("medium") ? (
                <TableCell
                  align="right"
                  key={i}
                  style={{ backgroundColor: "#007fad" }}
                >
                  {x}
                </TableCell>
              ) : x === t("good") ? (
                <TableCell
                  align="right"
                  key={i}
                  style={{ backgroundColor: "#2BB673" }}
                >
                  {x}
                </TableCell>
              ) : (
                <TableCell
                  align="right"
                  key={i}
                  style={{ backgroundColor: "#d91e48" }}
                >
                  {x}
                </TableCell>
              )
            ) : (
              console.log()
            )
          )}
        </TableRow>
      </React.Fragment>
    );
  });

  // ROWS FROM DATA
  const rowsFidelityFromData = rowsFidelity.map((row, i) => (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row[0]}
        </TableCell>
        {row.map((x, i) =>
          i > 0 ? (
            x === "-" || "" || x.includes("ago") || x.includes("now") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "transparent" }}
              >
                {x}
              </TableCell>
            ) : x === t("medium") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#007fad" }}
              >
                {x}
              </TableCell>
            ) : x === t("good") ? (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#2BB673" }}
              >
                {x}
              </TableCell>
            ) : (
              <TableCell
                align="right"
                key={i}
                style={{ backgroundColor: "#d91e48" }}
              >
                {x}
              </TableCell>
            )
          ) : (
            console.log()
          )
        )}
      </TableRow>
    </React.Fragment>
  ));

  // COLUMNS FROM DATA
  const columnFromData = columns.map((col, i) => (
    <TableCell align="right">
      <span>
        {col.ssid}
        <br></br>
        {col.mac}
      </span>
    </TableCell>
  ));
  const columnFromSpeedData = columnsSpeed.map((col, i) => (
    <TableCell align="right">
      <span>
        {col.ssid}
        <br></br>
        {col.mac}
      </span>
    </TableCell>
  ));

  const modemPlacementColumnHeaders = [
    { id: 0, title: "" },
    { id: 1, title: t("sessionId") },
    { id: 2, title: t("ModemMac") },
    { id: 3, title: t("modemLocation") },
    { id: 4, title: t("fidelity") },
    { id: 5, title: t("speed") },
    { id: 6, title: t("quality") },
    { id: 7, title: t("latency") },
    { id: 8, title: t("score") },
  ];

  const columnModemPlacement = modemPlacementColumnHeaders.map((col, i) => (
    <TableCell align="right">
      <span>{col.title}</span>
    </TableCell>
  ));

  //draws thicker underline when session id changes
  function underline() {
    var rows = document.getElementsByClassName("mp_score");
    var currentSessionId = document.getElementById("mp_sessionId0").innerHTML;

    for (var j = 0; j < rows.length; j++) {
      var elementSessionId = document.getElementById(
        "mp_sessionId" + j
      ).innerHTML;
      if (currentSessionId === elementSessionId) {
      } else if (currentSessionId !== elementSessionId) {
        document.getElementById("mp_score" + j).style.borderTop =
          "3px solid white";
        document.getElementById("mp_sessionId" + j).style.borderTop =
          "3px solid white";
        document.getElementById("mp_officePartName_id" + j).style.borderTop =
          "3px solid white";
        document.getElementById("mp_modemMac" + j).style.borderTop =
          "3px solid white";
        document.getElementById("mp_fidelity" + j).style.borderTop =
          "3px solid white";
        document.getElementById("mp_speed" + j).style.borderTop =
          "3px solid white";
        document.getElementById("mp_quality" + j).style.borderTop =
          "3px solid white";
        document.getElementById("mp_latency" + j).style.borderTop =
          "3px solid white";
        document.getElementById("mp_arrow" + j).style.borderTop =
          "3px solid white";
        currentSessionId = elementSessionId;
      }
    }
  }

  //finds rows with maximum scores for each session id
  function maxi() {
    var rows = document.getElementsByClassName("mp_score");
    var maxScore = 0;
    var currentSessionId = document.getElementById("mp_sessionId0").innerHTML;
    var highlightId = [];

    for (var i = 0; i < rows.length; i++) {
      var score = document.getElementById("mp_score" + i).innerHTML;
      var elementSessionId = document.getElementById(
        "mp_sessionId" + i
      ).innerHTML;
      if (currentSessionId === elementSessionId) {
      } else if (currentSessionId !== elementSessionId) {
        for (var j = 0; j < highlightId.length; j++) {
          // console.log("score:: " + maxScore);
          document.getElementById(
            "mp_score" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_sessionId" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_officePartName_id" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_modemMac" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_fidelity" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_speed" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_quality" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_latency" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
        }

        // console.log(highlightId);
        currentSessionId = elementSessionId;
        maxScore = 0;
        highlightId = [];
      }

      if (score > maxScore) {
        maxScore = score;
        highlightId.pop();
        highlightId.push(i);
      } else if (score === maxScore) {
        maxScore = score;
        highlightId.push(i);
      }

      if (i === rows.length - 1) {
        for (j = 0; j < highlightId.length; j++) {
          // console.log("score2: " + maxScore);
          document.getElementById(
            "mp_score" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_sessionId" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_officePartName_id" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_modemMac" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_fidelity" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_speed" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_quality" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
          document.getElementById(
            "mp_latency" + highlightId[j]
          ).style.backgroundColor =
            maxScore >= 70 ? "#2bb673" : maxScore > 35 ? "#007fad" : "#d91e48";
        }
      }
    }
  }

  useEffect(() => {
    if (
      modemPlacement === true &&
      document.getElementById("mp_sessionId0") !== null
    ) {
      maxi();
      underline();
    }
  }, [modemPlacementData, modemPlacement]);

  //stas table rows
  const collapsibleStasTable = stasData.map((item, i) => {
    var data = [
      item.staMac,
      item.modemMac,
      item.createdAt,
      item.isp,
      item.fidelity,
      item.linkSpeed,
      item.wifiSpeed,
      item.ssid,
      item.rssi,
      item.channel,
      item.channelWidth,
      item.mpSessionId,
      item.partName,
    ];

    return <MappedTableBody Rows={data}></MappedTableBody>;
  });

  //measurements table rows
  const collapsibleMesuramentsTable = measurementsData.map((item, i) => {
    var data = [
      item.macAddress,
      item.ssid,
      item.rssi,
      item.channel,
      item.channelWidth,
      item.createdAt,
      item.mpSessionId,
      item.partName,
    ];
    return <MappedTableBody Rows={data}></MappedTableBody>;
  });

  //ping stas table rows
  const collapsiblePingStasTable = pingStasData.map((item, i) => {
    var data = [
      item.staMac,
      item.modemMac,
      item.count,
      item.avgPingTime,
      item.packetLoss,
      item.gwAvgPingTime,
      item.gwPacketLoss,
      item.jitter,
      item.gwJitter,
      item.mpSessionId,
      item.partName,
      item.createdAt,
    ];
    return <MappedTableBody Rows={data}></MappedTableBody>;
  });
  //ping measurements table rows
  const collapsiblePingMeasurementsTable = pingMeasurementsData.map(
    (item, i) => {
      var data = [
        item.macAddress,
        item.pingTime,
        item.createdAt,
        item.hostName,
        item.mpSessionId,
        item.partName,
      ];
      return <MappedTableBody Rows={data}></MappedTableBody>;
    }
  );
  //Streaming table rows
  const collapsibleStreamingTable = streamingData.map((item, i) => {
    var data = [
      item.modemMac,
      item.staMac,
      item.createdAt,
      item.latency,
      item.quality,
      item.mpSessionId,
      item.partName,
    ];
    return <MappedTableBody Rows={data}></MappedTableBody>;
  });

  //speed test table rows
  const collapsibleSpeedTestTable = speedTestData.map((item, i) => {
    var data = [
      item.modemMac,
      item.staMac,
      item.ssid,
      item.createdAt,
      item.speed,
      item.uploadSpeed,
      item.ping,
      item.mpSessionId,
      item.partName,
    ];
    return <MappedTableBody Rows={data}></MappedTableBody>;
  });
  //modem placement table rows with collapsible table rows
  const modemPlacementRows = modemPlacementData.map((row, i) => {
    var mp_session_id = "mp_sessionId" + i;
    var modemMac_id = "mp_modemMac" + i;
    var mp_officePartName_id = "mp_officePartName_id" + i;
    var mp_fidelity_id = "mp_fidelity" + i;
    var mp_speed_id = "mp_speed" + i;
    var mp_quality_id = "mp_quality" + i;
    var mp_latency_id = "mp_latency" + i;
    var mp_score_id = "mp_score" + i;
    var mp_arrow = "mp_arrow" + i;

    function openCollapse() {
      if (collapseID !== i) {
        setOpenTable(false);
        setcollapseID(i);
        setOpenTable(true);
      } else {
        setOpenTable(!openTable);
        setcollapseID(i);
      }

      // console.log(
      //   "sessionid " +
      //     row.mp_session_id +
      //     " ofid " +
      //     row.mpOfficePartId +
      //     " cid " +
      //     localStorage.getItem("companyId")
      // );

      let stasTable = [];
      let measurementsTable = [];
      let pingStasTable = [];
      let pingMeasurementsTable = [];
      let streamingTable = [];
      let speedTestTable = [];

      //fetch data von collapsible tables
      axios
        .post(
          "https://backend.ambeent.ai/ambeentcore/api/v1/OfficePart/GetModemPlacementDetails",
          {
            mpSessionId: row.mp_session_id,
            mpOfficePartId: row.mpOfficePartId,
            companyId: localStorage.getItem("companyId"),
          }
        )
        .then((response) => {
          // console.log("element" + response.data);
          //Stas table data
          response.data.stasMP.forEach((element) => {
            // console.log(element);
            stasTable.push({
              staMac: element.staMac,
              modemMac: element.modemMac,
              createdAt: element.createdAt,
              isp: element.isp,
              fidelity: element.fidelity,
              linkSpeed: element.linkSpeed,
              wifiSpeed: element.wifiSpeed,
              ssid: element.ssid,
              rssi: element.rssi,
              channel: element.channel,
              channelWidth: element.channelWidth,
              mpSessionId: element.mpSessionId,
              partName: element.partName,
            });
          });
          setStasData(stasTable);
          //Measurements table data
          response.data.measurementsMP.forEach((element) => {
            // console.log("mesurements " + element);
            measurementsTable.push({
              macAddress: element.macAddress,
              ssid: element.ssid,
              rssi: element.rssi,
              channel: element.channel,
              channelWidth: element.channelWidth,
              createdAt: element.createdAt,
              mpSessionId: element.mpSessionId,
              partName: element.partName,
            });
          });
          setMeasurementsData(measurementsTable);
          //Pingstas table data
          response.data.pingStasMP.forEach((element) => {
            // console.log("mesurements " + element);
            pingStasTable.push({
              staMac: element.staMac,
              modemMac: element.modemMac,
              count: element.count,
              avgPingTime: element.avgPingTime,
              packetLoss: element.packetLoss,
              gwAvgPingTime: element.gwAvgPingTime,
              gwPacketLoss: element.gwPacketLoss,
              jitter: element.jitter,
              gwJitter: element.gwJitter,
              mpSessionId: element.mpSessionId,
              partName: element.partName,
              createdAt: element.createdAt,
            });
          });
          setPingStas(pingStasTable);
          //Ping measurements table data
          response.data.pingMeasurementsMP.forEach((element) => {
            // console.log("mesurements " + element);
            pingMeasurementsTable.push({
              macAddress: element.macAddress,
              pingTime: element.pingTime,
              createdAt: element.createdAt,
              hostName: element.hostName,
              mpSessionId: element.mpSessionId,
              partName: element.partName,
            });
          });
          setPingMeasurements(pingMeasurementsTable);
          //Streaming table data
          response.data.streamingMP.forEach((element) => {
            // console.log("mesurements " + element);
            streamingTable.push({
              modemMac: element.modemMac,
              staMac: element.staMac,
              createdAt: element.createdAt,
              latency: element.latency,
              quality: element.quality,
              mpSessionId: element.mpSessionId,
              partName: element.partName,
            });
          });
          setStreaming(streamingTable);
          //Speedtest table data
          response.data.speedtesMP.forEach((element) => {
            // console.log("mesurements " + element);
            speedTestTable.push({
              modemMac: element.modemMac,
              staMac: element.staMac,
              ssid: element.ssid,
              createdAt: element.createdAt,
              speed: element.speed,
              uploadSpeed: element.uploadSpeed,
              ping: element.ping,
              mpSessionId: element.mpSessionId,
              partName: element.partName,
            });
          });
          setSpeedTest(speedTestTable);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const collapsibleStasTableColumnHeadList = [
      t("stasMac"),
      t("ModemMac"),
      t("createdAt"),
      t("ISP"),
      t("fidelity"),
      t("Linkspeed"),
      t("Wifispeed"),
      t("ssid"),
      t("Rssi"),
      t("channel"),
      t("channelWidth"),
      t("sessionId"),
      t("deviceLocation"),
    ];

    const collapsibleMesuramentsTableColumnHeadList = [
      t("macAddress"),
      t("ssid"),
      t("Rssi"),
      t("channel"),
      t("channelWidth"),
      t("createdAt"),
      t("sessionId"),
      t("deviceLocation"),
    ];

    const collapsiblePingStasTableColumnHeadList = [
      t("stasMac"),
      t("ModemMac"),
      t("count"),
      t("avgPingTime"),
      t("packetLoss"),
      t("gwAvgPingTime"),
      t("gwPacketLoss"),
      t("jitter"),
      t("gwJitter"),
      t("sessionId"),
      t("deviceLocation"),
      t("createdAt"),
    ];

    const collapsiblePingMeasurementsTableColumnHeadList = [
      t("macAddress"),
      t("pingTime"),
      t("createdAt"),
      t("hostName"),
      t("sessionId"),
      t("deviceLocation"),
    ];

    const collapsibleStreamingTableColumnHeadList = [
      t("ModemMac"),
      t("stasMac"),
      t("createdAt"),
      t("latency"),
      t("quality"),
      t("sessionId"),
      t("deviceLocation"),
    ];

    const collapsibleSpeedTestTableColumnHeadList = [
      t("ModemMac"),
      t("stasMac"),
      t("ssid"),
      t("createdAt"),
      t("speed"),
      t("uploadSpeed"),
      t("ping"),
      t("sessionId"),
      t("deviceLocation"),
    ];

    return (
      <React.Fragment>
        <TableRow className={classes.root} key={i}>
          <TableCell id={mp_arrow}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => openCollapse()}
            >
              {i === collapseID ? (
                openTable ? (
                  <KeyboardArrowUpIcon className="mpUpArrow" />
                ) : (
                  <KeyboardArrowDownIcon className="mpDownArrow" />
                )
              ) : (
                <KeyboardArrowDownIcon className="mpDownArrow" />
              )}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" id={mp_session_id}>
            {row.mp_session_id}
          </TableCell>
          <TableCell component="th" scope="row" id={modemMac_id}>
            {row.modemMac_id}
          </TableCell>
          <TableCell component="th" scope="row" id={mp_officePartName_id}>
            {row.mp_officePartName_id}
          </TableCell>
          <TableCell component="th" scope="row" id={mp_fidelity_id}>
            {row.mp_fidelity_id}
          </TableCell>
          <TableCell component="th" scope="row" id={mp_speed_id}>
            {row.mp_speed_id}
          </TableCell>
          <TableCell component="th" scope="row" id={mp_quality_id}>
            {row.mp_quality_id}
          </TableCell>
          <TableCell component="th" scope="row" id={mp_latency_id}>
            {row.mp_latency_id}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            className="mp_score"
            id={mp_score_id}
          >
            {row.mp_score_id}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottom:
                i === collapseID ? (openTable ? "1px solid white" : 0) : 0,
            }}
            colSpan={12}
          >
            <Collapse
              in={i === collapseID ? openTable : false}
              timeout="auto"
              unmountOnExit
            >
              <p className="collapsibleTitle">{t("stasTable")}</p>
              <Table>
                <TableHead>
                  <TableRow className="collapsibleTableHeaderRow">
                    {collapsibleStasTableColumnHeadList.map((columnTitle) => (
                      <TableCell className="collapsibleTableHeaderCell">
                        {columnTitle}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {collapsibleStasTable.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      {collapsibleStasTableColumnHeadList.map((noData) => (
                        <TableCell
                          style={{ color: "#8c8ba", textAlign: "left" }}
                        >
                          {t("nodata")}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : (
                  collapsibleStasTable
                )}
              </Table>

              <p className="collapsibleTitle">{t("measurementsTable")}</p>
              <Table>
                <TableHead>
                  <TableRow className="collapsibleTableHeaderRow">
                    {collapsibleMesuramentsTableColumnHeadList.map(
                      (columnTitle) => (
                        <TableCell className="collapsibleTableHeaderCell">
                          {columnTitle}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                {collapsibleMesuramentsTable.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      {collapsibleMesuramentsTableColumnHeadList.map(
                        (noData) => (
                          <TableCell
                            style={{ color: "#8c8ba", textAlign: "left" }}
                          >
                            {t("nodata")}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableBody>
                ) : (
                  collapsibleMesuramentsTable
                )}
              </Table>

              <p className="collapsibleTitle">{t("pingStasTable")}</p>
              <Table>
                <TableHead>
                  <TableRow className="collapsibleTableHeaderRow">
                    {collapsiblePingStasTableColumnHeadList.map(
                      (columnTitle) => (
                        <TableCell className="collapsibleTableHeaderCell">
                          {columnTitle}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                {collapsiblePingStasTable.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      {collapsiblePingStasTableColumnHeadList.map((noData) => (
                        <TableCell
                          style={{ color: "#8c8ba", textAlign: "left" }}
                        >
                          {t("nodata")}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : (
                  collapsiblePingStasTable
                )}
              </Table>

              <p className="collapsibleTitle">{t("pingMeasurementsTable")}</p>
              <Table>
                <TableHead>
                  <TableRow className="collapsibleTableHeaderRow">
                    {collapsiblePingMeasurementsTableColumnHeadList.map(
                      (columnTitle) => (
                        <TableCell className="collapsibleTableHeaderCell">
                          {columnTitle}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                {collapsiblePingMeasurementsTable.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      {collapsiblePingMeasurementsTableColumnHeadList.map(
                        (noData) => (
                          <TableCell
                            style={{ color: "#8c8ba", textAlign: "left" }}
                          >
                            {t("nodata")}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableBody>
                ) : (
                  collapsiblePingMeasurementsTable
                )}
              </Table>

              <p className="collapsibleTitle">{t("streamingTable")}</p>
              <Table>
                <TableHead>
                  <TableRow className="collapsibleTableHeaderRow">
                    {collapsibleStreamingTableColumnHeadList.map(
                      (columnTitle) => (
                        <TableCell className="collapsibleTableHeaderCell">
                          {columnTitle}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                {collapsibleStreamingTable.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      {collapsibleStreamingTableColumnHeadList.map((noData) => (
                        <TableCell
                          style={{ color: "#8c8ba", textAlign: "left" }}
                        >
                          {t("nodata")}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : (
                  collapsibleStreamingTable
                )}
              </Table>

              <p className="collapsibleTitle">{t("speedTestTable")}</p>
              <Table style={{ marginBottom: "20px" }}>
                <TableHead>
                  <TableRow className="collapsibleTableHeaderRow">
                    {collapsibleSpeedTestTableColumnHeadList.map(
                      (columnTitle) => (
                        <TableCell className="collapsibleTableHeaderCell">
                          {columnTitle}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                {collapsibleSpeedTestTable.length === 0 ? (
                  <TableBody>
                    <TableRow>
                      {collapsibleSpeedTestTableColumnHeadList.map((noData) => (
                        <TableCell
                          style={{ color: "#8c8ba", textAlign: "left" }}
                        >
                          {t("nodata")}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                ) : (
                  collapsibleSpeedTestTable
                )}
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  });

  function openModemPlacement() {
    // console.log("dis");
    var modemPlacementArray = [];
    axios
      .post(
        "https://backend.ambeent.ai/ambeentcore/api/v1/OfficePart/GetModemPlacementResults?CompanyId=" +
          localStorage.getItem("companyId")
      )
      .then((response) => {
        // console.log(response.data);
        // console.log("string");
        response.data.forEach((element) => {
          modemPlacementArray.push({
            mp_session_id: element.mpSessionId,
            modemMac_id: element.macAddress,
            mp_officePartName_id: element.mpOfficePartName,
            mp_fidelity_id: element.fidelity,
            mp_speed_id: element.speed,
            mp_quality_id: element.quality,
            mp_latency_id: element.latency,
            mp_score_id: element.score,
            mpOfficePartId: element.mpOfficePartId,
          });
        });
        setmodemPlacementData(modemPlacementArray);
      })
      .catch(function (error) {
        console.log(error);
      });
    if (modemPlacement === true) {
      setmodemPlacement(false);
      setSettings(false);
      setSettingsLabel("Settings");
    } else {
      setmodemPlacement(true);
      setSettings(false);
    }
  }

  return (
    <div id="gridPage">
      <div id="gridRadioButtonsDiv" style={stylebutton}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="gender"
            name="filterselect"
            id="filterselectGrid"
            value={selectedFilter}
            onChange={(event) => {
              setSelectedFilter(event.target.value);
            }}
          >
            <div style={{ display: "flex" }}>
              {modemPlacement === false ? (
                <div style={{ display: "flex" }}>
                  <div>
                    <button id="gridSettingsBtn" onClick={openSettings}>
                      {t(settingsLabel)}
                    </button>{" "}
                  </div>
                  {settings === false ? (
                    <div style={{ display: "flex" }}>
                      <FormControlLabel
                        value="Fidelity"
                        control={<Radio />}
                        label={t("fidelity")}
                      />
                      <FormControlLabel
                        value="LinkSpeed"
                        control={<Radio />}
                        label={t("Linkspeed")}
                      />
                      <FormControlLabel
                        value="WifiSpeed"
                        control={<Radio />}
                        label={t("Wifispeed")}
                      />
                      <FormControlLabel
                        value="SpeedTest"
                        control={<Radio />}
                        label={t("Speedtest")}
                      />
                    </div>
                  ) : (
                    console.log()
                  )}
                </div>
              ) : (
                <div></div>
              )}
              <button
                id="gridModemPlacementButton"
                onClick={openModemPlacement}
              >
                {modemPlacement === true ? t("backGrid") : t("cpe")}
              </button>
            </div>
          </RadioGroup>
        </FormControl>
      </div>
      {modemPlacement === false ? (
        <div>
          {settings === false ? (
            <TableContainer
              component={Paper}
              className="GridViewTableContainer"
            >
              <Table aria-label="collapsible table">
                <TableHead className={classes.root}>
                  {/* ------COLUMNS----- */}
                  <TableRow
                    className={classes.root}
                    style={{ fontSize: "11px" }}
                  >
                    {columns !== null
                      ? selectedFilter === "SpeedTest"
                        ? columnFromSpeedData
                        : columnFromData
                      : console.log()}
                  </TableRow>
                </TableHead>
                {/* ------ROWS----- */}
                <TableBody className={classes.root}>
                  {rowsFidelity !== null
                    ? selectedFilter === "Fidelity"
                      ? rowsFidelityFromData
                      : selectedFilter === "LinkSpeed"
                      ? rowsLinkSpeedFromData
                      : selectedFilter === "WifiSpeed"
                      ? rowsWifiSpeedFromData
                      : selectedFilter === "SpeedTest"
                      ? rowsSpeedTestFromData
                      : console.log(111)
                    : console.log(222)}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div className="row">
              <Paper id="roomSettingsPaper" className={classes.root}>
                <div>
                  <input
                    className="addGrid"
                    placeholder={t("roomName")}
                    onChange={(event) => {
                      setRoomName(event.target.value);
                    }}
                  />
                  <br />
                  <button className="gridAddButton" onClick={addRoom}>
                    {t("add")}
                  </button>
                </div>
                {t("roomList")}
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="gridTitle">
                        {t("roomName")}
                      </TableCell>
                      <TableCell className="gridTitle">{t("delete")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rooms.map((rooms, z) => {
                      return (
                        <TableRow key={`row-${z}`}>
                          <TableCell className="gridRow">
                            {rooms.name}
                          </TableCell>
                          <TableCell>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              aria-label="delete"
                            >
                              <DeleteIcon onClick={deleteRoom.bind(this, z)} />
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
              <Paper id="acpSettingsPaper" className={classes.root}>
                <div>
                  <input
                    className="addGrid"
                    placeholder={t("macAddress")}
                    onChange={(event) => {
                      setMac(event.target.value);
                    }}
                  />
                  <br />
                  <input
                    className="addGrid"
                    placeholder={t("apNames")}
                    onChange={(event) => {
                      setRouterName(event.target.value);
                    }}
                  />
                  <br />
                  <div id="addAPInfo">{t("addAP")}</div>
                  <div>
                    <SelectInputRound groupedOptions={groupedOptions} />
                  </div>
                  <button className="gridAddButton" onClick={addRouter}>
                    {t("add")}
                  </button>
                </div>
                {t("apList")}
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="gridTitle">
                        {t("apNames")}
                      </TableCell>
                      <TableCell className="gridTitle">
                        {t("macAddress")}
                      </TableCell>
                      <TableCell className="gridTitle">{t("delete")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {routers.map((routers, x) => {
                      return (
                        <TableRow key={`row-${x}`}>
                          <TableCell className="gridRow">
                            {routers.name}
                          </TableCell>
                          <TableCell className="gridRow">
                            {routers.mac}
                          </TableCell>
                          <TableCell>
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              aria-label="delete"
                            >
                              <DeleteIcon
                                onClick={deleteRouter.bind(this, x)}
                              />
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          )}
        </div>
      ) : (
        <div>
          <TableContainer component={Paper} className="GridViewTableContainer">
            <Table aria-label="collapsible table">
              <TableHead className={classes.root}>
                {/* ------COLUMNS----- */}
                <TableRow className={classes.root} style={{ fontSize: "11px" }}>
                  {columnModemPlacement}
                </TableRow>
              </TableHead>
              {/* ------ROWS----- */}
              <TableBody className={classes.root}>
                {modemPlacementData.length === 0 ? (
                  <TableRow>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                    <TableCell style={{ color: "#8c8ba", textAlign: "center" }}>
                      {t("nodata")}
                    </TableCell>
                  </TableRow>
                ) : (
                  modemPlacementRows
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};
export default withTranslation()(GridView);
