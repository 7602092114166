import { BehaviorSubject } from "rxjs";
import apis from "../../Services/Apis/PopCount/popcount.apis";
import initialStates from "../InitialStates/popcount.initialStates";

class BadPopCount {
  badPopCountSubject;
  constructor() {
    this.badPopCountSubject = new BehaviorSubject(initialStates.badPopCount);
  }

  //get pop count object
  get get() {
    return this.badPopCountSubject.asObservable();
  }


  fetchbadPops(pop) {
    apis
      .getbadPops(pop)
      .then((response) => {
        this.badPopCountSubject.next(response)
        return response;;
      });
  }

  //reset popcount Subject objects state
  reset() {
    this.badPopCountSubject.next(initialStates.badPopCount);
  }
}

const BadPopCountService = (function () {
  var instance;

  function createInstance() {
    var classObj = new BadPopCount();
    return classObj;
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default BadPopCountService;
