import React from "react";
import { withTranslation } from "react-i18next";
import "./ClientIntegratons.css";

const StoreComponent = (props) => {
  return (
    
      <div className="storeField">
        <a href={props.storeUrl} style={{display:"flex",flexDirection:"row"}}>
          <img
            className="storeLogo"
            src={props.imgUrl}
            alt="appStore"
          />
          <div>
            <p className="downloadText">{props.downladText}</p>
            <p className="storeText">{props.storeName}</p>
          </div>
        </a>
      </div>

  );
};

export default withTranslation()(StoreComponent);
