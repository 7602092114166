import React from "react";
import { Switch, Route } from "react-router-dom";

import Whitepaper from "./Components/WhitePaperComponents/Whitepaper.jsx";
import SetupDashboardManuel from "./Pages/ClientIntegrations/SetupDashboardManuel.jsx";

const AppRouterWithoutLogin = (props) => {
  return (
    <Switch>
      <Route path="/info/whitepaper" component={Whitepaper} />
      <Route path="/info/integrationManuel" component={SetupDashboardManuel} />
    </Switch>
  );
};

export default AppRouterWithoutLogin;
