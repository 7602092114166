import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Tabs from "../../Components/HelperComponents/Tabs/Tabs";
import CustomerService from "../../Components/CustomerServiceComponents/CustomerService";
import Map from "../../Components/MapComponents/Map";
import Details from "../../Components/DetailsComponents/Details";
import Devices from "../../Components/Devices/Devices";
import DashboardKPI from "../../Components/DashboardKPI/DashboardKPI";
import Routers from "../../Components/RoutersComponents/Routers";
import Comdata from "../../Components/ComdataComponents/Comdata";
import Helpers from "../../Helpers/Helpers";

const EndUserAnalytics = (props) => {
  // const { t } = props;
  const activeTabDefault = Helpers.isCompanyISP()
    ? "customerBadExp"
    : "mapView";
  const [activeTab, setActiveTab] = useState(activeTabDefault);
  const tabs = Helpers.isCompanyISP()
    ? [
        "customerBadExp",
        "mapView",
        "customerDetails",
        "hardwareSoftwareAnalysis",
        "customerConnectionAnalytics",
      ]
    : [
        "mapView",
        "customerDetails",
        "hardwareSoftwareAnalysis",
        localStorage.getItem("companyId") === "e4cd672b-8b91-4c9e-9044-326efe32e2b6" || localStorage.getItem("companyId") === "c7be73e5-921d-4512-bd8f-434b430818e9" ? "Agent List" : console.log()
        //"wifiInfrastructre",
      ];
  const content = Helpers.isCompanyISP()
    ? [
        <CustomerService />,
        <Map />,
        <Details />,
        <Devices />,
        <DashboardKPI kpi={"ping"} timeMenu={true} />,
      ]
    : [<Map />, <Details />, <Devices />, <Comdata/>,  <Routers />];
  return (
    <Tabs
      tabs={tabs}
      defaultTabID={activeTab}
      activeTabCallback={setActiveTab}
      content={content}
    />
  );
};

export default withTranslation()(EndUserAnalytics);
