//Singleton services
import {
  ChannelNoiseListService,
  CustomerTraceService,
  NeighboorModemsService,
  PingService,
  RouterInfoService,
  SpeedTestService,
  StasInfoService,
  StreamingsService,
  TracesSankeyService,
  RecommendationService,
  AllHeatMapsService,
} from "../..";

const resetDetails = () => {
  //Singleton Objects
  var RouterInfoSubject = RouterInfoService.getInstance();
  var TracesSankeySubject = TracesSankeyService.getInstance();
  var CustomerTraceSubject = CustomerTraceService.getInstance();
  var StasInfoSubject = StasInfoService.getInstance();
  var StreamingsSubject = StreamingsService.getInstance();
  var ChannelNoiseListSubject = ChannelNoiseListService.getInstance();

  var PingSubject = PingService.getInstance();
  var SpeedTestSubject = SpeedTestService.getInstance();
  var NeighboorModemsSubject = NeighboorModemsService.getInstance();
  // var ChromeExtensionSubject = ChromeExtensionService.getInstance();
  var RecommendationSubject = RecommendationService.getInstance();
  var HeatMapAllSubject = AllHeatMapsService.getInstance();

  // Endpoint calls with modem mac and amb device id

  //reset router info data
  RouterInfoSubject.reset();

  TracesSankeySubject.reset();

  //reset stas info data
  StasInfoSubject.reset();

  //reset streaming data
  StreamingsSubject.reset();

  //reset channel noise list data
  ChannelNoiseListSubject.reset();

  //reset all heatmaps data
  HeatMapAllSubject.reset();
  
  //reset ping data
  PingSubject.reset();

  //reset speed test data
  SpeedTestSubject.reset();

  //reset Neighboor Modems data
  NeighboorModemsSubject.reset();

  //reset recommendation data
  RecommendationSubject.reset();

  

  // ChromeExtensionSubject.reset();

  CustomerTraceSubject.reset();
};

export default resetDetails;
