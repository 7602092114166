import React from "react";
import { withTranslation } from "react-i18next";
import Plot from "react-plotly.js";

// Observables
import {
  CustomerTraceService,
  DevicesService,
  ModemsService,
  TracesSankeyService,
} from "../../../Classes";
import initialStates from "../../../Classes/InitialStates/details.InitialStates";
import UseObservable from "../../../Helpers/UseObservable";
//Components
import TraceTable from "./TraceTable";
import DropdownFilter from "../../HelperComponents/DropdownFilter";

const style = {
  width: "95%",
  height: "87%",
  position: "none",
  marginRight: "10px",
  marginLeft: "10px",
};
const styleTrace = {
  width: "95%",
  height: "87%",
  position: "none",
  marginRight: "5px",
  marginLeft: "15px",
  marginTop: "15px",
};

const counts = [
  { value: 1, text: "1 Trace Record" },
  { value: 2, text: "2 Trace Record" },
  { value: 3, text: "3 Trace Record" },
  { value: 4, text: "4 Trace Record" },
  { value: 5, text: "5 Trace Record" },
  { value: 6, text: "6 Trace Record" },
  { value: 7, text: "7 Trace Record" },
  { value: 8, text: "8 Trace Record" },
  { value: 9, text: "9 Trace Record" },
  { value: 10, text: "10 Trace Record" },
  { value: 11, text: "11 Trace Record" },
  { value: 12, text: "12 Trace Record" },
  { value: 13, text: "13 Trace Record" },
  { value: 14, text: "14 Trace Record" },
  { value: 15, text: "15 Trace Record" },
  { value: 16, text: "16 Trace Record" },
  { value: 17, text: "17 Trace Record" },
  { value: 18, text: "18 Trace Record" },
  { value: 19, text: "19 Trace Record" },
  { value: 20, text: "20 Trace Record" },
];

const DetailsWiredNetwork = (props) => {
  const { t } = props;
  const { data: customerTrace } = UseObservable({
    observable: CustomerTraceService.getInstance().get,
    initialState: initialStates.customerTrace,
  });
  const { data: sankey } = UseObservable({
    observable: TracesSankeyService.getInstance().get,
    initialState: initialStates.tracesSankey,
  });

  //Get Observable States
  const { data: device } = UseObservable({
    observable: DevicesService.getInstance().get,
    initialState: initialStates.device,
  });

  const { data: modem } = UseObservable({
    observable: ModemsService.getInstance().get,
    initialState: initialStates.modem,
  });

  const countHandler = (count) => {
    TracesSankeyService.getInstance().reset();
    TracesSankeyService.getInstance().fetchData(
      device.selectedDevice.ambDeviceId,
      modem.selectedModem.modemMac,
      count
    );
  };

  return (
    <>
      {/*-------------- Wired Network - Traceroute --------------*/}
      <div className="col" id="detailsTrace">
        <div className="details-chart-title"> {t("trace")} </div>
        {modem.selectedModem.modemMac !== "" && (
          <DropdownFilter
            filterData={counts}
            setSelectedFilter={countHandler}
            selectedValue={5}
            style={{ float: "right", marginTop: "-40px" }}
          />
        )}

        <div style={style}>
          <Plot
            data={[
              {
                type: "sankey",
                orientation: "h",
                hoverinfo: "",
                node: {
                  label: sankey.label,
                  thickness: 15,
                  pad: 15,
                  color: "azure",
                }, // 10 Pixels

                link: {
                  source: sankey.source,
                  target: sankey.target,
                  value: sankey.value,
                  color: sankey.color,
                },
              },
            ]}
            layout={{
              title: sankey.label.length === 0 ? "NO DATA" : "",
              plot_bgcolor: "#182142",
              paper_bgcolor: "#182142",
              hoverdistance: 0,
              autosize: true,
              legend: { fontWeight: "300" },
            }}
            config={{ displayModeBar: false, responsive: true }}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>

      {/* -----------------Trace route table from sunburst   --------------------*/}
      <div className="col" id="detailsTraceTable">
        <div className="details-chart-title"> {t("tracetable")} </div>
        {/* <div className="row" id="timeTravelTraceTable"></div> */}
        <div style={styleTrace}>
          <TraceTable
            name="Trace Route Table"
            data={customerTrace.traceRouteTableData}
          />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(DetailsWiredNetwork);
