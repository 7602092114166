import React, { useState } from "react";
import { withTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import InputField from "../InputField.jsx";

import UseObservable from "../../../Helpers/UseObservable";
import initialStates from "../../../Classes/InitialStates/account.initialStates.js";
import { UserService } from "../../../Classes/index";

function usernameIsValid(username) {
  var validcharacters =
    "1234567890-_.abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

  for (var i = 0, l = username.length; i < l; ++i) {
    if (validcharacters.indexOf(username.substr(i, 1)) === -1) {
      return false;
    }
    return true;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  checked: {},
}));

const UserSettings = ({ t }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");

  const classes = useStyles();

  //Subjects
  const userSubject = UserService.getInstance();

  //Observables
  const { data: user } = UseObservable({
    observable: userSubject.get,
    initialState: initialStates.user,
  });

  function handleUserSubmit(event) {
    if (!usernameIsValid(firstName)) {
      alert(t("invalidFirstName"));
    } else if (!usernameIsValid(lastName)) {
      alert(t("invalidLastName"));
    } else {
      UserService.getInstance().updateUser({ firstName, lastName });
    }
    event.preventDefault();
  }
  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Paper className="accountPaper">
        <div className="accountUserSettings">
          <label className="accountSettingsHeader">{t("userSettings")}</label>
          <form
            id="accountForm"
            onSubmit={handleUserSubmit}
            className={classes.root}
            noValidate
            autoComplete="off"
          >
            <div className="accountUserSettingsContentArea">
              <InputField
                className="accountUserSettingsName"
                label={t("name")}
                setter={setFirstName}
              />
              <InputField
                className="accountUserSettingsSurname"
                label={t("surname")}
                setter={setlastName}
              />
              <div id="updateduser">{t(user?.isUpdated)}</div>
              <div style={{ marginTop: "20px", marginLeft: "30%" }}>
                <Button type="submit" variant="contained" color="secondary">
                  {t("save")}
                </Button>
              </div>
              {/* Redirects to keycloak to reset password */}
              <div style={{ marginTop: "20px" }}>
                <a href="https://backend.ambeent.ai/keycloak/realms/new_realm/account/password">
                  <p
                    style={{
                      color: "white",
                      fontSize: "16px",
                      textDecoration: "underline",
                    }}
                  >
                    {t("resetPassword")}
                  </p>
                </a>
              </div>
            </div>

            <div className="settingsSaveButon"></div>
          </form>
        </div>
      </Paper>
    </Grid>
  );
};

export default withTranslation()(UserSettings);
